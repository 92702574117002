<template>
  <v-dialog v-model="dialog" width="auto">
    <template #activator="{ on: onDialog, attrs: attrsDialog }">
      <v-tooltip left :disabled="!disabledTooltip">
        <template #activator="{ on: onTooltip }">
          <span v-on="onTooltip">
            <slot name="custom-button" v-bind="{ onClick: onDialog }">
              <v-btn
                color="error"
                small
                :disabled="disabledTooltip"
                v-bind="attrsDialog"
                v-on="onDialog"
                >delete</v-btn
              >
            </slot>
          </span>
        </template>
        <span> Delete camera(s) before delete kit. </span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title primary-title> Are you absolutely sure?</v-card-title>
      <v-divider />
      <ValidationObserver ref="form" v-slot="{ invalid, passes }">
        <v-card-text>
          <ERow>
            <ECol cols="12" class="mt-n6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Camera name"
                :rules="{
                  required: true,
                  equal_to: `${kitSummaryStore.kit.serial}`,
                }"
              >
                <v-text-field
                  v-model="verify"
                  type="text"
                  autocomplete="new-name"
                  :error-messages="errors"
                  label="Enter the kit'serial to delete"
                >
                  <template #label>
                    <div class="caption label-color">Kit serial</div>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </ECol>
          </ERow>
          <ERow>
            <ECol cols="12" class="my-n6">
              <ValidationProvider
                v-slot="{ errors }"
                name="This"
                rules="required"
              >
                <v-text-field
                  v-model="password"
                  type="password"
                  autocomplete="new-password"
                  :error-messages="errors"
                  label="Enter your current password"
                >
                  <template #label>
                    <div class="caption label-color">Password</div>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </ECol>
          </ERow>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(confirm)"
            >Yes, delete this kit</v-btn
          >
          <v-btn color="primary" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"

export default {
  name: "DeleteKitSummary",
  data() {
    return {
      password: "",
      verify: "",
      dialog: false,
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    disabledTooltip() {
      return this.kitSummaryStore.cameras.length === 0 ? false : true
    },
  },
  methods: {
    close() {
      this.$refs.form?.reset()
      this.dialog = false
      this.password = ""
      this.verify = ""
    },
    async confirm() {
      const payload = {
        password: this.password,
        verify: this.verify,
      }
      await this.kitSummaryStore.deleteKit(payload)
      this.close()
    },
  },
}
</script>
