<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="projects"
          :headers="headers"
          sort-by="inserted_at"
          :show-select="canDeleteProjects"
          :provider="projectsProvider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              v-if="canDeleteProjects"
              dark
              color="error"
              small
              @click="projectStore.deleteSelectedProjects"
            >
              Delete
            </v-btn>
          </template>
          <template #item.name="{ item, header }">
            <ETruncatedDiv :width="header.width">
              <NdaIcon
                v-if="isNDAProject(item)"
                :is-admin="accountStore.isAdmin"
              />
              <span
                class="cursor-pointer primary--text"
                @click="viewProjectDetails(item)"
              >
                {{ item.name }}
              </span>
            </ETruncatedDiv>
          </template>
          <template #item.status="{ item }">
            <span :class="getProjectStatusColor(item.status)">
              {{ getProjectStatusText(item.status) }}
            </span>
          </template>
          <template #item.zohoId="{ item }">
            <OpenInZohoButton
              v-if="item.zohoId"
              :zoho-id="item.zohoId"
              type="project"
            />
            <span v-else>-</span>
          </template>
          <template #item.demo="{ item }">
            <ImpersonateUserButton
              v-if="isNDAProject(item) && isConstructionProject(item)"
              :email="item.owner.email"
              :target-route="`/demos/projects/${item.exid}`"
              :nda-project="isNDAProject(item)"
              icon="fas fa-desktop"
            />
            <OpenInNewWindowButton
              v-else-if="isConstructionProject(item.exid)"
              :target-link="`/demos/projects/${item.exid}`"
              hint="Click to open as a demo"
              icon="fas fa-desktop"
            />
            <OpenInNewWindowButton
              v-else
              hint="Can't open non-construction projects as demo"
              icon="fas fa-desktop"
              :disabled="true"
            />
          </template>
          <template #item.timezone="{ item }">
            <v-edit-dialog
              v-if="canEditProjects"
              large
              :return-value.sync="item.timezone"
              @save="saveTimezone(item)"
            >
              {{ item.timezone }}
              <v-icon v-if="item.timezone" small color="primary"
                >fa-pencil-alt</v-icon
              >
              <v-icon v-else small color="primary">far fa-plus-square</v-icon>
              <template #input>
                <Autocomplete
                  v-model="item.timezone"
                  classes="mt-5"
                  :list-items="timezones"
                  label="Timezone"
                  vid="timezone"
                  item-text="name"
                  item-value="value"
                  :return-object="false"
                  :outlined="false"
                  hide-details
                />
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ item.timezone || "N/A" }}
            </span>
          </template>
          <template #item.cameras="{ item }">
            <CamerasColumn
              :item="item"
              :items-per-line="1"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </template>
          <template #item.insertedAt="{ item }">
            {{ formatDate(item.insertedAt) }}
          </template>
          <template #item.featureFlags="{ item }">
            <EReadMore :content="getFeatureFlags(item)" :max-length="2" />
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import header from "@/components/projects/projectsHeaders"
import filterFields from "@/components/projects/projectsSearchFilters"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import CamerasColumn from "@/components/CamerasColumn"
import ReportTable from "@/components/ReportTable"
import { Project } from "@evercam/shared/types/project"
import timezones from "@evercam/shared/constants/timezones"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ProjectUtils from "@/mixins/projectUtils"
import Vue from "vue"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import NdaIcon from "@/components/NdaIcon"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useAccountStore } from "@/stores/account"
import { ProjectFeatureFlags } from "@/components/constants"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.PROJECTS.VIEW],
  },
  components: {
    OpenInZohoButton,
    CamerasColumn,
    ReportTable,
    Autocomplete,
    OpenInNewWindowButton,
    ImpersonateUserButton,
    NdaIcon,
  },
  mixins: [ProjectUtils],
  data: () => {
    return {
      timezones: timezones,
      headers: [],
      filterFields,
    }
  },
  head() {
    return {
      title: "Admin - Projects",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore, useAccountStore),
    projectsProvider() {
      return AdminApi.projects.getAll
    },
    projectFeatureMap() {
      return ProjectFeatureFlags.reduce(
        (acc, feature) => ({ ...acc, [feature.value]: feature.name }),
        {}
      )
    },
    canDeleteProjects() {
      return this.$permissions.user.can(PERMISSIONS.PROJECTS.DELETE)
    },
    canEditProjects() {
      return this.$permissions.user.can(PERMISSIONS.PROJECTS.UPDATE)
    },
  },
  created() {
    this.headers = header(this)
  },
  methods: {
    isConstructionProject(project) {
      return project?.owner?.email === "construction@evercam.io"
    },
    getProjectLink(item: Project): string {
      return `/projects/${item.exid}`
    },
    saveTimezone(item) {
      this.projectStore.editedProject = item
      this.projectStore.editProject(this.projectStore.editedProject)
    },
    isNDAProject(item: Project): boolean {
      return item?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    getFeatureFlags(item) {
      return item.featureFlags.map(
        (feature) => this.projectFeatureMap[feature] || ""
      )
    },
    viewProjectDetails(item) {
      this.projectStore.editedProject = item
      this.projectStore.openDialog(item.exid)
    },
  },
})
</script>
