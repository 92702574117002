<template>
  <v-card flat>
    <v-simple-table class="map-table">
      <thead>
        <tr class="py-0">
          <th class="text-left py-0">
            <ERow>
              <div class="subtitle-2">Location</div>
              <div v-if="!readOnly">
                <v-btn
                  v-if="!isEditing"
                  text
                  x-small
                  color="primary"
                  @click="
                    () => {
                      isEditing = true
                      draggable = true
                    }
                  "
                >
                  <v-icon x-small> fas fa-pencil-alt </v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditing"
                  class="pl-2"
                  color="primary"
                  icon
                  x-small
                  @click="
                    () => {
                      isEditing = false
                      draggable = false
                      savePosition()
                    }
                  "
                >
                  <v-icon>fas fa-save</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditing"
                  class="pl-2"
                  color="primary"
                  icon
                  x-small
                  @click="
                    () => {
                      isEditing = false
                      draggable = false
                      resetMap()
                    }
                  "
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </div>
            </ERow>
          </th>
          <th class="text-right py-0">
            <v-select
              v-if="selectedMapView && !readOnly"
              v-model="selectedMapView"
              :items="mapViews"
              label="Map types"
              class="p-0 my-2"
              dense
              outlined
              item-text="name"
              hide-details
              return-object
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="px-0" colspan="2">
            <CameraMapFov
              v-if="markerReady"
              ref="map"
              :draggable="draggable"
              :is-editing="isEditing"
              :marker="marker"
              :height="340"
              :selectedMapView="selectedMapView"
              @marker-change="updateMarker"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script lang="ts">
import CameraMapFov from "@/components/CameraMapFov"
import {
  CameraStatus,
  CameraMapMarker,
  CameraUpdateRequestPayload,
} from "@evercam/shared/types/camera"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  name: "MapFov",
  components: {
    CameraMapFov,
  },
  props: {
    cameraId: {
      type: [Number, String],
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCamera: null,
      marker: null as CameraMapMarker | null,
      markerReady: false,
      isEditing: false,
      draggable: false,
      isOverlayImgLoading: false,
      selectedMapView: null,
      mapViews: [],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  watch: {
    cameraId: {
      immediate: true,
      async handler() {
        if (this.cameraId) {
          this.markerReady = false
          await this.cameraDialogStore.selectCamera(this.cameraId)

          this.selectedCamera = this.cameraDialogStore.camera

          this.initMarker()
          this.getMapViews()

          this.markerReady = true
        }
      },
    },
  },
  methods: {
    updateMarker(m: CameraMapMarker) {
      this.marker = m
    },
    initMarker() {
      this.marker = {
        isOnline: this.selectedCamera.status === CameraStatus.Online,
        location: this.selectedCamera.location,
        heading: this.selectedCamera.heading ?? 0,
        fovAngle: this.selectedCamera.fovAngle,
        fovRadius: this.selectedCamera.fovRadius,
      }
    },
    resetMap() {
      this.initMarker()
    },
    async savePosition() {
      let payload: CameraUpdateRequestPayload = {
        coordinates: [this.marker.location.lng, this.marker.location.lat],
        heading: this.marker.heading,
        fovAngle: this.marker.fovAngle,
        fovRadius: this.marker.fovRadius,
      }
      await AdminApi.cameras
        .update(this.cameraId, payload)
        .then(() => {
          this.$notifications.success("Position updated successfully.")
        })
        .catch((error) => {
          this.$notifications.error({
            text: "An error occurred while updating the position",
            error,
          })
        })
    },
    async getMapViews() {
      this.mapViews = [{ name: "None" }]
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/maps/${this.selectedCamera.projectExid}.json`
      )
      response = await response.json()
      for (const key in response) {
        this.mapViews.push(response[key])
      }

      if (this.mapViews.length > 0) {
        this.selectedMapView = this.mapViews[0]
      }
    },
  },
})
</script>
