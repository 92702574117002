import { KitStatuses } from "@/components/constants.js"
import { CountryId, PowerType } from "@evercam/shared/types"
import { decamelize } from "humps"

const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export default {
  name: {
    component: "TextFieldSearchFilter",
  },
  serial: {
    component: "TextFieldSearchFilter",
  },
  cameras: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...KitStatuses],
      "menu-props": { offsetY: true },
      isAutocomplete: true,
      value: "",
    },
  },
  countryId: {
    component: "SelectSearchFilter",
    attributes: {
      items: [
        { value: "", name: "All" },
        ...Object.values(CountryId)
          .map((name) => {
            if (typeof name === "string") {
              return {
                name: capitalize(decamelize(name, { separator: " " })),
                value: CountryId[name],
              }
            }
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
      ],
      "menu-props": { offsetY: true },
      isAutocomplete: true,
      value: "",
    },
  },
  powerType: {
    component: "SelectSearchFilter",
    attributes: {
      items: Object.entries(PowerType).map(([name, value]) => ({
        name,
        value,
      })),
      multiple: true,
      "menu-props": { offsetY: true },
      isAutocomplete: true,
    },
  },
}
