<template>
  <div v-if="!('hide_nav' in $route.query)">
    <v-app-bar :value="visibility" app color="grey lighten-2" dark height="45">
      <v-app-bar-nav-icon
        class="float-right grey--text text--darken-4"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      :key="layoutStore.isMiniSidebar"
      v-model="drawer"
      floating
      :mini-variant="layoutStore.isMiniSidebar"
      :mini-variant-width="$scssVars.sidebarWidthSm"
      class="the-sidebar-drawer grey darken-4 red--text"
      :width="$scssVars.sidebarWidth"
      app
      style="z-index: 11"
    >
      <v-toolbar-title style="width: 195px" class="ml-0 grey darken-3">
        <div class="cursor-pointer">
          <v-img
            :src="require('~/static/logo-circle.png')"
            alt="Evercam Admin"
            :eager="true"
            width="18px"
            height="18px"
            class="mt-3 ml-3 float-left"
            :class="[layoutStore.isMiniSidebar ? 'mr-5' : 'mr-1']"
            @click="toggleSidebar"
          />
          <nuxt-link to="/">
            <p class="float-left subtitle-1 mx-1 my-2 white--text">Admin</p>
          </nuxt-link>
        </div>
      </v-toolbar-title>
      <v-list dense expand>
        <TheSidebarList :list-items="items" />
      </v-list>

      <template #append>
        <v-list dense>
          <v-list-item class="pl-3">
            <v-list-item-action class="mr-1 my-0 d-flex justify-center">
              <v-icon small class="grey--text text--lighten-3">
                far fa-user-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text font-weight-medium text--lighten-3"
              >
                {{ accountStore.email }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pl-3" link @click="accountStore.logout">
            <v-list-item-action class="mr-1 my-0 d-flex justify-center">
              <v-icon small class="grey--text text--lighten-3">
                fa-sign-out-alt
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text font-weight-medium text--lighten-3"
              >
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import TheSidebarList from "@/components/TheSidebarList"
import { CameraStatus } from "@evercam/shared/types/camera"
import Vue from "vue"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { ListItem } from "@/components/TheSidebarList"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  name: "TheSidebar",
  components: {
    TheSidebarList,
  },
  data() {
    return {
      title: "Evercam",
      dialog: false,
      drawer: null,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useAccountStore, useLayoutStore),
    visibility() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)
    },

    items() {
      const items = [
        {
          icon: "fa-users-medical",
          text: "Access Control",
          requiredPermission: PERMISSIONS.ACCESS_CONTROL.VIEW_ROLES,
          subItems: [
            {
              href: "/authz/roles",
              icon: "fa-users-medical",
              text: "Roles",
              requiredPermission: PERMISSIONS.ACCESS_CONTROL.VIEW_ROLES,
            },
            {
              href: "/admins",
              icon: "far fa-user",
              text: "Admins",
              requiredPermission: PERMISSIONS.ACCESS_CONTROL.VIEW_ROLES,
            },
          ],
        },
        {
          href: "",
          icon: "far fa-user",
          text: "Platform",
          subItems: [
            {
              href: "/users",
              icon: "far fa-user",
              text: "Users",
              requiredPermission: PERMISSIONS.USERS.VIEW,
            },
            {
              href: "/share-requests",
              icon: "fa-users",
              text: "Pending users",
              requiredPermission: PERMISSIONS.USERS.VIEW,
            },
            {
              href: "/companies",
              icon: "far fa-building",
              text: "Companies",
              requiredPermission: PERMISSIONS.COMPANIES.VIEW,
            },
            {
              href: "/shares",
              icon: "fa-users",
              text: "Shares",
              requiredPermission: PERMISSIONS.USERS.VIEW_SHARES,
            },
            {
              href: "/audit-logs",
              icon: "fa-history",
              text: "Audit Logs",
              requiredPermission: PERMISSIONS.USERS.VIEW_AUDIT_LOGS,
            },
            {
              href: "/emailing",
              icon: "far fa-envelope",
              text: "Emailing",
              requiredPermission: PERMISSIONS.USERS.SEND_EMAILS,
            },
            {
              href: "/emailing/suppressions",
              icon: "far fa-mill-sign",
              text: "Email suppressions",
              requiredPermission: PERMISSIONS.USERS.SEND_EMAILS,
            },
          ],
        },
        {
          href: "/projects",
          icon: "fa-sitemap",
          text: "Projects",
          requiredPermission: PERMISSIONS.PROJECTS.VIEW,
        },
        {
          href: "",
          icon: "fa-camera",
          text: "Camera Kits",
          active: !this.layoutStore.isMiniSidebar,
          disabled: this.layoutStore.isMiniSidebar,
          requiredPermission: PERMISSIONS.CAMERAS.VIEW,
          subItems: [
            {
              href: "",
              icon: "fa-camera",
              text: "Camera Views",
              active: !this.layoutStore.isMiniSidebar,
              disabled: this.layoutStore.isMiniSidebar,
              requiredPermission: PERMISSIONS.CAMERAS.VIEW,
              subItems: [
                {
                  href: "/cameras",
                  text: "All",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: "/offline-cameras",
                  text: "Offline",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: "/offline-cameras?camera_owner=Construction%20Evercam&sort=last_online_at%7Cdesc&limit=50&page=1&battery_readings_last_voltage=<12000",
                  text: "Low Power",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: "/duplicate-cameras",
                  text: "Duplicate",
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: `/cameras?sort=created_at%7Cdesc&limit=50&page=1&statuses=${CameraStatus.Waiting}`,
                  text: "Waiting for activation",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: `/cameras?sort=created_at%7Cdesc&limit=50&page=1&statuses=${CameraStatus.Decommissioned}`,
                  text: "Decommissioned",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: `/cameras?sort=created_at%7Cdesc&limit=50&page=1&statuses=${CameraStatus.OnHold}`,
                  text: "On Hold",
                  reload: true,
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
                {
                  href: "/site-visit-cameras",
                  text: "Site Visit",
                  requiredPermission: PERMISSIONS.CAMERAS.VIEW,
                },
              ],
            },
            {
              href: "/kits",
              icon: "far fa-box",
              text: "Kits",
              requiredPermission: PERMISSIONS.KITS.VIEW,
            },
            {
              href: "/ex-nvr-health-check",
              icon: "far fa-hdd",
              text: "ExNVR Health-checks",
              requiredPermission: PERMISSIONS.NVRS.VIEW_HEALTHCHECKS,
            },
            {
              href: "/nvrs",
              icon: "far fa-hdd",
              text: "NVRs",
              requiredPermission: PERMISSIONS.NVRS.VIEW,
            },
            {
              href: "/routers",
              icon: "far fa-hdd",
              text: "Routers",
              requiredPermission: PERMISSIONS.ROUTERS.VIEW,
            },
            {
              href: "/sims",
              icon: "fa-sim-card",
              text: "SIMs",
              requiredPermission: PERMISSIONS.SIMS_AND_SMS.VIEW_SIMS,
            },
            {
              href: "/messages",
              icon: "far fa-comment-alt",
              text: "SMS Messages",
              requiredPermission: PERMISSIONS.SIMS_AND_SMS.VIEW_SMS,
            },
            {
              href: "/snapshot-extractions",
              icon: "fa-cloud",
              text: "Snapshot Extractions",
              requiredPermission: PERMISSIONS.CAMERAS.VIEW_EXTRACTIONS,
            },
          ],
        },
        {
          href: "/demos",
          icon: "fa-desktop",
          text: "Demos",
          requiredPermission: PERMISSIONS.DEMOS.VIEW,
        },
        {
          href: "/gate-report",
          icon: "fa-truck",
          text: "Gate report",
          requiredPermission: PERMISSIONS.GATE_REPORT.VIEW,
        },
        {
          href: "",
          icon: "fas fa-arrows-alt-h",
          text: "Bim Compares",
          requiredPermission: PERMISSIONS.BIM.VIEW_COMPARES,
          subItems: [
            {
              href: "/bim-compare",
              icon: "fas fa-arrows-alt-h",
              text: "Bim Compares List",
              requiredPermission: PERMISSIONS.BIM.VIEW_COMPARES,
            },
            {
              href: "/bim-uploader",
              text: "Bim Uploader",
              icon: "fa-cloud-upload-alt",
              requiredPermission: PERMISSIONS.BIM.UPLOAD,
            },
          ],
        },
        {
          href: "/360-view",
          icon: "fa-panorama",
          text: "360 Views",
          requiredPermission: PERMISSIONS.VIEW_360.VIEW,
        },
        {
          href: "/site-view",
          icon: "fa-building",
          text: "Site Views",
          requiredPermission: PERMISSIONS.SITE_VIEWS.VIEW,
        },
        {
          href: "/map-view",
          icon: "fa-map",
          text: "Map Views",
          requiredPermission: PERMISSIONS.MAP_VIEWS.VIEW,
        },
        {
          href: "/ingest",
          icon: "fa-person-walking-arrow-right",
          text: "Ingest",
          requiredPermission: PERMISSIONS.INGEST.VIEW,
        },
        {
          href: "/media-hub",
          text: "Media Hub",
          icon: "fas fa-archive",
          requiredPermission: PERMISSIONS.MEDIA_HUB.VIEW,
        },
        {
          href: "",
          icon: "far fa-paper-plane",
          text: "Progress Photos",
          requiredPermission: PERMISSIONS.PROGRESS_PHOTOS.VIEW,
          subItems: [
            {
              href: "/progress-photos",
              icon: "far fa-paper-plane",
              text: "Progress Photos list",
              requiredPermission: PERMISSIONS.PROGRESS_PHOTOS.VIEW,
            },
            {
              href: "/progress-photos-history",
              icon: "far fa-paper-plane",
              text: "Progress Photos Logs",
              requiredPermission: PERMISSIONS.PROGRESS_PHOTOS.VIEW_LOGS,
            },
          ],
        },
        {
          href: "/pa",
          icon: "fa-line-chart",
          text: "Product Analytics",
          requiredPermission: PERMISSIONS.PRODUCT_ANALYTICS.VIEW,
        },
        {
          href: "/copilot",
          icon: "fa-robot",
          text: "Copilot",
          requiredPermission: PERMISSIONS.COPILOT.VIEW,
        },
        {
          href: "/site-analytics",
          icon: "fa-chart-simple",
          text: "Site Analytics",
          requiredPermission: PERMISSIONS.SITE_ANALYTICS.VIEW,
        },
      ]

      return this.filterNavigationItems(items) as ListItem
    },
  },
  created() {
    if ("hide_nav" in this.$route.query) {
      this.cameraDialogStore.hideSidebar = true
    }
  },
  mounted() {
    this.accountStore.fetchPermissions()
  },
  methods: {
    toggleSidebar() {
      this.layoutStore.isMiniSidebar = !this.layoutStore.isMiniSidebar
      this.cameraDialogStore.tabFullScreen = this.layoutStore.isMiniSidebar
    },
    shouldHideItem(item: ListItem): boolean {
      const hasValidLink = item.href
      const hasSubItems = item.subItems?.length > 0
      const isPermissionDenied =
        hasValidLink &&
        item.requiredPermission &&
        !this.$permissions.user.can(item.requiredPermission)
      const allSubItemsHidden =
        hasSubItems && item.subItems.every(this.shouldHideItem)

      return (
        (!hasValidLink && !hasSubItems) ||
        isPermissionDenied ||
        allSubItemsHidden
      )
    },
    filterNavigationItems(items: ListItem[]): ListItem[] {
      return items.reduce((acc: ListItem[], item) => {
        const cleanedItem = { ...item }

        if (item.subItems && item.subItems.length > 0) {
          cleanedItem.subItems = this.filterNavigationItems(item.subItems)
        }

        if (!this.shouldHideItem(cleanedItem)) {
          acc.push(cleanedItem)
        }

        return acc
      }, [])
    },
    getFilteredItems(): ListItem[] {
      return this.filterNavigationItems(this.items)
    },
  },
})
</script>

<style>
.navigation-list-group .v-list-group__header {
  min-height: 25px !important;
}
</style>

<style scoped>
.active-item {
  background-color: #616161;
}
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 25px !important;
}
</style>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";

.the-sidebar-drawer .v-navigation-drawer__content {
  @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
}
</style>
