<template>
  <v-dialog v-model="isDialogOpen" max-width="600">
    <v-card>
      <v-card-title>{{ resource ? "Edit" : "Add" }} Resource</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="resourceName"
          label="Resource Name"
          hide-details
          dense
          outlined
          required
        ></v-text-field>
        <div class="pb-3">
          <v-text-field
            v-for="(permission, index) in permissions"
            :key="index"
            v-model="permissions[index]"
            outlined
            label="Permission"
            hide-details
            dense
            class="mt-2"
          ></v-text-field>
          <v-btn class="mt-3" text small color="primary" @click="addPermission">
            + Add Permission
          </v-btn>
        </div>
        <v-textarea
          v-model="description"
          label="Description (Optional)"
          hide-details
          dense
          outlined
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="saveResource">Save</v-btn>
        <v-btn small color="primary" text @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthzApi } from "@evercam/shared/api/authzApi"

export default {
  props: {
    value: Boolean,
    resource: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isDialogOpen: this.value,
      resourceName: "",
      permissions: [],
      description: "",
    }
  },
  watch: {
    value(val) {
      this.isDialogOpen = val
    },
    isDialogOpen(val) {
      this.$emit("input", val)
    },
    resource(val) {
      if (val) {
        this.resourceName = val.name
        this.permissions = val.permissions
        this.description = val.description
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false)
    },
    addPermission() {
      this.permissions.push("")
    },
    async saveResource() {
      if (!this.resourceName.trim()) {
        return
      }
      const payload = {
        name: this.resourceName,
        permissions: this.permissions.filter((p) => p.trim()),
        description: this.description,
      }
      try {
        if (this.resource) {
          await AuthzApi.scopes.update(this.resource.id, payload)
          this.$notifications.success("Resource updated successfully")
        } else {
          await AuthzApi.scopes.create(payload)
          this.$notifications.success("Resource added successfully")
        }
        this.$emit("resource-added")
      } catch (error) {
        this.$notifications.error(
          `Failed to ${this.resource ? "update" : "add"} resource`
        )
        console.error(error)
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
