<template>
  <v-container fluid class="logs pa-0 ma-0 overflow">
    <ERow>
      <ECol class="logs__sidebar py-0 pr-0 flex-shrink-0" cols="auto">
        <v-tabs
          :vertical="$vuetify.breakpoint.mdAndUp"
          :show-arrows="$vuetify.breakpoint.smAndDown"
          class="logs__sidebar__tabs custom-tabs"
          active-class="blue lighten-5"
        >
          <v-tab
            :to="`/gate-report/${$route.params.exid}/logs-history/verified-days`"
            nuxt
          >
            Verified Days
          </v-tab>
        </v-tabs>
      </ECol>
      <ECol class="pa-0 overflow-y-auto flex-grow-1">
        <NuxtChild />
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.VIEW_LOGS],
  },
  async asyncData({ route, params, redirect }) {
    if (route.name === "gate-report-exid-logs-history") {
      return redirect(`/gate-report/${params.exid}/logs-history/verified-days`)
    }
  },
}
</script>

<style lang="scss">
.logs {
  &__sidebar {
    width: auto !important;
    &__tabs {
      overflow-y: auto;
      max-height: calc(100vh - 82px);
      height: calc(100vh - 82px);

      & > .v-tabs-bar {
        border-right: 1px solid #e0e0e0;
        box-shadow: 5px 0px 11px -12px #000000b5;
        z-index: 1;
      }
      .v-tab {
        text-transform: none;
      }
    }
  }
}
</style>
