<template>
  <ERow>
    <ECol cols="12" sm="12" class="pt-2 pb-0 px-0">
      <v-date-picker
        ref="datepickerref"
        v-model="selectedDay"
        :allowed-dates="isDateAvailable"
        :picker-date.sync="dateCursor"
        :events="dailyEvents"
        :event-color="getEventColor"
        :show-current="false"
        no-title
        :value="selectedDay"
        color="primary"
        full-width
        :min="minDate"
        :max="maxDate"
        class="datepicker"
        :class="{ 'is-admin': isAdmin }"
        data-test-id="date-picker"
        @click:month="onClickMonth"
        @click:year="onClickYear"
      >
        <div
          v-if="isLoadingDays"
          class="datepicker__overlay position-absolute z-index-1 h-100 w-100 d-flex justify-center align-center"
        >
          <EvercamLoadingAnimation size="FiveXl" />
        </div>
      </v-date-picker>
      <v-divider></v-divider>
    </ECol>
    <ECol cols="12" sm="12" class="pt-0 pb-0 pr-2 pl-2">
      <HourPicker
        :time-picker="selectedHour"
        :date="selectedDay"
        :allowed-hours="availableHours"
        :loading-hours="isLoadingHours || isGateEventsLoading"
        :events="hourlyEvents"
        data-test-id="hour-picker"
        dense
        @change="(h) => (selectedHour = h)"
      />
      <v-divider></v-divider>
    </ECol>
    <ECol cols="12" sm="12" class="pt-0 pb-0 pr-0 pl-2">
      <div class="d-flex justify-space-between pt-2">
        <div>
          <v-btn
            text
            color="primary"
            width="100%"
            data-test-id="rec-first-btn"
            :disabled="disableOldest"
            @click="selectFirstFrame"
          >
            <v-icon size="15"> fas fa-chevron-left </v-icon>
            <v-icon size="15" class="pr-2"> fas fa-chevron-left </v-icon>
            {{ $t("title.first_snapshot") }}
          </v-btn>
        </div>
        <div>
          <v-btn
            text
            color="primary"
            width="100%"
            data-test-id="rec-first-btn"
            :disabled="disableLatest"
            @click="selectLastFrame"
          >
            {{ $t("title.last_snapshot") }}
            <v-icon size="15" class="pl-2"> fas fa-chevron-right </v-icon>
            <v-icon size="15"> fas fa-chevron-right </v-icon>
          </v-btn>
        </div>
      </div>
    </ECol>
  </ERow>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import HourPicker from "@evercam/shared/components/HourPicker"

export default {
  name: "DateTimePicker",
  components: {
    HourPicker,
    EvercamLoadingAnimation,
  },
  props: {
    value: {
      type: [String, Date],
      default: () => new Date().toISOString(),
    },
    isLoadingDays: {
      type: Boolean,
      default: false,
    },
    isLoadingHours: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: [Date, String, Number],
      default: () => new Date().toISOString(),
    },
    minDate: {
      type: [Date, String, Number],
      default: () => new Date(0).toISOString(),
    },
    availableDays: {
      type: Array,
      default: () => [],
    },
    availableHours: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    disableOldest: {
      type: Boolean,
      default: false,
    },
    disableLatest: {
      type: Boolean,
      default: false,
    },
    dailyEvents: {
      type: [Array, Function],
      default: () => [],
    },
    hourlyEvents: {
      type: [Array],
      default: () => [],
    },
    eventColor: {
      type: String,
      default: "#428dd7AA",
    },
  },
  data() {
    return {
      dateCursor: null,
      isGateEventsLoading: false,
    }
  },
  computed: {
    selectedDay: {
      get() {
        if (!this.value) {
          return
        }

        return this.$moment.tz(this.value, this.timezone).format("YYYY-MM-DD")
      },
      set(date) {
        const newDate = this.$moment.tz(date, this.timezone)
        const newValue = this.$moment
          .tz(this.value, this.timezone)
          .set({
            year: newDate.year(),
            month: newDate.month(),
            date: newDate.date(),
          })
          .format()

        this.$emit("input", newValue)
      },
    },
    selectedHour: {
      get() {
        return this.$moment.tz(this.value, this.timezone).format("H")
      },
      set(hour) {
        if (!hour) {
          return
        }
        const newValue = this.$moment
          .tz(this.value, this.timezone)
          .set({ hour: hour ? `${hour}`.split(":")[0] : 0 })
          .startOf("hour")
          .format()

        this.$emit("input", newValue)
        this.$emit("hour-change", newValue)
      },
    },
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
  watch: {
    selectedDay: {
      handler(date) {
        this.$emit("day-change", date)
      },
      immediate: true,
    },
    async dateCursor(date) {
      const isMonthView =
        this.$moment(date, "YYYY-MM").format("YYYY-MM") === date

      if (!isMonthView) {
        return
      }

      this.$emit("month-change", date)
    },
    availableHours(hours) {
      if (!hours.length) {
        this.selectedHour = ""

        return
      }
      if (!hours.includes(Number.parseInt(this.selectedHour))) {
        this.selectedHour = hours.slice(-1)[0]
      }
    },
    value(selectedTimestamp) {
      this.$emit("change", selectedTimestamp)
      const month = this.$moment
        .tz(selectedTimestamp, this.timezone)
        .format("YYYY-MM")
      if (this.dateCursor !== month) {
        this.dateCursor = month
      }
    },
  },
  created() {
    this.$root.$on(
      "is-gate-events-loading",
      (value) => (this.isGateEventsLoading = value)
    )
  },
  methods: {
    isDateAvailable(date) {
      if (this.isLoadingDays) {
        return true
      }

      return this.availableDays?.includes(
        Number.parseInt(this.$moment.tz(date, this.timezone).format("D"))
      )
    },
    onClickMonth(month) {
      this.$emit("month-year-click", { month })
    },
    onClickYear(year) {
      this.$emit("month-year-click", { year })
    },
    selectFirstFrame() {
      this.$emit("select-oldest")
    },
    selectLastFrame() {
      this.$emit("select-latest")
    },
    getEventColor(date) {
      let day = this.$moment(date).format("DD")

      return this.availableDays.includes(parseInt(day)) ? this.eventColor : ""
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_colors";
@import "~vuetify/src/styles/settings/_light";

.datepicker {
  &__overlay {
    top: 0;
    background: var(--v-background-base);
  }
  .v-date-picker-table--date .v-date-picker-table__events {
    bottom: 7px;
    & > div {
      height: 6px;
      width: 6px;
    }
  }
  &:not(.is-admin) {
    .theme--dark.v-picker__body {
      background: var(--v-background-base);
    }
    .v-date-picker-table .v-btn.v-btn--active {
      color: var(--v-on_primary-base);
    }
    .theme--dark.v-btn.v-btn--disabled {
      color: var(--v-surface-base) !important;
    }
    .theme--light.v-btn.v-btn--disabled {
      color: map-deep-get($material-light, "buttons", "disabled") !important;
    }
  }
}
</style>
