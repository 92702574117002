<template>
  <div>
    <div v-if="item.entity == 'cameras'">
      Camera
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.exid || item.details.camera.exid)"
      >
        {{ item.details.name || item.details.camera.name }}
      </span>
      deleted.
    </div>
    <div v-if="item.entity == 'archives'">
      {{ getArchiveName(item.details.type) }} deleted for
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.camera.exid)"
      >
        {{ item.details.camera.name }} </span
      >.
    </div>
    <div v-if="item.details.router">
      Router
      <span class="sim-hover primary--text">
        {{ item.details.router.serialNumber }}
      </span>
      deleted.
    </div>
    <div v-if="item.details.sim">
      Sim
      <span class="sim-hover primary--text">
        {{ item.details.sim.number }}
      </span>
      deleted.
    </div>
    <div v-if="item.details.project">
      Project {{ item.details.project.name }} deleted.
    </div>
    <div v-if="item.details.user">
      User
      <span class="sim-hover primary--text">
        {{ item.details.user.firstname }}
        {{ item.details.user.lastname }}
      </span>
      deleted.
    </div>
    <div v-if="item.entity == 'layers' && item.details">
      User <span class="sim-hover primary--text"> {{ item.who }} </span> deleted
      layer {{ item.details.name }}
      <span v-if="item.details.displayName">
        :{{ item.details.displayName }}
      </span>
      , id: {{ item.details.layerId }}
    </div>
    <div v-if="item.entity == 'roles'">
      Role
      <span class="sim-hover primary--text"> {{ item.details.name }} </span>
      deleted.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
    getArchiveName(type) {
      return type.charAt(0).toUpperCase() + type.slice(1)
    },
  },
}
</script>
