<template>
  <div v-resize-observer="handleRolesContainerResize" class="pt-4 h-100 w-100">
    <div class="w-100 mb-3 d-flex justify-end">
      <v-btn
        v-if="
          $permissions.user.can(PERMISSIONS.ACCESS_CONTROL.UPDATE_PERMISSIONS)
        "
        class="mr-2"
        small
        color="primary"
        @click="openAddResourceDialog"
      >
        <v-icon class="mr-2">fa fa-plus</v-icon> Add resource
      </v-btn>
      <v-btn
        v-if="$permissions.user.can(PERMISSIONS.ACCESS_CONTROL.CREATE_ROLE)"
        class="mr-2"
        small
        color="primary"
        @click="openAddRoleDialog"
      >
        <v-icon class="mr-2">fa fa-plus</v-icon> Add role
      </v-btn>
    </div>

    <div ref="rolesContainer">
      <RolesPermissionsTable
        :roles="roles"
        :resources="resources"
        :height="height"
        :can-update-permissions="canUpdatePermissions"
        @save-changes="handleSaveChanges"
        @rename-or-delete="initializeParentStates"
      />
    </div>

    <AddRoleDialog
      v-model="isAddRoleDialogOpen"
      @role-added="initializeParentStates"
    />

    <AddResourceDialog
      v-model="isAddResourceDialogOpen"
      @resource-added="initializeParentStates"
    />

    <ReviewChangesDialog
      v-model="isReviewChangesDialogOpen"
      :roles="roles"
      :modified-roles="modifiedRoles"
      @confirm-changes="confirmChanges"
    />
  </div>
</template>

<script>
import { PERMISSIONS } from "@/constants/permissions"
import { AuthzApi } from "@evercam/shared/api/authzApi"
import AddRoleDialog from "@/components/authz/AddRoleDialog.vue"
import AddResourceDialog from "@/components/authz/AddResourceDialog.vue"
import ReviewChangesDialog from "@/components/authz/ReviewChangesDialog.vue"
import RolesPermissionsTable from "@/components/authz/RolesPermissionsTable.vue"
import { PERMANENT_ROLES } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.ACCESS_CONTROL.VIEW_ROLES],
  },
  components: {
    AddRoleDialog,
    AddResourceDialog,
    ReviewChangesDialog,
    RolesPermissionsTable,
  },
  data() {
    return {
      isAddRoleDialogOpen: false,
      isAddResourceDialogOpen: false,
      isReviewChangesDialogOpen: false,
      roles: [],
      height: "100%",
      resources: [],
      PERMISSIONS,
      modifiedRoles: [],
    }
  },
  computed: {
    canUpdatePermissions() {
      return this.$permissions.user.can(
        PERMISSIONS.ACCESS_CONTROL.UPDATE_PERMISSIONS
      )
    },
  },
  mounted() {
    this.initializeParentStates()
  },
  methods: {
    async initializeParentStates() {
      await this.fetchRoles()
      await this.fetchResources()
    },
    async fetchRoles() {
      try {
        const rolesResponse = await AuthzApi.roles.get({ scope: "admin" })
        this.roles = rolesResponse.items.map((role) => {
          if (role.id == PERMANENT_ROLES.SUPER_ADMIN) {
            return {
              ...role,
              permissions: role.permissions.filter(
                (perm) => perm !== "app-super-admin"
              ),
            }
          }

          return role
        })
      } catch (error) {
        console.error("Error fetching roles:", error)
      }
    },
    async fetchResources() {
      try {
        const resourcesResponse = await AuthzApi.scopes.get()

        this.resources = resourcesResponse.map((resource) => ({
          name: resource.name,
          description: resource.description || "",
          exid: resource.exid,
          id: resource.id,
          permissions: (resource.permissions || []).map((perm) => ({
            name: perm.action,
            exid: perm.exid,
            permissions: this.roles.reduce((acc, role) => {
              acc[role.name] = role.permissions.includes(perm.exid)

              return acc
            }, {}),
          })),
        }))
      } catch (error) {
        console.error("Error fetching resources:", error)
      }
    },
    openAddRoleDialog() {
      this.isAddRoleDialogOpen = true
    },
    openAddResourceDialog() {
      this.isAddResourceDialogOpen = true
    },
    handleRolesContainerResize() {
      const container = this.$refs.rolesContainer
      this.height =
        window.innerHeight - container.getBoundingClientRect().top - 70
    },
    async handleSaveChanges(updatedRoles) {
      this.modifiedRoles = updatedRoles
      this.openReviewChangesDialog()
    },
    openReviewChangesDialog() {
      this.isReviewChangesDialogOpen = true
    },
    async confirmChanges(changedRoles) {
      try {
        const updatePromises = changedRoles.map((role) =>
          this.updateRolePermissions(role.id, role.name, role.permissions)
        )

        await Promise.all(updatePromises)

        this.$notifications.success("Roles updated successfully")
      } catch (error) {
        this.$notifications.error(
          "Failed to update roles. Please try again later."
        )
        console.error("Error updating roles:", error)
      } finally {
        this.isReviewChangesDialogOpen = false
        this.initializeParentStates()
      }
    },
    async updateRolePermissions(roleId, name, permissions) {
      try {
        await AuthzApi.roles.updateRole(roleId, { name, permissions })
      } catch (error) {
        console.error(`Error updating role "${name}" permissions:`, error)
        throw error
      }
    },
  },
}
</script>

<style lang="scss">
.expandable-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.roles-table {
  i {
    font-size: 1.3rem !important;
  }

  table-layout: fixed;
  th > span {
    white-space: nowrap;
  }
  .v-input__slot {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
</style>
