<template>
  <div>
    <SummaryElement
      v-if="exNvrDeviceField"
      v-model="exNvrDeviceField"
      is-copyable
    >
      <template #value-text>
        <ETruncatedDiv :width="200">
          <span class="cursor-pointer primary--text">
            {{ selectedExNvrDevice ? selectedExNvrDevice.id : "" }}
          </span>
        </ETruncatedDiv>
      </template>
    </SummaryElement>
    <SummaryElement v-else read-only label="Device ID">
      <ETruncatedDiv :width="200">
        <EvercamLoadingAnimation v-if="isLoading" size="ThreeXl" />
        <span v-else class="cursor-pointer primary--text">
          {{ selectedExNvrDevice.id ? selectedExNvrDevice.id : "-" }}
        </span>
      </ETruncatedDiv>
    </SummaryElement>
  </div>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { NvrModel } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "CameraSummaryNvrDeviceId",
  components: {
    SummaryElement,
    EvercamLoadingAnimation,
  },
  props: {
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      exNvrDevicesList: [],
      exNvrDeviceField: null,
      selectedExNvrDevice: [],
      isLoading: false,
      apiUrl: "",
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  watch: {
    camera: {
      immediate: true,
      handler(val, oldVal) {
        if (val?.exid === oldVal?.exid) {
          return
        }
        if (this.camera.nvrModel === NvrModel.ExNvr) {
          this.getExNvrToken()
        }
      },
    },
    "cameraDialogStore.isNvrHttpPortOpen": {
      immediate: true,
      handler() {
        if (!this.cameraDialogStore.isCheckingNvrHttpPortStatus) {
          this.getExNvrToken()
        }
      },
    },
    "cameraDialogStore.isCheckingNvrHttpPortStatus": {
      immediate: true,
      handler(value) {
        if (!value) {
          this.getExNvrToken()
          if (!this.cameraDialogStore.isNvrHttpPortOpen) {
            this.isLoading = false
          }
        }
      },
    },
    "cameraDialogStore.exNvrToken": {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchExNvrDevicesList()
        }
      },
    },
  },
  methods: {
    async getExNvrToken() {
      if (
        this.cameraDialogStore.isCheckingNvrHttpPortStatus ||
        !this.cameraDialogStore.isNvrHttpPortOpen ||
        this.camera.nvrModel !== NvrModel.ExNvr
      ) {
        return
      }
      this.isLoading = true
      this.apiUrl = `${this.camera.nvrScheme}://${this.camera.nvrHost}:${this.camera.nvrHttpPort}`

      try {
        await this.cameraDialogStore.getExNVRToken({
          cameraExid: this.camera.exid,
          token: this.accountStore.token,
        })
      } catch (error) {
        this.loginToExNvr()
      }
    },
    async loginToExNvr() {
      if (
        !this.camera.nvrScheme &&
        !this.camera.nvrHost &&
        !this.camera.nvrHttpPort &&
        !this.camera.nvrUsername &&
        !this.camera.nvrPassword &&
        !this.cameraDialogStore.isNvrHttpPortOpen
      ) {
        return
      }
      this.isLoading = true
      if (this.cameraDialogStore.exNvrToken) {
        return
      }
      try {
        await this.cameraDialogStore.loginToExNvr({
          apiUrl: this.apiUrl,
          username: this.camera.nvrUsername,
          password: this.camera.nvrPassword,
        })
      } catch (error) {
        this.$notifications.error({
          text: "EXNVR login failed",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    async fetchExNvrDevicesList() {
      if (
        !this.cameraDialogStore.exNvrToken ||
        this.exNvrDevicesList.length > 0
      ) {
        return
      }
      try {
        let response = await ExNvrApi.devices.getDevices({
          apiUrl: this.apiUrl,
          token: this.cameraDialogStore.exNvrToken,
        })
        this.exNvrDevicesList = response
        this.selectedExNvrDevice = this.exNvrDevicesList.find(
          ({ id }) => id === this.camera.nvrDeviceId
        )
        this.cameraDialogStore.isExNvrDeviceSelected =
          !!this.selectedExNvrDevice
        if (
          !this.selectedExNvrDevice &&
          this.exNvrDevicesList.length > 0 &&
          this.camera.nvrDeviceId
        ) {
          this.$notifications.error({
            text: "EXNVR device id doesn't match",
          })
        }
        this.exNvrDeviceField = {
          key: "deviceId",
          label: "Device ID",
          value: this.selectedExNvrDevice?.id,
          type: "SINGLE_SELECT",
          dataset: this.exNvrDevicesList.map(({ id }) => ({
            name: id,
            value: id,
          })),
        }
      } catch (error) {
        this.$notifications.error({
          text: "Failed to get ExNvr devices list",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
