import type {
  TimelineCountEvent,
  TimelineEvent,
  TimelineInterval,
} from "@evercam/ui"
import {
  type TimelineDateInterval,
  type TimelineProviderRequestParams,
  type CraneCount,
  type CraneLabel,
  type CameraExid,
  type DetectionsPresenceByLabel,
  SegmentLabel,
  type SegmentsPresenceByLabel,
  TimelinePrecision,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import moment from "moment-timezone"

export class TimelineSegmentsIntervalsProvider extends TimelineDataProvider {
  private static presenceIntervalsPromise: Promise<Array<CraneCount>> | null =
    null
  private static onIntervalsChange: (
    intervalsByLabel: DetectionsPresenceByLabel
  ) => void
  readonly cameraExid: CameraExid
  readonly labelFilterFn: (label: SegmentLabel) => boolean
  readonly groupedMode: boolean
  private fixedPrecision: TimelinePrecision | null
  private previousMode: boolean
  private previousInterval: TimelineDateInterval | null
  private previousResults: TimelineEvent[] | null

  constructor(params: {
    timezone: string
    cameraExid: CameraExid
    labelFilterFn: (label: CraneLabel) => boolean
    groupedMode: boolean
    fixedPrecision?: TimelinePrecision
  }) {
    super(params.timezone)
    this.cameraExid = params.cameraExid
    this.groupedMode = params.groupedMode
    this.fixedPrecision = params.fixedPrecision
    this.labelFilterFn = params.labelFilterFn || ((_) => true)
  }

  static registerIntervalsChangeCallback(
    callback: (intervalsByLabel: DetectionsPresenceByLabel) => void
  ) {
    TimelineSegmentsIntervalsProvider.onIntervalsChange = callback
  }

  async fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineCountEvent>> {
    return this.fetchEvents(params)
  }

  private async doFetchIntervals(
    params: TimelineProviderRequestParams
  ): Promise<SegmentsPresenceByLabel> {
    return await EvercamLabsApi.detections.getSegmentsDateIntervals({
      cameraExid: this.cameraExid,
      fromDate: params.fromDate,
      toDate: params.toDate,
      precision: this.fixedPrecision || params.precision,
    })
  }

  async fetchEvents(params: TimelineDateInterval): Promise<TimelineEvent[]> {
    if (
      this.previousInterval &&
      this.groupedMode === this.previousMode &&
      JSON.stringify(params) === JSON.stringify(this.previousInterval)
    ) {
      return this.previousResults
    }

    this.previousInterval = params
    this.previousMode = this.groupedMode
    if (!TimelineSegmentsIntervalsProvider.presenceIntervalsPromise) {
      TimelineSegmentsIntervalsProvider.presenceIntervalsPromise =
        this.doFetchIntervals(params).finally(() => {
          TimelineSegmentsIntervalsProvider.presenceIntervalsPromise = null
        })
    }

    const intervalsByLabel =
      await TimelineSegmentsIntervalsProvider.presenceIntervalsPromise

    if (TimelineSegmentsIntervalsProvider.onIntervalsChange) {
      TimelineSegmentsIntervalsProvider.onIntervalsChange(intervalsByLabel)
    }

    const results = this.groupedMode
      ? this.processGroupedIntervals(intervalsByLabel, params)
      : this.processSingleLabelIntervals(intervalsByLabel, params)

    this.previousResults = results

    return results
  }

  private processGroupedIntervals(
    intervalsByLabel: SegmentsPresenceByLabel,
    params: TimelineDateInterval
  ): TimelineEvent[] {
    const allIntervals = Object.values(intervalsByLabel).flat()

    if (allIntervals.length === 0) {
      return []
    }

    const sortedIntervals = this.sortIntervalsByDate(allIntervals)
    const mergedIntervals = this.mergeOverlappingIntervals(sortedIntervals)

    return this.convertIntervalsToEvents(mergedIntervals, params)
  }

  private processSingleLabelIntervals(
    intervalsByLabel: SegmentsPresenceByLabel,
    params: TimelineDateInterval
  ): TimelineEvent[] {
    const matchingLabel = Object.entries(intervalsByLabel).find(([label]) =>
      this.labelFilterFn(label)
    )

    if (!matchingLabel) {
      return []
    }

    return this.convertIntervalsToEvents(matchingLabel[1], params)
  }

  private sortIntervalsByDate(
    intervals: TimelineInterval[]
  ): TimelineInterval[] {
    return [...intervals].sort(
      (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime()
    )
  }

  private mergeOverlappingIntervals(
    sortedIntervals: TimelineInterval[]
  ): TimelineInterval[] {
    const merged: TimelineInterval[] = []
    let current = sortedIntervals[0]

    for (let i = 1; i < sortedIntervals.length; i++) {
      const next = sortedIntervals[i]
      if (new Date(next.fromDate) <= new Date(current.toDate)) {
        current.toDate =
          new Date(current.toDate) >= new Date(next.toDate)
            ? current.toDate
            : next.toDate
      } else {
        merged.push(current)
        current = next
      }
    }
    merged.push(current)

    return merged
  }

  private convertIntervalsToEvents(
    intervals: TimelineInterval[],
    params: TimelineDateInterval
  ): TimelineEvent[] {
    const precision = this.fixedPrecision || params.precision

    return intervals.map(({ fromDate, toDate }) => {
      let startDate = this.formatTimestamp(fromDate)
      let endDate = this.formatTimestamp(toDate)

      if (params.precision) {
        startDate = moment(startDate).startOf(precision).format()
        endDate = moment(endDate).endOf(precision).format()
      }

      return { startDate, endDate }
    })
  }
}
