<template>
  <v-breadcrumbs :items="items" class="py-3">
    <template #divider>
      <v-icon>fa-angle-right</v-icon>
    </template>
    <template #item="{ item }">
      <nuxt-link
        v-if="item.href"
        :to="item.href"
        class="d-flex align-center justify-center"
      >
        <v-icon v-if="item.icon" class="pr-2" color="primary">
          {{ item.icon }}
        </v-icon>
        <span class="subtitle-2">
          {{ item.name }}
          <span v-if="item.id" class="caption pl-1">({{ item.id }})</span>
          <ImpersonateUserButton
            v-if="item.impersonate && item.id"
            class="pl-2"
            :email="item.id"
            :target-route="item.impersonate"
            :tooltip="`Open In Dashboard`"
          />
        </span>
      </nuxt-link>

      <v-breadcrumbs-item v-else>
        <v-icon v-if="item.icon" class="pr-2 e-text-gray-600">
          {{ item.icon }}
        </v-icon>
        <span class="subtitle-2 e-text-gray-600">
          {{ item.name }}
          <span v-if="item.id" class="caption pl-1 e-text-gray-600"
            >({{ item.id }})</span
          >
        </span>
        <ImpersonateUserButton
          v-if="item.impersonate && item.id"
          class="pl-2"
          :email="item.id"
          :target-route="item.impersonate"
          :tooltip="`Open In Dashboard`"
        />
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
export default {
  name: "TheBreadcrumbs",
  components: {
    ImpersonateUserButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
