<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height class="login-view">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg3>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <v-img
                    src="/evercam_logo.png"
                    alt="Evercam Construction Cameras"
                    width="72%"
                    :eager="true"
                  />
                </div>
              </v-card-text>
              <v-card-text>
                <ValidationObserver v-slot="{ passes }">
                  <v-form ref="model" @submit.prevent="passes(preformLogin)">
                    <v-card-text>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="model.username"
                          :error-messages="errors"
                          :class="fixAutofill ? 'autofill' : ''"
                          class="caption"
                          label="Email"
                          name="email"
                          type="email"
                          @focus="fixAutofill = false"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required"
                      >
                        <v-text-field
                          id="password"
                          v-model="model.password"
                          :type="toggleValue ? 'text' : 'password'"
                          :error-messages="errors"
                          :class="fixAutofill ? 'autofill' : ''"
                          label="Password"
                          name="password"
                          class="caption pt-5"
                          @focus="fixAutofill = false"
                        >
                          <template slot="append">
                            <v-icon
                              v-if="toggleValue"
                              class="pt-1"
                              small
                              color="secondary"
                              @click="toggleIcon"
                            >
                              far fa-eye
                            </v-icon>
                            <v-icon
                              v-else
                              class="pt-1"
                              small
                              color="secondary"
                              @click="toggleIcon"
                            >
                              far fa-eye-slash
                            </v-icon>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        block
                        color="primary"
                        :loading="loading"
                        type="submit"
                      >
                        Sign in
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  meta: {
    requiredPermissions: [],
  },
  layout: "clean",
  middleware: [
    "unauth",
    ({ query, $keycloak }) => {
      const useKeycloak = query.keycloak == "true"
      if (useKeycloak && $keycloak) {
        $keycloak.redirectToSigninPage()
      }
    },
  ],
  data: () => ({
    toggleValue: false,
    loading: false,
    model: {
      username: "",
      password: "",
      from_admin: true,
    },
    fixAutofill: false,
  }),
  head() {
    return {
      title: "Admin - Login",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
      htmlAttrs: {
        class: "overflow-y-auto",
      },
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.detectAutofill()
  },
  methods: {
    async preformLogin() {
      this.accountStore.login(this.model)
    },
    detectAutofill() {
      let intervalDetectAutofill = setInterval(() => {
        if (
          document.querySelectorAll('input[type="password"]:-webkit-autofill')
            .length > 0
        ) {
          this.fixAutofill = true
          clearInterval(intervalDetectAutofill)
        }
      }, 100)

      this.$setTimeout(() => {
        if (intervalDetectAutofill) {
          clearInterval(intervalDetectAutofill)
          intervalDetectAutofill = null
        }
      }, 3000)
    },
    toggleIcon() {
      this.toggleValue = !this.toggleValue
    },
  },
}
</script>

<style scoped>
#login {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 1;
  height: 100%;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.login-view {
  background: url(https://evercam.io/wp-content/themes/evercam/img/home-banner-bg.jpg);
  background-position: center;
  background-size: cover;
}
</style>

<style>
.autofill.v-text-field--is-booted .v-label {
  transform: translateY(-18px) scale(0.75);
  pointer-events: auto;
}
</style>
