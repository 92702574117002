import { defineStore } from "pinia"
import {
  AdminCamera,
  CameraExid,
  ExNvrDeviceConfig,
  ExNvrLoginResponse,
  MilesightRequestParams,
  NvrModel,
  TaskStatus,
  ExNvrHealthCheckTaskId,
  ExNvrHealthCheckTaskResult,
  ExNvrHealthCheckTask,
  ExNvrRecordingInterval,
} from "@evercam/shared/types"
import { TimedRequest } from "@evercam/shared/api/client/axios"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import { AdminApi } from "@evercam/shared/api/adminApi"
import Vue from "vue"

interface ExNvrHealthCheckState {
  cameras: AdminCamera[]
  healthCheckTasksByExNvrUrl: Record<
    string,
    Array<
      ExNvrHealthCheckTask<
        | ExNvrHealthCheckTaskId.ExNvrLogin
        | ExNvrHealthCheckTaskId.ExNvrSystemStatus
      >
    >
  >
  healthCheckTasksByCameraExid: Record<
    CameraExid,
    Array<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
  >
}

export const useExNvrHealthCheckStore = defineStore({
  id: "exNvrHealthCheck",
  state: (): ExNvrHealthCheckState => ({
    cameras: [] as AdminCamera[],
    healthCheckTasksByCameraExid: {},
    healthCheckTasksByExNvrUrl: {},
  }),
  actions: {
    async init() {
      await this.fetchExNvrCameras()
      this.initTasks()
    },
    initTasks() {
      ;(this.cameras as AdminCamera[]).forEach(this.resetTasksForCamera)
    },
    resetTasksForCamera(camera: AdminCamera) {
      const taskIds = Object.values(ExNvrHealthCheckTaskId)
      const placeholderTasks = taskIds.map((taskId) => ({
        id: taskId,
        status: TaskStatus.Idle,
        error: null,
        duration: 0,
        result: null,
      }))

      Vue.set(this.healthCheckTasksByCameraExid, camera.exid, [
        ...placeholderTasks,
      ])

      const exNvrApiUrl = this.getExNvrApiUrl(camera)
      const exNvrPlaceholderTasks = [
        ExNvrHealthCheckTaskId.ExNvrLogin,
        ExNvrHealthCheckTaskId.ExNvrSystemStatus,
      ].map((taskId) => ({
        id: taskId,
        status: TaskStatus.Idle,
        error: null,
        duration: 0,
        result: null,
      }))
      Vue.set(this.healthCheckTasksByExNvrUrl, exNvrApiUrl, [
        ...exNvrPlaceholderTasks,
      ])
    },
    async performHealthCheckForCamera(
      cameraExid: CameraExid,
      onExNvrCheckComplete: (camera: AdminCamera) => void = this
        .fetchExNvrRecordings
    ) {
      const camera = this.getCameraByExid(cameraExid)
      this.resetTasksForCamera(camera)

      await Promise.all([
        this.loginToExNvr(camera).then(() => {
          this.fetchExNvrSystemStatus(camera)
          this.fetchExNvrDeviceConfig(camera).then(() => {
            onExNvrCheckComplete(camera)
          })
        }),
        this.fetchCameraSystemInfo(camera),
        this.fetchCameraTimeInfo(camera),
        this.fetchCameraStreamsInfo(camera),
        this.fetchCameraNetworkInfo(camera),
        this.fetchCameraOsdInfo(camera),
        this.fetchCameraStorageInfo(camera),
      ])
    },
    async performHealthCheckTask<T extends ExNvrHealthCheckTaskId>({
      cameraExid,
      taskId,
      resultProvider,
      exNvrApiUrl,
    }: {
      cameraExid?: CameraExid
      exNvrApiUrl?: string
      taskId: T
      resultProvider: () => TimedRequest<ExNvrHealthCheckTaskResult<T>>
    }) {
      let status = TaskStatus.Loading
      let err: Error | undefined
      let result: ExNvrHealthCheckTaskResult<T> | undefined
      let timeElapsed = 0

      this.updateTask({
        cameraExid,
        exNvrApiUrl,
        taskId,
        payload: {
          status,
        },
      })

      try {
        const { data, duration, error } = await resultProvider()
        if (error) {
          err = error
          status = TaskStatus.Error
        } else {
          result = data
          status = TaskStatus.Success
        }
        timeElapsed = duration
      } catch (e) {
        err = e as Error
        status = TaskStatus.Error
      } finally {
        this.updateTask({
          cameraExid,
          exNvrApiUrl,
          taskId,
          payload: {
            status,
            result,
            error: err,
            duration: timeElapsed,
          },
        })
      }
    },
    updateTask({
      cameraExid,
      exNvrApiUrl,
      taskId,
      payload,
    }: {
      cameraExid?: CameraExid
      exNvrApiUrl?: string
      taskId: ExNvrHealthCheckTaskId
      payload: Partial<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
    }) {
      if (cameraExid) {
        this._updateTask({
          targetId: cameraExid,
          targetTasks: this.healthCheckTasksByCameraExid,
          taskId,
          payload,
        })
      }

      if (exNvrApiUrl) {
        this._updateTask({
          targetId: exNvrApiUrl,
          targetTasks: this.healthCheckTasksByExNvrUrl,
          taskId,
          payload,
        })
      }
    },
    _updateTask({
      targetId,
      targetTasks,
      taskId,
      payload,
    }: {
      targetId?: CameraExid | string
      targetTasks?: Record<
        CameraExid,
        Array<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
      >
      taskId: ExNvrHealthCheckTaskId
      payload: Partial<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
    }) {
      const taskIndex = targetTasks[targetId].findIndex((t) => t.id === taskId)
      const task = targetTasks[targetId][taskIndex]

      Vue.set(targetTasks[targetId], taskIndex, {
        ...task,
        ...payload,
      })
    },
    async fetchExNvrCameras() {
      const { items } = await AdminApi.cameras.getCameras({
        params: {
          sort: "create_at|desc",
          nvrModel: NvrModel.ExNvr,
          limit: 1500,
          page: 1,
        },
      })
      this.cameras = items
    },
    async loginToExNvr(camera: AdminCamera) {
      const apiUrl = this.getExNvrApiUrl(camera)
      const existingTask =
        this.healthCheckTasksByExNvrUrl[apiUrl]?.[
          ExNvrHealthCheckTaskId.ExNvrLogin
        ]

      if (existingTask?.status === TaskStatus.Success) {
        this.healthCheckTasksByCameraExid[camera.exid] = {
          ...this.healthCheckTasksByCameraExid[camera.exid],
          [ExNvrHealthCheckTaskId.ExNvrLogin]: existingTask,
        }

        return
      }

      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        exNvrApiUrl: apiUrl,
        taskId: ExNvrHealthCheckTaskId.ExNvrLogin,
        resultProvider: () => {
          return ExNvrApi.users.login(
            {
              apiUrl,
              username: camera.nvrUsername,
              password: camera.nvrPassword,
            },
            {
              timing: true,
            }
          ) as unknown as Promise<TimedRequest<ExNvrLoginResponse>>
        },
      })
    },
    async fetchExNvrSystemStatus(camera: AdminCamera) {
      const apiUrl = this.getExNvrApiUrl(camera)
      const existingTask =
        this.healthCheckTasksByExNvrUrl[apiUrl]?.[
          ExNvrHealthCheckTaskId.ExNvrSystemStatus
        ]

      if (existingTask?.status === TaskStatus.Success) {
        this.healthCheckTasksByCameraExid[camera.exid] = {
          ...this.healthCheckTasksByCameraExid[camera.exid],
          [ExNvrHealthCheckTaskId.ExNvrSystemStatus]: existingTask,
        }

        return
      }

      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        exNvrApiUrl: apiUrl,
        taskId: ExNvrHealthCheckTaskId.ExNvrSystemStatus,
        resultProvider: () => {
          return ExNvrApi.devices.getSystemStatus(
            {
              apiUrl: this.getExNvrApiUrl(camera),
              token: this.getExNvrToken(camera),
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrDeviceConfig>
        },
      })
    },
    async fetchExNvrDeviceConfig(camera: AdminCamera) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: ExNvrHealthCheckTaskId.ExNvrConfigCheck,
        resultProvider: () => {
          return ExNvrApi.devices.getDeviceConfig(
            {
              deviceId: camera.nvrDeviceId,
              apiUrl: this.getExNvrApiUrl(camera),
              token: this.getExNvrToken(camera),
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrDeviceConfig>
        },
      })
    },
    async fetchExNvrRecordings(camera: AdminCamera) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: ExNvrHealthCheckTaskId.ExNvrRecordings,
        resultProvider: () => {
          return ExNvrApi.devices.getAvailableRecordings(
            {
              deviceId: camera.nvrDeviceId,
              apiUrl: this.getExNvrApiUrl(camera),
              token: this.getExNvrToken(camera),
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrRecordingInterval[]>
        },
      })
    },
    async performCameraTask<T extends ExNvrHealthCheckTaskResult<any>>(
      camera: AdminCamera,
      taskId: ExNvrHealthCheckTaskId,
      apiCall: (params: MilesightRequestParams, config: any) => Promise<T>
    ) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: taskId,
        resultProvider: () => {
          return apiCall(
            {
              host: camera.cameraHost,
              httpPort: camera.cameraHttpPort,
              username: camera.cameraUsername,
              password: camera.cameraPassword,
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<T>
        },
      })
    },
    async fetchCameraStreamsInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraStreamsConfigCheck,
        EvercamLabsApi.milesight.getVideoConfig
      )
    },
    async fetchCameraNetworkInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraNetworkConfigCheck,
        EvercamLabsApi.milesight.getNetworkConfiguration
      )
    },
    async fetchCameraSystemInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraSystemConfigCheck,
        EvercamLabsApi.milesight.getSystemSettings
      )
    },
    async fetchCameraTimeInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraTimeConfigCheck,
        EvercamLabsApi.milesight.getTimeSettings
      )
    },
    async fetchCameraOsdInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraOsdConfigCheck,
        EvercamLabsApi.milesight.getOsdConfiguration
      )
    },
    async fetchCameraStorageInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraStorageConfigCheck,
        EvercamLabsApi.milesight.getSdCardInfo
      )
    },
  },
  getters: {
    getCameraByExid: (state) => (exid: CameraExid) => {
      return state.cameras.find((c) => c.exid === exid) as AdminCamera
    },
    getExNvrApiUrl: () => (camera: AdminCamera) => {
      return `${camera.nvrScheme}://${camera.nvrHost}:${camera.nvrHttpPort}`
    },
    getExNvrToken: (state) => (camera: AdminCamera) => {
      const exNvrApiUrl = useExNvrHealthCheckStore().getExNvrApiUrl(camera)
      const exNvrLoginResponse = state.healthCheckTasksByExNvrUrl[
        exNvrApiUrl
      ]?.find((t) => t.id === ExNvrHealthCheckTaskId.ExNvrLogin)
        ?.result as ExNvrLoginResponse

      return exNvrLoginResponse?.accessToken
    },
  },
})
