<template>
  <div>
    <ERow
      v-if="gateReportStore.showControls"
      id="heatmap"
      no-gutters
      class="pt-2"
    >
      <ECol cols="12">
        <!-- Heatmap chart -->
        <EventsHeatmap
          class="d-flex justify-center flex-grow-1 w-100"
          :selected-date="gateReportStore.selectedDate"
          :items="items"
          :format-label="formatLabel"
          :is-date-disabled="isDateDisabled"
          @date-selected="onDateSelected"
        />
      </ECol>
    </ERow>

    <v-divider class="pb-2" :class="{ 'mt-1': gateReportStore.showControls }" />
    <!-- Filters / Action buttons -->
    <ERow
      id="filters"
      class="d-flex align-center mx-2 mb-0 mt-0 justify-start"
      :class="{
        'flex-nowrap': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <!-- Filters summary -->
      <ECol class="pt-0 pb-0 pr-0">
        <EventsFiltersSummary />
      </ECol>

      <ECol class="pa-0" cols="3">
        <ERow no-gutters class="mb-1">
          <ECol class="pa-0 pr-1">
            <OpenInDashGateReportBtn
              v-if="showImpersonation"
              :date="gateReportStore.selectedDate"
              class="w-100"
            />
          </ECol>

          <!-- Events validation button -->
          <ECol class="pa-0 pr-1">
            <v-btn
              v-if="showValidation"
              color="primary"
              small
              block
              :disabled="isEventsValidationDisabled"
              @click="
                gateReportEventsValidationStore.eventsValidationDialog = true
              "
            >
              Events validation
              <v-icon class="ml-2">fa-photo-video</v-icon>
            </v-btn>
          </ECol>
        </ERow>

        <ERow no-gutters>
          <ECol class="pr-1 pt-0">
            <MarkDayAsVerifiedButton
              v-if="showVerification"
              :full-width="true"
              :selected-date="gateReportStore.selectedDate"
              @verify-day="gateReportStore.verifyDay"
            />
          </ECol>
        </ERow>
      </ECol>

      <!-- Controls visibility toggle -->
      <v-btn icon @click="toggleControlsVisibility">
        <v-icon size="22" class="v-icon--custom" color="primary">
          fas fa-chevron-{{ gateReportStore.showControls ? "up" : "down" }}
        </v-icon>
      </v-btn>

      <!-- Events validation editor -->
      <EventsValidationDialog
        v-if="gateReportEventsValidationStore.eventsValidationDialog"
      />
    </ERow>

    <!-- Verified/Unverified scores -->
    <div class="ml-5 mb-1">
      Events ( Verified:<strong style="color: #4a9100">{{
        gateReportStore.verifiedFilteredEventsCount
      }}</strong
      >, Unverified:<strong style="color: #f18125">{{
        gateReportStore.unverifiedFilteredEventsCount
      }}</strong
      >)
      <ScoreBar
        :max-score="gateReportStore.filteredEventsCount"
        :score="gateReportStore.verifiedFilteredEventsCount"
        class="mt-1"
        style="height: 14px; width: 200px"
        :colors="['#fdae74', '#eed59d', '#d6e3a4', '#b4d984', '#8cc96b']"
      />
    </div>
  </div>
</template>

<script>
import ScoreBar from "@evercam/shared/components/ScoreBar"
import EventsFiltersSummary from "@/components/gateReport/EventsFiltersSummary"
import EventsValidationDialog from "@/components/gateReport/EventsValidationDialog"
import EventsHeatmap from "@/components/gateReport/EventsHeatmap"
import { debounce, updateQuery } from "@evercam/shared/utils"
import OpenInDashGateReportBtn from "@/components/gateReport/OpenInDashGateReportBtn"
import MarkDayAsVerifiedButton from "@/components/gateReport/MarkDayAsVerifiedButton"
import { camelizeKeys } from "humps"
import { VEHICLE_TYPE_IDS } from "@evercam/shared/constants/gateReport"
import { mapStores } from "pinia"
import { useGateReportEventsValidationStore } from "@/stores/gateReportEventsValidation"
import { useGateReportStore } from "@/stores/gateReport"
import { useAccountStore } from "@/stores/account"
import { useDayVerificationStore } from "@/stores/dayVerification"

export default {
  name: "EventsControls",
  components: {
    ScoreBar,
    MarkDayAsVerifiedButton,
    EventsFiltersSummary,
    EventsValidationDialog,
    EventsHeatmap,
    OpenInDashGateReportBtn,
  },
  props: {
    showValidation: {
      type: Boolean,
      default: true,
    },
    showImpersonation: {
      type: Boolean,
      default: true,
    },
    showVerification: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vehicleTypeIds: VEHICLE_TYPE_IDS,
      isInitialized: false,
    }
  },
  computed: {
    ...mapStores(
      useGateReportEventsValidationStore,
      useGateReportStore,
      useAccountStore,
      useDayVerificationStore
    ),
    filteredEvents() {
      return this.gateReportStore.getFilteredEvents({
        events: this.gateReportStore.events,
        searchParams: this.gateReportStore.searchParams,
      })
    },
    isEventsValidationDisabled() {
      return (
        this.gateReportStore.isLoading ||
        this.gateReportStore.isLoadingEvents ||
        !this.gateReportStore.selectedCameras?.length
      )
    },
    items() {
      return this.gateReportStore.dailyCounts.map((d) => {
        const totalIn = d?.in?.total || 0
        const totalOut = d?.out?.total || 0

        return {
          ...d,
          count: Math.ceil((totalIn + totalOut) / 2),
          disabled: this.isDateDisabled(d.day),
        }
      })
    },
    labels() {
      return {
        countIn: "in",
        countOut: "out",
        countUnknown: "n/a",
        unprocessed: "Not processed",
      }
    },
  },
  watch: {
    "gateReportStore.isLoadingEvents"(val, oldVal) {
      if (!val && oldVal) {
        this.gateReportEventsValidationStore.eventsValidationDialog =
          camelizeKeys(this.getQueryParams()).eventsValidationDialog === "true"
      }
    },
    "gateReportStore.selectedCameraExids"() {
      if (!this.isInitialized) {
        return
      }
      this.gateReportStore.fetchDailyCounts()
      this.gateReportStore.fetchDailyStatus()
      this.gateReportStore.fetchVerifiedDays()
    },
    "gateReportStore.selectedVehicleTypes"(value) {
      if (value?.length) {
        if (!this.isInitialized) {
          return
        }
        this.gateReportStore.fetchDailyCounts()
        this.gateReportStore.fetchDailyStatus()
      }
    },
    "gateReportStore.selectedVerifiedEventsVisibility"() {
      if (!this.isInitialized) {
        return
      }
      this.gateReportStore.fetchDailyCounts()
      this.gateReportStore.fetchDailyStatus()
    },
    "gateReportEventsValidationStore.eventsValidationDialog"(isOpen) {
      updateQuery({ eventsValidationDialog: isOpen })
      if (!isOpen) {
        this.gateReportEventsValidationStore.$reset()
      }
    },
    "gateReportEventsValidationStore.editedEvent"(event) {
      if (event?.id) {
        updateQuery({ eventId: event?.id })
      }
    },
  },
  created() {
    this.gateReportStore.updateStateFromUrl(this.$route.query)
  },
  async mounted() {
    await this.initialize()
  },
  methods: {
    isDateDisabled(date) {
      return !this.gateReportStore.dailyStatus?.includes(date)
    },
    formatLabel(item) {
      const date = this.$moment(item.day || item.date).format("ddd DD/MM/YYYY")
      const totalIn = item?.in?.total || null
      const totalOut = item?.out?.total || null

      if (item.disabled) {
        return `${date} (${this.labels.unprocessed})`
      }

      const textIn = `${this.labels.countIn}: ${
        totalIn || this.labels.countUnknown
      }`
      const textOut = `${this.labels.countOut}: ${
        totalOut || this.labels.countUnknown
      }`

      const verificationInfo = this.dayVerificationStore.verifiedDays.find(
        ({ day }) => day === item.day
      )
      const verificationInfoText = verificationInfo
        ? `, verified by: ${
            verificationInfo.verifiedBy || "n/a"
          } on ${this.$moment(verificationInfo.insertedAt).format(
            "ddd DD/MM/YYYY, HH:mm"
          )}`
        : ""

      return `${date} (${textIn}, ${textOut})${verificationInfoText}`
    },
    toggleControlsVisibility: debounce(function () {
      this.gateReportStore.showControls = !this.gateReportStore.showControls
      window.localStorage?.setItem(
        "showEventsControls",
        this.gateReportStore.showControls
      )
    }),
    getControlsVisibility() {
      const localStorageValue =
        window.localStorage?.getItem("showEventsControls")
      if (localStorageValue) {
        return localStorageValue === "true"
      } else {
        return true
      }
    },
    async initialize() {
      try {
        const queryParams = this.getQueryParams()
        this.gateReportStore.selectedDate = this.$moment(
          queryParams.date
        ).format("YYYY/MM/DD")
        this.gateReportStore.showControls = this.getControlsVisibility()

        if (!this.gateReportStore.selectedVehicleTypes.length) {
          this.gateReportStore.selectedVehicleTypes = this.vehicleTypeIds
        }

        this.gateReportStore.fetchDailyStatus()
        this.gateReportStore.fetchVerifiedDays()
        await this.gateReportStore.fetchDailyCounts()

        if (!this.gateReportStore.selectedDate) {
          let latestActivity = this.dailyCounts?.length
            ? this.dailyCounts.slice(-1)[0]
            : { day: new Date() }

          this.gateReportStore.selectedDate = this.$moment(
            latestActivity?.day
          ).format("YYYY/MM/DD")
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isInitialized = true
      }
    },
    async onDateSelected(date) {
      this.gateReportStore.selectedDate =
        this.$moment(date).format("YYYY/MM/DD")
      this.$emit("retrieve-events")
    },
  },
}
</script>
