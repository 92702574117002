import { defineNuxtRouteMiddleware, navigateTo } from "#app"

import { useAccountStore } from "@/stores/account"

export default defineNuxtRouteMiddleware((to) => {
  const accountStore = useAccountStore()
  const viewAsRole = to.query.viewAsRole
  const requiredPermissions = to.meta[0]?.requiredPermissions || []
  if (viewAsRole) {
    try {
      const decodedRole = JSON.parse(atob(viewAsRole))
      accountStore.temporaryPermissions = decodedRole.permissions
      accountStore.temporaryRole = decodedRole.role
    } catch (error) {
      console.error("Failed to decode permissions:", error)
    }
  }

  const hasAccess = requiredPermissions.every((perm) => {
    if (accountStore.temporaryPermissions) {
      return (
        accountStore.temporaryPermissions &&
        accountStore.temporaryPermissions.includes(perm)
      )
    }

    return accountStore.permissions.includes(perm)
  })

  if (!hasAccess) {
    console.warn("Access denied: Missing required permissions")

    return navigateTo("/unauthorized")
  }
})
