<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="cameras"
          class="camera-table"
          :show-select="canViewExtractions"
          page-limit-field="limit"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500],
          }"
          :headers="headers"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <CamerasSnapshotExtractor
              v-if="canViewExtractions"
              :cameras="reportStore.selectedItems"
            />
            <CamerasAddToProject
              v-if="canUpdateCameras"
              :cameras="reportStore.selectedItems"
            />
          </template>
          <template #item.zohoId="{ item }">
            <OpenInZohoButton
              v-if="item.zohoId"
              :zoho-id="item.zohoId"
              type="camera"
            />
          </template>
          <template #item.demo="{ item }">
            <ImpersonateUserButton
              v-if="isNDAProject(item)"
              :email="item.userEmail"
              :target-route="`/demos/cameras/${item.exid}`"
              :nda-project="isNdaProject(item)"
              icon="fas fa-desktop"
            />
            <OpenInNewWindowButton
              v-else
              :target-link="`/demos/cameras/${item.exid}`"
              hint="Click to open as a demo"
              icon="fas fa-desktop"
            />
          </template>
          <template #item.powerType="{ item }">
            {{ camelizeText(item.powerType) }}
          </template>
          <template #item.isPublic="{ item }">
            <ShowActiveValue :value="item.isPublic" />
          </template>
          <template #item.recordingFromNvr="{ item }">
            <ShowActiveValue :value="item.recordingFromNvr" />
          </template>
          <template #item.status="{ item }">
            <v-edit-dialog
              v-if="canUpdateCameras"
              large
              :return-value.sync="item.status"
              @save="save(item.exid, item.status)"
            >
              <span :class="getStatusColor(item.status)">
                {{ getStatusText(item.status) }}
              </span>
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
              <template #input>
                <v-select
                  v-model="item.status"
                  class="mt-3"
                  :items="statusesItems"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  @change="onChange(item)"
                />
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ getStatusText(item.status) }}
            </span>
          </template>
          <template #item.cloudRecordingStorageDuration="{ item }">
            <span
              v-if="item.cloudRecordingStorageDuration === -1"
              class="fas fa-infinity"
            ></span>
            <span v-else>
              {{
                parseCloudRecordingStorageDurations(
                  item.cloudRecordingStorageDuration
                )
              }}
            </span>
            <v-icon
              v-if="
                item.cloudRecordingStorageDuration &&
                !isDecommissioned(item.status) &&
                !isOnHold(item.status) &&
                canUpdateCameras
              "
              small
              color="primary"
              @click="cameraDialogStore.openDialog({ camera: item.exid })"
            >
              fa-pencil-alt
            </v-icon>
          </template>
          <template #item.frequency="{ item }">
            <span
              v-if="
                item.frequency &&
                !isDecommissioned(item.status) &&
                !isOnHold(item.status) &&
                canUpdateCameras
              "
              class="cursor-pointer"
              @click="cameraDialogStore.openDialog({ camera: item.exid })"
            >
              {{ parseFrequency(item.frequency) }}
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
            </span>
            <span v-else-if="item.frequency">{{
              parseFrequency(item.frequency)
            }}</span>
          </template>
          <template #item.cloudRecordingStatus="{ item }">
            <span
              v-if="
                item.cloudRecordingStatus &&
                !isDecommissioned(item.status) &&
                !isOnHold(item.status) &&
                canUpdateCameras
              "
              class="cursor-pointer"
              @click="cameraDialogStore.openDialog({ camera: item.exid })"
              >{{ parseCRStatus(item.cloudRecordingStatus) }}
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
            </span>
            <span v-else-if="item.cloudRecordingStatus">
              {{ parseCRStatus(item.cloudRecordingStatus) }}
            </span>
          </template>
          <template #item.name="{ item }">
            <v-tooltip
              top
              transition="none"
              :open-delay="200"
              :nudge-top="10"
              :nudge-right="35"
              content-class="cameras-table__camera-tooltip__container"
            >
              <template #activator="{ on }">
                <NdaIcon v-if="isNDAProject(item)" />
                <span
                  class="cursor-pointer primary--text"
                  @click="cameraDialogStore.openDialog({ camera: item.exid })"
                  v-on="on"
                  >{{ item.name }}
                </span>
              </template>
              <CameraCard
                :camera="item"
                :token="accountStore.token"
                :width="160"
                class="cameras-table__camera-tooltip"
              />
            </v-tooltip>
          </template>
          <template #item.location="{ item }">
            <span v-if="item.location">
              lat: {{ (item.location || {}).lat }} / lng:
              {{ (item.location || {}).lng }}
              <OpenInNewWindowButton
                :target-link="`https://www.google.com/maps/search/?api=1&query=${item.location.lat},${item.location.lng}`"
                hint="Open in Google maps"
              />
            </span>
            <span v-else>-</span>
          </template>
          <template #item.projectName="{ item, header }">
            <div v-if="item.projectName" class="d-flex align-center">
              <span v-if="isNdaProject(item)" class="mr-1">
                <NdaIcon />
              </span>
              <ETruncatedDiv :width="header.width">
                {{ item.projectName }}
              </ETruncatedDiv>
              <ImpersonateUserButton
                v-if="canImpersonateUsers"
                :email="item.userEmail"
                :target-route="`/projects/${item.projectExid}`"
                :nda-project="isNdaProject(item)"
              />
            </div>
            <span v-else>-</span>
          </template>
          <template #item.userFullname="{ item }">
            <div v-if="item.userFullname">
              {{ item.userFullname }}
              <ImpersonateUserButton
                v-if="canImpersonateUsers"
                :email="item.userEmail"
                :target-route="`/projects/${item.projectExid}/${item.exid}`"
              />
            </div>
            <span v-else>-</span>
          </template>
          <template #item.featureFlags="{ item }">
            <EReadMore :content="getFeatureFlags(item)" :max-length="2" />
          </template>
          <template #item.region="{ item }">
            {{ region(item.timezone) }}
          </template>
          <template #item.cameraModel="{ item }">
            {{ camelizeText(item.cameraModel) }}
          </template>
          <template #item.nvrModel="{ item }">
            {{ camelizeText(item.nvrModel) }}
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import headers, {
  parseFrequency,
  parseCRStatus,
  parseCloudRecordingStorageDurations,
} from "@/components/cameras/camerasHeaders"
import filterFields from "@/components/cameras/camerasSearchFilters"
import { CameraStatuses } from "@/components/constants.js"
import CameraUtils from "@/mixins/cameraUtils"
import timezones from "@evercam/shared/constants/timezones"
import CamerasSnapshotExtractor from "@/components/cameras/CamerasSnapshotExtractor"
import CamerasAddToProject from "@/components/cameras/CamerasAddToProject"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import NdaIcon from "@/components/NdaIcon"
import ShowActiveValue from "@/components/ShowActiveValue"
import CameraCard from "@evercam/shared/components/CameraCard.vue"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import ReportTable from "@/components/ReportTable"
import { CameraStatus } from "@evercam/shared/types/camera"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import vendorModels from "@evercam/shared/constants/vendorModels"
import { pascalize } from "humps"
import { getRegion } from "@evercam/shared/constants/regions"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { PERMISSIONS } from "@/constants/permissions"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.CAMERAS.VIEW],
  },
  name: "Cameras",
  components: {
    CamerasSnapshotExtractor,
    CamerasAddToProject,
    OpenInZohoButton,
    NdaIcon,
    OpenInNewWindowButton,
    ReportTable,
    ShowActiveValue,
    ImpersonateUserButton,
    CameraCard,
  },
  mixins: [CameraUtils],
  data: () => {
    return {
      timezones: timezones,
      statusesItems: CameraStatuses,
      headers: [],
      filterFields: {},
      vendorModels: vendorModels.CAMERAMODELS,
      PERMISSIONS,
    }
  },
  head() {
    return {
      title: "Admin - Cameras",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore, useAccountStore),
    provider() {
      return AdminApi.cameras.getCameras
    },
    canUpdateCameras() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.UPDATE)
    },
    canViewExtractions() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.VIEW_EXTRACTIONS)
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
    canUseDemo() {
      return this.$permissions.user.can(PERMISSIONS.DEMOS.VIEW)
    },
  },
  async created() {
    this.filterFields = filterFields(this)
    this.headers = headers(this).filter(
      (header) => this.canUseDemo || header.value !== "demo"
    )
  },
  mounted() {
    this.$root.$on("camera-updated", this.updateCameraItem)
  },
  methods: {
    parseFrequency,
    parseCRStatus,
    parseCloudRecordingStorageDurations,
    save(exid, status) {
      try {
        AdminApi.cameras.update(exid, {
          status: status,
        })
        this.$notifications.success("Camera status updated successfully.")
      } catch (error) {
        this.$notifications.error({
          text: "Camera status update failed!",
          error,
        })
      }
    },
    isNdaProject(item) {
      return item.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    async onChange(item) {
      if (this.isDecommissioned(item.status)) {
        if (
          await this.$confirmDialog.open({
            title: "Confirm new status ?",
            message: "This will permanently decommission the camera!",
          })
        ) {
          this.save(item.exid, item.status)
        }
      }
    },
    saveTimezone(exid, timezone) {
      try {
        AdminApi.cameras.update(exid, {
          timezone: timezone,
        })
        this.$notifications.success("Camera timezone updated successfully.")
      } catch (error) {
        this.$notifications.error({
          text: "Camera timezone update failed!",
          error,
        })
      }
    },
    updateCameraItem() {
      this.reportStore.items = this.reportStore.items.map((camera) => {
        return camera.exid === this.cameraDialogStore.camera.exid
          ? this.cameraDialogStore.camera
          : camera
      })
    },
    isDecommissioned(status) {
      return status === CameraStatus.Decommissioned
    },
    isOnHold(status) {
      return status === CameraStatus.OnHold
    },
    isNDAProject(camera) {
      return camera?.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    getFeatureFlags(item) {
      return item.featureFlags.map((feature) =>
        this.snakeCaseToTitleCase(feature)
      )
    },
    region(timezone) {
      let region = getRegion(timezone)

      return region ? region.name : "Other"
    },
    camelizeText(value) {
      return value ? pascalize(value) : value
    },
  },
})
</script>

<style lang="scss">
.cameras-table {
  &__camera-tooltip {
    box-shadow: none !important;
    border: 1px solid #bbb;
    background: #eeeeee !important;

    .snapshot-card__img {
      box-shadow: none !important;
      border-bottom: 1px solid #bbb;
      box-sizing: content-box !important;
    }

    &__container {
      opacity: 1 !important;
    }
  }
}
</style>
