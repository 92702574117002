<template>
  <div class="bim-viewer h-100">
    <ERow ref="container" v-resize="updateFrameDimensions" no-gutters>
      <iframe
        v-if="project360"
        :src="`https://${project360.providerPrefix}${
          providerLinks[project360.integrationType]
        }${project360.providerSuffix}/${
          providerParams[project360.integrationType]
        }${project360.providerID}${panoViewerParams}`"
        :style="iframeStyle"
        allowFullScreen="true"
        v-bind="$attrs"
        v-on="$listeners"
        @load="updateFrameDimensions"
      ></iframe>
    </ERow>
  </div>
</template>

<script>
import {
  _360ProvidersLinks,
  _360Providers,
  _360ProvidersParams,
  _360ProvidersSuffixes,
} from "@evercam/shared/constants/360Providers"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.VIEW_360.VIEW],
  },
  name: "Viewer360",
  data: () => ({
    iframeStyle: {},
    project360: null,
    providerLinks: _360ProvidersLinks,
    providerParams: _360ProvidersParams,
    providers: _360Providers,
    providersSuffixes: _360ProvidersSuffixes,
  }),
  head() {
    return {
      title: "Evercam Admin | 360° View",
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    panoViewerParams() {
      return this.project360?.integrationType == this.providers.Evercam
        ? "&user=admin"
        : ""
    },
  },
  async mounted() {
    this.onResize()
    await this.get360Data()
  },
  methods: {
    async get360Data() {
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/360/${this.$route.params.exid}.json`
      )
      this.project360 = await response.json()
      if (!this.project360.providerPrefix) this.project360.providerPrefix = ""
      if (!this.project360.providerSuffix) {
        this.project360.providerSuffix =
          this.providersSuffixes[this.project360.integrationType]
      }
    },
    updateFrameDimensions() {
      const container = this.$refs.container?.$el?.getBoundingClientRect()
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.iframeStyle = {
          height: `${window.innerHeight - container?.top}px`,
          width: `${window.innerWidth - container?.left}px`,
        }
      }, 1000)
    },
    onResize() {
      this.updateFrameDimensions()
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
@import "assets/vars";

.bim-viewer {
  iframe {
    border: none;
  }
  &__main > v-sheet {
    height: 100vh;
  }
}
</style>
