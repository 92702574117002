<template>
  <ERow class="">
    <PaActiveUsers class="w-100" />
  </ERow>
</template>

<script>
import PaActiveUsers from "@/components/pa/PaActiveUsers"
import { PERMISSIONS } from "@/constants/permissions"
export default {
  meta: {
    requiredPermissions: [PERMISSIONS.PRODUCT_ANALYTICS.VIEW],
  },
  components: {
    PaActiveUsers,
  },
}
</script>
