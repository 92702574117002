<template>
  <div class="d-flex flex-column">
    <div
      v-if="isProcessing"
      class="d-flex flex-column justify-center align-center pa-4"
    >
      <div class="caption-1">
        {{ $t("content.recordings.brain_tool_processing") }}
      </div>
      <div class="d-flex justify-center py-4">
        <EvercamLoadingAnimation size="FourXl" />
      </div>
    </div>

    <div v-else-if="error" class="d-flex justify-center">
      <v-alert type="error" width="250">
        {{ error }}
      </v-alert>
    </div>

    <div v-else class="d-flex px-4">
      <v-slider
        v-model="transparency"
        label="Transparency"
        @end="onTransparencySliderEnd"
      ></v-slider>
    </div>
    <!-- Divs to contain labels and icons for each segment -->
    <div
      v-for="segment in segments"
      :key="'segment-' + (segment.label + 1)"
      class="segment-label d-flex"
    >
      <!-- Replacing polygons with images -->
      <img
        :src="cutImage(segment.polygon)"
        :style="{
          width: calculateWidth(segment.polygon) + 'px',
          height: calculateHeight(segment.polygon) + 'px',
          margin: '0.5rem auto',
          maxWidth: '75%',
          maxHeight: '60px',
        }"
        :class="{ hovered: segment.isHovered }"
        @mouseenter="handleImageHover(segment, true)"
        @mouseleave="handleImageHover(segment, false)"
      />
    </div>
    <!-- Polygons -->
    <portal to="evercam-boxes-container">
      <div
        v-if="segments.length"
        id="segments"
        class="w-100 h-100 position-absolute t-0"
      >
        <svg
          :viewbox="`0 0 ${imageDimensions.width} ${imageDimensions.height}`"
          :height="imageDimensions.height"
          :width="imageDimensions.width"
          :style="svgStyles"
          preserveAspectRatio="none"
        >
          <polygon
            v-for="segment in segments"
            :key="segment.label"
            :points="formatPoints(segment.polygon)"
            :fill="
              highlightedIndex === segment.label
                ? 'yellow'
                : stringToColor(segment.label)
            "
            stroke="black"
            @mouseenter="handleImageHover(segment, true)"
            @mouseleave="handleImageHover(segment, false)"
          />
        </svg>
      </div>
    </portal>
  </div>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { AiApi } from "@evercam/shared/api/aiApi"

export default {
  name: "Segmentation",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    imageDimensions: {
      type: Object,
      default: () => ({
        height: 0,
        width: 0,
      }),
    },
    timestamp: {
      type: String,
      required: true,
    },
    cameraExid: {
      type: String,
      required: true,
    },
    imgElement: {
      type: HTMLImageElement,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: true,
      segments: [],
      transparency: 50,
      error: null,
      highlightedIndex: null,
      isHovered: false,
    }
  },
  computed: {
    svgStyles() {
      return {
        opacity: 1 - this.transparency / 100,
      }
    },
  },
  watch: {
    isProcessing(val) {
      this.$emit("is-processing", val)
    },
    timestamp: {
      immediate: true,
      handler: "performSegmentation",
    },
  },
  methods: {
    onTransparencySliderEnd(value) {
      this.$emit("transparency-change", value)
    },
    async performSegmentation() {
      this.error = null
      this.isProcessing = true
      this.segments = []
      try {
        const { response } = await AiApi.brainTool.getSegments({
          cameraex: this.cameraExid,
          timestamp: this.timestamp,
        })
        if (response === "segmentation-error") {
          this.error = response

          return
        }
        this.segments = response
          .filter(
            (segment) =>
              Array.isArray(segment?.mask) &&
              segment?.mask.every(
                (point) => Array.isArray(point) && point.length === 2
              )
          )
          .map((segment) => {
            return {
              label: segment.label,
              polygon: Object.values(segment.mask).map(([x, y]) => ({ x, y })),
            }
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.isProcessing = false
      }
    },
    formatPoints(points) {
      return points
        .map(
          (p) =>
            `${p.x * this.imageDimensions.width},${
              p.y * this.imageDimensions.height
            }`
        )
        .join(" ")
    },
    stringToColor(s) {
      let hash = 0
      let str = `${s}${s}${s}`
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      let color = "#"
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        color += ("00" + value.toString(16)).substr(-2)
      }

      return color
    },
    cutImage(polygon) {
      let minX = Infinity
      let minY = Infinity
      let maxX = -Infinity
      let maxY = -Infinity

      for (const point of polygon) {
        minX = Math.min(minX, point.x * this.imageDimensions.width)
        minY = Math.min(minY, point.y * this.imageDimensions.height)
        maxX = Math.max(maxX, point.x * this.imageDimensions.width)
        maxY = Math.max(maxY, point.y * this.imageDimensions.height)
      }

      const width = maxX - minX
      const height = maxY - minY

      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      canvas.width = width
      canvas.height = height

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.beginPath()
      ctx.moveTo(
        polygon[0].x * this.imageDimensions.width - minX,
        polygon[0].y * this.imageDimensions.height - minY
      )
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(
          polygon[i].x * this.imageDimensions.width - minX,
          polygon[i].y * this.imageDimensions.height - minY
        )
      }
      ctx.closePath()
      ctx.clip()
      ctx.drawImage(
        this.imgElement,
        -minX,
        -minY,
        this.imageDimensions.width,
        this.imageDimensions.height
      )

      return canvas.toDataURL()
    },
    calculateWidth(polygon) {
      let minX = Infinity
      let maxX = -Infinity

      for (const point of polygon) {
        minX = Math.min(minX, point.x * this.imageDimensions.width)
        maxX = Math.max(maxX, point.x * this.imageDimensions.width)
      }

      let width = maxX - minX
      if (width > this.parentWidth) {
        width = this.parentWidth
      }

      return width
    },
    calculateHeight(polygon) {
      let minY = Infinity
      let maxY = -Infinity

      for (const point of polygon) {
        minY = Math.min(minY, point.y * this.imageDimensions.height)
        maxY = Math.max(maxY, point.y * this.imageDimensions.height)
      }

      let height = maxY - minY

      if (height > this.parentHeight) {
        height = this.parentHeight
      }

      return height
    },
    handleImageHover(segment, isHovered) {
      segment.isHovered = isHovered
      if (isHovered) {
        this.highlightedIndex = segment.label
      } else {
        this.highlightedIndex = null
        this.segments.forEach((seg) => {
          seg.isHovered = false
        })
      }
    },
  },
}
</script>

<style scoped>
.segment-label {
  margin-bottom: 10px;
}

.label {
  margin-right: 10px;
  width: 100px;
}

.hovered {
  transition: "filter 0.3s ease-out";
  cursor: "pointer";
  display: "inline";
  box-shadow: "6px 6px 10px 0px rgba(60, 138, 240, 100)";
  opacity: 1;
  filter: drop-shadow(0.01rem 0.01rem 1px rgb(60, 138, 240))
    drop-shadow(-0.01rem 0.01rem 1px rgb(60, 138, 240))
    drop-shadow(0.01rem -0.01rem 1px rgb(60, 138, 240))
    drop-shadow(-0.01rem -0.01rem 1px rgb(60, 138, 240));
}
</style>
