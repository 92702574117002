import { defineStore } from "pinia"
import { useReportStore } from "@/stores/report"
import { snakeCaseToTitleCase } from "@evercam/shared/utils"
import { useRoute } from "#app"
export type Bookmark = {
  name: string
  url: string
  route: string
}

export type BookmarkState = {
  bookmarks: Bookmark[]
  filteredBookmarks: Bookmark[]
  isBookmarksDisplayed: boolean
  isPathBookmarked: boolean
}

export const useBookmarksStore = defineStore({
  id: "bookmark",
  state: (): BookmarkState => ({
    filteredBookmarks: [],
    bookmarks: JSON.parse(localStorage?.getItem("bookmarks")) ?? [],
    isBookmarksDisplayed: true,
    isPathBookmarked: false,
  }),

  actions: {
    loadBookmarks() {
      const route = useRoute()
      this.filteredBookmarks = this.bookmarks?.filter(
        (bookmark) => bookmark.route === route.path
      )
    },
    removeFromBookmarks(url = "") {
      const path = url.length > 0 ? url : this.getPathname()
      this.bookmarks = this.bookmarks?.filter(
        (bookmarkItem: Bookmark) => bookmarkItem.url !== path
      )

      this.isPathBookmarked = !!this.bookmarks?.find(
        (item) => item.url === this.getPathname()
      )
      this.loadBookmarks()
      localStorage.setItem("bookmarks", JSON.stringify(this.bookmarks))
    },
    saveToBookmarks() {
      const reportStore = useReportStore()
      const path = this.getPathname()
      const route = window.location.pathname
      const { page, limit, sort, ...rest } = reportStore.searchFilters
      let title = ""
      if (Object.keys(rest).length === 0) {
        title = `All ${route.split("/")[1]}`
        // this case is for ingest tabs, seems to have a problem on route now, should work when it is fixed
      } else if (
        Object.keys(rest).length === 1 &&
        Object.keys(rest).includes("tab")
      ) {
        if (parseInt(rest.tab) === 0) {
          title = `All Drone ${route.split("/")[1]}`
        } else if (parseInt(rest.tab) === 1) {
          title = `All 360 ${route.split("/")[1]}`
        } else if (parseInt(rest.tab) === 2) {
          title = `All BIM ${route.split("/")[1]}`
        }
      } else {
        Object.entries(rest).forEach(([key, value]) => {
          let name = null

          if (Array.isArray(value)) {
            if (value.length === 0) return
            name = `${title.length > 0 ? ", " : ""}${snakeCaseToTitleCase(
              key
            )}: `
            name =
              name + value.map((item) => snakeCaseToTitleCase(item)).join(" + ")
          } else {
            if (!(value.length === 0 || value === undefined)) {
              name = `${title.length > 0 ? ", " : ""}${snakeCaseToTitleCase(
                key
              )}: `
              const stringValue =
                typeof value === "string" ? value : value.toString()
              name = name + snakeCaseToTitleCase(stringValue)
            }
          }
          if (!name) return
          title = title + name
        })
      }
      if (title.length === 0) title = `All ${route.split("/")[1]}`

      this.bookmarks = [
        ...this.bookmarks,
        {
          name: title,
          url: path.slice(-1) === "?" ? path.slice(0, -1) : path,
          route,
        },
      ]
      this.isPathBookmarked = !!this.bookmarks?.find(
        (item) => item.url === this.getPathname()
      )
      this.loadBookmarks()
      localStorage.setItem("bookmarks", JSON.stringify(this.bookmarks))
    },
    isBookmarkAlreadyExist(url: string) {
      return !!this.bookmarks?.find((item: Bookmark) => item.url === url)
    },
    toggleBookmarks() {
      if (this.isBookmarkAlreadyExist(this.getPathname())) {
        this.removeFromBookmarks()
      } else {
        this.saveToBookmarks()
      }
    },
    getPathname() {
      return `${document.location.pathname}${document.location.search}`
    },
  },
})
