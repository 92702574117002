<template>
  <DemoIframe
    :key="$route.path"
    v-bind="$attrs"
    resource="users"
    :providerParams="{ term: $route.params.user }"
    :demoUrl="demoUrl"
    @load-item="loadUser"
  />
</template>

<script>
import DemoIframe from "@/components/DemoIframe"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { PERMISSIONS } from "@/constants/permissions"
export default {
  meta: {
    requiredPermissions: [PERMISSIONS.DEMOS.VIEW],
  },
  components: {
    DemoIframe,
  },
  data() {
    return {
      demoUrl: "",
    }
  },
  methods: {
    async loadUser(user) {
      const { token } = await AdminApi.users.impersonateUser(user?.email)
      this.demoUrl = `${this.$config.public.dashURL}/projects?impersonation_token=${token}&demo=true`
    },
  },
}
</script>
