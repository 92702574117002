<template>
  <div
    v-if="chartOptions"
    class="kit-metric__graph-chart e-flex e-w-full position-relative e-flex-wrap"
  >
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import VueHighcharts from "@evercam/shared/components/Highcharts"
import Highcharts from "highcharts"
import {
  GrafanaMetricResponse,
  HighchartsStackingBehavior,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "KitMetricGraphChart",
  components: {
    highcharts: VueHighcharts,
  },
  props: {
    metric: {
      type: Object as PropType<GrafanaMetricResponse>,
      required: true,
    },
    chartType: {
      type: String,
      default: "area",
    },
    convertToBytes: {
      type: Boolean,
      default: false,
    },
    convertToBitsPerSecond: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    temperature: {
      type: Boolean,
      default: false,
    },
    defaultTooltip: {
      type: Boolean,
      default: false,
    },
    stacking: {
      type: [String, Boolean] as PropType<HighchartsStackingBehavior | false>,
      default: HighchartsStackingBehavior.Normal,
    },
  },
  computed: {
    labelFormatter():
      | Highcharts.AxisLabelsFormatterCallbackFunction
      | undefined {
      const that = this
      if (this.convertToBytes) {
        return function () {
          return that.$units.formatBytes(this.value)
        }
      } else if (this.convertToBitsPerSecond) {
        return function () {
          return that.$units.formatBitsPerSecond(this.value)
        }
      } else if (this.percentage) {
        return function () {
          return that.$units.formatPercentage(this.value)
        }
      } else if (this.temperature) {
        return function () {
          return that.$units.formatTemperature(this.value)
        }
      }

      return undefined
    },
    tooltipFormatter():
      | Highcharts.TooltipFormatterCallbackFunction
      | undefined {
      if (this.defaultTooltip) {
        return undefined
      }

      if (this.convertToBytes) {
        return this.createTooltipFormatter(this.$units.formatBytes)
      } else if (this.convertToBitsPerSecond) {
        return this.createTooltipFormatter(this.$units.formatBitsPerSecond)
      } else if (this.percentage) {
        return this.createTooltipFormatter(this.$units.formatPercentage)
      } else if (this.temperature) {
        return this.createTooltipFormatter(this.$units.formatTemperature)
      }

      return undefined
    },
    chartOptions(): Highcharts.Options | null {
      if (!this.metric.results) {
        return null
      }

      const series = Object.entries(this.metric.results).reduce(
        (acc, [_resultKey, result]) => {
          const frameSeries = result.frames.reduce((frameAcc, frame) => {
            const fields = frame.schema.fields
            const dataValues = frame.data.values
            const timeField = fields.find((f) => f.type === "time")
            const valueField = fields.find((f) => f.type === "number")

            if (
              timeField &&
              valueField &&
              !["node_memory_MemTotal_bytes"].includes(valueField.name)
            ) {
              const [times, values] = dataValues
              const meanValue =
                values.reduce((sum, val) => sum + val, 0) / values.length

              frameAcc.push({
                name: this.getFieldName(valueField),
                data: times.map((time, index) => [time, values[index]]),
                zIndex: -meanValue,
              })
            }

            return frameAcc
          }, [])

          return [...acc, ...frameSeries]
        },
        []
      )

      return {
        series,
        chart: {
          height: "200em",
          type: this.chartType,
          zoomType: "x",
          style: {
            fontSize: "14px",
          },
        },
        title: false,
        xAxis: {
          tickInterval: 3600_000 / 6,
          type: "datetime",
          title: {
            enabled: false,
          },
        },
        yAxis: {
          tickAmount: 6,
          title: {
            enabled: false,
          },
          labels: {
            formatter: this.labelFormatter,
          },
        },
        tooltip: {
          shared: true,
          xDateFormat: "%Y-%m-%d %H:%M:%S",
          formatter: this.tooltipFormatter,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointStart: 2012,
          },
          area: {
            stacking: this.stacking,
            lineColor: "#666666",
            lineWidth: 0.2,
            marker: {
              radius: 1,
              lineWidth: 0.2,
              lineColor: "#666666",
            },
          },
        },
        legend: {
          itemStyle: {
            fontSize: "12px",
          },
          symbolWidth: 16,
          symbolHeight: 16,
          itemDistance: 10,
        },
      }
    },
  },

  methods: {
    createTooltipFormatter(
      formatter: (value: number) => string
    ): Highcharts.TooltipFormatterCallbackFunction {
      return function () {
        const points = this.points || []
        const timeStr = Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", this.x)

        return `<b>${timeStr}</b><br/>${points
          .map((point) => `${point.series.name}: ${formatter(point.y)}`)
          .join("<br/>")}`
      }
    },
    getFieldName(field) {
      if (!field) return "Value"
      if (field.labels?.mountpoint) {
        return `${field.labels.mountpoint} (${field.labels.fstype})`
      }
      if (field.labels?.device) return field.labels.device
      if (field.config?.displayNameFromDS) return field.config.displayNameFromDS
      if (field.name) return field.name

      return "Value"
    },
  },
})
</script>
