import {
  type _360Scene,
  type Media,
  type DroneModel,
  type MobileCaptureSnapshot,
  type TimelineDateInterval,
  type TimelinePrecision,
  type Comment,
  TaskStatus,
  ExNvrHealthCheckTaskId,
  type ExNvrHealthCheckTask,
} from "@evercam/api/types"
import type {
  TimelineChartType,
  TimelineEvent,
  TimelineEventsGroup,
} from "@evercam/ui"
import { TimelineDataProvider } from "@evercam/shared/components/timelinePlayer/providers"

export enum WatermarkedImageDownloadOrigin {
  Drone = "drone",
  Bim = "BIM",
  Snapshot = "snapshot",
  The360 = "360",
  SitePlanner = "Site planner",
}

export enum PlayerMode {
  Video = "video",
  Jpegs = "jpegs",
}

export type TasksStatusMap = {
  [key: string]: TaskStatus
}

export type TaskProgressItem = {
  status: TaskStatus
  description: string
  percentDone?: number
  duration?: number
}

export type GlobalSearchMatch = {
  path: Array<string>
  value: string | number | boolean
}

export type GlobalSearchResult = {
  index: number
  matches: Array<GlobalSearchMatch>
}

export type GlobalSearchResultInput = Array<Record<any, any> | Array<any>>

export type TimelineProviderRequestParams = TimelineDateInterval & {
  precision?: TimelinePrecision
}

export type TimelinePlayerGroupConfig = {
  provider?: TimelineDataProvider
  events?: Array<TimelineEvent>
  getChartType?: (precision: TimelinePrecision) => TimelineChartType
} & Omit<TimelineEventsGroup, "events">

export type TimelinePlayerConfig = {
  [groupName: string]: TimelinePlayerGroupConfig
}

export type ExNvrConfigList = {
  items: ExNvrConfigListItem[]
  isDisabled?: boolean
  gridLayout?: string
}

export type ExNvrConfigListItem = {
  label: string
  labelColor?: string
  value?: string | number
  valueColor?: string
  disabled?: boolean
}

export type ExNvrTaskWithDescription<T extends ExNvrHealthCheckTaskId> =
  ExNvrHealthCheckTask<T> & {
    description: string
    hidden: boolean
  }

export type TimelinePlayerTooltipItem = {
  label?: string
  title?: string
  thumbnailUrl: string
  placeholderThumbnailUrl?: string
  fallbackThumbnailUrl?: string
}

export enum TimelineGroupId {
  ThreeSixty = "w360",
  Drone = "drone",
  Bim = "bim",
  Media = "media",
  Comments = "comments",
  Anpr = "anpr",
  MobileCapture = "mobileCapture",
  ExNvrRecordings = "exNvrRecordings",
  Luminance = "luminance",
  ObjectInspector = "objectInspector",
}

export type TimelineOverlayType =
  | TimelineGroupId.ThreeSixty
  | TimelineGroupId.Drone
  | TimelineGroupId.Media
  | TimelineGroupId.MobileCapture
  | TimelineGroupId.Bim
  | TimelineGroupId.Comments
  | "compare"

export type TimelineOverlayData<T extends TimelineOverlayType> =
  T extends TimelineGroupId.ThreeSixty
    ? _360Scene
    : T extends TimelineGroupId.Drone
    ? DroneModel
    : T extends TimelineGroupId.Media
    ? Media
    : T extends TimelineGroupId.MobileCapture
    ? MobileCaptureSnapshot
    : T extends TimelineGroupId.Comments
    ? Comment
    : never

export enum TimelineMarkerId {
  Xray = "xray",
  CurrentSnapshot = "current",
  CompareBeforeSnapshot = "compareBefore",
  CompareAfterSnapshot = "compareAfter",
  ObjectInspectorFirstSeen = "firstSeen",
  ObjectInspectorLastSeen = "lastSeen",
}

export type TimelineMediaHubItemsByType = {
  clip?: Media[]
  compare?: Media[]
  edit?: Media[]
  timelapse?: Media[]
  xRay?: Media[]
}

export enum HighchartsStackingBehavior {
  Overlap = "overlap",
  Normal = "normal",
  Percent = "percent",
  Stream = "stream",
}
