<template>
  <ECard
    title="No cameras"
    :image="imageUrl"
    blur-background
    :image-translation="20"
    :image-size="55"
    :image-padding="5"
    v-bind="$attrs"
    class="kit-camera-placeholder e-pr-0"
  >
    <template #title>
      <div class="e-flex e-items-center"></div>
    </template>

    <div class="e-flex e-justify-center e-items-center e-p-6">
      <AddCameraWizard
        :project-id="kitSummaryStore.project.id"
        :project-exid="kitSummaryStore.project.exid"
        :project-timezone="kitSummaryStore.project?.timezone"
        :kit-id="kitSummaryStore.kit.id"
        :router-id="kitSummaryStore.router.id"
        :nvr="kitSummaryStore.nvr"
      />
    </div>
  </ECard>
</template>

<script lang="ts">
import Vue from "vue"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { mapStores } from "pinia"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import AddCameraWizard from "@/components/cameras/AddCameraWizard.vue"

export default Vue.extend({
  components: { AddCameraWizard },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useKitSummaryStore, useAccountStore),
    imageUrl(): string {
      return require("~/assets/img/camera/hikvision.gif")
    },
  },
})
</script>

<style lang="scss">
.kit-camera-placeholder {
}
</style>
