import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin((app) => {
  const accountStore = useAccountStore()

  const user = {
    can: (permission) => {
      if (accountStore.temporaryPermissions) {
        return (
          accountStore.temporaryPermissions &&
          accountStore.temporaryPermissions.includes(permission)
        )
      }

      return accountStore.permissions.includes(permission)
    },
    is: (roleId) => {
      if (accountStore.temporaryRole) {
        return accountStore.temporaryRole.id === roleId
      }

      return accountStore.roles.some((role) => role.id === roleId)
    },
  }

  app.provide("permissions", { user })
})
