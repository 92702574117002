<template>
  <div>
    <v-divider></v-divider>
    <v-card flat :loading="isLoading">
      <v-card-title class="pa-0 pl-2 project-details__header">
        <div v-if="projectDetails && !isLoading" class="ma-2">
          <HomeButton />
          <span class="mx-2">|</span>
          <span class="subtitle-2 black--text text--lighten-4">
            <v-icon left>fa-sitemap</v-icon>
            <NdaIcon v-if="isNDAProject" />
            <span class="ml-1">{{ projectDetails.name }}</span>
            ({{ projectDetails.exid }}
            <CopyToClipboardBtn
              v-if="projectDetails.exid"
              :text="projectDetails.exid"
              color="primary"
              icon
            />)
            <span v-if="projectDetails.status" class="mx-1"
              >| {{ projectDetails.status }}</span
            >
            <OpenInZohoButton :zoho-id="projectDetails.zohoId" type="project" />
          </span>
          <v-btn
            class="close-icon pb-2"
            color="primary"
            icon
            dark
            @click="closeDialog"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-tabs
        v-model="projectStore.currentTab"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :show-arrows="$vuetify.breakpoint.smAndDown"
        class="project-details__tabs custom-tabs"
        active-class="blue lighten-5"
      >
        <v-tab key="SUMMARY">SUMMARY</v-tab>
        <v-tab key="GRAPHS">GRAPHS</v-tab>
        <v-tab key="USERS">USERS</v-tab>
        <v-tab key="HDDS">STORAGE</v-tab>
        <v-tab key="CAMERA STATUS">CAMERA STATUS</v-tab>

        <v-tabs-items v-model="projectStore.currentTab" class="mx-2">
          <v-tab-item key="SUMMARY">
            <ERow no-gutters>
              <ECol :cols="$vuetify.breakpoint.lgAndUp ? 4 : 6">
                <ProjectData :project-data="projectDetails" />
                <v-divider />
                <ProjectsFeatureFlags :disabled="!canEditProject" />
              </ECol>
              <v-divider vertical />
              <ECol :cols="$vuetify.breakpoint.lgAndUp ? 8 : 6">
                <ProjectDetails :read-only="!canEditProject" />
                <v-divider />
                <ProjectsLogos
                  v-if="projectDetails.exid"
                  :project-id="projectDetails.exid"
                  :editable="canEditProject"
                />
              </ECol>
            </ERow>
            <v-divider></v-divider>
            <ProjectsCamerasTable
              :project="projectDetails"
              @update-timezone="updateTimezone"
            />
          </v-tab-item>
          <v-tab-item key="GRAPHS">
            <PaEventsStats
              v-if="projectDetails.exid"
              :id="projectDetails.exid"
              :events-of="'projects'"
            />
          </v-tab-item>
          <v-tab-item key="USERS" class="overflow-y-hidden">
            <ProjectsUsersTable
              v-if="projectDetails.exid"
              :project-id="projectDetails.exid"
              :company-id="companyId"
            />
          </v-tab-item>
          <v-tab-item key="HDDS">
            <ProjectHdds :project-id="projectDetails.id" />
          </v-tab-item>
          <v-tab-item key="CAMERA STATUS">
            <ProjectCamerasStatus :project-id="projectDetails.id" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script lang="ts">
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import HomeButton from "@/components/HomeButton"
import NdaIcon from "@/components/NdaIcon"
import ProjectData from "@/components/projects/ProjectData"
import ProjectDetails from "@/components/projects/ProjectDetails"
import ProjectsLogos from "@/components/projects/ProjectsLogos"
import ProjectsFeatureFlags from "@/components/projects/ProjectsFeatureFlags"
import ProjectsCamerasTable from "@/components/projects/ProjectsCamerasTable"
import ProjectsUsersTable from "@/components/projects/ProjectsUsersTable"
import ProjectHdds from "@/components/hdds/ProjectHdds"
import PaEventsStats from "@/components/pa/PaEventsStats"
import Vue from "vue"
import { Project, ProjectFeatureFlag } from "@evercam/shared/types/project"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import ProjectCamerasStatus from "@/components/projects/ProjectCamerasStatus.vue"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  name: "ProjectSummary",
  components: {
    CopyToClipboardBtn,
    OpenInZohoButton,
    ProjectData,
    ProjectDetails,
    ProjectsFeatureFlags,
    ProjectsLogos,
    ProjectsCamerasTable,
    ProjectsUsersTable,
    ProjectHdds,
    PaEventsStats,
    HomeButton,
    NdaIcon,
    ProjectCamerasStatus,
  },
  props: {
    projectExid: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    zohoId: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      projectDetails: {} as Project,
      PERMISSIONS,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useBreadcrumbStore),
    isNDAProject() {
      return this.projectDetails?.featureFlags?.includes?.(
        ProjectFeatureFlag.NdaMc
      )
    },
    canEditProject() {
      return this.$permissions.user.can(PERMISSIONS.PROJECTS.UPDATE)
    },
  },
  watch: {
    projectExid() {
      this.fetchProjectDetails()
    },
  },
  mounted() {
    const { tab } = this.getSubQueryParams()
    if (tab !== undefined) {
      this.projectStore.currentTab = Number.parseInt(tab)
    }
    this.fetchProjectDetails()
  },
  methods: {
    async fetchProjectDetails() {
      this.isLoading = true
      try {
        const { data } = await AdminApi.pa.getPAProject(
          this.projectExid,
          this.$route.query
        )
        this.projectDetails = data
        this.projectStore.editedProject = this.projectDetails
        this.projectStore.fetchProjectTPulseLink(this.projectExid)
        if (this.inline) {
          this.updateBreadcrumbs()
        }
      } catch (e) {
        if ("with_zoho" in this.$route.query && e.response.status === 404) {
          this.error({
            statusCode: 404,
            message: "This project is not found in Evercam",
            // @ts-ignore
            homePageLink: false,
          })
        } else if (e?.response?.status === 404) {
          this.error({
            statusCode: 404,
            message: "Project not found",
            // @ts-ignore
            homePageLink: true,
          })
        } else {
          this.$notifications.error({
            text: "Load product analytics project details failed!",
            error: e,
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    updateBreadcrumbs() {
      if (this.companyId) {
        this.breadcrumbStore.breadcrumbs = [
          {
            icon: "fa-solid fa-home",
            href: "/",
          },
          {
            name: "Companies",
            href: "/companies",
          },
          {
            name: this.companyName || this.companyId,
            href: `/companies/${this.companyId}`,
            icon: "far fa-building",
          },
          {
            name: this.projectDetails.name,
            id: this.projectDetails.exid,
            icon: "fa-sitemap",
          },
        ]
      }
    },
    closeDialog() {
      if (this.inline) {
        this.$router.go(-1)
      } else {
        this.$emit("close")
      }
    },
    updateTimezone(timezone) {
      this.projectDetails.timezone = timezone
    },
  },
})
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "assets/vars";
.project-details {
  overflow: hidden;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: calc(100vw - #{$sidebar-width});
    margin-left: $sidebar-width !important;
  }

  &__header {
    box-shadow: 0px 5px 12px -13px #0000007a;
    z-index: 1;
  }

  &__tabs {
    & > .v-tabs-bar {
      border-right: 1px solid #e0e0e0;
      box-shadow: 5px 0px 11px -12px #000000b5;
      z-index: 1;
    }
    & > .v-tabs-items {
      overflow-y: auto;
      max-height: calc(100vh - 57px);
      height: calc(100vh - 57px);
    }
    .v-tab {
      text-transform: none;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-tabs-bar {
        border-bottom: thin solid #e0e0e0;
      }
    }
  }

  &--fullscreen {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: calc(100vw - #{$sidebar-width--sm});
      margin-left: $sidebar-width--sm !important;
    }
  }
}
</style>
