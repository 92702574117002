<template>
  <div
    class="kit-metric__table e-flex e-w-full position-relative e-flex-wrap e-m-2"
  >
    <v-simple-table dense class="filesystem-table">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">Mountpoint</th>
            <th class="text-left">Device</th>
            <th class="text-left">Filesystem Type</th>
            <th class="text-left">Total Space</th>
            <th class="text-left">Used Space</th>
            <th class="text-left">Read Only</th>
            <th class="text-left">network_region 1</th>
            <th class="text-left">network_region 2</th>
            <th class="text-left">network_region 3</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getFormattedFileSystemData(metric)"
            :key="index"
          >
            <td>{{ item.mountpoint }}</td>
            <td>{{ item.device }}</td>
            <td>{{ item.fstype }}</td>
            <td>{{ item.totalSpace }}</td>
            <td>
              <ScoreBar
                class="kit-metric__gauge-chart"
                :score="item.usedPercent"
                :max-score="100"
                invert-colors
                suffix="%"
              />
            </td>
            <td>{{ item.readonly ? "YES" : "NO" }}</td>
            <td>{{ item.region1 }}</td>
            <td>{{ item.region2 }}</td>
            <td>{{ item.region3 }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import ScoreBar from "@evercam/shared/components/ScoreBar.vue"
import { GrafanaMetricResponse } from "@evercam/shared/types"

export default Vue.extend({
  name: "KitMetricTable",
  components: { ScoreBar },
  props: {
    metric: {
      type: Object as PropType<GrafanaMetricResponse>,
      required: true,
    },
  },
  methods: {
    getUsageColor(percentage: number) {
      if (percentage < 70) return "#7EB26D"
      if (percentage < 85) return "#EAB839"

      return "#E24D42"
    },
    getFormattedFileSystemData() {
      if (!this.metric?.results) return []

      const filesystems = []
      const resultEntries = Object.entries(this.metric.results)

      const sizeData = resultEntries.find(([key]) => key === "A")?.[1]
      const usageData = resultEntries.find(([key]) => key === "B")?.[1]
      const readonlyData = resultEntries.find(([key]) => key === "C")?.[1]

      if (!sizeData) return []

      sizeData.frames.forEach((frame, index) => {
        const labels = frame.schema.fields[1].labels
        const totalSpace = frame.data.values[1][0]
        const usedPercent = usageData?.frames[index]?.data.values[1][0] || 0
        const readonly = readonlyData?.frames[index]?.data.values[1][0] || 0

        filesystems.push({
          mountpoint: labels.mountpoint,
          device: labels.device,
          fstype: labels.fstype,
          totalSpace: this.$units.formatBytes(totalSpace),
          usedPercent: parseFloat(usedPercent.toFixed(1)),
          readonly: readonly === 1,
          region1: labels.network_region || "emea",
          region2: labels.network_region || "emea",
          region3: labels.network_region || "emea",
        })
      })

      return filesystems
    },
  },
})
</script>

<style scoped>
.kit-metric__table {
  .v-data-table--dense > .v-data-table__wrapper > table {
    td,
    th {
      height: 20px !important;
      font-size: 12px !important;
      color: rgb(75, 85, 99) !important;
      border: none !important;
    }
    & > thead {
      tr {
        background-color: rgba(226, 228, 229, 0.71) !important;
      }
      &:after {
        content: "-";
        display: block;
        line-height: 6px;
        color: transparent;
      }
      th:first-of-type {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        overflow: hidden;
      }
      th:last-of-type {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        overflow: hidden;
      }
    }
    tr:nth-child(odd) {
      background-color: rgba(240, 242, 243, 0.71);
      overflow: hidden;
    }

    & > tbody {
      tr:first-child {
        td:first-child {
          border-top-left-radius: 0.5em;
        }
        td:last-child {
          border-top-right-radius: 0.5em;
        }
      }
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 0.5em;
        }
        td:last-child {
          border-bottom-right-radius: 0.5em;
        }
      }
    }
  }
}
</style>
