<template>
  <v-chip x-small :color="color" text-color="white" class="kit-status-chip">
    {{ label }}
  </v-chip>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { KitStatus } from "@evercam/shared/types"
import { KitStatusesIdsClasses } from "~/components/constants"

export default Vue.extend({
  name: "KitStatusChip",
  props: {
    status: {
      type: String as PropType<KitStatus>,
      required: true,
    },
  },
  computed: {
    color(): string {
      return KitStatusesIdsClasses[this.status]?.color
    },
    label(): string {
      return KitStatusesIdsClasses[this.status]?.value?.toLowerCase()
    },
  },
})
</script>

<style>
.kit-status-chip.v-chip {
  &.v-size--x-small {
    border-radius: 4px;
    font-size: 10px;
    height: 15px;
    padding: 0 7px;
    .v-chip__content {
      font-weight: bold;
    }
  }
  & > .v-chip__content {
    font-size: 10px !important;
  }
}
</style>
