<template>
  <v-dialog
    v-model="showDialog"
    class="addCamera"
    eager
    hide-overlay
    scrollable
    persistent
    width="1000"
    @keydown.esc="closeHideShow()"
  >
    <template #activator="{ on, attrs }">
      <v-btn plain color="primary" v-bind="attrs" small class="mr-1" v-on="on">
        <v-icon class="mr-2">fa-plus </v-icon> Add Camera
      </v-btn>
    </template>
    <ValidationObserver v-slot="{ invalid }" ref="addCamera">
      <v-card tile>
        <v-card-title>
          Add Camera
          <v-btn
            class="close-icon"
            color="primary"
            icon
            dark
            large
            @click="closeHideShow()"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form style="width: 100%">
          <v-stepper v-model="step" non-linear>
            <v-stepper-header class="form-wizard-header">
              <v-stepper-step :complete="step > 1" step="1" editable>
                Step 1
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2" editable>
                Step 2
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" editable> Step 3 </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card flat>
                  <ERow>
                    <ECol cols="12" md="6" class="mt-n2">
                      <ERow>
                        <ECol cols="7" class="mt-n2">
                          <v-checkbox
                            v-model="showCloneCameras"
                            class="resize-checkbox"
                            @change="toggleCloneCameraCheckbox"
                          >
                            <template #label>
                              <div class="caption">
                                Copy settings from existing camera
                              </div>
                            </template>
                          </v-checkbox>
                        </ECol>
                      </ERow>
                    </ECol>
                    <ECol cols="12" md="6" class="mt-n2">
                      <!-- CAMERA  -->
                      <Autocomplete
                        v-if="showCloneCameras"
                        v-model="selectedCamera"
                        item-value="exid"
                        item-text="name"
                        label="Camera"
                        attach
                        clearable
                        :provider="getCameras"
                        :provider-params="cameraParams"
                        @change="onChangeCamera"
                      />
                    </ECol>
                    <ECol cols="12" class="mt-n8">
                      <v-divider></v-divider>
                    </ECol>
                    <!-- CAMERA NAME -->
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="name"
                        name="Camera name"
                        rules="required"
                        immediate
                      >
                        <v-text-field
                          v-model="cameraName"
                          class="caption"
                          maxlength="24"
                          hint="To identify your camera in the dashboard, Max 24 characters. Containing letters, spaces and hyphens."
                          label="Camera Name"
                          placeholder="Camera Friendly Name"
                          :error-messages="errors"
                          dense
                          outlined
                          required
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>
                    <!-- CAMERA OWNER -->
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <Autocomplete
                        v-model="cameraOwner"
                        item-value="id"
                        item-text="email"
                        label="Camera Owner email"
                        clearable
                        attach
                        vid="owner_id"
                        rules="required"
                        search-on-mounted
                        :provider="getAdmins"
                        :provider-params="cameraOwnerParams"
                      />
                    </ECol>
                    <!-- CAMERA EXTERNEL IP -->
                    <ECol cols="12" sm="12" md="6" lg="6" class="mt-n6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="external_host"
                        name="External IP (or URL)"
                        rules="required"
                        immediate
                      >
                        <v-text-field
                          v-model="externalIP"
                          class="caption"
                          hint="This should be in the form of 149.5.43.10 or portlarochelle.com."
                          label="External IP (or URL)"
                          placeholder="External IP (or URL)"
                          :error-messages="errors"
                          required
                          dense
                          outlined
                        >
                          <template slot="prepend-inner">
                            <span class="input-group-addon caption"
                              >https://</span
                            >
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>
                    <!-- CAMERA MODEL -->
                    <ECol cols="12" sm="12" md="6" lg="6" class="mt-n6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Camera Model"
                        rules="required"
                        immediate
                      >
                        <v-select
                          v-model="cameraModel"
                          :items="cameraModels"
                          item-value="id"
                          item-text="name"
                          class="caption"
                          hint="Select 'Default' if you're unsure"
                          return-object
                          label="Camera Model"
                          placeholder="Camera Model"
                          :error-messages="errors"
                          dense
                          outlined
                          @change="onSelectModel"
                        >
                          <template #item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                class="caption"
                                v-text="item.name"
                              />
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </ECol>

                    <ECol cols="12" sm="12" md="6" lg="6" class="mt-n6">
                      <!-- Snapshot URL -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Snapshot URL"
                        rules="required"
                      >
                        <v-text-field
                          v-model="snapshotUrl"
                          :error-messages="errors"
                          class="caption"
                          :disabled="snapshotUrlDisabled"
                          label="Snapshot URL"
                          placeholder="Snapshot URL"
                          hint="Include the entire snapshot path after the domain eg.data-externe/webcam/webcam.jpg"
                          required
                          dense
                          outlined
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>

                    <ECol cols="12" class="mt-n10">
                      <ERow class="align-end">
                        <ECol
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          class="flex-column"
                        >
                          <span class="subtitle-2"> NVR Configs </span>
                          <!-- CAMERA NVR RECORDING -->
                          <v-checkbox
                            v-model="isRecordingNvr"
                            class="mb-n4 resize-checkbox"
                            label="Recording From NVR"
                            dense
                          />
                          <ERow>
                            <!-- NVR Serial Number -->
                            <ECol cols="12" sm="12" md="6" lg="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="Serial Number"
                                :rules="{ required: !!nvrSerialNumber }"
                              >
                                <Autocomplete
                                  v-model="nvrSerialNumber"
                                  :error-messages="errors"
                                  item-value="id"
                                  item-text="serial"
                                  label="Serial Number"
                                  :list-items="nvrs"
                                  attach
                                  :disabled="nvrDisabled"
                                  resource="nvrs"
                                  :search="nvrSearch"
                                  :is-combobox="true"
                                  @change="selectedNvr"
                                />
                              </ValidationProvider>
                            </ECol>
                            <!-- NVR Mac Address -->
                            <ECol cols="12" sm="12" md="6" lg="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="Mac Address"
                                :rules="{ required: !!nvrMacAddress }"
                              >
                                <v-text-field
                                  v-model="nvrMacAddress"
                                  :error-messages="errors"
                                  class="caption"
                                  label="Mac Address"
                                  placeholder="Mac Address"
                                  :disabled="nvrDisabled"
                                  required
                                  dense
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </ECol>
                          </ERow>
                        </ECol>
                        <!-- NVR settings -->
                        <ECol
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          class="flex-column"
                        >
                          <ERow>
                            <!-- NVR MODEL -->
                            <ECol cols="12" sm="12" md="6" lg="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="Nvr Model"
                                :rules="{
                                  required: isRecordingNvr,
                                }"
                              >
                                <v-select
                                  v-model="nvrModel"
                                  :items="nvrModels"
                                  item-value="id"
                                  item-text="name"
                                  class="caption"
                                  return-object
                                  label="NVR Model"
                                  placeholder="NVR Model"
                                  :error-messages="errors"
                                  :disabled="nvrDisabled"
                                  dense
                                  outlined
                                  clearable
                                >
                                  <template #item="{ item }">
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="caption"
                                        v-text="item.name"
                                      />
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </ValidationProvider>
                            </ECol>
                            <!-- NVR Device Type -->
                            <ECol cols="12" sm="12" md="6" lg="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="Device Type"
                                :rules="{ required: !!nvrDeviceType }"
                              >
                                <v-select
                                  v-model="nvrDeviceType"
                                  :items="nvrDeviceTypes"
                                  item-value="value"
                                  item-text="name"
                                  class="caption"
                                  label="Device Type"
                                  placeholder="Device Type"
                                  :error-messages="errors"
                                  :disabled="nvrDisabled"
                                  dense
                                  outlined
                                  clearable
                                >
                                </v-select>
                              </ValidationProvider>
                            </ECol>
                          </ERow>
                        </ECol>
                      </ERow>
                    </ECol>

                    <!-- NVR Host/IP -->
                    <ECol cols="12" sm="12" md="6" lg="6" class="mt-n11">
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="nvr_host"
                        name="NVR IP (or URL)"
                        rules="required"
                        immediate
                      >
                        <v-text-field
                          v-model="nvrIP"
                          class="caption"
                          hint="This should be in the form of 149.5.43.10 or portlarochelle.com."
                          label="NVR IP (or URL)"
                          placeholder="NVR IP (or URL)"
                          :error-messages="errors"
                          :disabled="nvrDisabled"
                          required
                          dense
                          outlined
                        >
                          <template slot="prepend-inner">
                            <span class="input-group-addon caption"
                              >https://</span
                            >
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>
                    <!-- NVR Username -->
                    <ECol cols="12" sm="12" md="3" lg="3" class="mt-n11">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Username"
                        :rules="{ required: doValidate }"
                        immediate
                      >
                        <v-text-field
                          v-model="nvrUsername"
                          :error-messages="errors"
                          class="caption"
                          label="Username"
                          autocomplete="new-username"
                          placeholder="Username"
                          :disabled="nvrDisabled"
                          required
                          dense
                          outlined
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>
                    <!-- NVR Password -->
                    <ECol cols="12" sm="12" md="3" lg="3" class="mt-n11">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Password"
                        :rules="{ required: doValidate }"
                        immediate
                      >
                        <v-text-field
                          v-model="nvrPassword"
                          :append-icon="
                            showNvrPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showNvrPassword ? 'text' : 'password'"
                          :error-messages="errors"
                          class="caption"
                          label="Password"
                          autocomplete="new-password"
                          placeholder="Password"
                          :disabled="nvrDisabled"
                          required
                          dense
                          outlined
                          @click:append="showNvrPassword = !showNvrPassword"
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </ECol>
                  </ERow>
                </v-card>
                <StepNav hide-prev @next="step = 2" />
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card flat>
                  <ERow>
                    <!-- CAMERA TIMEZONE -->
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <Autocomplete
                        v-model="timezone"
                        :list-items="timezones"
                        label="Timezone"
                        vid="timezone"
                        item-text="name"
                        item-value="value"
                        attach
                        clearable
                        :return-object="false"
                        :rules="{ required: !hasTimezone }"
                        :disabled="hasTimezone"
                      />
                      <!-- CAMERA STATUS -->
                      <v-select
                        v-model="cameraStatus"
                        :items="statuses"
                        label="Camera Status"
                        item-text="name"
                        item-value="value"
                        dense
                        outlined
                      >
                        <template #item="{ item }">
                          <span :class="getStatusColor(item.value)">{{
                            item.name
                          }}</span>
                        </template>
                        <template #selection="{ item }">
                          <span :class="getStatusColor(item.value)">{{
                            item.name
                          }}</span>
                        </template>
                      </v-select>
                      <ERow>
                        <!-- CAMERA PTZ -->
                        <ECol cols="12" sm="12" md="7" lg="7">
                          <v-checkbox
                            v-model="isPtzSupported"
                            class="mt-n2 resize-checkbox"
                            label="PTZ enabled"
                            dense
                          />
                        </ECol>
                      </ERow>
                    </ECol>
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <!-- CAMERA USERNAME -->
                      <v-text-field
                        v-model="cameraUsername"
                        class="caption"
                        autocomplete="new-username"
                        label="Camera Username"
                        placeholder="Camera Username"
                        hint="Required if the camera needs authenticating."
                        required
                        dense
                        outlined
                      />
                      <!-- CAMERA PASSWORD -->
                      <v-text-field
                        v-model="cameraPassword"
                        :append-icon="
                          showCameraPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="showCameraPassword ? 'text' : 'password'"
                        class="caption"
                        autocomplete="new-password"
                        label="Camera Password"
                        placeholder="Camera Password"
                        hint="Enter the Password of the camera.."
                        required
                        dense
                        outlined
                        @click:append="showCameraPassword = !showCameraPassword"
                      />
                    </ECol>
                    <ECol cols="12" class="pl-0 my-n4">
                      <v-card-subtitle class="font-weight-medium pt-0">
                        Cloud Recordings
                      </v-card-subtitle>
                    </ECol>
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <ERow>
                        <!-- Storage Duration -->
                        <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="Storage duration"
                            rules="required"
                          >
                            <v-select
                              v-model="storageDuration"
                              :items="storageDurations"
                              label="Storage Duration"
                              item-text="name"
                              item-value="value"
                              :error-messages="errors"
                              dense
                              outlined
                            />
                          </ValidationProvider>
                        </ECol>
                        <!-- frecuency -->
                        <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="Frequency"
                            rules="required"
                          >
                            <v-select
                              v-model="frequency"
                              :items="frequencies"
                              label="Frequency"
                              item-text="name"
                              item-value="value"
                              :error-messages="errors"
                              dense
                              outlined
                            />
                          </ValidationProvider>
                        </ECol>
                        <!-- Status -->
                        <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="Status"
                            rules="required"
                          >
                            <v-select
                              v-model="crStatus"
                              :items="crStatuses"
                              label="Status"
                              item-text="name"
                              item-value="value"
                              :error-messages="errors"
                              dense
                              outlined
                            />
                          </ValidationProvider>
                        </ECol>
                      </ERow>
                    </ECol>
                    <ECol cols="12" sm="12" md="6" lg="6">
                      <ESchedulePicker
                        v-if="showScheduler"
                        v-model="schedule"
                        :preload-schedule="schedule"
                        :schedule-type="scheduleType"
                      />
                    </ECol>
                  </ERow>
                </v-card>
                <StepNav @next="step = 3" @prev="step = 1" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat>
                  <ERow>
                    <ECol cols="6" class="mt-n8">
                      <v-card-subtitle class="font-weight-medium ml-n4">
                        Google maps
                      </v-card-subtitle>
                      <v-select
                        v-if="selectedMapView"
                        v-model="selectedMapView"
                        :items="mapViews"
                        label="Map types"
                        class="p-0 my-2"
                        dense
                        outlined
                        item-value=""
                        item-text="name"
                        hide-details
                        return-object
                      />
                      <CameraMapFov
                        v-if="markerReady && step === 3"
                        ref="map"
                        class="camera-map"
                        :draggable="true"
                        :is-editing="true"
                        :marker="marker"
                        :height="350"
                        :selectedMapView="selectedMapView"
                        @marker-change="onMapMarkerChange"
                      />
                    </ECol>
                    <ECol cols="6" class="text-center mt-4">
                      <v-img
                        :src="testSnapshot"
                        aspect-ratio="2"
                        class="test-img mx-auto"
                        :eager="true"
                        alt="Thsi is image"
                      />
                      <v-btn
                        class="mt-4"
                        color="primary"
                        :loading="loading.snapshotTest"
                        :disabled="loading.snapshotTest"
                        @click="doTestSnapshot()"
                      >
                        Test Snapshot
                      </v-btn>
                      <p class="text-center">{{ snapshotTestMessage }}</p>
                    </ECol>
                  </ERow>
                </v-card>
                <StepNav
                  hide-next
                  :loading="loading.submitForm"
                  :submit-disabled="invalid"
                  @prev="step = 2"
                  @submit="addCamera"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script lang="ts">
import { schedules } from "@evercam/ui"
import timezones from "@evercam/shared/constants/timezones"
import {
  CloudRecordingsFrequencies,
  CloudRecordingsStatuses,
  CloudRecordingsStorageDuration,
  CameraStatuses,
} from "@/components/constants.js"
import StepNav from "@/components/cameras/form/StepNav.vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import CameraMapFov from "@/components/CameraMapFov"
import { CameraStatus, CameraFeatureFlag } from "@evercam/shared/types/camera"
import Vue, { PropType } from "vue"
import vendorModels from "@evercam/shared/constants/vendorModels"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useKitSummaryStore } from "@/stores/kitSummary"
import CameraUtils from "@/mixins/cameraUtils"
import { Nvr } from "@evercam/shared/types"
import { ScheduleType } from "@evercam/ui"

export default Vue.extend({
  components: {
    StepNav,
    Autocomplete,
    CameraMapFov,
  },
  mixins: [CameraUtils],
  props: {
    projectId: {
      type: [Number, String],
      default: null,
    },
    projectExid: {
      type: String,
      default: "",
    },
    projectTimezone: {
      type: String,
      default: "",
    },
    kitId: {
      type: [Number, String],
      default: null,
    },
    routerId: {
      type: [Number, String],
      default: null,
    },
    nvr: {
      type: Object as PropType<Nvr>,
      default: () => ({} as Nvr),
    },
  },
  data() {
    return {
      // camera fields
      cameraOwner: {
        id: 13959,
        name: "Construction Evercam",
        email: "construction@evercam.io",
      },
      isPtzSupported: false,
      isRecordingNvr: false,
      cameraName: "",
      snapshotUrl: "",
      nvrSnapshotUrl: "",
      streamUrl: "",
      cameraUsername: "",
      cameraPassword: "",
      deviceId: "",
      cameraStatus: CameraStatus.Waiting,
      httpStatus: " ",
      cameraModel: null,
      nvrModel: null,
      cameraHttpPort: "443",
      nvrHttpPort: "443",
      nvrSerialNumber: "",
      nvrDeviceType: "",
      nvrMacAddress: "",
      nvrUsername: "",
      nvrPassword: "",
      nvrIP: "",
      nvrSearch: "",
      nvrs: [],
      nvrId: null,
      httpUrlScheme: "https",
      nvrDeviceTypes: [
        { name: "RPI 4", value: "rpi4" },
        { name: "Orin Nano", value: "orin_nano" },
      ],
      nvrRtspPort: "",
      cameraRtspPort: "",
      timezone: "",
      externalIP: "",
      storageDuration: -1,
      frequency: 12,
      crStatus: "on",
      schedule: schedules.continuous,
      // form data
      step: 1,
      showDialog: false,
      selectedCamera: null,
      showCloneCameras: false,
      cameraModels: vendorModels.CAMERAMODELS,
      nvrModels: vendorModels.NVRMODELS,
      timezones: timezones,
      snapshotUrlDisabled: true,
      showProgress: false,
      isValidPort: false,
      statuses: CameraStatuses,
      crStatuses: CloudRecordingsStatuses,
      frequencies: CloudRecordingsFrequencies,
      storageDurations: CloudRecordingsStorageDuration,
      scheduleType: ScheduleType.Custom,
      marker: null,
      markerReady: true,
      snapshotTestMessage: "",
      testSnapshot: "",
      loading: {
        snapshotTest: false,
        submitForm: false,
      },
      selectedMapView: null,
      mapViews: [],
      nvrDisabled: false,
      showNvrPassword: false,
      showCameraPassword: false,
    }
  },
  computed: {
    ...mapStores(useReportStore, useKitSummaryStore),
    getCameras() {
      return AdminApi.cameras.getCameras
    },
    getAdmins() {
      return AdminApi.users.getAdmins
    },
    showScheduler() {
      return this.crStatus === "on-scheduled"
    },
    hasTimezone() {
      return this.projectTimezone ? true : false
    },
    doValidate() {
      return !(this.nvr.id && this.nvr.model)
    },
  },
  watch: {
    projectTimezone(val) {
      this.timezone = val
    },
    projectExid: "getMapViews",
    showDialog(val) {
      if (val) {
        this.timezone = this.projectTimezone
        this.updateUrls()
        this.bindNvr()
      }
    },
  },
  mounted() {
    this.nvrModel = this.nvrModels.filter((el) => el.exid === "ex_nvr")[0]
  },
  methods: {
    updateUrls() {
      if (this.kitSummaryStore.kit) {
        let region = this.kitSummaryStore.kit.countryRegion.replace("na", "us")
        let kitSerial = this.kitSummaryStore.kit.serial.toLowerCase()
        this.externalIP = `192-168-8-101-${kitSerial}.${region}-vpn.evercam.io`
        this.nvrIP = `${kitSerial}.${region}-vpn.evercam.io`
      }
    },
    bindNvr() {
      this.$refs.addCamera.validate()
      if (!this.nvr.httpUrl) {
        return
      }

      const httpUrl = new URL(this.nvr.httpUrl)
      let nvrSelectedModel = this.nvr.model
      this.nvrModel = this.nvrModels.filter(
        (el) => el.exid === nvrSelectedModel
      )[0]
      this.nvrId = this.nvr.id
      this.nvrSerialNumber = this.nvr.serial
      this.nvrMacAddress = this.nvr.macAddress
      this.nvrUsername = this.nvr.username
      this.nvrPassword = this.nvr.password
      this.nvrDeviceType = this.nvr.deviceType
      this.nvrRtspPort = this.nvr.rtspUrl
        ? this.nvr.rtspUrl.split("/")[2].split(":")[1]
        : null
      this.nvrHttpPort = httpUrl.port
      this.nvrIP = httpUrl.hostname
      this.nvrDisabled = true
    },
    closeHideShow() {
      this.clearForm()
      this.showDialog = false
    },
    clearForm() {
      this.$refs.addCamera?.reset()
      this.initFormfields()
      this.step = 1
      this.selectedCamera = null
      this.showCloneCameras = false
    },
    toggleCloneCameraCheckbox(value) {
      if (!value) {
        this.$refs.addCamera.reset()
      } else {
        if (this.selectedCamera) {
          this.cloneData()
        }
      }
    },
    cameraParams(val) {
      return {
        sort: "created_at|asc",
        limit: 50,
        page: 1,
        cameraName: val,
      }
    },
    cameraOwnerParams(val) {
      return {
        sort: "email|asc",
        perPage: 50,
        page: 1,
        email: val,
      }
    },
    onSelectModel(data) {
      this.cameraModel = data
      this.snapshotUrl = this.cameraModel.pictureEndpoint?.replace(
        "{device_id}",
        "101"
      )
      this.streamUrl = this.cameraModel.streamingEndpoint?.replace(
        "{device_id}",
        this.deviceId
      )
      this.snapshotUrlDisabled = data.exid != "other"
      this.onChangeDeviceId(this.deviceId)
    },
    onChangeDeviceId(value) {
      if (value) {
        this.snapshotUrl = this.cameraModel.pictureEndpoint?.replace(
          "{device_id}",
          "101"
        )
        this.streamUrl = this.cameraModel.streamingEndpoint?.replace(
          "{device_id}",
          value
        )
        if (this.cameraModel.exid == "dahua") {
          this.nvrSnapshotUrl = `${this.cameraModel.pictureEndpoint}?channel=${value}`
        } else if (this.cameraModel.exid.indexOf("hikvision") !== -1) {
          this.nvrSnapshotUrl = `${this.cameraModel.pictureEndpoint?.replace(
            "{device_id}",
            value
          )}?videoResolutionWidth=1920&videoResolutionHeight=1080`
        }
      }
    },
    onMapMarkerChange(v) {
      this.marker = v
    },
    async doTestSnapshot() {
      this.testSnapshot = ""
      let error = ""
      if (!this.externalIP) {
        error = "External IP Address (or URL) is incorrect"
      } else if (!this.cameraHttpPort) {
        error = "Port is missing"
      } else if (!this.cameraModel) {
        error =
          "Please choose camera vendor model or add your camera snapshot URL."
      }

      if (error !== "") {
        this.$notifications.warn(error)

        return
      }
      this.loading.snapshotTest = true
      let payload = {
        externalUrl: `https://${this.externalIP}:${
          this.recordingFromNvr ? this.nvrHttpPort : this.cameraHttpPort
        }`,
        jpgUrl: this.recordingFromNvr ? this.nvrSnapshotUrl : this.snapshotUrl,
        camUsername: this.cameraUsername,
        camPassword: this.cameraPassword,
        model: this.cameraModel.exid,
      }
      try {
        this.snapshotTestMessage = ""
        const { data } = await AdminApi.cameras.testSnapshot(payload)
        this.testSnapshot = data
      } catch (error) {
        this.snapshotTestMessage = error.response?.data?.message
        this.$errorTracker.save(error.response?.data?.message)
      } finally {
        this.loading.snapshotTest = false
      }
    },
    onChangeCamera(camera) {
      if (camera) {
        this.cloneData()
      } else {
        this.showProgress = false
        this.isValidPort = false
        this.$refs.addCamera.reset()
      }
    },
    async cloneData() {
      this.markerReady = false
      this.showProgress = false
      this.isValidPort = false
      this.externalIP = this.selectedCamera.cameraHost
      this.cameraHttpPort = this.selectedCamera.cameraHttpPort
      this.cameraRtspPort = this.selectedCamera.cameraRtspPort
      this.cameraUsername = this.selectedCamera.cameraUsername
      this.cameraPassword = this.selectedCamera.cameraPassword
      this.isPtzSupported = this.selectedCamera?.featureFlags.includes(
        CameraFeatureFlag.PTZ
      )
      this.isRecordingNvr = this.selectedCamera.recordingFromNvr
      this.deviceId = this.selectedCamera.nvrDeviceId
      this.timezone = this.selectedCamera.timezone
      this.cameraStatus = CameraStatus.Waiting
      this.marker = {
        isOnline: this.selectedCamera.status === CameraStatus.Online,
        location: this.selectedCamera.location,
        heading: this.selectedCamera.heading ?? 0,
        fovAngle: this.selectedCamera.fovAngle,
        fovRadius: this.selectedCamera.fovRadius,
      }

      let cameraSelectedModel = this.selectedCamera.cameraModel
      this.cameraModel = this.cameraModels.filter(
        (el) => el.exid === cameraSelectedModel
      )[0]
      if (cameraSelectedModel !== "other") {
        this.snapshotUrlDisabled = true
        this.snapshotUrl = this.cameraModel.pictureEndpoint
        this.streamUrl = this.cameraModel.streamingEndpoint?.replace(
          "{device_id}",
          this.deviceId
        )
      } else {
        this.snapshotUrlDisabled = false
        this.snapshotUrl = ""
      }
      this.frequency = this.frequencies.find(
        (el) => el.value === this.selectedCamera.cloudRecordingFrequency
      )?.value
      this.crStatus = this.crStatuses.find(
        (el) => el.value === this.selectedCamera.cloudRecordingStatus
      )?.value
      this.storageDuration = this.storageDurations.find(
        (el) => el.value === this.selectedCamera.cloudRecordingStorageDuration
      )?.value
      this.onChangeDeviceId(this.deviceId)
      this.markerReady = true

      if (!this.nvr.httpUrl) {
        this.nvrHttpPort = this.selectedCamera.nvrHttpPort
        this.nvrRtspPort = this.selectedCamera.nvrRtspPort
        let nvrSelectedModel = this.selectedCamera.nvrModel
        this.nvrModel = this.cameraModels.filter(
          (el) => el.exid === nvrSelectedModel
        )[0]
      }
    },
    async addCamera() {
      if (typeof this.schedule !== "object") {
        this.schedule = JSON.parse(this.schedule)
      }
      let payload = {
        name: this.cameraName,
        ownerId: this.cameraOwner?.id,
        status: this.cameraStatus,
        ptz: this.isPtzSupported,
        coordinates: [this.marker.location.lng, this.marker.location.lat],
        timezone: this.timezone,
        recordingFromNvr: this.isRecordingNvr,
        projectId: this.projectId,
        kitId: this.kitId,
        routerId: this.routerId,
        deviceId: this.deviceId,
        cloudRecordings: {
          frequency: this.frequency,
          status: this.crStatus,
          storageDuration: this.storageDuration,
          schedule: this.schedule,
        },
        cameraConfig: {
          model: this.cameraModel.exid,
          scheme: "https",
          host: this.externalIP,
          httpPort: this.cameraHttpPort,
          username: this.cameraUsername,
          password: this.cameraPassword,
          rtspPort: this.cameraRtspPort,
          pictureEndpoint: this.snapshotUrl,
          streamEndpoint: this.streamUrl,
        },
        ...this.getNvrConfig(),
        isPublic: false,
        heading: this.marker.rotation,
        fovAngle: this.marker.fovAngle,
        fovRadius: this.marker.fovRadius,
        project: { id: this.projectId, timezone: this.timezone },
        cloneCameraId: this.selectedCamera?.id,
      }
      try {
        this.loading.submitForm = true
        await AdminApi.cameras.createCameras(payload)
        this.$notifications.success("Camera added successfully.")
        this.$refs.addCamera.reset()
        this.httpStatus = ""
        this.snapshotTestMessage = ""
        this.testSnapshot = ""
        this.reportStore.searchFilters = {}
        this.$emit("camera-added", this.timezone)
        this.refreshData()
        this.closeHideShow()
      } catch (error) {
        this.$notifications.error({ text: "Failed to add camera!", error })
      } finally {
        this.loading.submitForm = false
      }
    },
    getNvrConfig() {
      if (this.nvrModel) {
        return {
          nvr: {
            id: this.nvrId,
            kitId: this.kitId,
            model: this.nvrModel.exid,
            serial: this.nvrSerialNumber,
            macAddress: this.nvrMacAddress,
            username: this.nvrUsername,
            password: this.nvrPassword,
            deviceType: this.nvrDeviceType,
            httpUrl: `${this.httpUrlScheme}://${this.nvrIP}:${this.nvrHttpPort}`,
            rtspUrl: this.nvrRtspPort
              ? `rtsp://${this.nvrIP}:${this.nvrRtspPort}`
              : null,
          },
        }
      } else {
        return {}
      }
    },
    initFormfields() {
      this.cameraOwner = {
        id: 13959,
        name: "Construction Evercam",
        email: "construction@evercam.io",
      }
      this.isPtzSupported = false
      this.isRecordingNvr = false
      this.cameraName = ""
      this.snapshotUrl = ""
      this.nvrSnapshotUrl = ""
      this.cameraUsername = ""
      this.cameraPassword = ""
      this.deviceId = null
      this.cameraStatus = CameraStatus.Waiting
      this.httpStatus = " "
      this.cameraModel = null
      this.nvrModel = this.nvrModels.filter((el) => el.exid === "ex_nvr")[0]
      this.cameraHttpPort = "443"
      this.nvrHttpPort = "443"
      this.nvrSerialNumber = ""
      this.nvrDeviceType = ""
      this.nvrMacAddress = ""
      this.nvrUsername = ""
      this.nvrPassword = ""
      this.nvrId = null
      this.nvrIP = ""
      this.httpUrlScheme = "https"
      this.nvrRtspPort = ""
      this.cameraRtspPort = ""
      this.externalIP = ""
      this.storageDuration = -1
      this.frequency = 12
      this.crStatus = "on"
      this.schedule = schedules.continuous
      this.selectedCamera = null
      this.showCloneCameras = false
      this.marker = null
    },
    async getMapViews() {
      this.mapViews = [{ name: "None" }]
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/maps/${this.projectExid}.json`
      )
      response = await response.json()
      for (const key in response) {
        this.mapViews.push(response[key])
      }

      if (this.mapViews.length > 0) {
        this.selectedMapView = this.mapViews[0]
      }
    },
    selectedNvr(item) {
      if (!item.serial) {
        return
      }
      let nvrSelectedModel = item.model
      this.nvrModel = this.nvrModels.filter(
        (el) => el.exid === nvrSelectedModel
      )[0]
      this.nvrId = item.id
      this.nvrSerialNumber = item.serial
      this.nvrMacAddress = item.macAddress
      this.nvrUsername = item.username
      this.nvrPassword = item.password
      this.nvrDeviceType = item.deviceType
      this.nvrRtspPort = item.rtspPort
      this.httpUrlScheme = item.scheme
      this.nvrHttpPort = item.httpPort
      this.nvrIP = item.host
    },
    refreshData() {
      if (this.nvrId) {
        this.kitSummaryStore.fetchCameras()
      } else {
        this.kitSummaryStore.refreshKit()
      }
    },
  },
})
</script>

<style scoped>
.form-wizard-header {
  height: 50px;
  align-content: center;
}
.test-img {
  border: solid 1px rgb(179, 179, 179);
  margin: 5px 5px 0px 5px;
}
.v-input--selection-controls {
  margin-top: 4px !important;
  padding-top: 4px !important;
}
</style>
