<template>
  <component
    :is="isAutocomplete ? 'v-autocomplete' : 'v-select'"
    v-model="model"
    :placeholder="getProp('placeholder', 'Search')"
    :item-value="getProp('itemValue', 'value')"
    dense
    hide-details
    :item-text="getProp('itemText', 'name')"
    :menu-props="{ closeOnClick: true }"
    v-bind="$attrs"
    @change="onChange"
    v-on="$listeners"
  >
    <template #selection="{ item, index }">
      <div v-if="isMultiSelection">
        <div v-if="index === 0">
          <span v-if="isAllSeleted"> All </span>
          <span v-else>
            {{ item[getProp("itemValue", "name")] }}
            <span v-if="model.length > 1" class="grey--text caption">
              , (+{{ model.length - 1 }} selected)
            </span>
          </span>
        </div>
      </div>
      <div v-else>{{ item[getProp("itemValue", "name")] }}</div>
    </template>
  </component>
</template>

<script>
import { camelizeKeys } from "humps"
import { VAutocomplete, VSelect } from "vuetify/lib"
export default {
  name: "SelectSearchField",
  components: {
    VAutocomplete,
    VSelect,
  },
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      model: null,
    }
  },
  computed: {
    isMultiSelection() {
      return this.$attrs?.multiple
    },
    isAutocomplete() {
      return this.$attrs?.isAutocomplete
    },
    isAllSeleted() {
      if (!this.isMultiSelection) {
        return
      }

      return this.model?.length === this.$attrs?.items?.length
    },
  },
  mounted() {
    this.$root.$on("update-filter-fields", () => this.updateFilterField())
    this.updateFilterField()
  },
  methods: {
    updateFilterField() {
      let route = camelizeKeys(this.$route.query)

      let arrayModel
      if (
        route[this.fieldName] &&
        !Array.isArray(route[this.fieldName]) &&
        this.$attrs.multiple
      ) {
        arrayModel = [route[this.fieldName]]
      }
      this.model =
        arrayModel ||
        Number.parseInt(route[this.fieldName]) ||
        route[this.fieldName] ||
        this.$attrs?.value
    },
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    onChange(mounted = false) {
      if (mounted && ["", null, undefined].includes(this.model)) {
        return
      }
      this.$emit("search", { [this.fieldName]: this.model })
    },
  },
}
</script>
