import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AuthzApi } from "@evercam/shared/api/authzApi"
import axios from "@evercam/shared/api/client/axios"
import {
  LoginResponsePayload,
  LoginRequestPayload,
} from "@evercam/shared/types"
import { AxiosError } from "axios"
import { useNuxtApp } from "#app"

export type AccountState = {
  email: string | null
  firstname: string | null
  lastname: string | null
  token: string | null
  redirectUrl: string | null
  isAdmin: boolean
  permissions: Array<any>
  roles: Array<any>
  temporaryPermissions: Array<any> | null
  temporaryRole: Object | null
}

export const useAccountStore = defineStore({
  id: "account",
  state: (): AccountState => ({
    email: null,
    firstname: null,
    lastname: null,
    token: null,
    redirectUrl: null,
    isAdmin: false,
    permissions: [],
    roles: [],
    temporaryPermissions: null,
    temporaryRole: null,
  }),
  actions: {
    async logout({ revoke = true, redirect = true } = {}) {
      const isTokenExpired = useNuxtApp().nuxt2Context.$auth.isTokenExpired()
      if (revoke && !isTokenExpired) {
        try {
          await EvercamApi.users.logout({ token: this.token, fromAdmin: true })
        } catch (error) {
          if (error instanceof AxiosError && error?.response?.status !== 401) {
            useNuxtApp().nuxt2Context.$notifications.error({
              text: "Logout failed!",
              error,
            })
          }
        }
      }
      this.unsetUser()
      axios.setToken(null)
      if (redirect) {
        useNuxtApp().nuxt2Context.app.router.push("/users/signin")
      }
    },
    async login(form?: LoginRequestPayload) {
      if (form?.username === "construction@evercam.io") {
        useNuxtApp().nuxt2Context.$notifications.warn(
          "Construction account is not allowed in admin, please use your private account."
        )

        return
      }

      const authCode = useNuxtApp().nuxt2Context.route.query.code as string,
        ssoProvider = window.localStorage?.getItem("ssoProvider")

      let response: LoginResponsePayload
      try {
        if (authCode && ssoProvider) {
          response = await EvercamApi.users.loginWithProvider(ssoProvider, {
            code: authCode,
            redirectUri: `${window.location.origin}/auth/${ssoProvider}`,
          })
        } else {
          response = await EvercamApi.users.login(form)
        }

        this.setUser({
          firstname: response.firstname,
          lastname: response.lastname,
          email: response.email,
          isAdmin: response.isAdmin,
        })
        this.token = response.token
        axios.setToken(response.token)

        if (this.redirectUrl && this.redirectUrl !== "/users/signin") {
          useNuxtApp().nuxt2Context.app.router.push(this.redirectUrl)

          return
        }
        if (this.redirectUrl === "/users/signin") {
          this.redirectUrl = null
        }
        useNuxtApp().nuxt2Context.app.router.push("/")
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Invalid credentials!",
          error,
        })
      } finally {
        if (ssoProvider) {
          window.localStorage?.removeItem("ssoState")
          window.localStorage?.removeItem("ssoProvider")
        }
      }
    },
    setUser({ firstname, lastname, email, isAdmin }) {
      this.firstname = firstname
      this.lastname = lastname
      this.email = email
      this.isAdmin = isAdmin
      useNuxtApp().nuxt2Context.$errorTracker.setUser({
        email,
        username: `${firstname} ${lastname}`,
      })
    },
    unsetUser() {
      this.firstname = null
      this.lastname = null
      this.email = null
      this.isAdmin = false
      this.token = null
      this.redirectUrl = null
    },
    async fetchPermissions() {
      const roleResponse = await AuthzApi.roles.getUserRoles()

      this.permissions = roleResponse.roles
        .map((role) => role.permissions || [])
        .flat()
      this.roles = roleResponse.roles
    },
  },
  getters: {
    isAuthorizedUser(): boolean {
      return (
        this.token &&
        this.email &&
        !useNuxtApp().nuxt2Context?.$auth?.isTokenExpired()
      )
    },
  },
  // @ts-ignore
  persist: {
    key: "adminAccountStates",
    afterRestore: () => {
      const store = useAccountStore()
      store.temporaryPermissions = null
      store.temporaryRole = null
    },
  },
})
