<template>
  <div v-resize-observer="onCardResized" class="d-flex align-center">
    <v-checkbox
      v-if="!readOnly"
      v-model="checkedEvents"
      :value="event.id"
      hide-details
      class="resize-checkbox ma-0 mb-1"
    ></v-checkbox>
    <div class="d-flex flex-column w-100" :class="{ 'my-3': event.isSelected }">
      <GateReportGenericRowItem
        ref="anprEvent"
        :item="event"
        :headers="headers"
        :is-selected="event.isSelected"
        :is-errored="event.isPlate === false"
        :is-duplicate="event.isDuplicate === true"
        :is-not-processed="!event.isPostprocessed"
        @click="onEventSelected"
      >
        <template #thumbnailUrl>
          <v-hover v-slot="{ hover }" @input="handleThumbnailHover">
            <div class="anpr-event__thumbnail">
              <img
                v-show="!isLoadingThumbnail"
                :src="event.thumbnailUrl"
                width="100"
                @load="isLoadingThumbnail = false"
                @error="isLoadingThumbnail = false"
              />
              <v-fade-transition>
                <div
                  v-show="hover && !isLoadingThumbnail"
                  class="anpr-event__thumbnail__play-btn"
                >
                  <SnapshotPreview
                    :camera="eventCamera"
                    :project="anprStore.selectedProject"
                    :title="eventPreviewTitle"
                    :timestamp="eventTimestamp"
                    :selectable-cameras="selectableCamera"
                    :auth-token="accountStore.token"
                    show-snapshot-quality
                  >
                    <template #custom-button>
                      <v-btn fab x-small>
                        <v-icon x-small> fas fa-play </v-icon>
                      </v-btn>
                    </template>
                  </SnapshotPreview>
                </div>
              </v-fade-transition>
              <div
                v-if="isLoadingThumbnail"
                class="anpr-event__thumbnail__loader"
              >
                <EvercamLoadingAnimation size="TwoXl" />
              </div>
            </div>
          </v-hover>
        </template>
        <template #plateNumber>
          <span
            class="anpr--plate caption font-weight-regular text-truncate max-w-100"
          >
            {{ event.plateNumber }}
          </span>
        </template>
        <template #captureTime>
          <div>
            {{ eventTime }}
            <OpenInDashRecordingsBtn
              :timestamp="eventTimestamp"
              :camera-exid="cameraExid"
              :project-exid="projectExid"
              :owner-email="ownerEmail"
            />
          </div>
        </template>
        <template #direction>
          <div class="d-flex justify-center">
            <span class="mr-2">{{
              $t(`content.anpr.directions.${event.direction}`)
            }}</span>
            <v-icon small> {{ getEventDirectionIcon(event) }} </v-icon>
          </div>
        </template>
        <template #vehicleType>
          <div class="d-flex align-center justify-center">
            <ESvgIcon :icon="event.vehicleType" size="30" />
            <ETruncatedDiv width="5rem" :fixed-width="true">
              {{ getVehicleLabel(event.vehicleType) }}
            </ETruncatedDiv>
          </div>
        </template>
        <template #id></template>
      </GateReportGenericRowItem>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import GateReportGenericRowItem from "@/components/gateReport/GateReportGenericRowItem"
import OpenInDashRecordingsBtn from "@/components/gateReport/OpenInDashRecordingsBtn"
import SnapshotPreview from "@evercam/shared/components/SnapshotPreview"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

import { VEHICLE_TYPES } from "@evercam/shared/constants/gateReport"
import { AnprDirection } from "@evercam/shared/types"
import { useAnprStore } from "@/stores/anpr"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  name: "AnprEventItem",
  components: {
    GateReportGenericRowItem,
    OpenInDashRecordingsBtn,
    SnapshotPreview,
    EvercamLoadingAnimation,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    selectedCameraType: {
      type: String,
      default: "anpr",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "event-selected", "height-changed"],
  data() {
    return {
      isLoadingThumbnail: true,
    }
  },
  computed: {
    ...mapStores(useAnprStore, useAccountStore),
    checkedEvents: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    eventTimestamp() {
      return this.$moment(this.event.captureTime)
        .tz(this.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
    },
    eventTime() {
      return this.$moment(this.event.captureTime)
        .tz(this.timezone)
        .format("HH:mm:ss")
    },
    cameraExid() {
      return this.event.cameraExid
    },
    ownerEmail() {
      return this.anprStore.selectedProject?.owner?.email
    },
    projectExid() {
      return this.anprStore.selectedProject?.exid
    },
    eventCamera() {
      return this.selectedCameraType === "anpr"
        ? this.selectableCamera[0]
        : this.selectableCamera[1]
    },
    selectableCamera() {
      return [
        this.anprStore.cameras.find((c) => c.exid === this.event.cameraExid) ||
          {},
        this.anprStore.cameras.find(
          (camera) => camera.exid === this.anprStore.selectedEvent.contextCamera
        ) || {},
      ]
    },
    eventPreviewTitle() {
      const eventTypeVerbs = {
        [AnprDirection.Arrived]: "Entering",
        [AnprDirection.Left]: "Exiting",
      }

      const formattedTimestamp = this.$moment
        .tz(this.event.captureTime, this.timezone)
        .format("L LTS")

      return `${
        eventTypeVerbs[this.event.direction] || AnprDirection.Unknown
      } ${this.getVehicleLabel(this.event.vehicleType)} - ${formattedTimestamp}`
    },
  },
  methods: {
    getEventDirectionIcon(event) {
      if (event.direction === AnprDirection.Arrived) {
        return "fa fa-long-arrow-alt-up"
      }
      if (event.direction === AnprDirection.IsLoadingThumbnaileft) {
        return "fa fa-level-down-alt"
      }

      return ""
    },
    onEventSelected(event) {
      this.$emit("event-selected", event.id)
    },
    onCardResized({ contentRect }) {
      const cardHeight = contentRect.height
      this.$emit("height-changed", {
        eventId: this.event.id,
        height: cardHeight,
      })
    },
    getVehicleLabel(vehicleId) {
      return VEHICLE_TYPES.find((vehicle) => vehicle.value === vehicleId)?.label
    },
    handleThumbnailHover(isHovered) {
      this.$emit("thumbnail-hovered", {
        isHovered,
        url: this.event.thumbnailUrl,
        targetRect: this.$refs.anprEvent.$el.getBoundingClientRect(),
      })
    },
  },
}
</script>

<style scoped lang="scss">
.anpr-event {
  &__thumbnail {
    position: relative;
    &__loader,
    &__play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
