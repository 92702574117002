<template>
  <v-dialog v-model="isDialogOpen" max-width="500">
    <v-card>
      <v-card-title>{{ id ? "Rename" : "Add" }} Role</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="roleName"
          label="Role Name"
          dense
          outlined
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="disabled" small color="primary" @click="saveRole"
          >Save</v-btn
        >
        <v-btn
          :disabled="disabled"
          small
          color="primary"
          text
          @click="closeDialog"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthzApi } from "@evercam/shared/api/authzApi"

export default {
  props: {
    value: { type: Boolean, default: false },
    id: { type: Number, default: null },
    name: { type: String, default: "" },
  },
  data() {
    return {
      isDialogOpen: this.value,
      roleName: "",
      disabled: false,
    }
  },
  watch: {
    value(val) {
      this.isDialogOpen = val
    },
    isDialogOpen(val) {
      this.$emit("input", val)
    },
    name(val) {
      this.roleName = val
    },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false)
    },
    async saveRole() {
      if (!this.roleName.trim()) {
        return
      }
      this.disabled = true
      try {
        if (this.id) {
          await AuthzApi.roles.updateRole(this.id, { name: this.roleName })
        } else {
          await AuthzApi.roles.createRole({ name: this.roleName })
        }
        this.$emit("role-added")
      } catch (error) {
        console.error(error)
      } finally {
        this.disabled = false
        this.closeDialog()
      }
    },
  },
}
</script>
