<template>
  <div>
    <SummaryElement
      v-if="cameraProjectField"
      v-model="cameraProjectField"
      :read-only="readOnly"
    >
      <template #value-text>
        <ETruncatedDiv :width="100">
          <span class="cursor-pointer primary--text" @click="openProjectDialog">
            {{ camera.projectName }}
          </span>
        </ETruncatedDiv>
      </template>
    </SummaryElement>
    <SummaryElement v-else read-only label="Project">
      <ETruncatedDiv :width="100">
        <span class="cursor-pointer primary--text" @click="openProjectDialog">
          {{ camera.projectName }}
        </span>
      </ETruncatedDiv>
    </SummaryElement>
  </div>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default {
  name: "CameraSummaryProject",
  components: {
    SummaryElement,
  },
  props: {
    camera: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projects: [],
      cameraProjectField: null,
      selectedProject: [],
    }
  },
  computed: {
    ...mapStores(useProjectStore),
  },
  async mounted() {
    const { items } = await AdminApi.search.search({
      params: {
        resource: "projects",
        term: "",
        limit: 10000,
      },
    })

    this.projects = items

    this.selectedProject = this.projects.find(
      ({ exid }) => exid === this.camera.projectExid
    )

    if (!this.selectedProject) {
      return
    }

    this.cameraProjectField = {
      key: "projectId",
      label: "Project",
      value: this.selectedProject.id,
      type: "SINGLE_SELECT",
      dataset: this.projects.map(({ id, name }) => ({
        name,
        value: id,
      })),
    }
  },
  methods: {
    openProjectDialog() {
      this.projectStore.openDialog(this.camera.projectExid)
    },
  },
}
</script>
