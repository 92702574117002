<template>
  <v-container fluid class="pa-0">
    <LayerTabComponent :read-only="!canEditCompares" />
  </v-container>
</template>

<script>
import LayerTabComponent from "@/components/bim/layerTabs/LayerTabComponent"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { PERMISSIONS } from "@/constants/permissions"
export default {
  meta: {
    requiredPermissions: [PERMISSIONS.BIM.VIEW_COMPARES],
  },
  components: {
    LayerTabComponent,
  },
  asyncData({ params }) {
    useBreadcrumbStore().breadcrumbs = [
      {
        icon: "fa-solid fa-home",
        href: "/",
      },
      {
        name: "Bim",
        href: "/bim-compare",
      },
      {
        name: params.exid,
        href: `/bim-compare/${params.exid}/layers`,
      },
      {
        name: "layers",
      },
    ]
  },
  computed: {
    canEditCompares() {
      return this.$permissions.user.can(PERMISSIONS.BIM.UPDATE_COMPARES)
    },
  },
}
</script>
