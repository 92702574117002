<template>
  <div class="w-100 h-100">
    <EventsControls
      :show-validation="canEditEvents"
      :show-verification="canEditEvents"
      :show-impersonation="canImpersonateUsers"
      @retrieve-events="retrieveEvents"
    />
    <!-- Global search -->
    <EGlobalSearch
      :dark="$vuetify.theme.dark"
      :items="gateReportStore.events"
      :timezone="timezone"
      @search-results="onGlobalSearchResultsChange"
      @reset="onGLobalSearchResultsReset"
    />
    <GateReportEventsGrid
      :events="filteredEvents"
      :custom-sort="customSort"
      :read-only="!canEditEvents"
      @event-selected="onEventSelected"
    />
  </div>
</template>

<script>
import CustomSort from "@/mixins/customSort"
import { GateReportDetectionType } from "@evercam/shared/types"
import GateReportEventsGrid from "@/components/gateReport/GateReportEventsGrid"
import EventsControls from "@/components/gateReport/EventsControls"
import { useGateReportEventsValidationStore } from "@/stores/gateReportEventsValidation"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { updateQuery } from "@evercam/shared/utils"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.VIEW],
  },
  name: "Events",
  components: {
    GateReportEventsGrid,
    EventsControls,
  },
  mixins: [CustomSort],
  async middleware({ params }) {
    await useGateReportStore().init(params.exid)
  },
  async asyncData({ params }) {
    const eventBreadcrumbs = [
      {
        name: "Gate report",
        href: "/gate-report",
      },
      {
        name: params.exid,
        href: `/gate-report/${params.exid}/events`,
      },
      {
        name: useGateReportStore().breadcrumbsTitle,
      },
    ]
    useBreadcrumbStore().breadcrumbs = eventBreadcrumbs
  },
  data() {
    return {
      sortBy: "truck_type",
      sortDesc: true,
      page: 1,
      isGlobalSearchActive: false,
      globalSearchResults: [],
      sortFields: [
        {
          key: "isEdited",
          customSort(_a, _b, isDesc) {
            const [a, b] = isDesc ? [_a, _b] : [_b, _a]

            return a && b && this.isEventEdited(a) > this.isEventEdited(b)
              ? -1
              : 1
          },
        },
        {
          key: "truckType",
          customSort(_a, _b, isDesc) {
            const [a, b] = isDesc ? [_a, _b] : [_b, _a]

            return a &&
              b &&
              this.getEventVehicleType(a) > this.getEventVehicleType(b)
              ? -1
              : 1
          },
        },
        {
          key: "eventTime",
          type: "date",
        },
        {
          key: "detectionType",
          customSort(_a, _b, isDesc) {
            const [a, b] = isDesc ? [_a, _b] : [_b, _a]

            return a && b && this.getDetectionType(a) > this.getDetectionType(b)
              ? -1
              : 1
          },
        },
      ],
      isInitialized: false,
    }
  },
  computed: {
    ...mapStores(
      useGateReportEventsValidationStore,
      useGateReportStore,
      useBreadcrumbStore
    ),
    filteredEvents() {
      return this.gateReportStore.getFilteredEvents({
        events: this.gateReportStore.events,
        searchParams: this.gateReportStore.searchParams,
        globalSearchResults: this.globalSearchResults,
        isGlobalSearchActive: this.isGlobalSearchActive,
      })
    },
    timezone() {
      return this.gateReportStore.selectedProject?.timezone || "Europe/Dublin"
    },
    canEditEvents() {
      return this.$permissions.user.can(PERMISSIONS.GATE_REPORT.UPDATE_EVENTS)
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
  },
  watch: {
    "gateReportStore.selectedVehicleTypes"(value) {
      updateQuery({ vehicleTypes: value })
    },
    "gateReportStore.selectedCameraExids"(value) {
      updateQuery({ cameras: value })
    },
    "gateReportStore.selectedVerifiedEventsVisibility"(value) {
      updateQuery({ verifiedEventVisibility: value })
    },
    "gateReportStore.selectedDate"() {
      this.updateBreadcrumbs()
      this.retrieveEvents()
    },
    filteredEvents() {
      this.gateReportStore.filteredEventsCount = this.filteredEvents.length

      const [verifiedEventsCount, unverifiedEventsCount] =
        this.filteredEvents.reduce(
          ([verified, unverified], e) => {
            return [
              verified + (e.isPublic ? 1 : 0),
              unverified + (e.isPublic ? 0 : 1),
            ]
          },
          [0, 0]
        )
      this.gateReportStore.verifiedFilteredEventsCount = verifiedEventsCount
      this.gateReportStore.unverifiedFilteredEventsCount = unverifiedEventsCount
      this.initPreselectedEventFromUrl()
    },
  },
  mounted() {
    this.retrieveEvents()
  },
  methods: {
    updateBreadcrumbs() {
      const eventBreadcrumbs = [
        {
          name: "Gate report",
          href: "/gate-report",
        },
        {
          name: this.gateReportStore.selectedProject.exid,
          href: `/gate-report/${this.gateReportStore.selectedProject.exid}/events`,
        },
        {
          name: useGateReportStore().breadcrumbsTitle,
        },
      ]
      this.breadcrumbStore.breadcrumbs = eventBreadcrumbs
    },
    onEventSelected(event) {
      this.gateReportEventsValidationStore.editedEvent = event
      this.gateReportEventsValidationStore.eventsValidationDialog = true
    },
    async retrieveEvents(updateSnapshots = true) {
      if (updateSnapshots) {
        await this.gateReportStore.fetchAvailableSnapshotsHours()
      }

      await this.gateReportStore.fetchEvents()

      this.updateQuery({
        date: this.gateReportStore.selectedDate,
      })

      this.isInitialized = true
    },
    initPreselectedEventFromUrl() {
      const queryParams = this.getQueryParams()
      const eventId = Number.parseInt(queryParams.event_id || 0)

      if (!eventId) {
        return
      }

      const preselectedEventFromUrl = this.filteredEvents.find(
        (e) => e.id === eventId
      )

      if (preselectedEventFromUrl) {
        this.gateReportEventsValidationStore.preselectedEventFromUrl =
          preselectedEventFromUrl
      }
    },
    isEventEdited(event) {
      return !!event.edited
    },
    getEventVehicleType(event) {
      return event.truckType || event.truckType
    },
    getDetectionType(event) {
      if (
        [GateReportDetectionType.Auto, GateReportDetectionType.Cloud].includes(
          event.detectionType
        )
      ) {
        return "cloud"
      } else {
        return event.detectionType
      }
    },
    onGlobalSearchResultsChange(results) {
      this.globalSearchResults = results
      this.isGlobalSearchActive = true
    },
    onGLobalSearchResultsReset() {
      this.isGlobalSearchActive = false
    },
  },
}
</script>

<style scoped>
.selected >>> .svg-icon g,
.svg-icon path,
.svg-icon rect,
.svg-icon ellipse,
.svg-icon polygon {
  fill: #1976d2;
}

.unselected >>> .svg-icon g,
.svg-icon path,
.svg-icon rect,
.svg-icon ellipse,
.svg-icon polygon {
  fill: #3c3c3c;
}
.global-search--fixed {
  position: fixed;
}
</style>
