<template>
  <v-snackbar v-model="showViewAsRoleSnackbar" :timeout="-1" rounded="pill">
    <span v-if="showViewAsRoleSnackbar">
      Viewing as <b>{{ accountStore.temporaryRole.name }}</b>
    </span>

    <template #action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="exitViewAsRole">
        Exit
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
export default {
  computed: {
    ...mapStores(useAccountStore),
    showViewAsRoleSnackbar() {
      return this.accountStore.temporaryRole !== null
    },
  },
  methods: {
    exitViewAsRole() {
      this.accountStore.temporaryRole = null
      this.accountStore.temporaryPermissions = null
      this.$router.push("/")
    },
  },
}
</script>
