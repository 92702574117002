<template>
  <UserDialog :inline="true" :user-id="userId" />
</template>

<script>
import UserDialog from "@/components/users/UserDialog"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.USERS.VIEW],
  },
  components: {
    UserDialog,
  },
  async asyncData({ params }) {
    return {
      userId: params.id,
    }
  },
}
</script>
