<template>
  <div v-if="userDetails && !isLoading" class="ma-2">
    <span class="subtitle-2 black--text text--lighten-4">
      <v-icon left>far fa-user</v-icon>
      <span class="ml-1">{{ userDetails.fullname }}</span>
      <span v-if="userDetails.companyName">
        (<NuxtLink :to="`/companies/${userDetails.companyId}`">{{
          userDetails.companyName
        }}</NuxtLink
        >)
      </span>
    </span>
    <div class="close-btns d-flex mt-1">
      <UsersDeleteDialog
        v-if="allowDelete"
        :user-email="userDetails.email"
        :has-cameras="userDetails.camerasOwned > 0"
        @delete="onUserDelete"
      />

      <div class="mx-2">
        <v-btn
          v-if="allowPasswordReset"
          color="primary"
          small
          @click="getPasswordResetLink()"
        >
          Password Reset Link
        </v-btn>
        <UserPasswordResetDialog
          v-model="passwordDialog"
          :reset-link="passwordResetLink"
        />
      </div>

      <v-btn
        v-if="!inline"
        class="large-icon"
        color="primary"
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import UsersDeleteDialog from "@/components/users/UsersDeleteDialog"
import UserPasswordResetDialog from "@/components/users/UserPasswordResetDialog"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/users"

export default {
  name: "UserDialogHeader",
  components: {
    UsersDeleteDialog,
    UserPasswordResetDialog,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    allowPasswordReset: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      passwordDialog: false,
    }
  },
  computed: {
    ...mapStores(useUserStore, useReportStore),
    userDetails() {
      return this.userStore.userDetails
    },
    isLoading() {
      return this.userStore.isLoading
    },
    passwordResetLink() {
      return this.userStore.passwordResetLink
    },
  },
  methods: {
    onUserDelete(email) {
      this.reportStore.items = this.reportStore.items.filter(
        (item) => item.email && item.email !== email
      )
      this.userStore.closeDialog()
    },
    async getPasswordResetLink() {
      await this.userStore.getPasswordResetLink(
        this.userStore.userDetails.email
      )
      this.passwordDialog = true
    },
  },
}
</script>

<style lang="scss">
.close-btns {
  position: absolute !important;
  top: 5px;
  right: 5px;
}
</style>
