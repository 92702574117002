<template>
  <v-progress-linear
    :value="(100 * parsedScore) / maxScore"
    :color="color"
    height="100%"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="!isNaN(parsedScore)">
      <template v-if="suffix"> {{ parsedScore }} {{ suffix }} </template>
      <template v-else>
        {{ parsedScore }}
        / {{ maxScore }}
      </template>
    </span>
    <span v-else> - </span>
  </v-progress-linear>
</template>

<script>
export default {
  name: "ScoreBar",
  props: {
    score: {
      type: [Number, String, null],
      default: 0,
    },
    maxScore: {
      type: Number,
      default: 10,
    },
    invertColors: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: "",
    },
    colors: {
      type: Array,
      default: () => [
        "#ff0f00",
        "#f44336",
        "#f57c00",
        "#ffa000",
        "#76c34a",
        "#66bb6a",
        "#4caf50",
      ],
    },
  },
  computed: {
    parsedScore() {
      return Number.parseFloat(Number.parseFloat(this.score).toFixed(1))
    },
    color() {
      const colors = this.invertColors
        ? this.colors.slice().reverse()
        : this.colors

      return colors[
        Math.floor(((colors.length - 1) * this.score) / this.maxScore)
      ]
    },
  },
}
</script>
