<template>
  <v-dialog v-model="isDialogOpen" max-width="500">
    <v-card>
      <v-card-title class="headline">Review Role Changes</v-card-title>
      <v-card-subtitle class="ma-1">
        Below is a summary of the permissions added and removed for each role,
        please review and confirm if the changes are accurate
      </v-card-subtitle>
      <v-card-text>
        <v-expansion-panels flat>
          <v-expansion-panel
            v-for="role in rolesWithChanges"
            :key="role.id"
            :disabled="
              !role.addedPermissions.length && !role.removedPermissions.length
            "
            class="expansion-panel"
          >
            <v-expansion-panel-header>
              <div class="d-flex justify-space-between">
                <span class="role-name">{{ role.name }}</span>
                <div>
                  <template
                    v-if="
                      role.addedPermissions.length ||
                      role.removedPermissions.length
                    "
                  >
                    <span class="role-summary green--text e-font-bold"
                      >+{{ role.addedPermissions.length }}</span
                    >
                    <span class="role-summary red--text e-font-bold mx-1"
                      >-{{ role.removedPermissions.length }}</span
                    >
                  </template>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="permission-section pl-2 mb-4">
                <h4 class="green--text mb-2">Added Permissions</h4>
                <div class="pl-2">
                  <ul v-if="role.addedPermissions.length" class="pl-0">
                    <li
                      v-for="permission in role.addedPermissions"
                      :key="'added-' + permission"
                      class="d-flex align-center mb-1"
                    >
                      <v-icon color="green" class="mr-2">mdi-plus</v-icon>
                      {{ permission }}
                    </li>
                  </ul>
                  <p v-else class="no-permissions">No permissions added.</p>
                </div>
              </div>
              <div class="permission-section pl-2 mb-4">
                <h4 class="red--text mb-2">Removed Permissions</h4>
                <div class="pl-2">
                  <ul v-if="role.removedPermissions.length" class="pl-0">
                    <li
                      v-for="permission in role.removedPermissions"
                      :key="'removed-' + permission"
                      class="d-flex align-center mb-1"
                    >
                      <v-icon color="red" class="mr-2">mdi-minus</v-icon>
                      {{ permission }}
                    </li>
                  </ul>
                  <p v-else class="no-permissions">No permissions removed.</p>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions class="pb-2">
        <v-spacer></v-spacer>
        <v-btn small color="primary" text class="mr-2" @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn small color="primary" @click="confirmChanges"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    roles: {
      type: Array,
      required: true,
    },
    modifiedRoles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDialogOpen: this.value,
    }
  },
  computed: {
    rolesWithChanges() {
      return this.roles
        .map((role) => {
          const modifiedRole = this.modifiedRoles.find(
            (modRole) => modRole.id === role.id
          )
          if (!modifiedRole) {
            return null
          }
          const originalPermissions = new Set(role.permissions || [])
          const updatedPermissions = new Set(modifiedRole.permissions || [])

          const addedPermissions = Array.from(updatedPermissions).filter(
            (perm) => !originalPermissions.has(perm)
          )
          const removedPermissions = Array.from(originalPermissions).filter(
            (perm) => !updatedPermissions.has(perm)
          )

          return {
            ...role,
            addedPermissions,
            removedPermissions,
          }
        })
        .filter(
          (role) =>
            role &&
            (role.addedPermissions.length || role.removedPermissions.length)
        )
    },
  },
  watch: {
    value(val) {
      this.isDialogOpen = val
    },
    isDialogOpen(val) {
      this.$emit("input", val)
    },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false)
    },
    confirmChanges() {
      const changedRoles = this.modifiedRoles.filter((role) => {
        const originalRole = this.roles.find((r) => r.id === role.id)

        return (
          JSON.stringify(originalRole.permissions) !==
          JSON.stringify(role.permissions)
        )
      })

      this.$emit("confirm-changes", changedRoles)
      this.closeDialog()
    },
  },
}
</script>

<style scoped>
li {
  list-style: none;
}

.role-name {
  font-weight: bold;
  font-size: 16px;
}

.role-summary {
  font-size: 14px;
}

.no-permissions {
  font-style: italic;
  color: gray;
}
.expansion-panel {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
}
</style>
