import { render, staticRenderFns } from "./error.vue?vue&type=template&id=238a795d&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=238a795d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238a795d",
  null
  
)

export default component.exports