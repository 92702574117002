<template>
  <div>
    <div v-if="item.entity == 'roles'">
      <span class="sim-hover primary--text">{{ item.who }}</span>
      assigned the role
      <span class="sim-hover primary--text"
        >{{ item.details.id }} - {{ item.details.name }}</span
      >
      to {{ item.details.email }} on {{ item.details.scope }}:{{
        item.details.resourceId
      }}.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
