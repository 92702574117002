<template>
  <v-menu v-model="datepicker" :close-on-content-click="false" max-width="290">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeDisplay"
        placeholder="Date Range"
        readonly
        dense
        hide-details
        v-bind="{ ...attrs, ...textFieldAttrs }"
        v-on="{ ...on, ...textFieldListeners }"
      />
    </template>
    <v-date-picker
      v-model="dateRange"
      range
      v-bind="datePickerAttrs"
      v-on="datePickerListeners"
      @change="onDateRangePick"
    />
  </v-menu>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "DatePickerRangeSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      dateRange: [],
      datepicker: false,
      datePickerAttrs: {},
      datePickerListeners: {},
      textFieldAttrs: {},
      textFieldListeners: {},
    }
  },
  computed: {
    dateRangeDisplay() {
      return this.dateRange.join(" - ")
    },
  },
  created() {
    let { datePickerAttrs, textFieldAttrs } = this.$attrs
    this.datePickerAttrs = datePickerAttrs || {}
    this.textFieldAttrs = textFieldAttrs || {}
    let { datePickerListeners, textFieldListeners } = this.$listeners
    this.datePickerListeners = datePickerListeners || {}
    this.textFieldListeners = textFieldListeners || {}
    this.dateRange =
      camelizeKeys(this.$route.query)[this.fieldName] ||
      this.datePickerAttrs?.value ||
      []
  },
  methods: {
    onDateRangePick(range) {
      const [fromDate, toDate] = range
      if (this.$moment(fromDate).isAfter(this.$moment(toDate))) {
        this.dateRange = [toDate, fromDate]
      }
      this.$emit("search", {
        [this.fieldName]: this.dateRange,
      })
      this.datepicker = false
    },
  },
}
</script>
