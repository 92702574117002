export const CAMERA_SETTINGS = {
  zohoId: "Zoho Id",
  isPowerScheduleActive: "Is Power Schedule Active",
  featureFlags: "Feature Flags",
  recordingFromNvr: "Recording from NVR?",
  "config.externalHost": "Host",
  nvrChannel: "NVR Channel",
  "config.auth.username": "Username",
  "config.auth.password": "Password",
  "config.urls.h264": "h264 URL",
  "config.urls.jpg": "jpg URL",
  "config.urls.nvrJpg": "NVR jpg URL",
  "config.nvrHttpPort": "NVR HTTP port",
  "config.externalRtspPort": "RTSP port",
  "config.externalHttpPort": "HTTP port",
  "config.cameraHttpPort": "Camera HTTP port",
}

export const LAYER_SETTINGS = {
  name: "Name",
  layerType: "Layer type",
  displayName: "Display name",
  cameraId: "Camera",
  "shapes.width": "Shapes width",
  "shapes.rotation": "Shapes rotation",
  "shapes.x": "Shapes x position",
  "shapes.y": "Shapes y position",
  "shapes.scaleX": "Shapes x scale",
  "shapes.scaleY": "Shapes y scale",
  "shapes.height": "Shapes height",
  "shapes.viewerWidth": "Shapes viewer width",
  "shapes.viewerHeight": "Shapes viewer height",
}

export const ROUTER_SETTINGS = {
  serialNumber: "Serial Number",
  routerType: "Router Type",
  routerHttpPort: "Router HTTP Port",
  powerType: "Power Type",
  powerSchedule: "Power Schedule",
  status: "Status",
  vpnUserId: "VPN User ID",
  vpnPassword: "VPN Password",
  routerUserId: "Router User ID",
  routerPassword: "Router Password",
  vpnServer: "VPN Server",
}

export const PROJECT_SETTINGS = {
  exid: "Exid",
  name: "Name",
  status: "Status",
  zohoId: "Zoho ID",
}

export const ROUTER_DEVICE_SETTINGS = {
  id: "ID",
  mac: "MAC",
  imei: "IMEI",
  imsi: "IMSI",
  name: "Name",
  model: "Model",
  serial: "serial",
  status: "Status",
  wanIp: "WAN IP",
  cellId: "Cell ID",
  firmware: "Firmware",
  wanState: "WAN State",
  modemModel: "Modem Model",
  companyName: "Company Name",
  productCode: "Product Code",
  modemFirmware: "Modem Firmware",
  originalModel: "Original Model",
  connectionType: "Connection Type",
  bootloaderVersion: "Bootloader Version",
  creditExpireDate: "Credit Expire Date",
}

export const NVR_SETTINGS = {
  model: "Model",
  deviceName: "Device",
  macAddress: "Mac Address",
  firmwareVersion: "Firmware",
  resolution: "Resolution",
  bitrateType: "Bitrate Type",
  videoQuality: "Video Quality",
  frameRate: "FPS",
  bitrate: "Max Bitrate",
  videoEncoding: "Encoding",
  hddName: "HDD Name",
  hddCapacity: "HDD Capacity",
  freeSpace: "HDD Free Space",
  hddStatus: "HDD Status",
  hddProperty: "HDD Property",
  nvrStatus: "NVR Status",
  timeMode: "Time Mode",
  localTime: "Local Time",
  timezone: "Timezone",
  addressingFormatType: "NTP Addressing Format Type",
  hostName: "NTP Host Name",
  portNo: "NTP Port No",
  synchronizeInterval: "NTP Synchronize Interval",
}

export const ROI_SETTINGS = {
  name: "Name",
  shapes: "Shapes",
}

export const CLOUD_RECORDINGS_SETTINGS = [
  { name: "Status", attr: "status" },
  { name: "Storage Duration", attr: "storageDuration" },
  { name: "Frequency", attr: "frequency" },
  { name: "Schedule", attr: "schedule" },
]

export const ROLE_SETTINGS = {
  name: "Name",
  permissions: "Permissions",
}
