<template>
  <div
    ref="container"
    :style="{
      width: $vuetify.breakpoint.mdAndUp ? '270px' : '100%',
    }"
    class="overflow-y-auto overflow-x-hidden"
  >
    <v-card elevation="0">
      <BimTransparencyToolBar v-if="!readOnly" />
      <v-tabs v-model="tab" centered fixed-tabs class="custom-tabs">
        <v-tab> Camera View </v-tab>
        <v-tab> BIM </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <SnapshotDateTimeCalendar
              v-model="bimCompareStore.snapshotCurrentDate"
              :selected-camera="cameraDialogStore.camera"
              @fetch-error="errorOnRequest"
              @first-frame="setOldestSnapshot"
              @last-frame="setLatestSnapshot"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item :eager="true">
          <DateTimePicker
            v-model="bimCompareStore.bimDatetime"
            :max-date="bimCompareStore.bimMaxDate"
            :min-date="bimCompareStore.bimMinDate"
            :timezone="timezone"
            :available-days="bimAvailableDays"
            :available-hours="bimAvailableHours"
            :daily-events="bimCompareStore.bimDailyEvents"
            :hourly-events="bimAvailableHours"
            :disable-oldest="
              !selectedBimDate || selectedBimDate === bimCompareStore.bimMinDate
            "
            :disable-latest="
              !selectedBimDate || selectedBimDate === bimCompareStore.bimMaxDate
            "
            @select-oldest="selectFirstFrame"
            @select-latest="selectLastFrame"
            @month-change="getBimAvailableDays"
            @day-change="getBimAvailableHours"
            @change="$emit('load-model-image')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-divider v-if="hasMultipleModels"></v-divider>
    <v-card v-if="hasMultipleModels" class="pt-2 mt-2" elevation="0">
      <v-select
        v-model="bimCompareStore.model"
        :items="bimCompareStore.models"
        label="Model"
        class="pa-3 pb-0"
        style="z-index: 8"
        dense
        @change="$emit('load-model-data')"
      >
        <template
          #selection="{ item }"
          data-test-id="snapshot-editor-procore-selected-project"
        >
          <span class="caption">{{ item }}</span>
        </template>
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="caption"
              data-test-id="snapshot-editor-procore-project-name"
            >
              {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mt-2" elevation="0">
      <div class="pl-2 subtitle-1 pt-1">
        Transparency
        <v-btn
          v-if="isModelMovable"
          text
          icon
          height="28"
          width="28"
          color="primary"
          class="float-right"
          @click="$emit('save-model-position')"
        >
          <v-icon size="14"> fa fa-save </v-icon>
        </v-btn>
        <v-btn
          v-if="isModelMovable"
          text
          icon
          height="28"
          width="28"
          color="primary"
          class="float-right"
          @click="$emit('disable-model-move')"
        >
          <v-icon size="14"> fa fa-times </v-icon>
        </v-btn>
        <v-btn
          v-if="!isModelMovable"
          text
          icon
          height="28"
          width="28"
          color="primary"
          class="float-right"
          @click="$emit('enable-model-move')"
        >
          <v-icon size="14"> fa fa-cog </v-icon>
        </v-btn>
      </div>
      <div class="slider_panel pl-3 pr-3 pt-5 pb-5">
        <v-slider
          ref="slider"
          v-model="bimCompareStore.transparency"
          thumb-label="always"
          dense
          hide-details
          thumb-size="8"
          thumb-color="#1976d2"
        >
          <template #thumb-label="props">
            <p class="transparency-label">{{ props.value }} %</p>
          </template>
        </v-slider>
      </div>
    </v-card>
  </div>
</template>

<script>
import BimTransparencyToolBar from "@/components/bim/BimTransparencyToolBar"
import SnapshotDateTimeCalendar from "@/components/SnapshotDateTimeCalendar"
import DateTimePicker from "@evercam/shared/components/DateTimePicker"

import { useBimCompareStore } from "@/stores/bimCompare"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { mapStores } from "pinia"

export default {
  name: "BimCompareSidebar",
  components: {
    BimTransparencyToolBar,
    SnapshotDateTimeCalendar,
    DateTimePicker,
  },
  props: {
    isModelMovable: {
      type: Boolean,
      default: () => false,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      tab: null,
      bimAvailableDays: [],
      bimAvailableHours: [],
    }
  },
  computed: {
    ...mapStores(useBimCompareStore, useCameraDialogStore),
    hasMultipleModels() {
      return this.bimCompareStore.models?.length
    },
    selectedBimDate() {
      if (!this.bimCompareStore.bimDatetime) {
        return
      }

      return this.$moment
        .tz(this.bimCompareStore.bimDatetime, this.timezone)
        .format("YYYY-MM-DD")
    },
    timezone() {
      return this.cameraDialogStore.selectedCamera?.timezone
    },
  },
  watch: {
    "bimCompareStore.fullDates": {
      handler() {
        if (!this.bimCompareStore.bimDatetime) {
          return
        }
        const month = this.$moment(this.bimCompareStore.bimDatetime).format(
          "YYYY-MM"
        )
        this.getBimAvailableDays(month)
        this.getBimAvailableHours()
      },
      immediate: true,
    },
  },
  methods: {
    getBimAvailableDays(month) {
      this.bimAvailableDays = this.bimCompareStore.bimDailyEvents.reduce(
        (acc, date) => {
          if (this.$moment(date).format("YYYY-MM") === month) {
            return [
              ...acc,
              Number.parseInt(this.$moment.tz(date, this.timezone).format("D")),
            ]
          }

          return acc
        },
        []
      )
    },
    getBimAvailableHours() {
      this.bimAvailableHours = this.bimCompareStore.fullDates.reduce(
        (acc, date) =>
          this.$moment(date).format("YYYY-MM-DD") === this.selectedBimDate
            ? [...acc, Number.parseInt(this.$moment(date).format("HH"))]
            : acc,
        []
      )
    },
    selectLastFrame() {
      this.bimCompareStore.bimDatetime = this.bimCompareStore.bimMaxDate
    },
    selectFirstFrame() {
      this.bimCompareStore.bimDatetime = this.bimCompareStore.bimMinDate
    },
    setOldestSnapshot() {
      this.bimCompareStore.cameraImage = this.snapshotStore.oldestImageJson.data
    },
    setLatestSnapshot() {
      this.bimCompareStore.cameraImage = this.snapshotStore.latestImageJson.data
    },
    errorOnRequest(text, error) {
      this.$notifications.error({ text, error })
    },
  },
}
</script>

<style scoped>
.transparency-label {
  width: 40px;
  text-align: center;
  background-color: #1976d2;
}
</style>
