<template>
  <div class="bim-viewer h-100">
    <ERow ref="container" v-resize="updateFrameDimensions" no-gutters>
      <iframe
        ref="iframeCesium"
        :src="iframeSource"
        :style="iframeStyle"
        allowFullScreen="true"
        v-bind="$attrs"
        allow="clipboard-read; clipboard-write"
        v-on="$listeners"
        @load="updateFrameDimensions"
      ></iframe>
    </ERow>
  </div>
</template>

<script>
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_VIEWS.VIEW],
  },
  name: "BimViewer",
  data: () => ({
    iframeStyle: {},
  }),
  computed: {
    iframeSource() {
      const type = (this.$route.query.type || "").toLowerCase()
      switch (type) {
        case "cesium":
          return `${this.$config.public.bimIframeUrl}/3d?projectID=${this.$route.params.exid}&type=site-view&user=admin`
        case "itwin":
          return `${this.$config.public.iTwinIframeUrl}/?projectID=${this.$route.params.exid}&type=site-view&user=admin`
        case "forge":
          return `${this.$config.public.forgeIframeUrl}/?projectID=${this.$route.params.exid}&type=site-view&user=admin`
        default:
          return `${this.$config.public.bimIframeUrl}/error`
      }
    },
  },
  mounted() {
    this.updateFrameDimensions()
  },
  methods: {
    updateFrameDimensions() {
      const container = this.$refs.container?.$el?.getBoundingClientRect()
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.iframeStyle = {
          height: `${window.innerHeight - container?.top}px`,
          width: `${window.innerWidth - container?.left}px`,
        }
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.bim-viewer {
  &__main > v-sheet {
    height: 100vh;
  }
  .cesium-infoBox {
    display: none;
  }
}
</style>
