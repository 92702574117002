import { decamelizeKeys } from "humps"
import moment from "moment-timezone"
import {
  type DateTime,
  type Frame,
  type MatchedSnapshotEvent,
  ResolutionDimensions,
  type ResolutionInfo,
  ResolutionLabel,
  type SnapshotEvent,
  TaskStatus,
  type TimelapseSchedules,
} from "@evercam/shared/types"
import { type Schedule } from "@evercam/ui"
import { RESOLUTIONS } from "@evercam/admin/components/constants"
import { toQueryString, queryStringToObject } from "@evercam/api/utils"

export { queryStringToObject, toQueryString }

export type Falsy = "" | null | undefined | false

export interface QueryParams {
  [k: string]: string | number | boolean | string[] | Falsy
}

export type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday"

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

type FunctionArgs = Parameters<
  (...args: [value?: undefined]) => void | Promise<void>
>

export function throttle(
  callback: (...args: unknown[]) => void,
  limit: number
): () => void {
  let waiting = false

  return function (this: unknown, ...args: FunctionArgs) {
    if (!waiting) {
      callback.apply(this, args)
      waiting = true
      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}

export function debounce(
  callback: (value?: never) => void | Promise<void>,
  wait = 1000,
  immediate: boolean = false
) {
  let timeout: ReturnType<typeof setTimeout> | null

  return function (this: unknown, ...args: FunctionArgs) {
    const context = this
    const callNow = immediate && !timeout
    clearTimeout(timeout!)

    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) {
        callback.apply(context, args)
      }
    }, wait)

    if (callNow) {
      callback.apply(context, args)
    }
  }
}

export function secondsToHms(seconds: number) {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export function getFormattedDatesDiff(
  before: string | Date,
  after: string | Date
) {
  const now = moment(after)
  const end = moment(before)
  let duration = moment.duration(end.diff(now))

  const days = duration.days()
  duration.subtract(moment.duration(days, "days"))

  const hours = duration.hours()
  duration.subtract(moment.duration(hours, "hours"))

  const minutes = duration.minutes()
  duration.subtract(moment.duration(minutes, "minutes"))

  const seconds = duration.seconds()

  let diff = ""
  if (days > 0) {
    diff += `${days} days, `
  }
  if (hours > 0) {
    diff += `${hours} hours, `
  }
  if (minutes > 0) {
    diff += `${minutes} minutes, `
  }
  if (seconds > 0) {
    diff += `${seconds} seconds`
  }

  return diff
}

export function allowKeypress(
  evt: KeyboardEvent,
  { comparison = false, number = false, phoneNumber = false } = {}
) {
  evt = evt ? evt : (window.event as KeyboardEvent)
  var charCode = evt.which ? evt.which : evt.keyCode

  // include comparison operators: >, <, =
  let includeComparison = comparison ? [60, 61, 62].includes(charCode) : false

  let includePlus = phoneNumber ? charCode === 43 : false

  // include numbers: 0 to 9
  let includeNumber =
    number || phoneNumber ? charCode > 47 && charCode < 58 : false

  if (includeComparison || includeNumber || includePlus || charCode < 32) {
    return true
  }
  evt.preventDefault()
}

export function getCharNotifyDays(days: Day[]) {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ] as Day[]

  return daysOfWeek
    .reduce<string[]>((acc, day) => {
      return days.includes(day) ? [...acc, day.charAt(0)] : [...acc, "_"]
    }, [])
    .join(" ")
}

export function sortScheduleByDay(schedule: Schedule) {
  if (!Object.keys(schedule)?.length) {
    return {}
  }

  const sortedDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]

  return sortedDays.reduce((acc, day) => {
    return { ...acc, [day]: schedule[day as keyof Schedule] }
  }, {}) as TimelapseSchedules
}

export function generateUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

export function getQueryParams() {
  return queryStringToObject(document.location.href.split("?")[1] || "")
}

export function getSubQueryString() {
  return document.location.href.split("#")[1] || ""
}

export function getSubQueryParams(): QueryParams {
  const filteredEntries = [
    ...new URLSearchParams(getSubQueryString()).entries(),
  ].filter(([, value]) => value)

  return Object.fromEntries(filteredEntries)
}

export function extractParamsFromQuery(
  paramsEnum: Record<string, string>,
  query: QueryParams = getQueryParams()
): QueryParams {
  return Object.values(paramsEnum).reduce<QueryParams>(
    (acc, param) => ({ ...acc, [param]: query[param] }),
    {}
  )
}

export function clearParamsFromQuery(
  paramsEnum: Record<string, string> = {},
  forceSnakeCase = false,
  query: QueryParams = getQueryParams()
) {
  const updatedQuery = { ...query }
  clearQuery()

  Object.values(paramsEnum).forEach((param) => {
    delete updatedQuery[param]
  })

  updateQuery(updatedQuery, forceSnakeCase)
}

export function clearQuery() {
  const [, subQuery] = (document.location.href.split("?")[1] || "").split("#")

  history.replaceState(
    {},
    "",
    `${document.location.href.split("?")[0]}${subQuery ? "#" + subQuery : ""}`
  )
}

export function clearSubQuery({ pushToHistory = false } = {}) {
  const [beforeHashLink, afterHash] = document.location.href.split("#")
  if (!afterHash) {
    return
  }

  if (pushToHistory) {
    history.pushState({}, "", `${beforeHashLink}`)
  } else {
    history.replaceState({}, "", `${beforeHashLink}`)
  }
}

export function updateSubQuery({
  subQueryObj = {},
  overridePreviousQuery = false,
  pushToHistory = false,
}: {
  subQueryObj: QueryParams
  overridePreviousQuery?: boolean
  pushToHistory?: boolean
}) {
  const currentSubQueryParams = getSubQueryParams()
  let updatedSubQueryParams = {
    ...(overridePreviousQuery ? {} : currentSubQueryParams),
    ...decamelizeKeys(subQueryObj),
  }

  updatedSubQueryParams = Object.entries(updatedSubQueryParams).reduce(
    (a, [k, v]) =>
      !["", null, undefined, false].includes(v as Falsy) ? { ...a, [k]: v } : a,
    {}
  )

  const subQueryString = Object.entries(updatedSubQueryParams).reduce(
    (acc, [key, val]) => {
      return `${acc}${acc ? "&" : ""}${key}=${val}`
    },
    ""
  )

  const newUrl = `${document.location.href.split("#")[0]}${
    subQueryString ? "#" : ""
  }${subQueryString}`

  if (pushToHistory) {
    history.pushState({}, "", newUrl)
  } else {
    history.replaceState({}, "", newUrl)
  }
}

export function updateQuery(
  queryObj: QueryParams = {},
  forceSnakeCase: boolean = true
) {
  const currentQueryParams = getQueryParams()
  let updatedQueryParams = {
    ...currentQueryParams,
    ...(forceSnakeCase ? decamelizeKeys(queryObj) : queryObj),
  }
  updatedQueryParams = Object.entries(updatedQueryParams).reduce(
    (a, [k, v]) => (!!v || v === false ? { ...a, [k]: v } : a),
    {}
  )

  const queryString = toQueryString(updatedQueryParams, { forceSnakeCase })

  const subQueryString = getSubQueryString()

  history.replaceState(
    {},
    "",
    `${document.location.href.split(/[?#]/g)[0]}${queryString ? "?" : ""}${
      queryString ? queryString : ""
    }${subQueryString ? "#" : ""}${subQueryString}`
  )
}

export function getIOSMediaUrl(url: string) {
  if (url.includes("http")) {
    return url
  }
  if (url.includes("data:image/png;base64")) {
    return URL.createObjectURL(
      b64toBlob(url.replace("data:image/png;base64,", ""), "image/png")
    )
  }

  return URL.createObjectURL(
    b64toBlob(url.replace("data:image/jpeg;base64,", ""), "image/jpeg")
  )
}

export function downloadFile(url: string, filename: string) {
  const link = document.createElement("a")
  link.href = url
  link.download = filename
  link.target = "_blank"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadImage(url: string, title: string) {
  let image
  if (url.indexOf("http") === 0) {
    image = url
  } else {
    image = URL.createObjectURL(
      b64toBlob(url.replace("data:image/jpeg;base64,", ""), "image/jpeg")
    )
  }
  const filename = `${title}.jpeg`
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(image, filename)
  } else {
    downloadFile(image, filename)
  }
}

export function findNearestTimelineIndex(
  snapshotDate: string | number,
  timelineDates: Array<string | number>
) {
  const targetDate = new Date(snapshotDate).getTime()

  const closestIndex = timelineDates.reduce(
    (acc, date, i) => {
      const timelineDate = moment(date, "DD-MM-YYYY HH:mm").toDate().getTime()
      const diff = Math.abs(timelineDate - targetDate)

      return diff < acc.smallestDiff ? { index: i, smallestDiff: diff } : acc
    },
    { index: -1, smallestDiff: Infinity }
  ).index

  return closestIndex as string | number
}

/**
 * Adds the nearest snapshot frame index to each gate report event.
 * The default threshold for deciding if an event
 * and a snapshot should be paired is 15 seconds
 */
export function matchEventsWithClosestPlayerFrameIndex<EventType extends {}>(
  events: SnapshotEvent<EventType>[],
  frames: Frame[]
): MatchedSnapshotEvent<SnapshotEvent<EventType>>[] {
  let linkedEvents: EventType[] = []
  const defaultThreshold = 100

  for (let i = 0; i < events.length; i++) {
    let matchedFrameIndex = null
    let matchedSnapshotTimestamp = null
    let threshold = defaultThreshold
    for (let j = 0; j < frames.length; j++) {
      const eventTime = moment(events[i].eventTime)
      const frameTime = moment(frames[j].timestamp)
      const diff = eventTime.diff(frameTime, "seconds")
      const absDiff = Math.abs(diff)
      if (diff < 0 && absDiff > threshold) {
        break
      }

      if (absDiff < threshold) {
        threshold = absDiff
        matchedFrameIndex = j
        matchedSnapshotTimestamp = frames[j].timestamp
      }
    }

    if (matchedFrameIndex) {
      linkedEvents = [
        ...linkedEvents,
        {
          ...events[i],
          frameIndex: matchedFrameIndex,
          snapshotTimestamp: matchedSnapshotTimestamp,
        },
      ]
    }
  }

  return linkedEvents as MatchedSnapshotEvent<SnapshotEvent<EventType>>[]
}

export function getImageData(
  image: HTMLImageElement,
  x = 0,
  y = 0,
  width = image?.naturalWidth,
  height = image?.naturalHeight
) {
  if (!image) {
    return
  }
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  canvas.width = width
  canvas.height = height
  ctx!.drawImage(image, x, y, width, height, 0, 0, width, height)
  const dataUrl = canvas.toDataURL()
  canvas.remove()

  return dataUrl
}

export function getImageCrop(
  image: HTMLImageElement,
  x: number,
  y: number,
  width: number,
  height: number
) {
  return getImageData(image, x, y, width, height)
}

export const base64UrlEncode = (url: string) => {
  return urlEncode(window.btoa(url)) as string
}

function urlEncode(url: string) {
  return url.replaceAll("+", "-").replaceAll("/", "_")
}

export function isDateWithinRange(
  date: DateTime,
  startDate: DateTime,
  endDate: DateTime
): boolean {
  const start = moment(startDate || 0)
  const end = moment(endDate || new Date())

  if (!start && !end) {
    return true
  }

  return moment(date).isBetween(start, end, null, "[]")
}

export function getConvertedUtcDateTimetoTimezone(
  date: DateTime,
  timezone: string,
  format: string | null = null
): DateTime {
  let dateFormat: string
  const isUsTimezone = timezone?.includes("America/")
  if (format) {
    dateFormat = format
  } else {
    dateFormat = isUsTimezone ? "MM/DD/YYYY HH:mm:ss A" : "DD/MM/YYYY HH:mm:ss"
  }

  return moment.utc(date).tz(timezone).format(dateFormat)
}

export function snakeCaseToTitleCase(text: string) {
  return (
    text
      ?.replace(/_/g, " ")
      ?.toLowerCase()
      ?.replace(/\b\w/g, (l) => l.toUpperCase()) || "-"
  )
}

export function stringToColour(str: string) {
  let hash = 0
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = "#"
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, "0")
  }

  return colour
}

export function hexToRgb(
  hex: string
): { r: number; g: number; b: number } | null {
  hex = hex.replace(/^#/, "")
  const bigint = parseInt(hex, 16)

  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  }
}

export function findGreatestCommonDivisor(a: number, b: number): number {
  while (b !== 0) {
    let temp = b
    b = a % b
    a = temp
  }

  return a
}

export function getResolutionInfo(
  width: number,
  height: number
): ResolutionInfo {
  const Resolutions = RESOLUTIONS as Record<
    ResolutionDimensions,
    ResolutionLabel
  >
  const divisor = findGreatestCommonDivisor(width, height)
  const simplifiedWidth = width / divisor
  const simplifiedHeight = height / divisor
  const ratioString = `${simplifiedWidth}:${simplifiedHeight}`
  const ratioFloat = parseFloat((simplifiedWidth / simplifiedHeight).toFixed(2))
  const resolutionDimensions = `${width}x${height}` as ResolutionDimensions
  const resolutionLabel =
    Resolutions[resolutionDimensions] || "Custom Resolution"

  return {
    ratioString,
    resolutionDimensions,
    resolutionLabel,
    ratioFloat,
  }
}

export function getFormattedStorageSize(bytes: number | string): string {
  const bytesInt = typeof bytes === "number" ? bytes : Number.parseInt(bytes)
  const sizeInKB = bytesInt / 1024
  if (sizeInKB > 1024) {
    const sizeInGB = sizeInKB / 1024

    return `${sizeInGB.toFixed(2)}GB`
  } else {
    return `${(Math.floor(100 * sizeInKB) / 100).toFixed(2)}MB`
  }
}

export { makeFullScreen, exitFullScreen } from "@evercam/ui"

export function captureVideoFrameToBase64(
  videoElement: HTMLVideoElement
): string {
  const canvas = document.createElement("canvas")
  canvas.width = videoElement.videoWidth
  canvas.height = videoElement.videoHeight
  const ctx = canvas.getContext("2d")
  ctx!.drawImage(videoElement, 0, 0, canvas.width, canvas.height)

  return canvas.toDataURL()
}

export function createCustomOverlay(bounds, image64) {
  /*global google*/
  class CustomOverlay extends google.maps.OverlayView {
    bounds
    image
    div
    constructor(bounds, image) {
      super()
      this.bounds = bounds
      this.image = image
    }
    onAdd() {
      this.div = document.createElement("div")
      this.div.style.borderStyle = "none"
      this.div.style.borderWidth = "0px"
      this.div.style.position = "absolute"
      const img = document.createElement("img")
      img.src = this.image
      img.style.width = "100%"
      img.style.height = "100%"
      img.style.position = "absolute"
      this.div.appendChild(img)
      const panes = this.getPanes()
      panes.overlayLayer.appendChild(this.div)
    }
    draw() {
      const overlayProjection = this.getProjection()
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      )
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      )
      if (this.div) {
        this.div.style.left = sw.x + "px"
        this.div.style.top = ne.y + "px"
        this.div.style.width = ne.x - sw.x + "px"
        this.div.style.height = sw.y - ne.y + "px"
      }
    }
    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        delete this.div
      }
    }
  }

  return new CustomOverlay(bounds, image64)
}

/**
 * Binary search function.
 * @param {Array} arr - The array to search.
 * @param {Function} pivotFunction - Callback that determines if the item matches.
 *      It should return:
 *      0 if the item is a match
 *      -1 if the search should continue to the left,
 *      1 if the search should co>ntinue to the right.
 * @returns {any} The matched item, or null if not found.
 */
export function binarySearch<T extends any = any>(
  arr: Array<T>,
  pivotFunction: (item: T) => -1 | 0 | 1
): T | null {
  let left = 0
  let right = arr.length - 1

  while (left <= right) {
    let mid = Math.floor((left + right) / 2)
    const result = pivotFunction(arr[mid])

    if (result === 0) {
      return arr[mid]
    } else if (result < 0) {
      right = mid - 1
    } else {
      left = mid + 1
    }
  }

  return null
}

export async function withProgress(taskId: string, taskFn: () => Promise<any>) {
  emitProgress(taskId, TaskStatus.Loading)
  try {
    const result = await taskFn()
    emitProgress(taskId, TaskStatus.Success)

    return result
  } catch (error) {
    emitProgress(taskId, TaskStatus.Error)
    throw error
  }
}

function emitProgress(taskId: string, status: TaskStatus) {
  document.dispatchEvent(
    new CustomEvent("taskProgress", {
      detail: {
        taskId,
        status,
      },
    })
  )
}

export function getFormattedPlateNumber(plateNumber: string) {
  if (!plateNumber) {
    return "unknown"
  }

  const irishPlateNumberRegex = /^([0-9]{2}[1-2]?)([A-Z]+)([0-9]+)$/

  const match = irishPlateNumberRegex.exec(plateNumber)
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`
  }

  return plateNumber
}

export function caseInsensitiveIncludes(target: string, query: string) {
  return (
    !query ||
    (target?.toString()?.toLowerCase() ?? "").includes(
      query?.toString()?.toLowerCase()
    )
  )
}

export function camelToKebabCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
}

export function invertEnum(enumObj: any): { [key: string]: string | number } {
  const inverted: { [key: string]: string | number } = {}

  for (const key in enumObj) {
    if (Object.prototype.hasOwnProperty.call(enumObj, key)) {
      const value = enumObj[key]
      if (typeof value === "string" || typeof value === "number") {
        inverted[value] = key
      }
    }
  }

  return inverted
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

export function pickEvenlySpacedItems<T>(
  items: Array<T>,
  maxCount: number
): T[] {
  if (items.length <= maxCount) {
    return items
  }

  const step = Math.ceil(items.length / maxCount)
  const result = []

  for (let i = 0; i < items.length; i += step) {
    result.push(items[i])
  }

  if (result[result.length - 1] !== items[items.length - 1]) {
    result.push(items[items.length - 1])
  }

  return result
}

export function filterDayHours(condition: (hour: number) => boolean): string[] {
  const allHours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  )

  return allHours.filter((hour) => condition(Number(hour)))
}

export function isScalar(value: unknown): boolean {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  )
}
