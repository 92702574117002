<template>
  <ERow>
    <ECol cols="12" md="8" class="pl-4 py-1">
      <HomeButton />
      <span class="mx-2">|</span>
      <NdaIcon v-if="isNDAProject" />
      <span class="subtitle-2 black--text text--lighten-4">
        {{ camera.name }}
        ({{ camera.exid }}
        <CopyToClipboardBtn :text="camera.exid" icon color="primary" />)
      </span>
      <ImpersonateUserButton
        v-if="canUseDemos"
        :email="camera.userEmail"
        :target-route="`/projects/${camera.projectExid}/${camera.exid}`"
        :nda-project="isNDAProject"
      />
      <span :class="getStatusColor(camera.status)" class="mx-2 subtitle-2">
        {{ getStatusText(camera.status) }}
      </span>
      <span class="mr-2">|</span>
      <span class="subtitle-2 text-no-wrap black--text text--lighten-4">
        {{ camera.userFullname }}
      </span>
      <OpenInZohoButton :zoho-id="camera.zohoId" type="camera" />
      <span class="mx-2">|</span>
      <ImpersonateUserButton
        v-if="isNDAProject && canUseDemos"
        :email="camera.userEmail"
        :target-route="`/demos/cameras/${camera.exid}`"
        :nda-project="isNDAProject"
        icon="fas fa-desktop"
      />
      <OpenInNewWindowButton
        v-else-if="canUseDemos"
        :target-link="`/demos/cameras/${camera.exid}`"
        hint="Click to open as a demo"
        icon="fas fa-desktop"
      />
    </ECol>
    <div class="d-flex align-center ml-auto">
      <v-btn
        v-if="!inline"
        color="primary"
        icon
        dark
        class="large-icon mr-5"
        @click="$emit('close')"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
    </div>
  </ERow>
</template>

<script>
import CameraUtils from "@/mixins/cameraUtils"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import NdaIcon from "@/components/NdaIcon"
import HomeButton from "@/components/HomeButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  name: "CameraDialogHeader",
  components: {
    CopyToClipboardBtn,
    OpenInNewWindowButton,
    OpenInZohoButton,
    NdaIcon,
    HomeButton,
    ImpersonateUserButton,
  },
  mixins: [CameraUtils],
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    camera: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNDAProject() {
      return this.camera?.projectFeatureFlags?.includes(
        ProjectFeatureFlag.NdaMc
      )
    },
    canUseDemos() {
      return (
        this.$permissions.user.can(PERMISSIONS.DEMOS.VIEW) &&
        this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
      )
    },
  },
}
</script>
