export default [
  {
    value: "name",
    text: "Name",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "serial",
    text: "Serial #",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 110,
    sortable: true,
  },
  {
    value: "projectName",
    text: "Project",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "countryId",
    text: "Country",
    width: 110,
    sortable: true,
  },
  {
    value: "powerType",
    text: "type",
    width: 110,
    sortable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: 150,
    sortable: true,
  },
]
