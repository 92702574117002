<template>
  <div class="events-grid mx-4">
    <HoverEventThumbnailContainer :hovered-thumbnail="hoveredThumbnail" />
    <div class="events-grid__header">
      <div
        v-for="header in headers"
        :key="header.value"
        class="column font-weight-500"
        :class="{
          'column--sortable': header.sortable,
          'arrow-up': !isDesc,
          'arrow-down': isDesc,
          'is-selected': sortedColumn === header.value,
          [header.className || '']: true,
        }"
        :style="header.value === 'thumbnail' ? headerThumbnailStyle : {}"
        @click="sort(header.value)"
      >
        {{ header.text }}
      </div>
    </div>

    <!-- Loader -->
    <v-divider
      v-if="isTaskInProgress || gateReportStore.isLoadingEvents"
    ></v-divider>

    <div v-if="isTaskInProgress || gateReportStore.isLoadingEvents">
      <div class="w-100 d-flex justify-center pt-4">
        <TasksProgressIndicator :tasks="tasksProgressItems" />
      </div>
    </div>

    <v-list-item-group
      v-else
      ref="gridContainer"
      v-resize-observer="handleResize"
      class="overflow-y-auto overflow-x-hidden events-grid__list"
      @hook:mounted="handleResize"
    >
      <div v-if="!events.length" class="d-flex w-100 justify-center py-4">
        No events to show.
      </div>
      <v-lazy
        v-for="item in events"
        :key="item.id"
        :options="{
          threshold: 0.5,
        }"
        min-height="55"
        height="55"
        transition="fade-transition"
        class="w-100"
      >
        <GateReportEventCard
          class="w-100"
          :event="item"
          show-id
          show-is-edited
          show-event-type
          show-detection-type
          :show-delete-button="false"
          :thumbnail-width="minThumbnailsWidth"
          :read-only="readOnly"
          :class="`selected-event-${item.id}`"
          @click="openEventsValidationDialog(item)"
          @thumbnail-width="onThumbnailWidthMeasured"
          @thumbnail-hovered="handleHoveredThumbnail"
        />
      </v-lazy>
    </v-list-item-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import GateReportEventCard from "@/components/gateReport/GateReportEventCard"
import HoverEventThumbnailContainer from "@/components/gateReport/HoverEventThumbnailContainer"
import {
  GateReportEvent,
  TaskStatus,
  GateReportVehicleType,
} from "@evercam/shared/types"
import TasksProgressIndicator from "@/components/TasksProgressIndicator.vue"
import { decamelize } from "humps"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"

import headers from "@/components/gateReport/eventsHeaders"
export default Vue.extend({
  name: "GateReportEventsGrid",
  components: {
    TasksProgressIndicator,
    GateReportEventCard,
    HoverEventThumbnailContainer,
  },
  props: {
    events: {
      type: Array as PropType<Array<GateReportEvent>>,
      default: () => [],
    },
    customSort: {
      type: Function,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers,
      isDesc: false,
      sortedColumn: "eventTime",
      minThumbnailsWidth: undefined,
      hoveredThumbnail: { isHovered: false, url: false },
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    tasksProgressItems() {
      return Object.entries(this.gateReportStore.tasksStatuses).map(
        ([taskName, status]) => {
          return {
            status,
            description: decamelize(taskName, { separator: " " }),
          }
        }
      )
    },
    isTaskInProgress() {
      return !!Object.values(this.gateReportStore.tasksStatuses).find(
        (status) => status === TaskStatus.Loading
      )
    },
    headerThumbnailStyle() {
      if (!this.minThumbnailsWidth) {
        return {}
      }

      return {
        width: `${this.minThumbnailsWidth}px`,
      }
    },
  },
  watch: {
    events() {
      this.customSort(this.events, [this.sortedColumn], [this.isDesc])
    },
    "gateReportStore.showControls"() {
      this.handleResize()
    },
  },
  methods: {
    handleResize() {
      const eventsGrid = this.$refs.gridContainer?.$el
      const eventsGridRect = eventsGrid?.getBoundingClientRect()
      if (!eventsGridRect) {
        return
      }

      eventsGrid.style.height = `${window.innerHeight - eventsGridRect.top}px`
    },
    sort(column) {
      this.isDesc = !this.isDesc
      this.sortedColumn = column
      this.customSort(this.events, [this.sortedColumn], [this.isDesc])
    },
    onThumbnailWidthMeasured(width) {
      if (!this.minThumbnailsWidth || width > this.minThumbnailsWidth) {
        this.minThumbnailsWidth = width
      }
    },
    handleHoveredThumbnail(hoveredThumbnail) {
      this.hoveredThumbnail = hoveredThumbnail
    },
    openEventsValidationDialog(event) {
      if (event.truckType !== GateReportVehicleType.Unknown) {
        return
      }

      this.$emit("event-selected", event)
    },
  },
})
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
$card-height: 55px;
.events-grid {
  &__loader {
    background: rgb(225 233 239 / 45%);
  }
  &__list {
    @include custom-scrollbar();
  }
  .events-grid__header,
  .gr-event-card__fields-container {
    display: grid !important;
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr 2fr 2fr 2fr 2fr 2fr;
    grid-template-rows: $card-height;
    & > * {
      display: flex;
      align-items: center;
    }
  }
  .events-grid__header {
    grid-template-rows: $card-height / 2;
    background: #becbd5;
    border-radius: 4px;
    .column {
      user-select: none;
      &.arrow-up::after {
        content: "▲";
        margin-left: 2px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &.arrow-down::after {
        content: "▼";
        margin-left: 2px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &--sortable {
        cursor: pointer;
        &:hover.arrow-up::after,
        &.is-selected.arrow-up::after,
        &:hover.arrow-down::after,
        &.is-selected.arrow-down::after {
          opacity: 1;
        }
      }
    }
  }
  .gr-event-card {
    height: $card-height;
    &__fields-container.d-flex {
      display: grid !important;
    }
  }
  .gr-event-card:nth-of-type(even) {
    background: #dee9f7;
  }
}
</style>
