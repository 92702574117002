<template>
  <div>
    <div class="d-flex">
      <div class="mr-3">
        <span class="font-weight-500"> Plate </span>

        <v-text-field
          v-model="anprStore.plateNumber"
          hide-details
          outlined
          label="Search plate number"
          dense
        ></v-text-field>
      </div>
      <div class="mr-3">
        <span class="font-weight-500"> Direction </span>
        <EToggleSwitch
          v-model="anprStore.direction"
          :options="directionOptions"
          :size="Size.Md"
          color="primary"
        />
      </div>
      <div class="d-flex flex-column">
        <v-switch
          v-model="anprStore.showDuplicates"
          small
          dense
          inset
          label="Show Duplicates"
          :disabled="!anprStore.showPostProcessed"
          hide-details
        />
        <v-switch
          v-model="anprStore.showFalsePositives"
          class="ma-0 pa-0"
          small
          dense
          inset
          label="Show False Positives"
          :disabled="!anprStore.showPostProcessed"
          hide-details
        />
      </div>
      <div class="d-flex flex-column ml-2">
        <v-tooltip bottom max-width="200">
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch
                v-model="anprStore.showNotPostProcessed"
                small
                dense
                inset
                label="Show Not postprocessed"
                hide-details
              />
            </div>
          </template>
          <span
            >Events that were not post-processed due to video not being recorded
            or other failures.</span
          >
        </v-tooltip>
        <v-switch
          v-model="anprStore.showPostProcessed"
          class="ma-0 pa-0"
          small
          dense
          inset
          label="Show Postprocessed"
          hide-details
        />
      </div>
    </div>
    <div class="d-flex">
      <ChipsToggleFilter
        v-model="anprStore.selectedVehicleTypes"
        title="Truck types"
        :items="anprStore.vehicleTypes"
        all-label="All trucks"
        :item-key="(item) => item.value"
        :item-label="(item) => item.label"
        class="ma-0"
      />
      <ChipsToggleFilter
        v-model="anprStore.selectedCameraExids"
        title="Cameras"
        :items="anprStore.cameras"
        all-label="All Cameras"
        :item-key="(item) => item.exid"
        :item-label="(item) => item.name"
        class="ma-0"
      />
    </div>
  </div>
</template>

<script>
import { AnprDirection } from "@evercam/shared/types"
import ChipsToggleFilter from "@evercam/shared/components/ChipsToggleFilter"
import { useAnprStore } from "@/stores/anpr"
import { mapStores } from "pinia"
import { Size } from "@evercam/ui"
export default {
  components: {
    ChipsToggleFilter,
  },
  data() {
    return {
      directionOptions: [
        { label: "All", value: null },
        { label: "In", value: AnprDirection.Arrived },
        { label: "Out", value: AnprDirection.Left },
        { label: "Unknown", value: AnprDirection.Unknown },
      ],
      Size,
    }
  },
  computed: {
    ...mapStores(useAnprStore),
  },
}
</script>
