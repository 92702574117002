export const PERMISSIONS = Object.freeze({
  USERS: {
    VIEW: "users-view-users",
    UPDATE: "users-update-user",
    DELETE: "users-delete-user",
    VIEW_CREDENTIALS: "users-view-user-credentials",
    IMPERSONATE: "users-user-impersonation",
    VIEW_AUDIT_LOGS: "users-view-audit-logs",
    SEND_EMAILS: "users-send-emails",
    VIEW_SHARES: "users-view-shares",
    RESEND_SHARES: "users-resend-shares",
    DELETE_SHARES: "users-delete-shares",
    EDIT_SHARES: "users-edit-shares",
  },
  CAMERAS: {
    VIEW: "cameras-view-cameras",
    UPDATE: "cameras-update-camera",
    DELETE: "cameras-delete-camera",
    VIEW_EDGE_HEALTHCHECK: "cameras-view-edge-healthcheck",
    VIEW_EXTRACTIONS: "cameras-view-extractions",
    DELETE_EXTRACTIONS: "cameras-delete-extractions",
  },
  KITS: {
    VIEW: "kits-view-kits",
    CREATE: "kits-create-kit",
    UPDATE: "kits-update-kit",
    DELETE: "kits-delete-kit",
  },
  NVRS: {
    VIEW: "nvrs-view-nvrs",
    VIEW_HEALTHCHECKS: "nvrs-view-healthchecks",
  },
  ROUTERS: {
    VIEW: "routers-view-routers",
    UPDATE: "routers-edit-router",
    DELETE: "routers-delete-router",
  },
  SIMS_AND_SMS: {
    VIEW_SIMS: "sims-and-sms-view-sims",
    EDIT_SIMS: "sims-and-sms-edit-sims",
    DELETE_SIMS: "sims-and-sms-delete-sims",
    VIEW_SMS: "sims-and-sms-view-sms",
    SEND_SMS: "sims-and-sms-send-sms",
  },
  PROJECTS: {
    VIEW: "projects-view-project",
    UPDATE: "projects-update-project",
    DELETE: "projects-delete-project",
  },
  COMPANIES: {
    VIEW: "companies-view-companies",
    EDIT: "companies-edit-companies",
    RESYNC_WITH_CRM: "companies-resync-with-crm",
    DELETE: "companies-delete-company",
  },
  GATE_REPORT: {
    VIEW: "gate-report-view-gate-report",
    UPDATE_EVENTS: "gate-report-update-gate-report-events",
    DELETE_EVENTS: "gate-report-delete-gate-report-events",
    CONFIGURE: "gate-report-configure-gate-report",
    VIEW_LOGS: "gate-report-view-gate-report-logs",
  },
  BIM: {
    VIEW_COMPARES: "bim-view-bim-compares",
    UPDATE_COMPARES: "bim-update-bim-compares",
    DELETE_COMPARES: "bim-delete-bim-compares",
    UPLOAD: "bim-upload-bim",
  },
  VIEW_360: {
    VIEW: "view-360-view-360",
    UPDATE: "view-360-update-360",
    DELETE: "view-360-delete-360",
  },
  SITE_VIEWS: {
    VIEW: "site-views-view-site-views",
    UPDATE: "site-views-update-site-views",
    DELETE: "site-views-delete-site-views",
  },
  MAP_VIEWS: {
    VIEW: "map-views-view-map-views",
    UPDATE: "map-views-update-map-views",
    DELETE: "map-views-delete-map-views",
  },
  INGEST: {
    VIEW: "ingest-view-ingest",
    UPDATE_UPLOADS: "ingest-update-uploads",
    DELETE_UPLOADS: "ingest-delete-uploads",
  },
  MEDIA_HUB: {
    VIEW: "media-hub-view-media-hub",
    DELETE_MEDIA: "media-hub-delete-media",
  },
  PROGRESS_PHOTOS: {
    VIEW: "progress-photos-view-progress-photos",
    UPDATE: "progress-photos-update-progress-photos",
    DELETE: "progress-photos-delete-progress-photos",
    VIEW_LOGS: "progress-photos-view-logs",
  },
  PRODUCT_ANALYTICS: {
    VIEW: "product-analytics-view-product-analytics",
  },
  COPILOT: {
    VIEW: "copilot-view-copilot",
  },
  DEMOS: {
    VIEW: "demos-view-demos",
  },
  SITE_ANALYTICS: {
    VIEW: "site-analytics-view-site-analytics",
  },
  ACCESS_CONTROL: {
    VIEW_ROLES: "access-control-view-roles",
    CREATE_ROLE: "access-control-create-new-role",
    UPDATE_ROLE_PERMISSIONS: "access-control-update-role-permissions",
    ASSIGN_ROLES: "access-control-assign-roles-to-users",
    UPDATE_PERMISSIONS: "access-control-update-permissions",
    DELETE_PERMISSIONS: "access-control-delete-permissions",
  },
})

export const PERMANENT_ROLES = Object.freeze({
  SUPER_ADMIN: 5445,
  EMPLOYEE: 5446,
})
