var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ECol',{staticClass:"pa-0"},[_c('ERow',{staticClass:"align-start justify-start",attrs:{"no-gutters":""}},[_c('ECol',{class:{
        'flex-grow-0': _vm.gateReportStore.cameras.length <= 2,
      }},[_c('ChipsToggleFilter',{staticClass:"ma-0",attrs:{"title":"Cameras","items":_vm.gateReportStore.cameras,"all-label":"All Cameras","item-key":(item) => item.exid,"item-label":(item) => `${item.name} - (${item.exid})`},model:{value:(_vm.gateReportStore.selectedCameraExids),callback:function ($$v) {_vm.$set(_vm.gateReportStore, "selectedCameraExids", $$v)},expression:"gateReportStore.selectedCameraExids"}})],1),_vm._v(" "),_c('ECol',{staticClass:"pl-2"},[_c('ChipsToggleFilter',{staticClass:"ma-0",attrs:{"title":"Truck types","items":_vm.gateReportStore.vehicleTypes,"all-label":"All trucks","item-key":(item) => item.value,"item-label":(item) => item.label},model:{value:(_vm.gateReportStore.selectedVehicleTypes),callback:function ($$v) {_vm.$set(_vm.gateReportStore, "selectedVehicleTypes", $$v)},expression:"gateReportStore.selectedVehicleTypes"}})],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"2"}},[_c('div',{staticClass:"font-weight-500"},[_vm._v("Visibility")]),_vm._v(" "),_vm._l((_vm.gateReportStore.verifiedEventsVisibilityItems),function({
          text,
          value,
        }){return _c('v-chip',{key:value,staticClass:"mr-1 cursor-pointer",attrs:{"x-small":"","outlined":"","label":"","color":_vm.gateReportStore.selectedVerifiedEventsVisibility === value ||
          _vm.gateReportStore.selectedVerifiedEventsVisibility === 0
            ? 'primary'
            : 'secondary'},on:{"click":function($event){_vm.gateReportStore.selectedVerifiedEventsVisibility = value}}},[_vm._v("\n        "+_vm._s(text)+"\n      ")])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }