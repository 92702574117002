<template>
  <ERow class="ex-nvr-diagnostics">
    <ECol cols="5" class="d-flex flex-column pl-4 pr-0">
      <!-- SECTION: EX NVR GENERAL -->
      <ExNvrDiagnosticsPanelSection
        title="Ex NVR"
        :config-lists="exNvrConfigLists"
        :related-tasks="exNvrRelatedTasks"
        class="pl-4"
      />
      <div class="separator--bottom w-100 pt-4"></div>
      <!-- SECTION: CAMERA SYSTEM -->
      <ExNvrDiagnosticsPanelSection
        title="Camera general"
        class="pt-2 pl-4"
        :config-lists="cameraSystemConfigLists"
        :related-tasks="cameraSystemRelatedTasks"
      />
    </ECol>

    <ECol cols="7" class="position-relative d-flex flex-column separator--left">
      <!-- SECTION: CAMERA STREAMS -->
      <ExNvrDiagnosticsPanelSection
        title="Camera streams"
        :config-lists="cameraStreamsConfigLists"
        :related-tasks="cameraStreamsRelatedTasks"
      />
      <div
        class="ex-nvr-diagnostics__timeline-container mb-0 mt-auto position-relative d-flex flex-column"
      >
        <ETimeline
          class="w-100"
          :events-groups="tlEventsGroups"
          :start-date="tlStartDate"
          :end-date="tlEndDate"
          :markers="tlMarkers"
          :bar-height="15"
          :auto-resize="false"
        />
      </div>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { decamelize } from "humps"
import {
  AdminCamera,
  ExNvrConfigList,
  ExNvrConfigListItem,
  ExNvrHealthCheckTask,
  ExNvrHealthCheckTaskId,
  ExNvrTaskWithDescription,
  MilesightCameraCodecId,
  MilesightCameraRateModeId,
  MilesightCameraStreamConfig,
  MilesightCameraStreamOsdInfo,
  MilesightCameraTimeInfo,
  MilesightCameraVbrQualityId,
  TaskStatus,
} from "@evercam/shared/types"
import { milesightTimezones } from "@evercam/shared/constants/timezones"
import { getResolutionInfo } from "@evercam/shared/utils"
import ExNvrDiagnosticsPanelSection from "@/components/nvrs/ExNvrDiagnosticsPanelSection"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { ETimeline, TimelineEventsByType } from "@evercam/ui"
import { AxiosError } from "axios"

export default Vue.extend({
  components: {
    ETimeline,
    ExNvrDiagnosticsPanelSection,
  },
  props: {
    camera: {
      type: Object as PropType<AdminCamera>,
      required: true,
    },
    tasks: {
      type: Array as PropType<
        Array<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
      >,
      required: true,
    },
  },
  data() {
    const cameraLifetime =
      new Date().getTime() - new Date(this.camera.createdAt).getTime()

    return {
      isExpanded: true,
      successColor: "#2f8114",
      errorColor: "#c50303",
      tlStartDate: this.$moment(this.camera.createdAt)
        .subtract(cameraLifetime / 4, "millisecond")
        .format(),
      tlEndDate: this.$moment(new Date())
        .add(cameraLifetime / 12, "millisecond")
        .format(),
      tlMarkers: [
        {
          timestamp: this.$moment(this.camera.createdAt).format(),
          label: "Camera installed",
          color: "#c3d8f5",
          textColor: "",
          id: "createAt",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    tasksStatuses(): TaskStatus[] {
      return this.tasks.map((t) => t.status)
    },
    exNvrRelatedTasks(): ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[] {
      return this.getFilteredTasks([
        ExNvrHealthCheckTaskId.ExNvrLogin,
        ExNvrHealthCheckTaskId.ExNvrConfigCheck,
      ])
    },
    cameraStreamsRelatedTasks(): ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[] {
      return this.getFilteredTasks([
        ExNvrHealthCheckTaskId.CameraStreamsConfigCheck,
      ])
    },
    cameraSystemRelatedTasks(): ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[] {
      return this.getFilteredTasks([
        ExNvrHealthCheckTaskId.CameraSystemConfigCheck,
        ExNvrHealthCheckTaskId.CameraNetworkConfigCheck,
        ExNvrHealthCheckTaskId.CameraTimeConfigCheck,
      ])
    },
    exNvrConfigLists(): ExNvrConfigList[] {
      if (this.hasError(this.exNvrRelatedTasks)) {
        return [
          {
            items: this.getErrorItems(
              this.exNvrRelatedTasks.find((t) => t.status === TaskStatus.Error)
            ),
            gridLayout: "1fr 3fr",
          },
        ]
      }

      if (!this.hasResults(this.exNvrRelatedTasks)) {
        return []
      }

      return [
        {
          items: this.exNvrConfigCheckResultItems,
          gridLayout: "1fr 3fr",
        },
      ]
    },
    cameraStreamsConfigLists(): ExNvrConfigList[] {
      if (this.hasError(this.cameraStreamsRelatedTasks)) {
        return [
          {
            items: this.getErrorItems(
              this.cameraStreamsRelatedTasks.find(
                (t) => t.status === TaskStatus.Error
              )
            ),

            gridLayout: "1fr 3fr",
          },
        ]
      }

      if (!this.hasResults(this.cameraStreamsRelatedTasks)) {
        return []
      }

      const streamList = this.cameraStreamsCheckTask?.result?.streamList

      return [
        {
          items: this.cameraMainStreamResultItems,
        },
        {
          items: this.cameraSubStreamResultItems,
          isDisabled: !this.isStreamEnabled(streamList?.subStream),
        },
        {
          items: this.cameraThirdStreamResultItems,
          isDisabled: !this.isStreamEnabled(streamList?.thirdStream),
        },
      ]
    },
    cameraSystemConfigLists(): Array<ExNvrConfigList> {
      if (this.hasError(this.cameraSystemRelatedTasks)) {
        return [
          {
            items: this.getErrorItems(
              this.cameraSystemRelatedTasks.find(
                (t) => t.status === TaskStatus.Error
              )
            ),
            gridLayout: "1fr 3fr",
          },
        ]
      }

      if (
        !this.hasResults(this.cameraSystemRelatedTasks) ||
        !this.hasResults(this.cameraStreamsRelatedTasks)
      ) {
        return []
      }

      return [
        {
          items: this.cameraSystemResultItems,
          gridLayout: "1fr 2fr",
        },
      ]
    },
    cameraStreamsCheckTask(): ExNvrTaskWithDescription<ExNvrHealthCheckTaskId.CameraStreamsConfigCheck> {
      return this.getTaskById(ExNvrHealthCheckTaskId.CameraStreamsConfigCheck)
    },
    exNvrConfigCheckResultItems(): Array<ExNvrConfigListItem> {
      const task = this.getTaskById(ExNvrHealthCheckTaskId.ExNvrConfigCheck)
      const systemStatusTask = this.getTaskById(
        ExNvrHealthCheckTaskId.ExNvrSystemStatus
      )

      const systemStatus = systemStatusTask?.result
      const config = task?.result

      return [
        {
          label: "State",
          value: config.state,
          valueColor: config.state === "recording" ? this.successColor : "",
        },
        {
          label: "Version",
          value: systemStatus.version,
        },
        {
          label: "MAC",
          value: this.getFormattedMacAddress(config.mac),
        },
        {
          label: "Device ID",
          value: config.id,
        },
        {
          label: "Snapshot URL",
          value: config.streamConfig?.snapshotUri,
        },
        {
          label: "Main stream",
          value: config.streamConfig?.streamUri,
        },
        {
          label: "Sub stream",
          value: config.streamConfig?.subStreamUri,
        },
        {
          label: "Timezone",
          value: config.timezone,
        },
      ]
    },
    cameraSystemResultItems(): ExNvrConfigListItem[] {
      const streamsConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraStreamsConfigCheck
      )?.result
      const systemConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraSystemConfigCheck
      )?.result
      const timeConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraTimeConfigCheck
      )?.result
      const networkConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraNetworkConfigCheck
      )?.result

      return [
        {
          label: "Model",
          value: systemConfig.model,
        },
        {
          label: "Firmware",
          value: systemConfig.firmwareVersion,
        },
        {
          label: "Serial Number",
          value: systemConfig.snCode,
        },
        {
          label: "MAC address",
          value: systemConfig.mac,
        },
        {
          label: "HTTP URL",
          value: `http://${systemConfig.ipaddress}:${networkConfig.httpPort}`,
        },
        {
          label: "RTSP URL",
          value: `rtsp://${systemConfig.ipaddress}:${streamsConfig.rtspPort}`,
        },
        {
          label: "Last reboot",
          value: this.$moment(systemConfig.systemBootTime).fromNow(),
        },
        {
          label: "Timezone",
          value: this.getFormattedTimezone(timeConfig),
        },
      ]
    },
    cameraMainStreamResultItems(): Array<ExNvrConfigListItem> {
      const config = this.cameraStreamsCheckTask?.result

      return [
        {
          label: "Main stream",
          value: "enabled",
          valueColor: this.successColor,
        },
        ...this.getVideoStreamConfigItems(config.streamList.mainStream),
      ]
    },
    cameraSubStreamResultItems(): Array<ExNvrConfigListItem> {
      const config = this.cameraStreamsCheckTask?.result
      const streamConfig = config.streamList.subStream
      const isEnabled = this.isStreamEnabled(streamConfig)
      const osdConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraOsdConfigCheck
      )?.result?.osdInfoList?.[1]

      return [
        {
          label: "Sub stream",
          value: isEnabled ? "enabled" : "disabled",
          valueColor: isEnabled ? this.successColor : "",
        },
        ...this.getVideoStreamConfigItems(streamConfig, osdConfig),
      ]
    },
    cameraThirdStreamResultItems(): Array<ExNvrConfigListItem> {
      const config = this.cameraStreamsCheckTask.result
      const streamConfig = config.streamList.thirdStream
      const isEnabled = this.isStreamEnabled(streamConfig)
      const osdConfig = this.getTaskById(
        ExNvrHealthCheckTaskId.CameraOsdConfigCheck
      )?.result?.osdInfoList?.[2]

      return [
        {
          label: "Third stream",
          value: isEnabled ? "enabled" : "disabled",
          valueColor: isEnabled ? this.successColor : "",
        },
        ...this.getVideoStreamConfigItems(streamConfig, osdConfig),
      ]
    },
    exNvrRecordingsTask(): ExNvrHealthCheckTask<ExNvrHealthCheckTaskId.ExNvrRecordings> {
      return this.tasks.find(
        (t) => t.id === ExNvrHealthCheckTaskId.ExNvrRecordings
      ) as ExNvrHealthCheckTask<ExNvrHealthCheckTaskId.ExNvrRecordings>
    },
    tlEventsGroups(): TimelineEventsByType {
      const isError = this.exNvrRecordingsTask.status === TaskStatus.Error
      const isLoading = this.exNvrRecordingsTask.status === TaskStatus.Loading

      return {
        recordings: {
          label: `Ex NVR ${isError ? "(ERROR!)" : "footage"}`,
          color: isError ? "#f00" : "rgba(81,145,64,0.96)",
          isLoading: isLoading && !isError,
          events: this.exNvrRecordingsTask?.result || [],
        },
      }
    },
  },
  methods: {
    getTaskById<T extends ExNvrHealthCheckTaskId>(
      taskId: T
    ): ExNvrTaskWithDescription<T> {
      return this.tasks.find((task) => task.id === taskId)
    },
    hasResults(
      tasks: ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[]
    ): boolean {
      return !tasks.some((t) =>
        [TaskStatus.Loading, TaskStatus.Idle].includes(t.status)
      )
    },
    isDone(tasks: ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[]): boolean {
      return tasks.reduce(
        (acc, t) => acc && t.status === TaskStatus.Success,
        true
      )
    },
    hasError(
      tasks: ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>[]
    ): boolean {
      return tasks.some((t) => t.status === TaskStatus.Error)
    },
    getFilteredTasks<T extends ExNvrHealthCheckTaskId>(
      targetTasks: ExNvrHealthCheckTaskId[]
    ): Array<ExNvrTaskWithDescription<T>> {
      return this.tasks.reduce((acc, t) => {
        const isTargetTask = targetTasks.includes(t.id)

        if (!isTargetTask) {
          return acc
        }

        const description = this.getFormattedDescription(t.id)

        return [
          ...acc,
          {
            ...t,
            description,
            duration: t.duration,
            hidden: description.includes("time info"),
          },
        ]
      }, [])
    },
    getFormattedDescription(taskId: ExNvrHealthCheckTaskId): string {
      return decamelize(taskId, { separator: " " })
        .replace("ex nvr ", "")
        .replace("camera ", "")
    },
    isStreamEnabled(streamConfig: MilesightCameraStreamConfig): boolean {
      return streamConfig.enable === 1
    },
    getFormattedCodec(codecId: MilesightCameraCodecId): string {
      switch (codecId) {
        case MilesightCameraCodecId.H264:
          return "H.264"
        case MilesightCameraCodecId.H265:
          return "H.265"
        case MilesightCameraCodecId.MJpeg1:
          return "MJPEG-1"
        case MilesightCameraCodecId.MJpeg2:
          return "MJPEG-2"
        default:
          return "unknown"
      }
    },
    getFormattedBitrateQuality(config: MilesightCameraStreamConfig): string {
      if (config.rateMode !== MilesightCameraRateModeId.VBR) {
        return "n/a"
      }
      switch (config.vbrQuality) {
        case MilesightCameraVbrQualityId.High:
          return "high"
        case MilesightCameraVbrQualityId.Medium:
          return "medium"
        case MilesightCameraVbrQualityId.Low:
          return "low"
        default:
          return "n/a"
      }
    },
    getErrorItems(
      erroredTask: ExNvrHealthCheckTask<any>
    ): ExNvrConfigListItem[] {
      const error = erroredTask.error as AxiosError
      let requestId = []

      if (error?.response?.headers?.["x-request-id"]) {
        requestId = [
          {
            label: "Request ID",
            value: error.response.headers["x-request-id"],
            valueColor: this.errorColor,
            labelColor: this.errorColor,
          },
        ]
      }

      return [
        {
          label: "Status",
          value: error.response.status || "n/a",
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        {
          label: "Method",
          value: error.config.method.toUpperCase(),
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        ...requestId,
        {
          label: "Code",
          value: error.code,
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        {
          label: "Message",
          value: error.message,
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        {
          label: "Response data",
          value: JSON.stringify(error.response.data) || "n/a",
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        {
          label: "Base URL",
          value: error.config.url.split("/api/")[0] + "/api",
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
        {
          label: "Route",
          value: "/" + (error.config.url.split("/api/")[1] || ""),
          valueColor: this.errorColor,
          labelColor: this.errorColor,
        },
      ]
    },
    getVideoStreamConfigItems(
      config: MilesightCameraStreamConfig,
      osdConfig?: MilesightCameraStreamOsdInfo
    ): ExNvrConfigListItem[] {
      const resolutionInfo = getResolutionInfo(config.width, config.height)
      let osdItems = []
      if (osdConfig) {
        const isOsdEnabled = osdConfig.osdDateTimeEnable === 1
        osdItems = [
          {
            label: "OSD Time",
            value: isOsdEnabled ? "enabled" : "disabled",
            disabled: !isOsdEnabled,
          },
        ]
      }

      return [
        {
          label: "Codec",
          value: this.getFormattedCodec(config.profileCodec),
        },
        {
          label: "Resolution",
          value: `${resolutionInfo.resolutionDimensions} (${resolutionInfo.resolutionLabel})`,
        },
        {
          label: "Aspect ratio",
          value: `${resolutionInfo.ratioFloat} (${resolutionInfo.ratioString})`,
        },
        {
          label: "Bitrate",
          value: `${config.bitrate} kbps`,
        },
        {
          label: "Bitrate mode",
          value:
            config.rateMode === MilesightCameraRateModeId.VBR
              ? "variable"
              : "constant",
        },
        {
          label: "VBR quality",
          value: this.getFormattedBitrateQuality(config),
          disabled: config.rateMode !== MilesightCameraRateModeId.VBR,
        },
        {
          label: "Frame rate",
          value: `${config.framerate} fps`,
        },
        {
          label: "I-Frame interval",
          value: `${config.profileGop} fps`,
        },
        {
          label: "Endpoint",
          value: `/${config.url}`,
        },
        ...osdItems,
      ]
    },
    getFormattedMacAddress(raw: string) {
      return raw ? raw.match(/.{1,2}/g)?.join(":") ?? "" : "n/a"
    },
    getFormattedTimezone(config: MilesightCameraTimeInfo) {
      const searchValue = `${config.timeZoneTz} ${config.zoneNameTz}`
      const matchingEntry = milesightTimezones.find(
        ({ value }) => value === searchValue
      )

      return matchingEntry ? matchingEntry.name : "unknown"
    },
  },
})
</script>

<style lang="scss">
.ex-nvr-diagnostics {
  border-top: 1px solid #cad2d7;
  &__result {
    transition: 0.2s;
    &--disabled {
      color: #3337;
    }
  }
  &__timeline-container {
    width: calc(100% - 12px);
    min-height: 90px;
    justify-content: end;
    .marker-label {
      .marker-label-text {
        box-shadow: none;
      }
      transform: translateY(-22px);
    }
    .marker {
      .marker-line {
        height: 78px !important;
        top: -18px;
      }
    }
    .e-timeline__svg-container {
      box-shadow: inset 0 0 4px -2px #000;
    }
    .e-timeline__background {
      background: rgb(241, 243, 246);
    }
  }
  .separator {
    &--bottom {
      border-bottom: 1px solid rgba(188, 201, 208, 0.68);
    }
    &--left {
      border-left: 1px solid rgba(188, 201, 208, 0.68);
    }
  }
}
</style>
