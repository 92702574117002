import { GateReportVehicleType, VEHICLE_TYPE_IDS } from "@evercam/api/types"

export const VEHICLE_TYPES = [
  { label: "Tipping truck", value: GateReportVehicleType.TippingTruck },
  { label: "Concrete truck", value: GateReportVehicleType.TruckMixer },
  { label: "Tank truck", value: GateReportVehicleType.TankTruck },
  { label: "Semi trailer", value: GateReportVehicleType.SemiTrailer },
  { label: "Truck head", value: GateReportVehicleType.TruckHead },
  {
    label: "Flatbed semi-trailer",
    value: GateReportVehicleType.FlatbedSemiTrailer,
  },
  { label: "Small truck", value: GateReportVehicleType.SmallTruck },
  { label: "Road vehicle", value: GateReportVehicleType.RoadVehicle },
  { label: "Concrete pump", value: GateReportVehicleType.ConcretePump },
  { label: "Other vehicles", value: GateReportVehicleType.OtherTruck },
  { label: "Unknown", value: GateReportVehicleType.Unknown },
]

export { VEHICLE_TYPE_IDS }
