export default [
  { name: "None", value: "" },
  { name: "OpenVPN1", value: "openvpn.evercam.io" },
  { name: "OpenVPN2", value: "openvpn2.evercam.io" },
  { name: "OpenVPN3", value: "openvpn3.evercam.io" },
  { name: "OpenVPN4", value: "openvpn4.evercam.io" },
  { name: "OpenVPN5", value: "openvpn5.evercam.io" },
  { name: "PPTP1", value: "vpn.evercam.io" },
  { name: "PPTP2", value: "vpn2.evercam.io" },
  { name: "WireGuard1", value: "wireguard.evercam.io" },
  { name: "WireGuard2", value: "wg2.evercam.io" },
  { name: "WireGuard3", value: "wg3.evercam.io" },
  { name: "WireGuard4", value: "wg4.evercam.io" },
  { name: "WireGuard5", value: "wg5.evercam.io" },
  { name: "WireGuard6", value: "wg6.evercam.io" },
  { name: "WireGuard7", value: "wg7.evercam.io" },
  { name: "WireGuard8", value: "wg8.evercam.io" },
  { name: "WireGuard9", value: "wg9.evercam.io" },
  { name: "WireGuard10", value: "wg10.evercam.io" },
]
