import Vue from "vue"
import * as Sentry from "@sentry/vue"
import Axios from "axios"
import type { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"

const ignoredErrors = [
  "Unexpected token '<'",
  'Unexpected token "<"',
  "Non-Error promise rejection",
  "timeout exceeded",
  "Unable to set property 'lastMousedownWasOutside' of undefined or null reference",
  'Unable to set property "lastMousedownWasOutside" of undefined or null reference',
  "Cannot set property ctrlKey of #<TouchEvent> which has only a getter",
  "AbortError: The play() request was interrupted because the media was removed from the document.",
  "AbortError: The play() request was interrupted because video-only background media was paused to save power.",
  "AbortError: The play() request was interrupted because the media was removed from the document. https://goo.gl/LdLk22",
  "AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22",
  "AbortError: The play() request was interrupted because video-only background media was paused to save power. https://goo.gl/LdLk22",
  "AbortError: The play() request was interrupted by a call to pause().",
  "AbortError: The operation was aborted.",
  "A network error occurred. This could be a CORS issue, a cancel request or a dropped internet connection.",
  "Request aborted",
  "Failed to fetch",
  "Snapshot not found in the selected timestamp", //This is the message we are displaying on requested snapshot not found on timeline
  "Network Error", //happens on bad internet connection
]

export default () => (app: NuxtRuntimeConfig) => {
  const { router } = app.nuxt2Context.app
  const { isDev, env, $config } = app.nuxt2Context

  // Disable sentry in dev mode or preview link
  if (isDev || env.NUXT_ENV_VERCEL_ENV === "preview") {
    app.provide("errorTracker", {
      setUser: () => {},
      save: async () => new Promise<void>((res) => res()),
      saveAndNotify: async () => new Promise<void>((res) => res()),
      leaveBreadcrumb: () => {},
    })

    return
  }
  const processEvent = (event: Sentry.Event, hint: Sentry.EventHint) => {
    let breadcrumbs = event.breadcrumbs || []
    let breadcrumbsDataUrl = breadcrumbs?.[0]?.data?.url || ""
    const user = app.nuxt2Context.$auth?.getUserInfo()
    const firstEventException = event.exception?.values![0]
    const isAxiosError =
      hint?.originalException && Axios.isAxiosError(hint.originalException) // Ignore Axios response errors
    const error = firstEventException?.value || ""
    const isCancelError =
      firstEventException?.type === "CanceledError" ||
      (firstEventException?.type === "UnhandledRejection" &&
        firstEventException?.value!.includes(
          "Object captured as promise rejection with keys"
        ))
    const isExnvrError = /\/api\/(users\/login|devices)/.test(
      breadcrumbsDataUrl
    )
    let shouldIgnoreError =
      ignoredErrors.reduce(
        (acc, ignoredError) => acc || error.indexOf(ignoredError) !== -1,
        false
      ) ||
      ((!user || !user?.email) &&
        (error.includes("400") ||
          error.includes("409") ||
          error.includes("401") ||
          error.includes("403") ||
          error.includes("object has no keys"))) ||
      isCancelError ||
      error.includes("404") ||
      isExnvrError
    // ignore the sentry if error is in ignore list or is cancelled request or if unauthorized user try to visit private camera
    if (shouldIgnoreError || isAxiosError) {
      return null
    }

    if (Array.isArray(breadcrumbs)) {
      breadcrumbs = breadcrumbs.map((b) => {
        if (b?.category === "xhr" && typeof b.data?.url === "string") {
          b.data.url = b.data.url.replace(
            /(api_id|api_key|credentials|auth)/g,
            "_"
          )
        }

        return b
      })
    }

    return {
      ...event,
      contexts: {
        ...(event.contexts || {}),
        ...getExtraContext(hint?.originalException),
      },
      user,
      breadcrumbs,
    }
  }

  Sentry.init({
    Vue,
    dsn: $config.public.sentryDsn,
    tracingOptions: {
      trackComponents: true,
    },
    // @ts-expect-error - this is a valid option
    beforeSend: $config.isDev ? console.log : processEvent,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
  })
  app.provide("errorTracker", {
    setUser: Sentry.setUser,
    save: (
      error: unknown,
      feature: string | null = null,
      extraContext: Record<string, any> = {}
    ) => {
      if (!error) {
        return
      }
      let e = error
      if (!(e instanceof Error)) {
        try {
          e = JSON.stringify(e)
        } catch {
          e = error
        }
        e = new Error(String(e))
      }
      Sentry.captureException(e, (scope) => {
        scope.clear()
        if (feature) {
          scope.setTag("feature", feature)
        }

        const extraContextEntries = Object.entries(extraContext)
        if (extraContextEntries.length) {
          extraContextEntries.forEach(([key, value]) =>
            scope.setExtra(key, value)
          )
        }

        return scope
      })
    },
    async saveAndNotify(params: {
      ERROR: Error
      REQUEST_PAYLOAD: Record<any, any>
      FEATURE: string
    }) {
      const EVENT_ID = Sentry.captureException(params.ERROR, (scope) => {
        scope.clear()
        if (params.FEATURE) {
          scope.setTag("feature", params.FEATURE)
        }
        if (params.REQUEST_PAYLOAD) {
          scope.addAttachment({
            filename: "REQUEST_PAYLOAD.txt",
            data: JSON.stringify(params.REQUEST_PAYLOAD),
          })
        }

        return scope
      })
      if (isDev) {
        return
      }
      invokeCliqNotificationLambda(params.FEATURE, params.ERROR, EVENT_ID)
    },
    leaveBreadcrumb: (message: string | Record<string, any>) => {
      if (typeof message === "string") {
        Sentry.captureMessage(message)
      }

      if (typeof message === "object") {
        Sentry.addBreadcrumb(message)
      }
    },
  })
  async function invokeCliqNotificationLambda(
    feature: string,
    error: Error,
    eventId: string
  ) {
    const https = require("https")
    const message = `*${feature || "Unknown"} error* : \`${
      error?.message
    }\` [more details on Sentry.](https://sentry.io/organizations/evercam/issues/?query=${eventId})`

    return new Promise((resolve, reject) => {
      const req = https.request(
        {
          hostname: $config.public.cliqLambda,
          port: 443,
          path: "/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Content-Length": message.length,
          },
        },
        (res) => {
          let responseChunks: Buffer[] = []
          res.on("data", (data: unknown) => {
            responseChunks.push(data as Buffer<ArrayBufferLike>)
          })
          res.on("end", () => {
            resolve(responseChunks)
          })
        }
      )

      req.on("error", (error: Error) => {
        reject(error)
      })
      req.write(message)
      req.end()
    })
  }
}

function getExtraContext(error?: unknown): any {
  if (Axios.isAxiosError(error)) {
    return { Axios: error }
  }

  return {}
}
