import {
  CameraStatus,
  CameraFeatureFlag,
  ResolutionDimensions,
  ResolutionLabel,
} from "@evercam/shared/types/camera"
import {
  ProjectStatus,
  ProjectFeatureFlag,
} from "@evercam/shared/types/project"
import { KitStatus } from "@evercam/shared/types/kit"
import { Persona, UserFeatureFlag } from "@evercam/shared/types/user"
import { ShareRequestStatus } from "@evercam/shared/types/shares"
import {
  MediaType,
  MediaStatus,
  MediaStatusId,
} from "@evercam/shared/types/media"
import { TimelapseStatus } from "@evercam/shared/types/timelapse"
import { CompareStatus } from "@evercam/shared/types/compare"
import { BimModelType } from "@evercam/shared/types/bim"
import {
  CloudRecordingFrequency,
  SnapshotExtractionStatus,
  SnapshotExtractionType,
  NvrHddStatus,
  CloudRecordingDuration,
  CloudRecordingStatus,
  RouterStatus,
  PowerType,
  PowerSchedule,
  SnapshotExtractionInterval,
  RoiType,
  RoiDirection,
  MapType,
  IngestProcessingStatus,
  VpnServer,
  _360IntergrationType,
  ExNvrDeviceState,
  CameraRegion,
} from "@evercam/shared/types"

export const ShareRequestStatuses = [
  { value: ShareRequestStatus.All, name: "All" },
  { value: ShareRequestStatus.Pending, name: "Pending" },
  { value: ShareRequestStatus.Cancelled, name: "Cancelled" },
  { value: ShareRequestStatus.Failed, name: "Failed" },
  { value: ShareRequestStatus.Used, name: "Used" },
]

export const SnapshotExtractionStatuses = [
  { name: "Completed", value: SnapshotExtractionStatus.Completed },
  { name: "Failed", value: SnapshotExtractionStatus.Failed },
  { name: "Processing", value: SnapshotExtractionStatus.Processing },
]

export const SnapshotExtractionTypes = [
  { name: "Cloud", value: SnapshotExtractionType.Cloud },
  { name: "Local", value: SnapshotExtractionType.Local },
]

export const NvrHddStatuses = [
  { name: "Error", value: NvrHddStatus.Error },
  { name: "Idle", value: NvrHddStatus.Idle },
  { name: "Not Exist", value: NvrHddStatus.NotExist },
  { name: "Ok", value: NvrHddStatus.Ok },
  { name: "Unformatted", value: NvrHddStatus.Unformatted },
]

export const MediaTypes = [
  { name: "Clip", value: MediaType.Clip },
  { name: "Local Clip", value: MediaType.LocalClip },
  { name: "Compare", value: MediaType.Compare },
  { name: "Url", value: MediaType.ExternalUrl },
  { name: "File", value: MediaType.File },
  { name: "XRay", value: MediaType.XRay },
  { name: "Mark-up", value: MediaType.EditedImage },
  { name: "Timelapse", value: MediaType.Timelapse },
  { name: "Mobile asset", value: MediaType.MobileAsset },
]

export const MediaStatuses = [
  {
    name: "Pending",
    value: MediaStatus.Pending,
    id: MediaStatusId.Pending,
  },
  {
    name: "Processing",
    value: MediaStatus.Processing,
    id: MediaStatusId.Processing,
  },
  {
    name: "Completed",
    value: MediaStatus.Completed,
    id: MediaStatusId.Completed,
  },
  {
    name: "Failed",
    value: MediaStatus.Failed,
    id: MediaStatusId.Failed,
  },
]

export const CompareStatuses = [
  {
    name: "Processing",
    value: CompareStatus.Processing,
  },
  {
    name: "Completed",
    value: CompareStatus.Completed,
  },
  {
    name: "Failed",
    value: CompareStatus.Failed,
  },
]

export const TimelapseStatuses = [
  {
    name: "Pending",
    value: TimelapseStatus.Pending,
  },
  {
    name: "Completed",
    value: TimelapseStatus.Completed,
  },
  {
    name: "Failed",
    value: TimelapseStatus.Failed,
  },
]

export const CameraStatusMap = {
  [CameraStatus.Online]: {
    class: "green--text text--darken-2",
    color: "green",
    value: "Online",
  },
  [CameraStatus.Offline]: {
    class: "red--text text--darken-2",
    color: "red",
    value: "Offline",
  },
  [CameraStatus.OfflineScheduled]: {
    class: "red--text text--darken-2",
    color: "red",
    value: "Offline (Scheduled)",
  },
  [CameraStatus.Decommissioned]: {
    class: "cyan--text text--darken-2",
    color: "cyan",
    value: "Decommissioned",
  },
  [CameraStatus.OnHold]: {
    class: "deep-orange--text text--darken-2",
    color: "deep-orange",
    value: "On Hold",
  },
  [CameraStatus.Waiting]: {
    class: "blue-orange--text text--darken-2",
    color: "blue",
    value: "Waiting for Activation",
  },
  [CameraStatus.UnderMaintenance]: {
    class: "yellow--text text--darken-2",
    color: "deep-orange",
    value: "Under Maintenance",
  },
  [CameraStatus.WaitingForSiteVisit]: {
    class: "yellow--text text--darken-2",
    color: "deep-orange",
    value: "Under Maintenance (Waiting for Site Visit)",
  },
}

export const ExNvrDeviceStateColor = {
  [ExNvrDeviceState.Recording]: {
    class: "green--text text--darken-2",
    color: "green",
    value: "Recording",
  },
  [ExNvrDeviceState.Failed]: {
    class: "red--text text--darken-2",
    color: "red",
    value: "Failed",
  },
  [ExNvrDeviceState.Stopped]: {
    class: "deep-orange--text text--darken-2",
    color: "deep-orange",
    value: "Stopped",
  },
}

export const CameraStatuses = [
  { name: "Decommissioned", value: CameraStatus.Decommissioned },
  { name: "On Hold", value: CameraStatus.OnHold },
  { name: "Under Maintenance", value: CameraStatus.UnderMaintenance },
  {
    name: "Under Maintenance (Waiting for Site Visit)",
    value: CameraStatus.WaitingForSiteVisit,
  },
  { name: "Offline", value: CameraStatus.Offline },
  { name: "Offline (Scheduled)", value: CameraStatus.OfflineScheduled },
  { name: "Waiting for Activation", value: CameraStatus.Waiting },
  { name: "Online", value: CameraStatus.Online },
]

export const KitStatuses = [
  { name: "New", value: KitStatus.New },
  { name: "Active", value: KitStatus.Active },
  { name: "Offline Scheduled", value: KitStatus.OfflineScheduled },
  { name: "Decommissioned", value: KitStatus.Decommissioned },
]

export const KitStatusesIdsClasses = {
  [KitStatus.New]: {
    class: "cyan--text text--darken-2",
    value: "New",
    color: "cyan",
  },
  [KitStatus.Active]: {
    class: "green--text text--darken-2",
    value: "Active",
    color: "green",
  },
  [KitStatus.OfflineScheduled]: {
    class: "red--text text--darken-2",
    value: "OfflineScheduled",
    color: "red",
  },
  [KitStatus.Decommissioned]: {
    class: "yellow--text text--darken-2",
    value: "Decommissioned",
    color: "yellow",
  },
}

export const CloudRecordingsFrequencies = [
  { value: CloudRecordingFrequency.EverySecond, name: "60 (1 per second)" },
  {
    value: CloudRecordingFrequency.Every2Seconds,
    name: "30 (1 every 2 second)",
  },
  {
    value: CloudRecordingFrequency.Every5Seconds,
    name: "12 (1 every 5 second)",
  },
  {
    value: CloudRecordingFrequency.Every10Seconds,
    name: "6 (1 every 10 second)",
  },
  {
    value: CloudRecordingFrequency.Every15Seconds,
    name: "4 (1 every 15 second)",
  },
  {
    value: CloudRecordingFrequency.Every30Seconds,
    name: "2 (1 every 30 second)",
  },
  {
    value: CloudRecordingFrequency.Every60Seconds,
    name: "1 (1 every 60 second)",
  },
  {
    value: CloudRecordingFrequency.Every5Minutes,
    name: "1 (1 every 5 minutes)",
  },
  {
    value: CloudRecordingFrequency.Every10Minutes,
    name: "1 (1 every 10 minutes)",
  },
]

export const FrequencyToSecondsMap = {
  [CloudRecordingFrequency.EverySecond]: 1,
  [CloudRecordingFrequency.Every2Seconds]: 2,
  [CloudRecordingFrequency.Every5Seconds]: 5,
  [CloudRecordingFrequency.Every10Seconds]: 10,
  [CloudRecordingFrequency.Every15Seconds]: 15,
  [CloudRecordingFrequency.Every30Seconds]: 30,
  [CloudRecordingFrequency.Every60Seconds]: 60,
  [CloudRecordingFrequency.Every5Minutes]: 300,
  [CloudRecordingFrequency.Every10Minutes]: 600,
}

export const CloudRecordingsStorageDuration = [
  { value: CloudRecordingDuration.Infinity, name: "Infinity" },
  { value: CloudRecordingDuration.Day, name: "24 Hours" },
  { value: CloudRecordingDuration.Week, name: "7 Days" },
  { value: CloudRecordingDuration.Month, name: "30 Days" },
  { value: CloudRecordingDuration.ThreeMonths, name: "90 Days" },
]

export const CloudRecordingsStatuses = [
  {
    name: "On",
    value: CloudRecordingStatus.On,
    class: "green--text text--darken-2",
  },
  {
    name: "Off",
    value: CloudRecordingStatus.Off,
    class: "red--text text--darken-2",
  },
  {
    name: "Paused",
    value: CloudRecordingStatus.Paused,
    class: "orange--text text--darken-2",
  },
  {
    name: "On Scheduled",
    value: CloudRecordingStatus.OnScheduled,
    class: "green--text text--darken-2",
  },
]

export const BimModelTypes = [
  { value: BimModelType.Architectural, name: "Architectural" },
  { value: BimModelType.Structural, name: "Structural" },
  { value: BimModelType.FourD, name: "4D" },
  { value: BimModelType.Mep, name: "Mep" },
]

export const PowerSchedules = [
  { value: PowerSchedule.Schedule, name: "Schedule" },
  { value: PowerSchedule.FullTime, name: "24/7" },
]

export const PowerTypes = [
  { value: PowerType.Mains, name: "Mains" },
  { value: PowerType.Battery, name: "Battery" },
  { value: PowerType.Solar, name: "Solar" },
  { value: PowerType.Generator, name: "Generator" },
]

export const VpnServers = Object.values(VpnServer).map((value) => ({
  value,
  name: value,
}))

export const RouterStatuses = [
  { value: RouterStatus.Active, name: "Active" },
  { value: RouterStatus.Inactive, name: "Inactive" },
]

export const CAMERA_TABS = {
  SUMMARY: 0,
  EXTRACTIONS: 1,
  RECOGNITION: 2,
  DEMO: 3,
  MILESTONES: 4,
  DANGER_ZONE: 5,
}

export const BrainToolTabs = {
  DETECTIONS: 1,
  SEGMENTATION: 2,
  DEPTH_ANALYSIS: 3,
}

export const PersonaItems = [
  { value: Persona.HQ, name: "Project Overview (e.g. Investor / Head Office)" },
  { value: Persona.PM, name: "Project Management (On Site)" },
  {
    value: Persona.QS,
    name: "Dispute Avoidance & Contract Administration (e.g. QS)",
  },
  { value: Persona.Marketing, name: "Marketing" },
  {
    value: Persona.HS,
    name: "Incident Review & Continuous Improvement (e.g. Health & Safety)",
  },
  {
    value: Persona.DI,
    name: "Digital / Innovation",
  },
  { value: Persona.Other, name: "Other" },
  { value: "None", name: "None: Not Construction Related" },
]

export const SnapshotExtractionIntervals = [
  {
    text: "1 Frame Every Second",
    value: SnapshotExtractionInterval.EverySecond,
  },
  {
    text: "1 Frame Every 5 seconds",
    value: SnapshotExtractionInterval.Every5Seconds,
  },
  {
    text: "1 Frame Every 10 seconds",
    value: SnapshotExtractionInterval.Every10Seconds,
  },
  {
    text: "1 Frame Every 15 seconds",
    value: SnapshotExtractionInterval.Every15Seconds,
  },
  {
    text: "1 Frame Every 20 seconds",
    value: SnapshotExtractionInterval.Every20Seconds,
  },
  {
    text: "1 Frame Every 30 seconds",
    value: SnapshotExtractionInterval.Every30Second,
  },
  {
    text: "1 Frame Every 1 min",
    value: SnapshotExtractionInterval.EveryMinute,
  },
  {
    text: "1 Frame Every 5 min",
    value: SnapshotExtractionInterval.Every5Minutes,
  },
  {
    text: "1 Frame Every 10 min",
    value: SnapshotExtractionInterval.Every10Minutes,
  },
  {
    text: "1 Frame Every 15 min",
    value: SnapshotExtractionInterval.Every15Minutes,
  },
  {
    text: "1 Frame Every 20 min",
    value: SnapshotExtractionInterval.Every20Minutes,
  },
  {
    text: "1 Frame Every 30 min",
    value: SnapshotExtractionInterval.Every30Minutes,
  },
  {
    text: "1 Frame Every hour",
    value: SnapshotExtractionInterval.EveryHour,
  },
  {
    text: "1 Frame Every 2 hours",
    value: SnapshotExtractionInterval.Every2Hours,
  },
  {
    text: "1 Frame Every 6 hours",
    value: SnapshotExtractionInterval.Every6Hours,
  },
  {
    text: "1 Frame Every 12 hours",
    value: SnapshotExtractionInterval.Every12Hours,
  },
  {
    text: "1 Frame Every 24 hours",
    value: SnapshotExtractionInterval.Every24Hours,
  },
]

export const ProjectStatuses = Object.values(ProjectStatus)

export const ProjectStatusMap = {
  [ProjectStatus.InProgress]: {
    class: "green--text text--darken-2",
    value: "In Progress",
  },
  [ProjectStatus.NotAplicable]: {
    class: "red--text text--darken-2",
    value: "Not Applicable",
  },
  [ProjectStatus.Completed]: {
    class: "cyan--text text--darken-2",
    value: "Completed",
  },
  [ProjectStatus.ToStart]: {
    class: "yellow--text text--darken-2",
    value: "To Start",
  },
}

export const UserAccessFeatures = [
  { value: UserFeatureFlag.CompanyAdmin, name: "Company Admin" },
  { value: UserFeatureFlag.VideoWalls, name: "Video Wall" },
  {
    value: UserFeatureFlag.MassCameraSharing,
    name: "Project sharing + share camera with all project members",
  },
  { value: UserFeatureFlag.Copilot, name: "Copilot" },
]

export const ProjectFeatureFlags = [
  { value: ProjectFeatureFlag.ThreeSixtyView, name: "360 View" },
  {
    value: ProjectFeatureFlag.Bim,
    name: "BIM",
    children: [
      {
        value: ProjectFeatureFlag.BimItwin,
        name: "Bentley iTwin",
      },
      { value: ProjectFeatureFlag.BimForge, name: "Autodesk Forge" },
    ],
  },
  { value: ProjectFeatureFlag.DroneView, name: "Drone View" },
  {
    value: ProjectFeatureFlag.GateReport,
    name: "Gate Report",
    children: [
      {
        value: ProjectFeatureFlag.GateReportAutoVerify,
        name: "Auto Publish Events",
      },
      {
        value: ProjectFeatureFlag.GateReportMotionDetection,
        name: "Motion detection",
      },
      {
        value: ProjectFeatureFlag.GateReportAnprBased,
        name: "ANPR sourced events",
      },
      {
        value: ProjectFeatureFlag.NoLicensePlate,
        name: "No License Plate",
      },
    ],
  },
  { value: ProjectFeatureFlag.AnprLegacy, name: "Anpr Legacy" },
  { value: ProjectFeatureFlag.NdaMc, name: "NDA/MC" },
  { value: ProjectFeatureFlag.MediaHubSharing, name: "Media Hub Sharing" },
  { value: ProjectFeatureFlag.NoCameras, name: "NO Cameras" },
]

export const CameraFeatureFlags = [
  {
    name: "Edge video",
    value: CameraFeatureFlag.EdgeVideo,
  },
  {
    name: "Gate Report",
    value: CameraFeatureFlag.GateReport,
  },
  {
    name: "Bim Compare",
    value: CameraFeatureFlag.BimCompare,
  },
  {
    name: "Stream HLS",
    value: CameraFeatureFlag.VideoStream,
  },
  {
    name: "Stream WebRTC",
    value: CameraFeatureFlag.WebRTC,
  },
  {
    name: "ANPR",
    value: CameraFeatureFlag.ANPR,
    children: [
      {
        name: "Reverse ANPR",
        value: CameraFeatureFlag.ReverseANPR,
      },
    ],
  },
  {
    name: "PTZ",
    value: CameraFeatureFlag.PTZ,
  },
  {
    name: "Object Detection",
    value: CameraFeatureFlag.ObjectDetection,
  },

  {
    name: "Segmentation",
    value: CameraFeatureFlag.Segmentation,
    children: [
      {
        name: "Segmentation Auto-Labelling",
        value: CameraFeatureFlag.SegmentationAutoLabelling,
      },
    ],
  },
]

export const RoiTypes = [
  {
    name: "Gate",
    value: RoiType.Gate,
  },
  {
    name: "Anpr Context",
    value: RoiType.AnprContext,
  },
  {
    name: "Exclusion Zone",
    value: RoiType.ExclusionZone,
  },
  {
    name: "Workflow Area",
    value: RoiType.WorkflowArea,
  },
]

export const RoiDirectionTypes = [
  {
    value: RoiDirection.Arrived,
    type: "Arrived",
  },
  {
    value: RoiDirection.Left,
    type: "Left",
  },
  {
    value: RoiDirection.Bidirectional,
    type: "Both",
  },
]

export const MapTypes = [
  { name: "map", value: MapType.Terrain },
  { name: "satellite", value: MapType.Hybrid },
]

export const BooleanTextOptions = [
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
]

export const BooleanOptions = [
  { name: "Yes", value: true },
  { name: "No", value: false },
]

export const IngestProcessingStatuses = [
  { name: "Created", value: IngestProcessingStatus.Created },
  { name: "Pending", value: IngestProcessingStatus.Pending },
  { name: "In Progress", value: IngestProcessingStatus.InProgress },
  { name: "Completed", value: IngestProcessingStatus.Completed },
  { name: "Failed", value: IngestProcessingStatus.Failed },
  { name: "Aborted", value: IngestProcessingStatus.Aborted },
  { name: "Paused", value: IngestProcessingStatus.Paused },
  { name: "Uploading", value: IngestProcessingStatus.Uploading },
]

export const Regions = Object.entries(CameraRegion).map(([name, value]) => ({
  name,
  value,
}))

export const _360IntegrationTypes = Object.values(_360IntergrationType).map(
  (value) => ({ value, name: value })
)

export const RESOLUTIONS = Object.entries(ResolutionDimensions).reduce(
  (acc, [key, value]) => {
    if (key in ResolutionLabel) {
      acc[value] = ResolutionLabel[key]
    }

    return acc
  },
  {}
)

export default {}
