<template>
  <div class="d-flex align-center justify-center">
    <v-menu
      v-if="selectedDateRange === RangeFieldTypes.Custom"
      v-model="dateRangePicker"
      :close-on-content-click="false"
    >
      <template #activator="{ on: onMenu, attrs: attrsMenu }">
        <v-text-field
          :value="dateRangeDisplay"
          readonly
          dense
          hide-details
          v-bind="attrsMenu"
          v-on="onMenu"
        />
      </template>
      <v-date-picker v-model="dateRange" range @change="onDateRangePick" />
    </v-menu>
    <v-select
      v-model="selectedDateRange"
      :items="rangeTypes"
      item-text="range"
      item-value="value"
      placeholder="Date Range"
      style="z-index: 12"
      hide-details
      dense
      :class="{
        'hide-range-field': selectedDateRange === RangeFieldTypes.Custom,
      }"
      @change="onRangeTypeSelection"
    />
  </div>
</template>

<script>
import { camelizeKeys } from "humps"

const RangeFieldTypes = Object.freeze({
  All: "All",
  Last7Days: "Last7Days",
  Last30Days: "Last30Days",
  Custom: "Custom",
})

export default {
  name: "SelectDatePickerRangeSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
    fieldNamesMap: {
      type: Object,
      default: () => ({
        rangeTypeField: "rangeTypeField",
        fromField: "from",
        toField: "to",
      }),
    },
  },
  data() {
    return {
      RangeFieldTypes,
      rangeTypes: [
        { range: "All", value: RangeFieldTypes.All },
        { range: "Last 7 Days", value: RangeFieldTypes.Last7Days },
        { range: "Last 30 Days", value: RangeFieldTypes.Last30Days },
        { range: "Custom", value: RangeFieldTypes.Custom },
      ],
      dateRangePicker: false,
      dateRange: [],
      selectedDateRange: RangeFieldTypes.All,
    }
  },
  computed: {
    dateRangeDisplay() {
      if (this.dateRange?.length !== 2) {
        return "( From - To )"
      }

      return this.dateRange.join(" - ")
    },
  },
  mounted() {
    const query = camelizeKeys(this.$route.query)

    const { rangeTypeField, fromField, toField } = this.fieldNamesMap
    const fromDate = query[fromField]
    const toDate = query[toField]

    if (fromDate && toDate) {
      this.dateRange = [fromDate, toDate]
    }
    this.selectedDateRange = query[rangeTypeField] || RangeFieldTypes.All
  },
  methods: {
    onRangeTypeSelection(rangeType) {
      const days = {
        [RangeFieldTypes.Last7Days]: 7,
        [RangeFieldTypes.Last30Days]: 30,
      }
      if (days[rangeType]) {
        const fromDate = this.$moment()
          .subtract({ days: days[rangeType] })
          .format("YYYY-MM-DD")
        const toDate = this.$moment().format("YYYY-MM-DD")
        this.dateRange = [fromDate, toDate]
      } else {
        this.dateRange = []
      }

      this.changeFilters()
    },
    onDateRangePick(range) {
      const [fromDate, toDate] = range
      if (this.$moment(fromDate).isAfter(this.$moment(toDate))) {
        this.dateRange = [toDate, fromDate]
      }
      this.changeFilters()
      this.dateRangePicker = false
    },
    changeFilters() {
      const { rangeTypeField, fromField, toField } = this.fieldNamesMap
      const [fromDate, toDate] = this.dateRange
      this.$emit("search", {
        [rangeTypeField]:
          this.selectedDateRange === RangeFieldTypes.All
            ? null
            : this.selectedDateRange,
        [fromField]: fromDate,
        [toField]: toDate,
      })
    },
  },
}
</script>

<style>
.hide-range-field .v-select__selections {
  display: none;
}
</style>
