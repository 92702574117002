<template>
  <v-container fluid class="h-100">
    <v-card
      flat
      class="text-center h-100 d-flex align-center justify-center flex-column"
    >
      <img src="https://dash.evercam.io/evercam-logo.svg" />
      <v-card-title class="headline">401 Unauthorized</v-card-title>
      <v-card-text>
        <p>
          You are not authorized to access this resource. Please contact your
          manager or
          <a href="mailto:support@evercam.io">support@evercam.io</a>.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  meta: {
    requiredPermissions: [],
  },
}
</script>

<style scoped>
v-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  width: 100px;
  height: 100px;
}
</style>
