<template>
  <div width="100%" class="height-auto" fluid>
    <v-card flat class="ml-2">
      <v-card-title class="mx-n3">
        Logos
        <v-spacer />
        <AddProjectLogoDialog
          v-if="editable"
          :project-id="projectId"
          @logo-added="fetchLogos"
        />
      </v-card-title>
      <div v-if="logos.length > 0" class="mx-n4">
        <div v-for="(logo, key) in logos" :key="`logo-${key}`">
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="logo.url"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Logo name</v-list-item-subtitle>
              <v-list-item-title
                class="subtitle-1"
                v-text="logo.name"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action v-if="logo.projectId">
              <div>
                <v-btn color="primary" icon @click="downloadLogo(logo)">
                  <v-icon small> fas fa-download </v-icon>
                </v-btn>
                <v-btn
                  v-if="editable"
                  color="primary"
                  icon
                  @click="toggleEditName(logo)"
                >
                  <v-icon small> fa-pencil-alt </v-icon>
                </v-btn>
                <v-btn
                  v-if="editable"
                  color="error"
                  icon
                  @click="openDeleteDialog(logo)"
                >
                  <v-icon small> fas fa-trash </v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="key < logos.length - 1" :key="key" class="ml-2" />
        </div>
      </div>
      <v-alert v-else border="left" text tile color="info"
        >There's nothing here yet.</v-alert
      >
    </v-card>

    <v-dialog v-model="editLogoName" scrollable max-width="300px">
      <v-card>
        <v-card-title>Update Logo Name</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3" style="max-height: 300px">
          <v-text-field v-model="logoName" placeholder="Logo Name">
          </v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeEditDiaglog">
              CANCEL
            </v-btn>
            <v-btn text color="primary" @click="updateLogo"> SAVE </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import AddProjectLogoDialog from "@evercam/shared/components/AddProjectLogoDialog"
import { downloadImage } from "@evercam/shared/utils"

export default {
  components: {
    AddProjectLogoDialog,
  },
  props: {
    projectId: {
      type: [String, undefined],
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logos: [],
      editLogoName: false,
      logoName: "",
      logo: null,
    }
  },
  mounted() {
    this.fetchLogos()
  },
  methods: {
    toggleEditName(logo) {
      this.logoName = logo.name
      this.logo = logo
      this.editLogoName = true
    },
    closeEditDiaglog() {
      this.editLogoName = false
      this.logoName = ""
      this.logo = null
    },
    async fetchLogos() {
      try {
        this.logos = await EvercamApi.projects.getLogos(this.projectId)
      } catch (err) {
        this.$errorTracker?.save(err)
      }
    },
    async openDeleteDialog(logo) {
      if (
        await this.$confirmDialog.open({
          title: "Delete Logo",
          message: "Are you sure you want to delete the selected logo ?",
        })
      ) {
        this.deleteLogo(logo)
      }
    },
    async deleteLogo(logo) {
      try {
        await EvercamApi.projects.deleteLogo(this.projectId, logo.id)
        this.fetchLogos()
      } catch (err) {
        this.$errorTracker?.save(err)
      }
    },
    async updateLogo() {
      try {
        await EvercamApi.projects.updateLogo(
          this.projectId,
          this.logo.id,
          this.logoName
        )
        this.fetchLogos()
      } catch (err) {
        this.$errorTracker?.save(err)
      } finally {
        this.editLogoName = false
      }
    },
    downloadLogo({ url, name }) {
      downloadImage(url, name)
    },
  },
}
</script>
