<template>
  <div>
    <div v-if="item.entity == 'cameras'">
      Camera
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.exid || item.details.camera.exid)"
      >
        {{ item.details.name || item.details.camera.name }}
      </span>
      updated.
    </div>
    <div v-if="item.entity == 'archives'">
      {{ getArchiveName(item.details.type) }} updated for
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.camera.exid)"
      >
        {{ item.details.camera.name }} </span
      >.
    </div>
    <div v-if="item.details.router">
      Router
      <span class="sim-hover primary--text">
        {{ item.details.router.serialNumber }}
      </span>
      updated.
    </div>
    <div v-if="item.details.sim">
      SIM
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            class="primary--text sim-hover"
            v-bind="attrs"
            @click="openSmsHistoryDialog(item.details.sim.number)"
            v-on="on"
          >
            {{ item.details.sim.number }}
          </span>
        </template>
        Open SMS History
      </v-tooltip>
      updated.
    </div>
    <div v-if="item.details.project">
      Project
      <span
        class="cursor-pointer primary--text"
        @click="projectStore.openDialog(item.details.project.exid)"
      >
        {{ item.details.project.name || item.details.project.exid }}
      </span>
      updated.
    </div>
    <div v-if="item.details.user">
      User
      <span class="sim-hover primary--text">
        {{ item.who }}
      </span>
      updated.
    </div>
    <div v-if="item.details && item.entity === 'rois'">
      User <span class="sim-hover primary--text"> {{ item.who }} </span>
      updated rois
      <span v-if="item.details.note">
        , <strong>note:</strong> {{ item.details.note }}
      </span>
    </div>
    <div v-if="item.entity == 'layers' && item.details">
      User <span class="sim-hover primary--text"> {{ item.who }} </span> updated
      layer {{ item.details.name }}
      <span v-if="item.details.displayName">
        :{{ item.details.displayName }}
      </span>
      , id: {{ item.details.layerId }}
    </div>
    <div v-if="item.entity === 'roles'">
      Role
      <span class="sim-hover primary--text"
        >{{ item.details.id }} - {{ item.details.name }}</span
      >
      updated.
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapStores(useProjectStore),
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
    openSmsHistoryDialog(item) {
      this.$emit("openSmsHistoryDialog", item)
    },
    getArchiveName(type) {
      return type.charAt(0).toUpperCase() + type.slice(1)
    },
  },
}
</script>
