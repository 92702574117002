<template>
  <v-list-item
    color="#000"
    class="gr-event-card px-0"
    :class="{
      'gr-event-card--new': isNew,
      'gr-event-card--active': isActive,
      'gr-event-card--loading': isLoading || isVerifying,
      'gr-event-card--saved': isSaved,
      'gr-event-card--errored': isErrored,
      'gr-event-card--duplicate': isDuplicate,
      'gr-event-card--selected': isSelected,
      'gr-event-card--edited': isEdited,
      'gr-event-card--deleted': isDeleted,
    }"
    :link="isSelectable"
    :selectable="isSelectable"
    :inactive="!isSelectable"
    v-on="$listeners"
  >
    <v-list-item-content class="py-0 h-100">
      <v-list-item-title
        class="h-100 gr-event-card__fields-container d-flex align-center justify-start"
      >
        <!-- THUMBNAIL -->
        <div
          class="h-100 thumbnail-container d-flex align-center"
          :style="thumbnailContainerStyle"
        >
          <v-hover v-slot="{ hover }">
            <div
              class="thumbnail h-100"
              :class="{
                'w-100': isLoadingThumbnail,
              }"
              @mouseenter="handleThumbnailHover(true)"
              @mouseleave="handleThumbnailHover(false)"
            >
              <img
                v-show="!isLoadingThumbnail"
                class="gr-event-card__thumbnail"
                :class="{
                  'gr-event-card__thumbnail--missing': isThumbnailMissing,
                }"
                :src="renderedThumbnailUrl"
                alt="event thumbnail"
                @load="onThumbnailLoaded"
                @error="onThumbnailError"
              />
              <v-fade-transition>
                <div
                  v-show="
                    hover &&
                    !isLoadingThumbnail &&
                    !isThumbnailMissing &&
                    showSnapshotPreview
                  "
                  class="thumbnail__play-btn"
                >
                  <SnapshotPreview
                    :camera="camera"
                    :project="gateReportStore.selectedProject"
                    :title="title"
                    :timestamp="event.eventTime"
                    :auth-token="accountStore.token"
                    show-snapshot-quality
                  >
                    <template #custom-button>
                      <v-btn fab x-small>
                        <v-icon x-small> fas fa-play </v-icon>
                      </v-btn>
                    </template>
                  </SnapshotPreview>
                </div>
              </v-fade-transition>
              <div
                v-if="isLoadingThumbnail"
                class="gr-event-card__thumbnail__loader w-100 h-100 mr-2"
              >
                <EvercamLoadingAnimation size="TwoXl" />
              </div>
            </div>
          </v-hover>
        </div>

        <!-- TRUCK TYPE -->
        <div class="d-flex align-center gr-event-card__truck-type px-2">
          <ESvgIcon :icon="event.truckType" size="40" class="mr-1" />
          <ETruncatedDiv v-if="isMotionEvent" width="8rem" :fixed-width="true">
            {{ event.truckType }}
          </ETruncatedDiv>
          <!-- Editable select -->
          <div v-else>
            <v-select
              v-model="truckTypeField"
              :disabled="readOnly"
              :items="vehicleTypeIds"
              @change="handleTruckTypeChange"
            ></v-select>
          </div>
        </div>
        <!-- TIMESTAMP -->
        <div class="grc-timestamp">{{ formattedTimestamp }}</div>

        <!-- DIRECTION -->
        <div class="d-flex gr-event-card__truck-type px-2">
          <v-icon class="mr-2"> {{ eventTypeIcon }} </v-icon>
          <ETruncatedDiv
            v-if="showEventType && isMotionEvent"
            class="mx-n2 grc-direction pl-2"
            width="2.5rem"
            :fixed-width="true"
          >
            {{ eventTypeLabel }}
          </ETruncatedDiv>
          <!-- Editable select -->
          <v-select
            v-else
            v-model="eventTypeField"
            :disabled="readOnly"
            :items="eventTypeIds"
            @change="handleEventTypeChange"
          ></v-select>
        </div>

        <!-- CAMERA NAME -->
        <div v-if="showCameraName" :title="camera.name" class="grc-exid">
          <ETruncatedDiv width="7rem" fixed-width>{{
            camera.name
          }}</ETruncatedDiv>
        </div>

        <!-- ACTIONS -->
        <div
          v-if="isEditable"
          class="d-inline-flex align-center justify-start pr-2 grc-actions"
        >
          <!-- Delete button -->
          <v-btn
            v-if="showDeleteButton"
            icon
            :loading="isLoading"
            class="mr-2"
            @click.native="onDelete"
          >
            <v-icon> fa-trash </v-icon>
          </v-btn>
          <!-- Verify / unverify -->
          <SingleEventVerifier
            :disabled="isMotionEvent || readOnly"
            :event="event"
            @success="onEventVerified"
            @click.native="onVerifyClicked"
          />
        </div>

        <!-- CAMERA EXID -->
        <div v-if="showCameraExid" :title="camera.exid" class="grc-exid">
          {{ camera.exid }}
        </div>

        <!-- EVENT ID -->
        <ETruncatedDiv
          v-if="showId"
          width="4rem"
          class="grc-id"
          :title="event.id"
          :fixed-width="true"
        >
          {{ event.id }}
        </ETruncatedDiv>

        <!-- IS EDITED -->
        <ETruncatedDiv
          v-if="showIsEdited"
          width="4rem"
          class="grc-edited"
          :title="event.edited"
          :fixed-width="true"
        >
          {{ event.edited }}
        </ETruncatedDiv>

        <!-- DETECTION TYPE -->
        <ETruncatedDiv
          v-if="showDetectionType"
          width="3rem"
          class="grc-load-state"
          :title="event.detectionType"
          :fixed-width="true"
        >
          {{ event.detectionType }}
        </ETruncatedDiv>

        <!-- EXTRA ACTIONS -->
        <slot name="actions" class="actions"></slot>
      </v-list-item-title>
    </v-list-item-content>

    <!-- LOADING OVERLAY -->
    <div
      v-if="isLoading"
      class="d-flex justify-center align-center gr-event-card__loading-overlay"
    >
      <EvercamLoadingAnimation size="TwoXl" />
    </div>
  </v-list-item>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import SingleEventVerifier from "@/components/gateReport/SingleEventVerifier"
import { UPDATE_TYPE } from "@/stores/gateReportEventsValidation"
import SnapshotPreview from "@evercam/shared/components/SnapshotPreview"
import {
  GateReportEvent,
  GateReportEventType,
  GateReportVehicleType,
} from "@evercam/shared/types"
import { VEHICLE_TYPES } from "@evercam/shared/constants/gateReport"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useGateReportEventsValidationStore } from "@/stores/gateReportEventsValidation"
import { useGateReportStore } from "@/stores/gateReport"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "GateReportEventCard",
  components: {
    SingleEventVerifier,
    SnapshotPreview,
    EvercamLoadingAnimation,
  },
  props: {
    event: {
      type: Object as PropType<GateReportEvent>,
      default: () => ({} as GateReportEvent),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isSaved: {
      type: Boolean,
      default: false,
    },
    isErrored: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    showEventType: {
      type: Boolean,
      default: true,
    },
    showDetectionType: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: undefined,
    },
    showCameraName: {
      type: Boolean,
      default: false,
    },
    showCameraExid: {
      type: Boolean,
      default: true,
    },
    showId: {
      type: Boolean,
      default: false,
    },
    showIsEdited: {
      type: Boolean,
      default: false,
    },
    showSnapshotPreview: {
      type: Boolean,
      default: true,
    },
    thumbnailWidth: {
      type: [Number, undefined],
      default: undefined,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isThumbnailMissing: false,
      isVerifying: false,
      isLoadingThumbnail: true,
      selectedTruckType: this.event.truckType,
      vehicleTypeIds: Object.values(GateReportVehicleType),
      eventTypeIds: [
        { text: "In", value: GateReportEventType.Arrived },
        { text: "Out", value: GateReportEventType.Left },
      ],
      isEditingEvent: false,
      isRegenerated: false,
      truckTypeField: null,
      eventTypeField: null,
      updateFieldMessages: {
        truckType: {
          success: "content.gate_report.success_update_truck_type",
          failure: "content.gate_report.failed_update_truck_type",
        },
        eventType: {
          success: "content.gate_report.success_update_event_type",
          failure: "content.gate_report.failed_update_event_type",
        },
      },
    }
  },
  computed: {
    ...mapStores(
      useGateReportEventsValidationStore,
      useGateReportStore,
      useAccountStore
    ),
    camera() {
      return (
        this.gateReportStore.cameras.find(
          (c) => c.exid === this.event.cameraExid
        ) || {}
      )
    },
    thumbnailUrl(): string {
      const { cameraExid, eventType, eventTime } = this.event

      return (
        this.event.thumbnailUrl ||
        AiApi.gateReport.getEventThumbnail({
          cameraExid,
          eventType,
          eventTime,
          token: this.accountStore.token,
        })
      )
    },
    timezone() {
      return this.gateReportStore.selectedProject?.timezone || "Europe/Dublin"
    },
    formattedTimestamp(): string {
      return this.$moment
        .tz(this.event.eventTime, this.timezone)
        .format("YYYY-MM-DDTHH:mm:ss")
    },
    thumbnailContainerStyle() {
      if (!this.thumbnailWidth) {
        return {}
      }
      const padding = 12

      return {
        width: `${this.thumbnailWidth + padding}px`,
      }
    },
    title() {
      const eventTypeVerbs = {
        arrived: "Entering",
        left: "Exiting",
      }

      const formattedTimestamp = this.$moment
        .tz(
          this.event.eventTime,
          this.gateReportStore.selectedProject?.timezone
        )
        .format("L LTS")

      return `${eventTypeVerbs[this.event.eventType]} ${this.getVehicleLabel(
        this.event.truckType
      )} - ${formattedTimestamp}`
    },
    renderedThumbnailUrl() {
      return this.isThumbnailMissing
        ? "/unavailable-small.jpg"
        : `${this.thumbnailUrl}&retry=${this.isRegenerated}`
    },
    isMotionEvent() {
      return this.event.truckType === GateReportVehicleType.Unknown
    },
    isMotionIcon() {
      return [
        GateReportVehicleType.Unknown,
        GateReportVehicleType.OtherTruck,
      ].includes(this.event.truckType)
    },
    eventTypeLabel() {
      return this.event.eventType === GateReportEventType.Arrived ? "in" : "out"
    },
    eventTypeIcon() {
      return `fa-${
        this.event.eventType === GateReportEventType.Arrived
          ? "arrow-down"
          : "arrow-up"
      }`
    },
  },
  watch: {
    thumbnailUrl(value) {
      if (value) {
        this.isLoadingThumbnail = true
        this.isThumbnailMissing = false
      }
    },
    "event.truckType": {
      handler(value) {
        this.truckTypeField = value
      },
      immediate: true,
    },
    "event.eventType": {
      handler(value) {
        this.eventTypeField = value
      },
      immediate: true,
    },
  },

  methods: {
    handleTruckTypeChange(value) {
      this.updateEventProperty("truckType", value)
    },

    handleEventTypeChange(value) {
      this.updateEventProperty("eventType", value)
    },
    async updateEventProperty(field, value) {
      try {
        const params = {
          payload: { [field]: value },
          updatedBy: useAccountStore().email,
        }
        await AiApi.gateReport.updateEventById(this.event.id, params)
        this.gateReportEventsValidationStore.updateEventsLists({
          event: {
            ...this.event,
            [field]: value,
          },
          updateType: UPDATE_TYPE.EDIT,
        })
        this.$notifications.success(
          this.$t(this.updateFieldMessages[field].success).toString()
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t(this.updateFieldMessages[field].failure).toString(),
          error,
        })
      }
    },
    onThumbnailLoaded(e, retries = 0) {
      this.isLoadingThumbnail = false
      const img = e.target
      const imgWidth = img?.getBoundingClientRect()?.width

      if (imgWidth) {
        this.$emit("thumbnail-width", imgWidth)
      } else if (imgWidth === 0 && retries < 5) {
        this.$setTimeout(() => {
          this.onThumbnailLoaded({ target: img }, retries + 1)
        }, 500)
      }
      this.$emit("thumbnail-url", this.renderedThumbnailUrl)
    },
    onThumbnailError() {
      if (!this.isRegenerated) {
        this.regenerateThumbnail()
      }
    },
    async regenerateThumbnail() {
      try {
        this.isLoadingThumbnail = true
        await AiApi.gateReport.regenerateThumbnail(this.event.id, {
          authorization: this.accountStore.token,
          cameraExid: this.event.cameraExid,
        })
        this.isRegenerated = true
        this.isThumbnailMissing = false
      } catch (error) {
        this.isThumbnailMissing = true
      } finally {
        this.isLoadingThumbnail = false
      }
    },
    onDelete(e) {
      e.stopPropagation()
      this.$emit("delete-event", this.event)
    },
    onIgnore(e) {
      e.stopPropagation()
      this.$emit("ignore-event", this.event)
    },
    onVerifyClicked(e) {
      e.stopPropagation()
      this.isVerifying = true
    },
    onEventVerified(event) {
      this.gateReportEventsValidationStore.updateMainPageEvents({
        event,
        updateType: UPDATE_TYPE.EDIT,
      })
      this.gateReportEventsValidationStore.events =
        this.gateReportEventsValidationStore.events.map((e) =>
          e.id === event.id ? event : e
        )
      this.isVerifying = false
    },
    getVehicleLabel(vehicleId) {
      return VEHICLE_TYPES.find((vehicle) => vehicle.value === vehicleId)?.label
    },
    handleThumbnailHover(isHovered) {
      this.$emit("thumbnail-hovered", {
        isHovered,
        url: this.renderedThumbnailUrl,
        targetRect: this.$el.getBoundingClientRect(),
      })
    },
  },
})
</script>

<style lang="scss">
.gr-event-card {
  margin: 3px;
  border-radius: 5px;
  background: rgb(232, 244, 253);
  height: 48px;
  transition: background 0.3s, opacity 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 1;
  &--new {
    background: #edfad4;
    &.gr-event-card--active {
      background: #8ec547;
    }
  }
  &--loading {
    filter: opacity(0.7);
  }
  &--saved {
    color: rgba(0, 0, 0, 0.75) !important;
    background: #c0e098;
  }
  &--active {
    box-shadow: inset 0 0 2px 0 #000;
  }
  &--selected {
    border: 2px solid #1b77d2;
  }
  &--errored {
    background: #e57373;
  }
  &--edited {
    color: rgba(0, 0, 0, 0.75) !important;
    background: #c0e098;
  }
  &--deleted {
    background: #e57373;
    filter: opacity(0.7);
  }

  &--duplicate {
    background-color: #ffa767;
  }
  &__thumbnail {
    height: 100%;
    border-radius: 3px;
    &--missing {
      border: 1px solid #00000036;
    }
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__truck-type {
    min-width: 8rem;
  }
  &__loading-overlay {
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(194, 194, 194, 0.5);
    height: 100%;
    width: 100%;
  }
  &.v-list-item--link {
    user-select: auto !important;
  }
  &:hover:not(.gr-event-card--duplicate) {
    background-color: #efffe5;
  }
}
.gr-tooltip-container {
  &.v-tooltip__content.menuable__content__active {
    opacity: 1;
  }
}
.thumbnail {
  position: relative;
  &__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
