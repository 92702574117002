<template>
  <v-dialog
    v-model="progressPhotoStore.progressPhotoDialog"
    max-width="600px"
    persistent
    @keydown.esc="progressPhotoStore.progressPhotoDialog"
  >
    <v-card>
      <v-card-title>
        <span>Edit Progress Photo</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-card-text class="pt-0 pb-0">
          <v-container class="pt-0 pb-0 px-5">
            <ECol class="pa-0 pt-3">
              <ERow class="pa-0">
                <ECol class="pa-0" cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Recipient"
                    rules="requiredRecipients|email"
                  >
                    <Autocomplete
                      v-model="progressPhotoStore.currentProgressPhoto.cameras"
                      :rules="{ requiredCameras: true }"
                      item-value="exid"
                      :list-items="progressPhotoStore.cameras"
                      classes="caption"
                      item-text="name"
                      label="Cameras"
                      append-icon="mdi-menu-down"
                      :error-messages="errors"
                      small-chips
                      multiple
                      dense
                      :outlined="false"
                      :return-object="false"
                      :cache-items="false"
                    >
                      <template #selection="{ attrs, selected, item, select }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          class="caption"
                          @click="select"
                          @click:close="removeCameraItem(item)"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </Autocomplete>
                  </ValidationProvider>
                </ECol>
                <ECol
                  v-if="
                    progressPhotoStore.currentProgressPhoto.provider === 'email'
                  "
                  class="pa-0 my-3"
                  cols="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Recipient"
                    rules="requiredRecipients"
                  >
                    <v-combobox
                      ref="combobox"
                      :value="
                        progressPhotoStore.currentProgressPhoto.config
                          .recipients
                      "
                      :error-messages="errors"
                      item-text="email"
                      item-value="id"
                      class="caption combobox pt-0"
                      name="nope"
                      type="search"
                      aria-autocomplete="off"
                      multiple
                      small-chips
                      :menu-props="{
                        openOnClick: false,
                        maxHeight: 100,
                      }"
                      @paste.native="handlePaste"
                      @input="handleUserChange"
                    >
                      <template slot="append">
                        <v-icon class="pr-2 d-none" small>
                          fa-caret-down
                        </v-icon>
                      </template>
                      <template #label>
                        <div class="caption label-color">Email Addresses</div>
                      </template>
                      <template
                        #selection="{ item, selected, disabled, parent }"
                      >
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-chip
                              :key="JSON.stringify(item)"
                              v-bind="attrs"
                              :input-value="selected"
                              :disabled="disabled"
                              :color="checkColor(item)"
                              class="ml-0 caption"
                              autocomplete="new-password"
                              @click:close="parent.selectItem(item)"
                              v-on="on"
                            >
                              <v-avatar
                                left
                                v-text="
                                  recipientLabel(item).slice(0, 1).toUpperCase()
                                "
                              />
                              {{ recipientLabel(item) }}
                              <v-icon
                                small
                                class="pl-1"
                                @click="parent.selectItem(item)"
                              >
                                fas fa-times-circle
                              </v-icon>
                            </v-chip>
                          </template>
                          <span> {{ item.email ?? item }} </span>
                        </v-tooltip>
                      </template>
                      <template #item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="caption"
                            v-text="recipientLabel(item)"
                          />
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </ValidationProvider>
                </ECol>
              </ERow>
              <ERow class="pa-0">
                <ECol cols="auto" class="pa-0">
                  <label class="v-label v-label--active">
                    <div class="time-label">Start Time</div>
                  </label>

                  <v-menu
                    ref="startTimeMenu"
                    v-model="timePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="
                          progressPhotoStore.currentProgressPhoto.notifyTime
                        "
                        class="snapmail-timepicker py-0 my-0"
                        readonly
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Time"
                      rules="required"
                    >
                      <v-time-picker
                        v-show="timePickerMenu"
                        v-model="
                          progressPhotoStore.currentProgressPhoto.notifyTime
                        "
                        full-width
                        color="primary"
                        format="24hr"
                        @click:minute="
                          updateTime(
                            progressPhotoStore.currentProgressPhoto.notifyTime
                          )
                        "
                      ></v-time-picker>
                      <p class="pl-3 caption time-error error--text">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </v-menu>
                </ECol>
                <ECol class="pa-0 mt-3">
                  <Autocomplete
                    v-model="progressPhotoStore.currentProgressPhoto.timezone"
                    :list-items="timezones"
                    rules="required"
                    item-text="name"
                    item-value="value"
                    :outlined="false"
                    :return-object="false"
                    label="Select Timezone"
                    classes="caption"
                    dense
                  >
                    <template #label>
                      <div class="caption">Select Timezone</div>
                    </template>
                    <template #item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          class="caption"
                          v-text="data.item.name"
                        />
                      </v-list-item-content>
                    </template>
                  </Autocomplete>
                </ECol>
              </ERow>
              <ERow class="pa-0">
                <ECol class="pa-0">
                  <ValidationProvider
                    ref="progressPhotoDays"
                    v-slot="{ errors }"
                    name="Day"
                    rules="requiredDays"
                  >
                    <v-item-group
                      v-model="
                        progressPhotoStore.currentProgressPhoto.notifyDays
                      "
                      multiple
                    >
                      <v-subheader class="caption px-0">Days</v-subheader>
                      <v-container class="d-flex pa-0 fluid">
                        <v-item>
                          <v-chip
                            class="suface"
                            active-class="blue--text"
                            :input-value="allDaysSelected"
                            @click="toggleAll"
                          >
                            {{ $t("content.progress_photo.all") }}
                          </v-chip>
                        </v-item>
                        <v-item
                          v-for="(day, key) in days"
                          :key="key"
                          v-slot="{ active, toggle }"
                          class="mx-1"
                          :value="day.value"
                        >
                          <v-chip
                            class="suface"
                            active-class="blue--text"
                            :input-value="active"
                            @click="toggle"
                          >
                            {{ day.letter }}
                          </v-chip>
                        </v-item>
                      </v-container>
                    </v-item-group>
                    <p class="pt-3 pl-3 caption error--text">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </ECol>
              </ERow>
            </ECol>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div>
            <v-btn
              color="warning"
              text
              :disabled="progressPhotoStore.loading"
              @click="
                progressPhotoStore.onPause(
                  !progressPhotoStore.currentProgressPhoto.isPaused
                )
              "
            >
              {{
                progressPhotoStore.currentProgressPhoto.isPaused
                  ? "Resume"
                  : "Pause"
              }}
            </v-btn>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="progressPhotoStore.progressPhotoDialog = false"
          >
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="progressPhotoStore.loading"
            :disabled="progressPhotoStore.loading || invalid"
            @click="passes(progressPhotoStore.update)"
          >
            {{ $t("actions.save") }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"

import weekDays from "@evercam/shared/constants/weekDays"
import timezones from "@evercam/shared/constants/timezones"
import Autocomplete from "@evercam/shared/components/Autocomplete"

import { useProgressPhotoStore } from "#build/imports"

export default Vue.extend({
  name: "EditProgressPhotosDialog",
  components: {
    Autocomplete,
  },
  data() {
    return {
      days: weekDays,
      timezones,
      timePickerMenu: false,
      // eslint-disable-next-line no-useless-escape
      reg: /^(?!.*\.{2})[a-zA-Z0-9!.#$%&'*+"\/=?^_`{|}~-]+@[a-zA-Z\d\-]+(\.[a-zA-Z\d\-]+)*\.[a-zA-Z0-9]+z*$/,
    }
  },
  computed: {
    ...mapStores(useProgressPhotoStore),
    allDaysSelected(): boolean {
      return this.progressPhotoStore.currentProgressPhoto.notifyDays.length == 7
    },
  },

  methods: {
    handlePaste(event) {
      if (event.clipboardData) {
        const pastedText = event.clipboardData
          .getData("Text")
          .replace(/[<>]/g, "")
        event.preventDefault()
        let items = pastedText.split(/[:,|\s]/).reduce((acc, item) => {
          if (item != "" && this.reg.test(item)) {
            return [...acc, { name: null, email: item.trim() }]
          } else {
            return acc
          }
        }, "") as string[]
        this.progressPhotoStore.currentProgressPhoto.config.recipients = [
          ...this.progressPhotoStore.currentProgressPhoto.config.recipients,
          ...items,
        ]
      }
    },
    handleUserChange(items) {
      this.progressPhotoStore.currentProgressPhoto.config.recipients =
        items.map((item) => {
          if (typeof item === "string") {
            return {
              name: null,
              email: item,
            }
          } else {
            return item
          }
        })
    },
    updateTime(time) {
      this.$refs.startTimeMenu.save(time)
    },
    checkColor(item) {
      return this.reg.test(item) ? "" : "error_container"
    },
    toggleAll() {
      if (
        this.progressPhotoStore.currentProgressPhoto.notifyDays?.length !== 7
      ) {
        this.progressPhotoStore.currentProgressPhoto.notifyDays = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]
      } else {
        this.progressPhotoStore.currentProgressPhoto.notifyDays = []
        this.$nextTick(() => {
          this.$refs.progressPhotoDays.validate()
        })
      }
    },
    removeCameraItem(item) {
      this.progressPhotoStore.currentProgressPhoto.cameras =
        this.progressPhotoStore.currentProgressPhoto.cameras.filter(
          (camera) => camera.exid !== item.exid
        )
    },
    removeEmailItem(item) {
      this.progressPhotoStore.currentProgressPhoto.config.recipients =
        this.progressPhotoStore.currentProgressPhoto.config.recipients.filter(
          (rec) => rec !== item.config.recipients
        )
    },
    recipientLabel(item) {
      return item.name ?? item.email ?? item
    },
  },
})
</script>
