<template>
  <ProjectSummary
    :project-exid="projectExid"
    :company-id="companyId"
    :company-name="companyName"
    :zoho-id="zohoId"
    inline
  />
</template>

<script>
import ProjectSummary from "@/components/projects/ProjectSummary"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.PROJECTS.VIEW],
  },
  components: {
    ProjectSummary,
  },
  async asyncData({ params, error, route }) {
    let projectExid
    let companyId
    let zohoId
    if (params.project) {
      projectExid = params.project
      companyId = params.id
    } else {
      projectExid = params.id
    }

    zohoId = route?.query?.zoho
    let response
    if (companyId) {
      try {
        response = await EvercamApi.companies.getPACompanyDetails(companyId)
      } catch (e) {
        console.error(e)
      }
    }

    return {
      error,
      projectExid,
      companyName: response ? response?.name : "",
      companyId,
      zohoId,
    }
  },
}
</script>
