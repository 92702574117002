<template>
  <ReportTable
    name="Drone"
    item-key="id"
    :headers="filteredHeaders"
    :filter-fields="filters"
    :provider="ingestAPI.drone.getDrones"
    :copy-to-clipboard="false"
    :hide-show="false"
  >
    <template #actions-left>
      <ERow justify="start" align="center">
        <v-btn icon color="primary" @click="refreshTable()"
          ><v-icon>fa-rotate-right</v-icon></v-btn
        >
        <div class="body-1 text--secondary my-3">
          Queued Drones ({{ droneQueueSize }}) | Total Queued ({{
            totalQueueSize
          }})
        </div>
      </ERow>
    </template>
    <template #actions-right>
      <ERow class="mr-1">
        <v-spacer />

        <v-btn
          v-if="allowUpload"
          small
          color="primary"
          class="text-center ml-2"
          @click="droneUploadDialog = true"
        >
          New {{ ingestUploadTypes.droneUploads }}
        </v-btn>
        <CreateUploadDialog
          :upload-dialog="droneUploadDialog"
          :ingest-upload-type="ingestUploadTypes.droneUploads"
          :provider="generateDroneUploadLink"
          :upload-object="droneUploadObject"
          @close-dialog="onDroneUplaodDialogClosed()"
        />
      </ERow>
    </template>
    <template #item.projectId="{ item }">
      <span
        >{{ item.projectId }}
        <OpenInNewWindowButton
          v-if="
            item.flightDate &&
            item.processingStatus === IngestProcessingStatus.Completed
          "
          :target-link="`${$config.public.dashURL}/projects/${
            item.projectId
          }/drone?${formatFlightDate(item.flightDate)}`"
        />
        <v-tooltip
          v-if="item.processingStatus === IngestProcessingStatus.Uploading"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :style="{ minWidth: 'unset' }"
              color="primary"
              class="pr-1 ma-0"
              small
              text
              v-bind="attrs"
              v-on="on"
              @click="copyProjectId(item.projectId)"
            >
              <v-icon x-small class="mr-2">far fa-copy</v-icon>
            </v-btn>
          </template>
          Copy
        </v-tooltip>
      </span>
    </template>
    <template #item.processingStatus="{ item }">
      <ERow justify="center" align="center">
        <v-chip
          v-if="
            (!item.processingStatus && !item.consumed) ||
            item.processingStatus == IngestProcessingStatus.Created
          "
          small
          rounded
          outlined
          color="primary"
          class="chip-size"
          @click="openDroneUploadDialog(item)"
        >
          <v-icon class="mr-2" color="primary">
            fa fa-external-link-alt
          </v-icon>
          {{ item.processingStatus }}
        </v-chip>
        <v-chip
          v-else-if="
            item.processingStatus &&
            item.processingStatus == IngestProcessingStatus.InProgress
          "
          small
          rounded
          outlined
          color="primary"
          class="chip-size"
        >
          <v-progress-circular
            indeterminate
            :size="15"
            width="1"
            color="primary"
            >{{ item.processingStatus }}</v-progress-circular
          >
        </v-chip>
        <v-chip
          v-else-if="
            item.processingStatus &&
            (item.processingStatus == IngestProcessingStatus.Failed ||
              item.processingStatus == IngestProcessingStatus.Pending ||
              item.processingStatus == IngestProcessingStatus.Aborted)
          "
          small
          rounded
          outlined
          :color="item.hasTusLinks ? '#FBC02D' : 'primary'"
          class="chip-size"
          @click="item.hasTusLinks && openReprocessDialog(item)"
        >
          <v-icon
            class="mr-2"
            :color="item.hasTusLinks ? '#FBC02D' : 'primary'"
          >
            {{
              item.hasTusLinks
                ? "fa-solid fa-rotate-right"
                : "fa-solid fa-clock-o"
            }}
          </v-icon>
          {{ item.processingStatus }}
        </v-chip>
        <v-chip
          v-else-if="
            item.processingStatus &&
            item.processingStatus === IngestProcessingStatus.Uploading
          "
          small
          rounded
          outlined
          color="primary"
          class="chip-size"
        >
          <v-icon color="primary" class="mr-2">fa-regular fa-circle-up</v-icon>
          {{ item.processingStatus }}</v-chip
        >
        <v-chip
          v-else
          small
          rounded
          outlined
          color="success"
          class="chip-size"
          target="_blank"
          :href="`https://ion.cesium.com/tilesetlocationeditor/${item.modelCesiumAssetId}`"
        >
          <v-icon color="success" class="mr-2"> fa-solid fa-check </v-icon>
          {{ item.processingStatus }}</v-chip
        >
      </ERow>
    </template>
    <template #item.modelZip="{ item }">
      <ArchiveDownloadLabel
        v-if="item.modelZip"
        :dataId="item.id"
        dataType="model"
        :download-archive="ingestAPI.drone.getArchive"
      />
    </template>
    <template #item.orthomosaicZip="{ item }">
      <ArchiveDownloadLabel
        v-if="item.orthomosaicZip"
        :dataId="item.id"
        dataType="orthomosaic"
        :download-archive="ingestAPI.drone.getArchive"
      />
    </template>
    <template #item.reportPdf="{ item }">
      <ArchiveDownloadLabel
        v-if="item.reportPdf"
        :dataId="item.id"
        dataType="report"
        :download-archive="ingestAPI.drone.getArchive"
      />
    </template>
    <template #item.flightGeolocation="{ item }">
      <span v-if="item.flightGeolocation"
        >Open In Google Maps
        <OpenInNewWindowButton
          :target-link="`http://maps.google.com/?q=${item.flightGeolocation}`"
      /></span>
    </template>
    <template #item.token="{ item }">
      {{ item.token ? item.token.substring(0, 9) : "" }}
    </template>

    <template #item.delete="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          target="_blank"
          @click="openDeleteDialog(item)"
        >
          <v-icon color="red"> fas fa-trash </v-icon>
        </v-btn>
      </ERow>
    </template>
  </ReportTable>
</template>

<script>
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import ArchiveDownloadLabel from "@/components/ingest/ArchiveDownloadLabel"
import ReportTable from "@/components/ReportTable"
import { DRONE_HEADER } from "@/components/ingest/ingestHeaders"
import { DRONE_FILTERS } from "@/components/ingest/ingestFilters"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { IngestProcessingStatus } from "@evercam/shared/types"

export default {
  components: {
    CreateUploadDialog,
    ReportTable,
    ArchiveDownloadLabel,
    OpenInNewWindowButton,
  },
  props: {
    allowUpload: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    headers: DRONE_HEADER,
    filters: DRONE_FILTERS,
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    IngestProcessingStatus,
    droneUploadObject: undefined,
    droneUploadDialog: false,
    droneQueueSize: "...",
    totalQueueSize: "...",
  }),
  computed: {
    ingestAPI() {
      return IngestApi
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        if (header.value === "delete") {
          return this.allowDelete
        }

        return true
      })
    },
  },
  async mounted() {
    await this.refreshTable()
  },
  methods: {
    async openDeleteDialog(deletedItem) {
      if (
        await this.$confirmDialog.open({
          title: "Delete Drone upload",
          message:
            "Are you sure you want to delete this Drone upload ?. If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        this.deleteDroneUpload(deletedItem.id)
      }
    },
    async deleteDroneUpload(id) {
      try {
        await this.ingestAPI.drone.deleteUpload(id)
        this.$notifications.success("Successfully deleted entry")
      } catch (e) {
        this.$notifications.error({ text: "Couldn't delete entry", error: e })
      } finally {
        this.refreshTable()
      }
    },
    async generateDroneUploadLink(payload) {
      try {
        const response = await this.ingestAPI.drone.createUpload(
          payload.project.exid,
          {
            uploadedBy: payload.uploadedBy,
          }
        )
        this.refreshTable()

        this.droneUploadObject = response
      } catch (e) {
        this.$notifications.error({ text: "Couldn't get token : ", error: e })
      }
    },
    openDroneUploadDialog(upload) {
      this.droneUploadObject = upload
      this.droneUploadDialog = true
    },
    onDroneUplaodDialogClosed() {
      this.droneUploadDialog = false
      this.droneUploadObject = undefined
    },
    async openReprocessDialog(item) {
      if (
        await this.$confirmDialog.open({
          title: "Reprocess Drone upload",
          message: `Are you sure you want to reprocess this Drone upload ${item.flightDate} ?. If you are not from the 3D team please contact them before doing this action`,
        })
      ) {
        this.reprocessDroneUpload(item.id)
      }
    },
    async reprocessDroneUpload(id) {
      try {
        await this.ingestAPI.drone.reprocessDrone(id)
        this.refreshTable()
        this.$notifications.success("Successfully start reprocessing")
      } catch (e) {
        this.$notifications.error({ text: "Couldn't reprocess", error: e })
      }
    },
    formatFlightDate(flightDate) {
      let formattedDate = this.$moment(flightDate, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      )
      formattedDate = new URLSearchParams({ date: formattedDate })

      return formattedDate.toString()
    },
    copyProjectId(projectId) {
      navigator.clipboard.writeText(projectId)

      this.$notifications.success("Project id copied to clipboard")
    },
    async refreshTable() {
      this.$root.$emit("refresh-report-table")
      this.droneQueueSize = "..."
      this.totalQueueSize = "..."
      this.droneQueueSize = await this.ingestAPI.drone.getDroneQueueSize()
      if (this.droneQueueSize) {
        this.droneQueueSize = this.droneQueueSize.size
      }

      this.totalQueueSize = await this.ingestAPI.getTotalQueueSize()
      if (this.totalQueueSize) {
        this.totalQueueSize = this.totalQueueSize.size
      }
    },
  },
}
</script>

<style>
.chip-size {
  width: 5vw;
  height: 5vh;
}
</style>
