<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="kits"
          :provider="provider"
          :headers="filteredHeaders"
          :filter-fields="filterFields"
          sort-by="created_at"
          actions-portal-target="breadcrumbs-right"
        >
          <template #item.name="{ item }">
            <NuxtLink :to="`/kits/${item.serial}`">{{ item.name }}</NuxtLink>
          </template>
          <template #item.countryId="{ item }">
            {{ item.countryName }}
          </template>
          <template #item.projectName="{ item }">
            <nuxt-link
              :to="`/projects#project_dialog=true&project_exid=${item.project.exid}`"
              >{{ item.project.name }}
            </nuxt-link>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #item.status="{ item }">
            <span :class="getKitStatusColor(item.status)">
              {{ getKitStatusText(item.status) }}
            </span>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import KitsHeader from "@/components/kits/kitsHeaders"
import ReportTable from "@/components/ReportTable"
import filterFields from "@/components/kits/kitsSearchFilters"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import countries from "@evercam/shared/constants/countries"
import { KitStatusesIdsClasses } from "@/components/constants.js"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.KITS.VIEW],
  },
  name: "Kits",
  components: {
    ReportTable,
  },
  async asyncData() {
    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Kits",
        icon: "fa-box",
      },
    ]
  },
  data: () => {
    return {
      headers: KitsHeader,
      countries: countries,
      loading: false,
      editedItem: {},
      filterFields,
    }
  },
  head() {
    return {
      title: "Admin - Kits",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.kits.getAllKits
    },
    canEditKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.UPDATE)
    },
    canDeleteKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.DELETE)
    },
    filteredHeaders() {
      return this.headers.filter(
        (header) =>
          this.canEditKits || this.canDeleteKits || header.value !== "actions"
      )
    },
  },
  methods: {
    getKitStatusColor(status) {
      return KitStatusesIdsClasses[status]?.class || ""
    },
    getKitStatusText(status) {
      return KitStatusesIdsClasses[status]?.value || status
    },
  },
}
</script>
