<template>
  <OfflineCamerasReport report="'site-visit-cameras'" />
</template>

<script>
import OfflineCamerasReport from "@/components/offlineCameras/OfflineCamerasReport"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.CAMERAS.VIEW],
  },
  components: { OfflineCamerasReport },
  head() {
    return {
      title: "Admin - Site Visit Cameras",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
}
</script>
