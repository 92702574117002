<template>
  <v-app>
    <TheAppBar />
    <TheSnackbar />
    <TheSidebar />
    <TheGlobalDialogs />
    <ViewAsRoleSnackbar />
    <v-main v-resize-observer="updateMainContentHeight">
      <div class="d-flex align-center e-relative z-index-1 e-bg-white">
        <TheBreadcrumbs
          v-if="breadcrumbStore.breadcrumbs.length"
          :items="breadcrumbStore.breadcrumbs"
        />
        <div class="ml-auto mr-2">
          <portal-target name="breadcrumbs-right"></portal-target>
        </div>
      </div>
      <v-divider v-if="breadcrumbStore.breadcrumbs.length" />
      <nuxt
        v-global-ref="'mainContent'"
        class="main-content"
        :style="mainContentStyle"
      />
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from "@/components/TheAppBar"
import TheSnackbar from "@/components/TheSnackbar"
import TheSidebar from "@/components/TheSidebar"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import TheGlobalDialogs from "@/components/TheGlobalDialogs"
import ViewAsRoleSnackbar from "@/components/authz/ViewAsRoleSnackbar"

import { mapStores } from "pinia"
import { useBreadcrumbStore } from "@/stores/breadcrumb"

export default {
  components: {
    TheAppBar,
    TheSnackbar,
    TheSidebar,
    TheBreadcrumbs,
    TheGlobalDialogs,
    ViewAsRoleSnackbar,
  },
  middleware: ["checkPermissions", "auth", "breadcrumbs"],
  data() {
    return {
      mainContentHeight: 0,
    }
  },
  head() {
    return {
      htmlAttrs: {
        class: this.hasBreadcrumbs
          ? "has-breadcrumbs e-max-h-screen"
          : "overflow-y-auto",
      },
    }
  },
  computed: {
    ...mapStores(useBreadcrumbStore),
    hasBreadcrumbs() {
      return !!this.breadcrumbStore.breadcrumbs?.length
    },
    mainContentStyle() {
      if (this.mainContentHeight && this.hasBreadcrumbs) {
        return {
          height: `${this.mainContentHeight}px`,
        }
      }

      return {}
    },
  },
  methods: {
    updateMainContentHeight() {
      const element = this.$globalRefs.mainContent
      if (!element) {
        return
      }

      const rect = element.getBoundingClientRect()
      const windowHeight = window.innerHeight
      this.mainContentHeight = windowHeight - rect.top
    },
  },
}
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
.v-main__wrap {
  overflow: hidden;
}

.has-breadcrumbs {
  overflow-y: hidden !important;
  .main-content {
    overflow-y: auto;
    overflow-x: hidden;
    @include custom-scrollbar(rgb(182, 185, 190), rgb(231, 231, 231));
  }
}
</style>
