export const IMAGE_TYPES = [".jpeg", ".jpg", ".png"]

export const BIM_TYPES = [".nwd", ".nwc", ".sp", ".rvt", ".ifc"]

export const INSTA_360_TYPES = [".insv", ".mp4"]

export const AUDIO_TYPES = [".m4a", ".mp3", ".wav"]

export const ROUTE_TYPES = [".gpx"]

export const MODELS_TYPES = [".fbx"]

export const ALLOWED_IMAGES_TYPES = [...IMAGE_TYPES]

export const ALLOWED_DRONE_TYPES = [".jpg", ".jpeg"]

export const ALLOWED_DASH_360_TYPES = [".insv"]

export const ALLOWED_360_TYPES = [...INSTA_360_TYPES]

export const ALLOWED_ROUTE_TYPES = [...ROUTE_TYPES]

export const ALLOWED_MODEL_TYPES = [...MODELS_TYPES]

export const ALLOWED_BIM_TYPES = [...BIM_TYPES]

export const INGEST_UPLOADS_TYPES = {
  droneUploads: "drone uploads",
  _360Uploads: "360 uploads",
  _360Assets: "360 Assets",
  bimUploads: "BIM uploads",
}

export const INGEST_MOBILE_PHOTO_ASSET_TYPE = "mobile_photos"

export const MAX_FILE_SIZE_IN_BYTES = 10737418240 //10737418240Bytes = 10 GB
