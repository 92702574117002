<template>
  <v-container fluid class="ex-nvr-container pa-0 h-100-vh">
    <ERow no-gutters class="px-4">
      <ECol cols="10" class="py-0 pr-2">
        <div class="grid-table pt-4">
          <div class="grid-row grid-header">
            <div v-for="(header, i) in headers" :key="i" class="grid-cell">
              {{ header }}
            </div>
          </div>
        </div>

        <div class="grid-table grid-table-body pr-2">
          <div class="grid-items-container">
            <div
              v-if="cameras.length === 0"
              class="w-100 d-flex justify-center pa-4"
            >
              <div>No results...</div>
            </div>
            <v-lazy
              v-for="camera in visibleCameras"
              :key="camera.exid"
              :options="{
                threshold: 0.5,
              }"
              :min-height="
                expandedCameraCardsHeights[camera.exid] || defaultCardHeight
              "
              :height="
                expandedCameraCardsHeights[camera.exid] || defaultCardHeight
              "
              transition="fade-transition"
              class="w-100 mb-2"
            >
              <ExNvrCameraItem
                :camera="camera"
                :token="accountStore.token"
                class="grid-surface"
                :health-check-tasks="getHealthCheckTasksForCamera(camera)"
                @start-health-check="performHealthCheckForCamera(camera)"
                @height-change="
                  (h) => updateCardContainerHeight(camera.exid, h)
                "
              />
            </v-lazy>
          </div>
        </div>

        <TablePagination
          class="grid-footer"
          :total-items="cameras.length"
          :rows-per-page.sync="rowsPerPage"
          @update-page="currentPage = $event"
        />
      </ECol>

      <!-- FILTERS PANEL -->
      <ECol cols="2" class="py-0 pl-2">
        <ExNvrFiltersPanel
          :cameras="exNvrHealthCheckStore.cameras"
          @change="(c) => (filteredCameras = c)"
        >
          <template #actions>
            <v-btn
              color="success"
              small
              class="white--text ma-2"
              :disabled="areCurrentCamerasHealthChecksProcessing"
              @click="performHealthCheckForCurrentCameras"
            >
              {{
                areCurrentCamerasHealthChecksProcessing ||
                areCurrentCamerasHealthChecksIdle
                  ? "Diagnose All"
                  : "Re-Diagnose All"
              }}
              <v-icon small class="ml-2"> fa-solid fa-heart-pulse </v-icon>
            </v-btn>
          </template>
          <template #footer>
            <!-- Cameras Count by Health Check Status -->
            <ExNvrCamerasHealthCheckStats
              v-if="showHealthCheckStats"
              :cameras="paginatedCameras"
              @cameras-by-selected-health-check-status="
                camerasBySelectedHealthCheckStatus = $event
              "
            />
          </template>
        </ExNvrFiltersPanel>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue"
import {
  AdminCamera,
  CameraExid,
  ExNvrHealthCheckTask,
  ExNvrHealthCheckTaskId,
  TaskStatus,
} from "@evercam/shared/types"
import ExNvrCameraItem from "@/components/nvrs/ExNvrCameraItem"
import ExNvrFiltersPanel from "@/components/nvrs/ExNvrFiltersPanel"
import TablePagination from "@/components/TablePagination"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useExNvrHealthCheckStore } from "@/stores/exNvrHealthCheck"
import ExNvrCamerasHealthCheckStats from "@/components/nvrs/ExNvrCamerasHealthCheckStats"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.CAMERAS.VIEW_EDGE_HEALTHCHECK],
  },
  name: "ExNvrHealthCheck",
  components: {
    ExNvrCameraItem,
    ExNvrFiltersPanel,
    TablePagination,
    ExNvrCamerasHealthCheckStats,
  },
  async asyncData() {
    await useExNvrHealthCheckStore().init()
  },
  data() {
    return {
      headers: ["Snapshot", "Status", "Info", "URLs", "Created at", "Actions"],
      filteredCameras: null as AdminCamera[],
      expandedCameraCardsHeights: {} as Record<CameraExid, number>,
      defaultCardHeight: 106,
      currentPage: 1,
      rowsPerPage: 25,
      camerasBySelectedHealthCheckStatus: null,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useExNvrHealthCheckStore),
    cameras(): AdminCamera[] {
      return (
        this.filteredCameras ??
        (this.exNvrHealthCheckStore.cameras as AdminCamera[])
      )
    },
    paginatedCameras(): AdminCamera[] {
      const start = (this.currentPage - 1) * this.rowsPerPage
      const end = start + this.rowsPerPage

      return this.cameras.slice(start, end)
    },
    visibleCameras(): AdminCamera[] {
      return this.camerasBySelectedHealthCheckStatus ?? this.paginatedCameras
    },
    currentCamerasHealthCheckTasks(): ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>[] {
      return this.paginatedCameras.flatMap((camera) =>
        this.getHealthCheckTasksForCamera(camera)
      )
    },
    areCurrentCamerasHealthChecksProcessing(): boolean {
      return this.currentCamerasHealthCheckTasks.reduce(
        (acc, t) => acc || t.status === TaskStatus.Loading,
        false
      )
    },
    areCurrentCamerasHealthChecksIdle(): boolean {
      return this.currentCamerasHealthCheckTasks.reduce(
        (acc, t) => acc || t.status === TaskStatus.Idle,
        false
      )
    },
    showHealthCheckStats() {
      return this.currentCamerasHealthCheckTasks.reduce(
        (acc, t) =>
          acc ||
          [TaskStatus.Error, TaskStatus.Loading, TaskStatus.Success].includes(
            t.status
          ),
        false
      )
    },
  },
  watch: {
    showHealthCheckStats(value) {
      if (!value) {
        this.camerasBySelectedHealthCheckStatus = null
      }
    },
  },
  methods: {
    getHealthCheckTasksForCamera(
      camera: AdminCamera
    ): ExNvrHealthCheckTask<any>[] {
      return this.exNvrHealthCheckStore.healthCheckTasksByCameraExid[
        camera.exid
      ]
    },
    performHealthCheckForCamera(camera: AdminCamera) {
      this.exNvrHealthCheckStore.performHealthCheckForCamera(camera.exid)
    },
    updateCardContainerHeight(cameraExid: CameraExid, height: number) {
      this.expandedCameraCardsHeights = {
        ...this.expandedCameraCardsHeights,
        [cameraExid]: height,
      }
    },
    performHealthCheckForCurrentCameras() {
      this.paginatedCameras.forEach((camera) =>
        this.exNvrHealthCheckStore.performHealthCheckForCamera(camera.exid)
      )
    },
  },
})
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
.ex-nvr-container {
  background: #f6f6f6;
}
.grid-table {
  width: 100%;
}
.grid-header {
  padding: 6px 12px;
  background: #c8d2d7;
  border: 1px solid #bcc9d0;
  margin-bottom: 10px;
  border-radius: 0.5em;
  box-shadow: 0 5px 16px -13px #000;
}
.grid-table-body {
  height: calc(100vh - 115px);
  width: 100%;
  overflow: auto;
  border-radius: 0.5em;
  border-bottom: 1px solid rgba(188, 201, 208, 0.68);
  @include custom-scrollbar();
}
.grid-filters {
  height: calc(100vh - 76px);
}
.grid-footer {
  margin-top: 5px;
  padding: 3px 0px;
  background: #c8d2d7;
  border: 1px solid #bcc9d0;
  border-radius: 0.5em;
  box-shadow: 0 5px 16px -13px #000;
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  justify-content: end;
}
.grid-items-container {
  overflow: hidden;
  border-radius: 0.5em;
}
.grid-row {
  gap: 10px;
  display: grid;
  grid-template-columns: 1.25fr 0.5fr 2.5fr 2fr 1.5fr 1.15fr;
  align-items: center;
  justify-content: start;
}
.grid-surface {
  border-radius: 0.5em;
  overflow: hidden;
  background: rgb(233, 236, 239);
  border: 1px solid rgba(188, 201, 208, 0.58);
}
</style>
