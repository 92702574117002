<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="Nvr"
          :headers="headers"
          item-key="exid"
          sort-by="name"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-left>
            <ERow>
              <ECol cols="4" class="mt-2 py-2"> Account: </ECol>
              <ECol cols="8" class="py-2">
                <v-select
                  v-model="account"
                  :items="accounts"
                  dense
                  item-value="value"
                  item-text="text"
                  hide-details
                  @change="onOwnerChange"
                ></v-select>
              </ECol>
            </ERow>
          </template>
          <template #item.hddName="{ item }">
            <div v-for="(hdd, key) in formatHddData(item.hddName)" :key="key">
              {{ hdd }}
            </div>
          </template>
          <template #item.hddCapacity="{ item }">
            <div
              v-for="(capacity, key) in formatHddData(item.hddCapacity)"
              :key="key"
            >
              {{ capacity }}
            </div>
          </template>
          <template #item.freeSpace="{ item }">
            <div
              v-for="(freeSpace, key) in formatHddFreeSpace(item)"
              :key="key"
              :class="styleHddData(freeSpace)"
            >
              {{ freeSpace }}
            </div>
          </template>
          <template #item.hddStatus="{ item }">
            <div
              v-for="(hddStatus, key) in formatHddData(item.hddStatus)"
              :key="key"
              :class="styleHddData(hddStatus, 'status')"
            >
              {{ formatStatus(hddStatus) }}
            </div>
          </template>
          <template #item.hddProperty="{ item }">
            <div
              v-for="(property, key) in formatHddData(item.hddProperty)"
              :key="key"
            >
              {{ property }}
            </div>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import NvrHeaders from "@/components/nvrs/nvrsHeaders"
import ReportTable from "@/components/ReportTable"
import filterFields from "@/components/nvrs/nvrsSearchFilters"
import { NvrHddStatuses } from "@/components/constants"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.NVRS.VIEW],
  },
  components: {
    ReportTable,
  },
  data: () => {
    return {
      headers: NvrHeaders,
      account: 13959,
      accounts: [
        { value: 13959, text: "Construction" },
        { value: 109148, text: "Old Construction" },
        { value: -1, text: "Non Construction" },
      ],
      filterFields: {},
    }
  },
  head() {
    return {
      title: "Admin - Nvr",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.nvrs.getNvrs
    },
  },
  created() {
    this.reportStore.initialSearchParams = {
      ...this.reportStore.initialSearchParams,
      ownerId: this.account,
    }
    this.filterFields = filterFields(this)
  },
  methods: {
    onOwnerChange() {
      this.reportStore.searchFilters = { ownerId: this.account }
    },
    formatHddFreeSpace(item) {
      const hddCapacities = this.formatHddData(item.hddCapacity)
      const hddFreeSpaces = this.formatHddData(item.freeSpace)
      const extractNumber = (item) => Number(item.match(/[0-9\\.]+/g))
      const freeSpaceWithPercentage = (freeSpace, index) => {
        if (!(hddCapacities[index] || freeSpace)) {
          return
        }
        if (!extractNumber(hddCapacities[index])) {
          return `${freeSpace} (0.00%)`
        }

        return `${freeSpace} (${(
          (extractNumber(freeSpace) * 100) /
          extractNumber(hddCapacities[index])
        )?.toFixed(2)}%)`
      }

      return hddFreeSpaces?.reduce(
        (acc, freeSpace, index) => [
          ...acc,
          freeSpaceWithPercentage(freeSpace, index),
        ],
        []
      )
    },
    formatHddData(data) {
      return data?.split("/")
    },
    styleHddData(item, type = "space") {
      if (!item) {
        return ""
      }

      if (
        (type === "space" && Number(item.match(/[0-9\\.]+/g)[0]) === 0) ||
        (type === "status" && item !== "ok")
      ) {
        return "error--text"
      }
    },
    formatStatus(status) {
      return NvrHddStatuses.find((item) => item.value === status)?.name || ""
    },
  },
}
</script>
