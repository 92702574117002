<template>
  <ERow no-gutters class="details">
    <ECol cols="12">
      <ERow class="ma-0">
        <ECol class="py-1">
          <SummaryElement v-model="serialNumber" :read-only="readOnly" />
          <SummaryElement v-model="routerType" :read-only="readOnly" />
          <SummaryElement v-model="routerHttpPort" :read-only="readOnly" />
          <SummaryElement v-model="routerUserId" :read-only="readOnly" />
          <SummaryElement v-model="routerPassword" :read-only="readOnly" />
          <SummaryElement v-model="vpnServer" :read-only="readOnly" />
          <SummaryElement v-model="vpnUserId" :read-only="readOnly" />
          <SummaryElement v-model="vpnPassword" :read-only="readOnly" />
          <SummaryElement v-model="powerType" :read-only="readOnly" />
        </ECol>

        <v-divider :vertical="$vuetify.breakpoint.smAndUp"></v-divider>

        <ECol class="py-1">
          <SummaryElement read-only label="Cameras">
            <CamerasColumn :item="router" />
          </SummaryElement>

          <SummaryElement read-only label="Projects">
            <div v-if="router.projects && router.projects.length > 0">
              <span
                v-for="(project, index) in router.projects"
                :key="`${project.id}${index}`"
                class="cursor-pointer primary--text"
                @click="projectStore.openDialog(project.exid)"
              >
                {{
                  index !== router.projects.length - 1
                    ? `${project.name},`
                    : project.name
                }}
              </span>
            </div>
            <span v-else>-</span>
          </SummaryElement>

          <SummaryElement read-only label="Sims">
            <SimsColumn :item="router" />
          </SummaryElement>

          <SummaryElement read-only label="Bill Payers">
            <div v-if="router.billPayers && router.billPayers.length > 0">
              <span
                v-for="(billPayer, index) in router.billPayers"
                :key="billPayer.name"
              >
                {{
                  index !== router.billPayers.length - 1
                    ? `${billPayer.name},`
                    : billPayer.name
                }}
              </span>
            </div>
            <span v-else>-</span>
          </SummaryElement>
        </ECol>
      </ERow>
      <v-divider></v-divider>

      <ERow class="ma-0">
        <v-tabs v-model="activeTab" class="router-summary__tabs">
          <v-tab v-if="router.id" :key="0">Logs</v-tab>
          <v-tab v-if="router.sims" :key="1">SMS History</v-tab>
          <v-tab v-if="IsSolarPowerType || IsBatteryPowerType" :key="2"
            >Battery Readings</v-tab
          >

          <v-tab-item v-if="router.id" :key="0">
            <RouterAuditLogs
              :router-id="router.id"
              :hide-camera-dialog="hideCameraDialog"
              :hide-router-dialog="true"
            />
          </v-tab-item>
          <v-tab-item v-if="router.sims && router.sims.length > 0" :key="1">
            <div class="pt-2">
              <SmsHistory
                :sim-number="router.sims[0].number"
                :readOnly="readOnly"
              />
            </div>
          </v-tab-item>
          <v-tab-item v-if="IsSolarPowerType || IsBatteryPowerType" :key="2">
            <BatteryReadings :id="router.id" />
          </v-tab-item>
        </v-tabs>
      </ERow>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import CamerasColumn from "@/components/CamerasColumn"
import SimsColumn from "@/components/SimsColumn"
import SmsHistory from "@/components/cameraTabs/SmsHistory"
import BatteryReadings from "@/components/batteries/BatteryReadings"
import Vue, { PropType } from "vue"
import { Router, PowerType } from "@evercam/shared/types/router"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default Vue.extend({
  name: "RouterSummaryDialog",
  components: {
    SummaryElement,
    CamerasColumn,
    SimsColumn,
    SmsHistory,
    BatteryReadings,
    RouterAuditLogs: () => import("@/components/auditLogs/AuditLogsTable"),
  },
  props: {
    router: {
      type: Object as PropType<Router>,
      required: true,
    },
    routerTypes: {
      type: Array,
      required: true,
    },
    hideCameraDialog: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
      wifi: true,
      serialNumber: {
        key: "serialNumber",
        label: "Serial Number",
        value: "",
        type: "TEXT_FIELD",
      },
      billPayers: {
        key: "billPayers",
        label: "Bill Payers",
        value: "",
        type: "TEXT_FIELD",
      },
      vpnServer: {
        key: "vpnServer",
        label: "VPN Server",
        value: "",
        type: "TEXT_FIELD",
      },
      vpnUserId: {
        key: "vpnUserId",
        label: "VPN User ID",
        value: "",
        type: "TEXT_FIELD",
      },
      vpnPassword: {
        key: "vpnPassword",
        label: "VPN Password",
        value: "",
        type: "TEXT_FIELD",
      },
      routerType: {
        key: "routerType",
        label: "Router Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [],
      },
      routerUserId: {
        key: "routerUserId",
        label: "Router User ID",
        value: "",
        type: "TEXT_FIELD",
      },
      routerPassword: {
        key: "routerPassword",
        label: "Router Password",
        value: "",
        type: "TEXT_FIELD",
      },
      routerHttpPort: {
        key: "routerHttpPort",
        label: "Router HTTP Port",
        value: "",
        type: "TEXT_FIELD",
      },
      powerType: {
        key: "powerType",
        label: "Power Type",
        value: "",
        type: "TEXT_FIELD",
      },
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    IsSolarPowerType(): boolean {
      return this.router.powerType === PowerType.Solar
    },
    IsBatteryPowerType(): boolean {
      return this.router.powerType === PowerType.Battery
    },
  },
  watch: {
    router: {
      immediate: true,
      deep: true,
      async handler() {
        this.serialNumber.value = this.router.serialNumber
        this.billPayers.value = this.router.billPayers
        this.vpnServer.value = this.router.vpnServer
        this.vpnUserId.value = this.router.vpnUserId
        this.vpnPassword.value = this.router.vpnPassword
        this.routerType.value = this.router.routerType
        this.routerUserId.value = this.router.routerUserId
        this.routerPassword.value = this.router.routerPassword
        this.routerHttpPort.value = this.router.routerHttpPort
        this.powerType.value = this.router.powerType
      },
    },
    routerTypes: {
      async handler() {
        let types = []
        this.routerTypes.forEach((element) => {
          types.push({ value: element, name: element })
        })
        this.routerType.dataset = types
      },
    },
  },
})
</script>

<style>
.router-summary__tabs .v-window {
  overflow: visible !important;
}
</style>
