import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { defineNuxtRouteMiddleware } from "#app"

export default defineNuxtRouteMiddleware((to) => {
  if (
    !/\/bim-compare\/|\/companies\/|\/users\/|\/gate-report\/|\/kits\//.test(
      to.path
    )
  ) {
    useBreadcrumbStore().breadcrumbs = []
  }
})
