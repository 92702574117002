<template>
  <div>
    <KitSummary :kit-id="kitId" inline />

    <portal to="breadcrumbs-right">
      <KitDeleteDialog v-if="canDeleteKits" />
    </portal>
  </div>
</template>

<script>
import KitSummary from "@/components/kits/KitSummary"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import KitDeleteDialog from "@/components/kits/KitDeleteDialog.vue"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  components: {
    KitDeleteDialog,
    KitSummary,
  },
  async asyncData({ params }) {
    const kitSummaryStore = useKitSummaryStore()
    await kitSummaryStore.loadKit(params.id)

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Kits",
        href: "/kits",
        icon: "fa-box",
      },
      {
        name: `${kitSummaryStore.kit?.name || ""} - (${
          kitSummaryStore.kit?.serial || ""
        })`,
      },
    ]

    return {
      kitId: params.id,
    }
  },
  computed: {
    canDeleteKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.DELETE)
    },
  },
}
</script>
