<template>
  <v-dialog v-model="dialogVisible" max-width="650px">
    <v-card>
      <v-card-title> Assign User Roles </v-card-title>
      <v-card-text>
        <p>The following users have been selected:</p>
        <!-- List of selected users -->
        <div class="users-group">
          <v-chip
            v-for="user in users"
            :key="user.id"
            class="py-2 mr-1 my-1 user-chip"
          >
            {{ user.email }}
          </v-chip>
        </div>

        <!-- Roles Selection -->
        <div class="mt-4">
          <p>Select the roles you want to assign to the selected users</p>
          <v-chip-group
            v-model="selectedRoles"
            multiple
            column
            class="roles-chip-group"
            active-class="primary--text"
          >
            <v-chip
              v-for="role in availableRoles"
              :key="role"
              :value="role"
              outlined
              filter
              class="ma-1"
            >
              {{ role.name }}
            </v-chip>
          </v-chip-group>
          <p class="mt-4">
            Please note that the selected roles will
            <b>overwrite the existing roles</b> of the selected users.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          small
          text
          @click="closeDialog"
          >Cancel</v-btn
        >
        <v-btn :loading="loading" color="primary" small @click="confirmRoles"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthzApi } from "@evercam/shared/api/authzApi"

export default {
  name: "SetAdminRoleDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableRoles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: this.value,
      selectedRoles: [],
      loading: false,
    }
  },
  watch: {
    value(val) {
      this.dialogVisible = val
    },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false)
    },
    async confirmRoles() {
      try {
        this.loading = true
        const rolePromises = this.availableRoles.map((role) => {
          const emails = this.users.map((user) => user.email)
          if (this.selectedRoles.includes(role)) {
            return this.assignRoles(role.id, emails)
          } else {
            return this.unassignRoles(role.id, emails)
          }
        })
        await Promise.all(rolePromises)
        this.$notifications.success("Roles assigned successfully")
        this.$emit("roles-assigned")
      } catch (error) {
        this.$notifications.error("Failed to assign roles")
        console.error(error)
      } finally {
        this.loading = false
      }
      this.closeDialog()
    },
    async assignRoles(roleId, emails) {
      try {
        await AuthzApi.roles.assignUsers(roleId, emails, "evercam-admin")
      } catch (error) {
        console.error(error)
      }
    },
    async unassignRoles(roleId, emails) {
      try {
        await AuthzApi.roles.unassignUsers(roleId, emails, "evercam-admin")
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style scoped>
.headline {
  font-weight: bold;
}

.roles-chip-group {
  display: flex;
  flex-wrap: wrap;
}
.user-chip {
  font-size: 12px;
}
.users-group {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
