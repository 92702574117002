<template>
  <ReportTable
    name="Suppressions"
    item-key="id"
    :provider="provider"
    :headers="headers"
    :filter-fields="filters"
    :hide-show="false"
  >
    <template #item.actions="{ item }">
      <ERow class="ml-2">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              x-small
              class="cursor-pointer ml-1"
              @click="deleteSuppression(item.id)"
              v-on="on"
            >
              <v-icon color="red" small> far fa-trash-alt </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </ERow>
    </template>
    <template #item.source="{ item }">
      {{ formatSource(item.source) }}
    </template>
  </ReportTable>
</template>

<script>
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { FILTERS } from "@/components/emailing/suppressions/filters"
import { HEADERS } from "@/components/emailing/suppressions/headers"
import { SuppressionSourcesOptions } from "@evercam/shared/constants/emailing"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.USERS.SEND_EMAILS],
  },
  components: {
    ReportTable,
  },
  data() {
    return {
      provider: AdminApi.emailing.suppressions,
      headers: HEADERS,
      filters: FILTERS,
    }
  },
  methods: {
    formatSource(source) {
      return SuppressionSourcesOptions.find((el) => el.value === source)?.name
    },
    async deleteSuppression(id) {
      if (
        await this.$confirmDialog.open({
          title: "Delete email suppression",
          message: "Are you sure you want to delete this email suppression",
        })
      ) {
        try {
          await AdminApi.emailing.deleteSuppression(id)
          this.$root.$emit("refresh-report-table")
        } catch (error) {
          this.$notifications.error({
            text: "Error deleting suppression",
            error,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
