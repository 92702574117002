<template>
  <v-dialog v-model="dialog" max-width="550px" persistent @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span>Add Router</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" ref="addRouter">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" md="6" class="mt-n7 mb-5">
                <v-checkbox
                  v-model="showCloneRouter"
                  class="resize-checkbox"
                  hide-details
                  @change="toggleCloneRouterCheckbox"
                >
                  <template #label>
                    <div class="caption">
                      Copy settings from existing router
                    </div>
                  </template>
                </v-checkbox>
              </ECol>
              <ECol cols="12" md="6" class="mt-n2 py-0">
                <Autocomplete
                  v-if="showCloneRouter"
                  v-model="selectedRouter"
                  item-value="id"
                  item-text="serialNumber"
                  label="Serial Number"
                  attach
                  clearable
                  no-filter
                  :hide-details="false"
                  :cache-items="false"
                  :provider="getRouters"
                  :provider-params="routerParams"
                  @change="onChangeRouter"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Serial Number"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.serialNumber"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Serial Number"
                    placeholder="110xxxxxx"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="HTTP URL"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.netbirdUrl"
                    dense
                    outlined
                    :error-messages="errors"
                    label="HTTP URL"
                    placeholder="https://vpn-server-url"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router Type"
                  rules="required"
                >
                  <v-combobox
                    v-model="editedItem.routerType"
                    :items="routerTypes"
                    label="Router Type"
                    persistent-hint
                    dense
                    outlined
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="VPN Server"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.vpnServer"
                    :items="VpnServers"
                    label="VPN Server"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router HTTP Port"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.routerHttpPort"
                    :error-messages="errors"
                    dense
                    outlined
                    label="Router HTTP Port"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerUserId"
                  dense
                  outlined
                  label="Router User ID"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerPassword"
                  dense
                  outlined
                  label="Router Password"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Type"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerType"
                    :items="powerTypes"
                    label="Power Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Schedule"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerSchedule"
                    :items="powerSchedules"
                    label="Power Schedule"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ERow no-gutters>
                  <ECol cols="12" sm="12" md="7" lg="7">
                    <Autocomplete
                      v-model="editedItem.sims"
                      item-value="id"
                      item-text="number"
                      label="SIMs"
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      :list-items="sims"
                      resource="sims"
                      :search="simSearch"
                      @on-search-item="initSelectedSim"
                    />
                  </ECol>
                  <ECol cols="12" sm="12" md="5" lg="5" class="px-1 mt-3">
                    <span class="caption">Or</span>
                    <SimsDialog
                      for-form-component
                      @reload-sims="(sim) => (simSearch = sim)"
                    />
                  </ECol>
                </ERow>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="passes(save)">
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {
  VpnServers,
  PowerSchedules,
  PowerTypes,
  RouterStatuses,
} from "@/components/constants.js"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import Vue from "vue"
import { PowerType, Router, RouterStatus } from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"
import SimsDialog from "@/components/sims/SimsDialog"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"

export default Vue.extend({
  name: "AddRouterDialog",
  components: {
    Autocomplete,
    SimsDialog,
  },
  props: {
    routerTypes: {
      type: Array,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      VpnServers,
      routerStatus: RouterStatuses,
      powerSchedules: PowerSchedules,
      powerTypes: PowerTypes,
      editedItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: 443,
        powerType: "mains" as PowerType,
        powerSchedule: "24/7",
        vpnServer: "",
        netbirdUrl: "",
        status: "active" as RouterStatus,
        sims: [],
        cameras: [],
      } as Router,
      defaultItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: null,
        powerType: "mains" as PowerType,
        powerSchedule: "24/7",
        vpnServer: "",
        netbirdUrl: "",
        status: "active" as RouterStatus,
        sims: [],
        cameras: [],
      } as Router,
      dialog: false,
      sims: [],
      simSearch: "",
      selectedRouter: null,
      showCloneRouter: false,
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    getRouters() {
      return AdminApi.routers.getRouters
    },
  },
  watch: {
    "$attrs.value": {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    dialog(val) {
      if (val) {
        this.editedItem = { ...this.defaultItem }
        this.updateNetbirdUrl()
      }
      this.$emit("input", val)
    },
  },
  methods: {
    updateNetbirdUrl() {
      let region = this.kitSummaryStore.kit.countryRegion.replace("na", "us")
      this.editedItem.netbirdUrl = `https://192-168-8-1-${this.kitSummaryStore.kit.serial}.${region}-vpn.evercam.io:443`
    },
    toggleCloneRouterCheckbox(value) {
      if (!value) {
        this.clear()
      } else {
        if (this.selectedRouter) {
          this.initForm(this.selectedRouter)
        }
      }
    },
    onChangeRouter(router) {
      if (router) {
        this.initForm(router)
      }
    },
    routerParams(val) {
      return {
        sort: "created_at|asc",
        limit: 50,
        page: 1,
        serialNumber: val,
      }
    },
    async save() {
      await this.kitSummaryStore.createRouter(this.editedItem)
      this.close()
    },
    initForm(router: Router) {
      this.editedItem = Object.assign({}, router)
      this.editedItem.id = null
      this.editedItem.sims = []
      this.editedItem.cameras = []
      this.editedItem.serialNumber = null
      this.editedItem.vpnServer = ""
    },
    close() {
      this.dialog = false
      this.clear()
    },
    clear() {
      this.$refs.addRouter.reset()
      this.selectedRouter = null
      this.showCloneRouter = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },
    initSelectedSim(items) {
      if (!this.simSearch) {
        return
      }
      this.editedItem.sims = [
        ...(this.editedItem.sims || []),
        items.find((value) => value.number === this.simSearch),
      ]
      this.sims = this.editedItem.sims
    },
  },
})
</script>
