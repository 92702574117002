<template>
  <ERow no-gutters class="summary">
    <!-- Summary -->
    <ECol cols="auto" lg="12">
      <ERow no-gutters>
        <ECol :cols="12">
          <CameraSummaryGeneral
            :camera="cameraDialogStore.camera"
            class="summary-container w-100"
            :class="{ 'h-100': $vuetify.breakpoint.mdAndUp }"
            @close="$emit('close')"
          />
        </ECol>
      </ERow>
    </ECol>
    <!-- Audit logs -->
    <ERow no-gutters>
      <CameraLogs
        :camera="cameraDialogStore.camera"
        :filter-chart="false"
        :read-only="!canEditCameras"
      />
    </ERow>
  </ERow>
</template>

<script>
import CameraLogs from "@/components/offlineCameras/CameraLogs"
import CameraSummaryGeneral from "@/components/cameraTabs/summary/CameraSummaryGeneral.vue"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  components: {
    CameraSummaryGeneral,
    CameraLogs,
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    canEditCameras() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.UPDATE)
    },
  },
}
</script>

<style lang="scss">
.camera-summary__tabs {
  .v-tabs-items {
    overflow: visible;
  }
}
</style>
