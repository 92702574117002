<template>
  <div>
    <v-data-table
      ref="projectCamerasTable"
      v-model="selected"
      :headers="headers"
      :items="items"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="admin-data-table"
      dense
      must-sort
      :loading="loading"
      loading-text="Loading..."
      calculate-widths
      :mobile-breakpoint="0"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      fixed-header
      :footer-props="{
        'items-per-page-options': [50, 100, 500],
      }"
      :hide-default-footer="items.length <= 50"
    >
      <template #top>
        <v-container fluid class="py-0 pr-0 pb-2">
          <ERow class="d-flex">
            <ECol class="shrink pl-0 pb-0 text-h6"> Cameras </ECol>
            <v-spacer />
            <ECol class="grow mb-1 pb-0 text-right">
              <CopyTableToClipboard :headers="headers" :items="filteredItems" />
            </ECol>
          </ERow>
        </v-container>
      </template>
      <template #item.name="{ item }">
        <NdaIcon v-if="isNDAProject" />
        <a @click="cameraDialogStore.openDialog({ camera: item.exid })">{{
          item.name
        }}</a>
        <ImpersonateUserButton
          v-if="canImpersonateUsers"
          :email="item.ownerEmail"
          :target-route="`/projects/${project.exid}/${item.exid}`"
          :nda-project="isNDAProject"
        />
      </template>
      <template #item.status="{ item }">
        <span :class="getStatusColor(item.status)">
          {{ getStatusText(item.status) }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import PaProjectCamerasTableHeader from "@/components/projects/projectsCamerasTableHeaders"
import CameraUtils from "@/mixins/cameraUtils"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { Project, ProjectFeatureFlag } from "@evercam/shared/types/project"
import NdaIcon from "@/components/NdaIcon"
import Vue, { PropType } from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  components: {
    ImpersonateUserButton,
    CopyTableToClipboard,
    NdaIcon,
  },
  mixins: [CameraUtils],
  props: {
    project: {
      type: Object as PropType<Project>,
      default: () => ({} as Project),
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: PaProjectCamerasTableHeader,
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
      PERMISSIONS,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    filteredItems() {
      let filteredItems = this.$refs.projectCamerasTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
    isNDAProject() {
      return this.project?.featureFlags?.includes?.(ProjectFeatureFlag.NdaMc)
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
  },
  watch: {
    project: "fetchCameras",
  },
  async mounted() {
    await this.fetchCameras()
  },
  methods: {
    async fetchCameras(timezone) {
      if (!this.project.exid) {
        return
      }
      try {
        if (typeof timezone === "string") {
          this.$emit("update-timezone", timezone)
        }
        this.loading = true
        const response = await AdminApi.pa.getPAProjectCameras(
          this.project.exid
        )
        this.loading = false
        this.total = response.total
        this.items = response.data
      } catch (error) {
        this.$notifications.error({ text: "Could not load Cameras!", error })
      }
    },
  },
})
</script>
