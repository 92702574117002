const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['breadcrumbs'] = require('../middleware/breadcrumbs.js')
middleware['breadcrumbs'] = middleware['breadcrumbs'].default || middleware['breadcrumbs']

middleware['checkPermissions'] = require('../middleware/checkPermissions.js')
middleware['checkPermissions'] = middleware['checkPermissions'].default || middleware['checkPermissions']

middleware['reportsAccess'] = require('../middleware/reportsAccess.js')
middleware['reportsAccess'] = middleware['reportsAccess'].default || middleware['reportsAccess']

middleware['unauth'] = require('../middleware/unauth.js')
middleware['unauth'] = middleware['unauth'].default || middleware['unauth']

export default middleware
