<template>
  <v-container fluid class="pa-0">
    <ERow no-gutters>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="ROI Logs"
          :headers="headers"
          item-key="id"
          sort-by="inserted_at"
          :provider="fetchVerifiedDays"
          :filter-fields="filterFields"
        >
          <template #item.insertedAt="{ item }">
            {{ formatDateInLocalTimezone(item.insertedAt) }}
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import ReportTable from "@/components/ReportTable"
import headers from "@/components/gateReport/verifiedDaysHeaders"
import filterFields from "@/components/gateReport/verifiedDaysSearchFilters"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.VIEW_LOGS],
  },
  components: { ReportTable },
  async asyncData({ params }) {
    await useGateReportStore().init(params.exid)
    useBreadcrumbStore().breadcrumbs = [
      {
        name: "Gate report",
        href: "/gate-report",
      },
      {
        name: params.exid,
        href: `/gate-report/${params.exid}/logs/verified-days`,
      },
      {
        name: "logs",
      },
      {
        name: "Verified Days",
      },
    ]

    return {
      projectExid: params.exid,
    }
  },
  data() {
    return {
      headers,
      filterFields: {},
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    timezone() {
      return this.gateReportStore.selectedProject?.timezone
    },
  },
  created() {
    this.filterFields = filterFields(this)
  },
  methods: {
    fetchVerifiedDays(params) {
      let { params: payload, cancelToken } = params

      payload = Object.entries(payload).reduce((acc, [key, value]) => {
        if (["", null].includes(value)) {
          return acc
        }

        return { ...acc, [key]: value }
      }, {})

      return AiApi.gateReport.getVerifiedDays(this.projectExid, payload, {
        cancelToken,
      })
    },
    formatDateInLocalTimezone(date) {
      return this.$moment.utc(date).local().format("DD MMM YYYY HH:mm:ss")
    },
  },
}
</script>
