<template>
  <v-list
    dense
    expand
    class="py-0"
    :style="{ filter: `brightness(${1 + (nestingLevel / 6) * 0.3})` }"
  >
    <template v-for="(item, key) in filteredListItems">
      <TheSidebarListItem
        v-if="!item.subItems || !item.subItems.length"
        :key="key"
        :item="item"
        :class="paddingClass"
      />
      <v-list-group
        v-else
        :key="key"
        v-model="item.active"
        class="navigation-list-group"
        append-icon="fa-chevron-down"
        :disabled="item.disabled"
      >
        <template #activator>
          <TheSidebarListItem :key="key" :item="item" :class="paddingClass" />
        </template>
        <TheSidebarList
          :list-items="item.subItems"
          :nesting-level="nestingLevel + 2"
        />
      </v-list-group>
    </template>
  </v-list>
</template>

<script lang="ts">
import { PropType } from "vue"
import TheSidebarListItem from "@/components/TheSidebarListItem"
export interface ListItem {
  text: string
  icon?: string
  hint?: string
  hide?: boolean
  href?: string
  action?: () => void
  subItems?: ListItem[]
  reload?: boolean
  external?: boolean
  [key: string]: any
}

export default {
  name: "TheSidebarList",
  components: {
    TheSidebarListItem,
  },
  props: {
    listItems: {
      type: Array as PropType<ListItem[]>,
      default: null,
    },
    nestingLevel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    filteredListItems() {
      return this.listItems.filter((item) => !item.hide)
    },
    paddingClass() {
      return `pl-${this.nestingLevel + 2}`
    },
  },
}
</script>
