import { AdminApi } from "@evercam/shared/api/adminApi"
import {
  CameraOption,
  ProgressPhotoItem,
  ProgressPhotosCreateUpdateRequestPayload,
} from "@evercam/shared/types"
import { defineStore } from "pinia"
import { useReportStore } from "./report"
import { useNuxtApp } from "#app"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import axios from "@evercam/shared/api/client/axios"

interface ProgressPhotoState {
  loading: boolean
  cameras: CameraOption[]
  currentProgressPhoto: ProgressPhotoItem | null
  progressPhotoDialog: boolean
  progressPhotoRecipients: string[]
}

export const useProgressPhotoStore = defineStore({
  id: "progressPhoto",
  state: (): ProgressPhotoState => ({
    loading: false,
    cameras: [],
    currentProgressPhoto: null,
    progressPhotoDialog: false,
    progressPhotoRecipients: [],
  }),
  getters: {
    reportStore() {
      return useReportStore()
    },
  },
  actions: {
    openEditDialog(item) {
      this.progressPhotoDialog = true
      this.progressPhotoRecipients = Array.isArray(item.config.recipients)
        ? item.config?.recipients
        : item.config?.recipients.split(",")
      this.currentProgressPhoto = item
      this.currentProgressPhoto.config.recipients = this.progressPhotoRecipients
      this.currentProgressPhoto.notifyDays = Array.isArray(item.notifyDays)
        ? item.notifyDays
        : item.notifyDays.split(",")
      this.getCameraSharesForRecipients()
    },

    async update() {
      this.loading = true
      const cameras: string[] =
        typeof this.currentProgressPhoto.cameras[0] === "string"
          ? (this.currentProgressPhoto.cameras as string[])
          : this.currentProgressPhoto.cameras.map((camera) => camera.exid)
      const payload: ProgressPhotosCreateUpdateRequestPayload = {
        cameraExids: cameras.join(","),
        config: {
          recipients: this.currentProgressPhoto.config.recipients
            .map((recipient) => recipient?.email)
            .join(","),
        },
        notifyTime: this.currentProgressPhoto.notifyTime,
        timezone: this.currentProgressPhoto.timezone,
        notifyDays: this.currentProgressPhoto.notifyDays.join(","),
        isPaused: this.currentProgressPhoto.isPaused,
      }
      try {
        const result = await AdminApi.progressPhotos.update(
          this.currentProgressPhoto.exid,
          payload
        )
        const index = this.reportStore.items.findIndex(
          (item) => item.exid === result.id
        )
        const names = result.cameraNames.split(",")
        const ids = result.cameraIds.split(",")
        this.currentProgressPhoto.cameras = names.map((name, index) => ({
          name,
          exid: ids[index],
        }))
        this.currentProgressPhoto.notifyDays = result.notifyDays.split(",")
        this.currentProgressPhoto.notifyTime = result.notifyTime
        this.currentProgressPhoto.timezone = result.timezone
        this.reportStore.items.splice(index, 1, this.currentProgressPhoto)
        this.loading = false
        this.progressPhotoDialog = false
        useNuxtApp().nuxt2Context.$notifications.success(
          "Progress Photo(s) edited successfully."
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to edit progress photo!",
          error,
        })
        this.loading = false
      }
    },
    async onPause(isPaused) {
      try {
        this.loading = true
        this.currentProgressPhoto.isPaused = isPaused
        await AdminApi.progressPhotos.update(this.currentProgressPhoto.exid, {
          isPaused,
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to pause progress photo!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        useNuxtApp().nuxt2Context.$notifications.warn(
          "At least select one progress photo!"
        )

        return
      }

      if (
        await useNuxtApp().nuxt2Context.$confirmDialog.open({
          title: "Delete progress photo(s) ?",
          message: "This will permanently delete selected progress photo(s)!",
        })
      ) {
        for (const progressPhotos of this.reportStore.selectedItems) {
          await this.doDelete(
            progressPhotos.exid,
            progressPhotos.userApiId,
            progressPhotos.userApiKey
          )
          this.reportStore.items = this.reportStore.items.filter(
            (item) => item.exid !== progressPhotos.exid
          )
        }
        this.reportStore.selectedItems = []
        useNuxtApp().nuxt2Context.$notifications.success(
          "Progress Photo(s) deleted successfully."
        )
      }
    },
    async doDelete(exid, apiId, apiKey) {
      try {
        await EvercamApi.progressPhotos.delete(exid, {
          apiId,
          apiKey,
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to delete progress photo!",
          error,
        })
      }
    },
    async getCameraSharesForRecipients() {
      if (this.progressPhotoRecipients.length === 0) {
        return
      }
      try {
        const results = await Promise.all(
          this.progressPhotoRecipients.map((recipient) =>
            axios.get(
              `/admin/camera-shares?sharee_email=${recipient}&limit=100`
            )
          )
        )
        // @ts-expect-error item contain,s pagination and an array of items
        const items = new Set(results.map((item) => item.items).flat())
        this.cameras = []
        items.forEach((share) => {
          this.cameras.push({
            exid: share.exid,
            name: share.name,
          })
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to fetch camera shares!",
          error,
        })
      }
    },
  },
})
