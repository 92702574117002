<template>
  <v-container class="parameters styled-scrollbars px-0 py-4" fluid>
    <ERow class="ma-0 px-3">
      <ECol class="pa-0 text-h6 font-weight-bold"> Basic configuration </ECol>
      <ECol class="pa-0 d-flex justify-end">
        <div style="width: max-content">
          <v-combobox
            v-if="gateReportStore.selectedCamera"
            v-model="gateReportStore.selectedCamera"
            :items="gateReportStore.cameras"
            label="Selected camera"
            dense
            outlined
            hide-details
            class="pr-2"
          >
            <template #selection="{ item }">
              <span> {{ item.name }}({{ item.exid }}) </span>
            </template>
            <template #item="{ item }">
              <span>{{ item.name }}({{ item.exid }})</span>
            </template>
          </v-combobox>
        </div>
      </ECol>
    </ERow>
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <div class="px-5">
        <ERow>
          <!-- START AND END DATES -->
          <ECol cols="12" md="6">
            <ERow class="d-flex align-center">
              <div class="d-flex flex-column pl-3 pb-3">
                <span class="pb-1 font-weight-medium"> Processing type</span>
                <small class="grey--text"
                  >This sets the start and end (optional) dates of the
                  project.</small
                >
              </div>
              <v-spacer></v-spacer>
              <v-switch
                v-model="continuous"
                class="mr-3"
                label="Continuous"
                color="primary"
                dense
                inset
                hide-details
              >
              </v-switch>
            </ERow>
            <ERow>
              <ECol cols="6" class="pb-0 d-flex align-center">
                <VueCtkDateTimePicker
                  v-model="startDate"
                  label="Start date"
                  format="YYYY-MM-DD HH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm:ss"
                  name="startDate"
                ></VueCtkDateTimePicker>
              </ECol>
              <!-- End date -->
              <ECol cols="6" class="pb-0 d-flex align-center">
                <VueCtkDateTimePicker
                  v-model="endDate"
                  label="End date"
                  format="YYYY-MM-DD HH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm:ss"
                  name="endDate"
                  :disabled="processingType == 'duration' ? false : true"
                  :minDate="minDate"
                ></VueCtkDateTimePicker>
              </ECol>
            </ERow>
          </ECol>
          <!-- OPENING AND CLOSING HOURS -->
          <ECol cols="12" md="6">
            <ERow class="d-flex align-center">
              <div class="d-flex flex-column pl-3 pb-3">
                <span class="pb-1 font-weight-medium">
                  Site opening and closing hours</span
                >
                <small class="grey--text"
                  >This sets the start and end time of the video downloads and
                  processing.</small
                >
              </div>
              <v-spacer></v-spacer>
              <v-switch
                v-model="skipWeekend"
                label="Skip weekends"
                color="primary"
                dense
                inset
                hide-details
              >
              </v-switch>
            </ERow>
            <ERow>
              <div class="w-100 px-3">
                <v-range-slider
                  v-model="siteRangeSetterValue"
                  :max="maxClosing"
                  :min="minOpening"
                  hide-details
                  dense
                  class="align-center grow"
                >
                  <template #prepend>
                    <span class="circle-content">{{
                      siteRangeSetterValue[0]
                    }}</span>
                  </template>
                  <template #append>
                    <span class="circle-content">{{
                      siteRangeSetterValue[1]
                    }}</span>
                  </template>
                </v-range-slider>
              </div>
            </ERow>
          </ECol>
        </ERow>
      </div>
      <v-expansion-panels flat multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="py-3 px-2 text-h6 font-weight-bold">
            Advanced configuration
            <template #actions>
              <v-icon class="pr-3"> fa-solid fa-chevron-down </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0 pl-3 pt-2">
            <div class="text-subtitle-1 font-weight-bold pb-2">
              Detection parameters
            </div>
            <v-container fluid class="pa-0 px-1">
              <ERow>
                <!-- queueSystem -->
                <ECol cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="queueSystem"
                    label="Queue system"
                    readonly
                    dense
                    hide-details
                  ></v-text-field>
                </ECol>
                <!-- detectionModel -->
                <ECol cols="12" sm="6" md="4">
                  <v-select
                    v-model="detectionModel"
                    :items="detectionModels"
                    label="Detection model"
                    dense
                    hide-details
                  ></v-select>
                </ECol>
                <!-- printLogsFrame -->
                <ECol cols="12" sm="6" md="4">
                  <v-select
                    v-model="printLogsFrame"
                    :items="logsFrames"
                    label="Print logs frame"
                    dense
                    hide-details
                  >
                  </v-select>
                </ECol>
              </ERow>
              <ERow>
                <!-- detectionThreshold -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption"> Detection threshold </span>
                  <v-slider
                    v-model="detectionThreshold"
                    :min="0"
                    :max="1"
                    step="0.05"
                    dense
                  >
                    <template #append>
                      <v-text-field
                        v-model="detectionThreshold"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field> </template
                  ></v-slider>
                </ECol>
                <!-- snapshotPerSecond -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption"> Snapshot per second </span>

                  <v-slider
                    v-model="snapshotPerSecond"
                    min="0"
                    max="1"
                    step="0.05"
                    dense
                    class="align-center"
                    hide-details
                  >
                    <template #append>
                      <v-text-field
                        v-model="snapshotPerSecond"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field>
                    </template>
                  </v-slider>
                </ECol>
                <!-- crossingVectorAngleLimit -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption"
                    >Crossing vector angle limit</span
                  >
                  <v-slider
                    v-model="crossingVectorAngleLimit"
                    :min="0"
                    :max="100"
                    step="1"
                    dense
                    hide-details
                  >
                    <template #append>
                      <v-text-field
                        v-model="crossingVectorAngleLimit"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field> </template
                  ></v-slider>
                </ECol>
              </ERow>
              <ERow>
                <!-- matchingEmbeddedDstCoeff -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption"
                    >Matching embed dst coefficient</span
                  >
                  <v-slider
                    v-model="matchingEmbeddedDstCoeff"
                    :min="0"
                    :max="1"
                    step="0.05"
                    ><template #append>
                      <v-text-field
                        v-model="matchingEmbeddedDstCoeff"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field> </template
                  ></v-slider>
                </ECol>
                <!-- matchingTimeCoeff -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption">Matching time coeff</span>
                  <v-slider
                    v-model="matchingTimeCoeff"
                    min="0"
                    max="1"
                    step="0.05"
                    ><template #append>
                      <v-text-field
                        v-model="matchingTimeCoeff"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field> </template
                  ></v-slider>
                </ECol>
                <!-- matchingQueueOrderCoeff -->
                <ECol cols="12" sm="6" md="4">
                  <span class="pl-0 text-caption"
                    >Matching queue order coefficient</span
                  >
                  <v-slider
                    v-model="matchingQueueOrderCoeff"
                    :min="0"
                    :max="1"
                    step="0.05"
                    ><template #append>
                      <v-text-field
                        v-model="matchingQueueOrderCoeff"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field> </template
                  ></v-slider>
                </ECol>
              </ERow>
              <ERow>
                <!-- framesToSkip -->
                <ECol cols="12" sm="6" md="1">
                  <v-text-field
                    v-model="framesToSkip"
                    dense
                    hide-details
                    :thumb-size="16"
                    thumb-label="always"
                    :min="0"
                    :max="10"
                    step="1"
                    type="number"
                    label="Frames to skip"
                  ></v-text-field>
                </ECol>

                <!-- minTruckLife -->
                <ECol cols="12" sm="6" md="1">
                  <v-text-field
                    v-model="minTruckLife"
                    dense
                    hide-details
                    :thumb-size="20"
                    thumb-label="always"
                    :min="0"
                    :max="500"
                    step="1"
                    type="number"
                    label="Min truck life"
                  ></v-text-field>
                </ECol>
                <!-- insideMinTime -->
                <ECol cols="12" sm="6" md="1">
                  <v-text-field
                    v-model="insideMinTime"
                    dense
                    hide-details
                    :thumb-size="20"
                    thumb-label="always"
                    :min="10"
                    :max="500"
                    step="1"
                    type="number"
                    label="Inside min time"
                  ></v-text-field>
                </ECol>
                <!-- trackerMaxAge -->
                <ECol cols="12" sm="6" md="1">
                  <v-text-field
                    v-model="trackerMaxAge"
                    dense
                    hide-details
                    :thumb-size="20"
                    thumb-label="always"
                    :min="5"
                    :max="300"
                    step="1"
                    type="number"
                    label="Tracker max age"
                  ></v-text-field>
                </ECol>
                <!-- trackerMinHit -->
                <ECol cols="12" sm="6" md="1">
                  <v-text-field
                    v-model="trackerMinHit"
                    dense
                    hide-details
                    :thumb-size="20"
                    thumb-label="always"
                    :min="1"
                    :max="100"
                    step="1"
                    type="number"
                    label="Tracker min hit"
                  ></v-text-field>
                </ECol>

                <!-- deepEventEndpointUrl -->
                <ECol cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="deepEventEndpointUrl"
                    hide-details
                    dense
                    label=" Deep event endpoint url"
                  />
                </ECol>
                <!-- timestampCalculation -->
                <ECol cols="12" sm="6" md="4">
                  <v-select
                    v-model="timestampCalculation"
                    hide-details
                    dense
                    :items="timestampItems"
                    label="Timestamp calculation"
                  >
                  </v-select>
                </ECol>
              </ERow>
            </v-container>
            <div class="text-subtitle-1 font-weight-bold pt-4">
              RabbitMQ configuration
            </div>
            <ERow>
              <!-- rabbitmqServerUrl -->
              <ECol cols="12" sm="6" md="4">
                <v-text-field
                  v-model="rabbitmqServerUrl"
                  label="Rabbitmq server"
                />
              </ECol>
              <!-- rabbitmqEventsQueue -->
              <ECol cols="12" sm="6" md="4">
                <v-text-field
                  v-model="rabbitmqEventsQueue"
                  label="Rabbitmq events queue"
                />
              </ECol>
              <!-- rabbitmqEventsExchange -->
              <ECol cols="12" sm="6" md="4">
                <v-text-field
                  v-model="rabbitmqEventsExchange"
                  label="Rabbitmq events exchange"
                />
              </ECol>
              <!-- rabbitmqCountingQueue -->
              <ECol cols="12" sm="6" md="4">
                <v-text-field
                  v-model="rabbitmqCountingQueue"
                  label="Rabbitmq counting queue"
                />
              </ECol>
              <!-- rabbitmqCountingExchange -->
              <ECol cols="12" sm="6" md="4">
                <v-text-field
                  v-model="rabbitmqCountingExchange"
                  label="Rabbitmq counting exchange"
                />
              </ECol>
            </ERow>
            <div class="text-subtitle-1 font-weight-bold">Miscellaneous</div>
            <ERow>
              <!-- gateReportActive -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="gateReportActive"
                  label="Gate report active"
                  color="primary"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </ECol>
              <!-- countingActive -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="countingActive"
                  label="Counting active"
                  color="primary"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </ECol>
              <!-- reprocess -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="reprocess"
                  label="Reprocess"
                  color="primary"
                  hide-details
                  dense
                  inset
                >
                </v-switch>
              </ECol>
              <!-- snapshotUpload -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="snapshotUpload"
                  label="Snapshot upload"
                  color="primary"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </ECol>

              <!-- danger zones -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="dangerZones"
                  label="Danger zones"
                  color="primary"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </ECol>

              <!-- workflows -->
              <ECol cols="12" sm="6" md="2" class="pt-0">
                <v-switch
                  v-model="workflows"
                  label="workflows"
                  color="primary"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </ECol>
            </ERow>
            <ERow>
              <ECol cols="12" sm="6" md="12">
                <p class="mb-2">Detection classes</p>
                <v-chip-group
                  v-model="detectionClasses"
                  column
                  multiple
                  active-class="primary--text"
                >
                  <v-chip
                    v-for="truckType in detectionClassesOptions"
                    :key="truckType"
                    :value="truckType"
                    filter
                    outlined
                    >{{ truckType }}</v-chip
                  >
                </v-chip-group>
              </ECol>
            </ERow>
            <div
              v-if="showLinkAnprCameraSelector"
              class="text-subtitle-1 font-weight-bold"
            >
              Link Gate Report and Anpr Cameras
            </div>
            <ERow v-if="showLinkAnprCameraSelector">
              <ECol cols="12">
                <LinkAnprCamerasSelector v-model="linkedAnprCameras" />
              </ECol>
            </ERow>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn type="submit" color="primary" class="px-5 mx-5">
        Save configuration</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useParametersStore, DetectionModel } from "@/stores/parameters"
import { useGateReportStore } from "@/stores/gateReport"

import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import { VEHICLE_TYPE_IDS } from "@evercam/shared/constants/gateReport"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { CameraFeatureFlag } from "@evercam/shared/types"
import LinkAnprCamerasSelector from "@/components/gateReport/LinkAnprCamerasSelector"
import { camelize } from "humps"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.CONFIGURE],
  },
  name: "Parameters",
  components: { VueCtkDateTimePicker, LinkAnprCamerasSelector },
  async asyncData({ params, app }) {
    try {
      await useGateReportStore().init(params.exid)
      useBreadcrumbStore().breadcrumbs = [
        {
          name: "Gate report",
          href: "/gate-report",
        },
        {
          name: params.exid,
          href: `/gate-report/${params.exid}/events`,
        },
        {
          name: "Parameters",
        },
      ]
    } catch (error) {
      app.$notifications.error({ text: "Failed to fetch parameters", error })
    }

    return {
      cameraExid: params.exid,
    }
  },
  data: function () {
    return {
      initialParameters: null,
      processingType: "",
      startDate: "",
      endDate: "",
      minDate: "",
      skipWeekend: true,
      gateReportActive: true,
      countingActive: false,
      dangerZones: false,
      workflows: false,
      queueSystem: "RABBITMQ",
      reprocess: false,
      framesToSkip: 0,
      detectionModel: DetectionModel.YoloTrucks,
      detectionClasses: VEHICLE_TYPE_IDS,
      detectionThreshold: 0.3,
      crossingVectorAngleLimit: 100,
      printLogsFrame: 1000,
      minTruckLife: 5,
      insideMinTime: 300,
      trackerMaxAge: 50,
      trackerMinHit: 2,

      snapshotUpload: false,
      snapshotPerSecond: 0.2,

      deepEventEndpointUrl: "http://95.217.34.156:8000/",
      timestampCalculation: "ocr",

      matchingEmbeddedDstCoeff: 0.6,
      matchingTimeCoeff: 0.2,
      matchingQueueOrderCoeff: 0.2,

      rabbitmqServerUrl: "rabbitmq.evercam.io",
      rabbitmqEventsQueue: "event-edge-analytics-q",
      rabbitmqEventsExchange: "event-edge-analytics-x",
      rabbitmqCountingQueue: "counting-analytics-q",
      rabbitmqCountingExchange: "counting-analytics-x",
      detectionModels: Object.values(DetectionModel),
      detectionClassesOptions: VEHICLE_TYPE_IDS,
      logsFrames: [100, 1000, 10000],
      timestampItems: ["ocr", "msec"],

      minOpening: 0,
      maxClosing: 23,
      siteRangeSetterValue: [0, 23],
      linkedAnprCameras: [],
      anprCameras: [],
    }
  },
  computed: {
    ...mapStores(useParametersStore, useGateReportStore),
    continuous: {
      get() {
        return this.processingType === "continuous"
      },
      set(val) {
        this.processingType = val ? "continuous" : "duration"
      },
    },
    showLinkAnprCameraSelector() {
      const isAnprSelectedCamera =
        this.gateReportStore.selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.ANPR
        )

      return !isAnprSelectedCamera && this.anprCameras?.length > 0
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"))
    },
    "gateReportStore.selectedCamera"() {
      this.fetchAnalyticsParameters()
    },
  },
  beforeDestroy() {
    this.parametersStore.$reset()
  },
  async beforeMount() {
    await this.fetchProjectAnprCameras()
    this.fetchAnalyticsParameters()
  },
  methods: {
    async fetchAnalyticsParameters() {
      await this.parametersStore.fetchParameters({
        cameraId: this.gateReportStore.selectedCamera?.exid,
      })
      this.updateData()
    },
    initLinkedAnprCamera() {
      this.linkedAnprCameras = []
      const linkedAnprCameras = this.initialParameters?.linkedAnprCameras || {}
      this.anprCameras.forEach((camera) => {
        const anprCameraType = linkedAnprCameras[camelize(camera?.exid)]
        this.linkedAnprCameras = [
          ...this.linkedAnprCameras,
          {
            ...camera,
            isEnabled: !!anprCameraType,
            type: anprCameraType || "in-out",
          },
        ]
      })
    },
    async fetchProjectAnprCameras() {
      const { items } = await AdminApi.cameras.getCameras({
        params: {
          projectName: this.gateReportStore.selectedProject.name,
          featureFlags: [CameraFeatureFlag.ANPR],
        },
      })
      this.anprCameras = items.map(({ exid, name }) => ({ exid, name }))
    },
    updateData() {
      this.initialParameters = this.parametersStore.parameters
      this.initLinkedAnprCamera()
      this.processingType = this.initialParameters.processingType
      this.minDate = this.$moment(this.initialParameters?.startDate)
        .utc()
        .format("YYYY-MM-DD")
      this.startDate = this.$moment(this.initialParameters?.startDate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss")
      this.endDate = this.$moment(this.initialParameters?.endDate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss")
      this.skipWeekend = this.initialParameters?.skipWeekend
      this.countingActive = this.initialParameters?.countingActive
      this.dangerZones = this.initialParameters?.dangerZones
      this.workflows = this.initialParameters?.workflows

      this.siteRangeSetterValue = [
        this.initialParameters?.hourSiteOpening,
        this.initialParameters?.hourSiteClosure,
      ]
      this.crossingVectorAngleLimit =
        this.initialParameters?.crossingVectorAngleLimit
      this.detectionClasses = this.initialParameters?.detectionClasses
      this.detectionModel = this.initialParameters?.detectionModel
      this.detectionThreshold = this.initialParameters?.detectionThreshold
      this.framesToSkip = this.initialParameters?.framesToSkip
      this.gateReportActive = this.initialParameters?.gateReportActive
      this.insideMinTime = this.initialParameters?.insideMinTime
      this.minTruckLife = this.initialParameters?.minTruckLife
      this.printLogsFrame = this.initialParameters?.printLogsFrame
      this.queueSystem = this.initialParameters?.queueSystem
        ? this.initialParameters?.queueSystem
        : this.queueSystem
      this.reprocess = this.initialParameters?.reprocess
      this.snapshotPerSecond = this.initialParameters?.snapshotPerSecond
      this.snapshotUpload = this.initialParameters?.snapshotUpload
      this.trackerMaxAge = this.initialParameters?.trackerMaxAge
      this.trackerMinHit = this.initialParameters?.trackerMinHit
      this.deepEventEndpointUrl = this.initialParameters?.deepEventEndpointUrl
      this.timestampCalculation = this.initialParameters?.timestampCalculation
      this.matchingEmbeddedDstCoeff =
        this.initialParameters?.matchingEmbeddedDstCoeff
      this.matchingTimeCoeff = this.initialParameters?.matchingTimeCoeff
      this.matchingQueueOrderCoeff =
        this.initialParameters?.matchingQueueOrderCoeff
      this.rabbitmqServerUrl = this.initialParameters?.rabbitmqServerUrl
      this.rabbitmqEventsQueue = this.initialParameters?.rabbitmqEventsQueue
      this.rabbitmqEventsExchange =
        this.initialParameters?.rabbitmqEventsExchange
      this.rabbitmqCountingQueue = this.initialParameters?.rabbitmqCountingQueue
      this.rabbitmqCountingExchange =
        this.initialParameters?.rabbitmqCountingExchange
    },
    async validate(e) {
      e.preventDefault()
      if (!this.$refs.form.validate()) {
        this.$notifications.error({ text: "Validation issue!" })

        return
      }
      const linkedAnprCameras = this.linkedAnprCameras?.reduce(
        (acc, camera) =>
          camera.isEnabled ? { ...acc, [camera.exid]: camera.type } : acc,
        {}
      )
      await this.parametersStore.upsertParameters({
        ...(this.initialParameters?.id
          ? { id: this.initialParameters?.id }
          : {}),
        cameraex: this.gateReportStore.selectedCamera.exid,
        processingType: this.processingType,
        startDate: this.$moment
          .utc(this.startDate)
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        endDate: this.$moment
          .utc(this.endDate)
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        countingActive: !!this.countingActive,
        dangerZones: !!this.dangerZones,
        workflows: !!this.workflows,
        skipWeekend: !!this.skipWeekend,
        hourSiteClosure: this.siteRangeSetterValue[1],
        hourSiteOpening: this.siteRangeSetterValue[0],
        crossingVectorAngleLimit: this.crossingVectorAngleLimit,
        gateReportActive: !!this.gateReportActive,
        queueSystem: this.queueSystem,
        detectionClasses: this.detectionClasses.toString(),
        detectionModel: this.detectionModel,
        detectionThreshold: this.detectionThreshold,
        framesToSkip: this.framesToSkip,
        insideMinTime: this.insideMinTime,
        minTruckLife: this.minTruckLife,
        printLogsFrame: this.printLogsFrame,
        reprocess: !!this.reprocess,
        snapshotPerSecond: this.snapshotPerSecond,
        snapshotUpload: !!this.snapshotUpload,
        trackerMaxAge: this.trackerMaxAge,
        trackerMinHit: this.trackerMinHit,
        deepEventEndpointUrl: this.deepEventEndpointUrl,
        timestampCalculation: this.timestampCalculation,
        matchingEmbeddedDstCoeff: this.matchingEmbeddedDstCoeff,
        matchingTimeCoeff: this.matchingTimeCoeff,
        matchingQueueOrderCoeff: this.matchingQueueOrderCoeff,
        rabbitmqServerUrl: this.rabbitmqServerUrl,
        rabbitmqEventsQueue: this.rabbitmqEventsQueue,
        rabbitmqEventsExchange: this.rabbitmqEventsExchange,
        rabbitmqCountingQueue: this.rabbitmqCountingQueue,
        rabbitmqCountingExchange: this.rabbitmqCountingExchange,
        linkedAnprCameras,
      })
      await this.fetchAnalyticsParameters()
    },
  },
})
</script>

<style scoped>
.circle-content {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 0px 0px 0px;
  background: #fff;
  border: 3px solid #1976d2;
  text-align: center;
}

.parameters {
  overflow-y: auto;
}
</style>
