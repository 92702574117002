<template>
  <ECard
    :title="camera.name"
    :image="imageUrl"
    blur-background
    :image-translation="$vuetify.breakpoint.smAndDown ? 10 : 20"
    :image-size="$vuetify.breakpoint.smAndDown ? 50 : 55"
    :image-padding="5"
    class="kit-camera e-pr-0"
    :class="{ 'kit-camera--detailed': exNvrDevice }"
  >
    <template #title>
      <div class="e-flex e-gap-3 e-items-center">
        <span
          class="cursor-pointer e-text-base primary--text"
          @click="openCameraDialog"
          >{{ camera.name }}
        </span>

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="e-flex e-gap-2 e-items-center"
        >
          <!-- Dashboard Status -->
          <v-chip
            v-if="exNvrDevice"
            x-small
            :color="statusColor"
            text-color="white"
          >
            {{ statusText }}
          </v-chip>

          <!-- Device state -->
          <v-chip
            v-if="exNvrDevice"
            x-small
            :color="exNvrDeviceStateColor"
            text-color="white"
          >
            {{ exNvrDeviceState }}
          </v-chip>
        </div>
      </div>
    </template>

    <div>
      <div
        class="e-flex"
        :class="{
          'e-flex-col': !exNvrDevice,
          'e-flex-col-reverse': $vuetify.breakpoint.smAndDown,
        }"
      >
        <div>
          <!-- Dashboard Status -->
          <SummaryElement v-if="!exNvrDevice" read-only label="Status">
            <v-chip x-small :color="statusColor" text-color="white">
              {{ statusText }}
            </v-chip>
          </SummaryElement>

          <!-- Exid -->
          <SummaryElement
            is-copyable
            read-only
            label="Exid"
            :value="camera.exid"
          />

          <!-- Vendor -->
          <SummaryElement
            read-only
            label="Vendor"
            :value="camera.cameraModel"
          />

          <!-- Model -->
          <SummaryElement
            v-if="exNvrDevice"
            read-only
            label="Model"
            :value="exNvrDevice.model"
          />

          <!-- Created at -->
          <SummaryElement read-only label="Created At">
            {{ createdAt }}
          </SummaryElement>

          <!-- Device Id -->
          <SummaryElement
            v-model="deviceId"
            is-copyable
            :has-default-save-callback="false"
            @save="updateCamera({ deviceId: deviceId.value })"
          />
        </div>

        <div class="e-flex">
          <CameraCard
            :camera="camera"
            :token="accountStore.token"
            :with-label="false"
            :absolute="false"
            :width="cameraCardWidth"
            class="mx-0"
            :class="{
              'ma-0 mr-2': exNvrDevice,
              'mt-2 mb-0': !exNvrDevice,
            }"
          />
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="e-flex e-flex-col e-gap-1 mt-1"
          >
            <!-- Dashboard Status -->
            <v-chip x-small :color="statusColor" text-color="white">
              {{ statusText }}
            </v-chip>

            <!-- Device state -->
            <v-chip
              v-if="exNvrDevice"
              x-small
              :color="exNvrDeviceStateColor"
              text-color="white"
            >
              {{ exNvrDeviceState }}
            </v-chip>
          </div>
        </div>
      </div>

      <!-- MAC Address -->
      <SummaryElement
        v-if="exNvrDevice"
        read-only
        label="MAC Address"
        :value="macAddress"
      />

      <!-- Main stream URL -->
      <SummaryElement
        v-if="exNvrDevice"
        :value="mainStreamUri"
        label="Main Stream"
        read-only
        is-copyable
      >
        <template #value-text>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
                <a target="_blank" :href="mainStreamUri" @click.stop>
                  <span>{{ mainStreamUri }}</span>
                </a>
              </ETruncatedDiv>
            </template>
            <span>{{ mainStreamUri }}</span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- Sub stream URL -->
      <SummaryElement
        v-if="exNvrDevice"
        :value="subStreamUri"
        label="Sub Stream"
        read-only
        is-copyable
      >
        <template #value-text>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
                <a target="_blank" :href="subStreamUri" @click.stop>
                  <span>{{ subStreamUri }}</span>
                </a>
              </ETruncatedDiv>
            </template>
            <span>{{ subStreamUri }}</span>
          </v-tooltip>
        </template>
      </SummaryElement>
    </div>
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { mapStores } from "pinia"
import {
  Camera,
  CameraModel,
  ExNvrDeviceConfig,
  ExNvrDeviceState,
} from "@evercam/shared/types"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { CameraStatusMap, ExNvrDeviceStateColor } from "@/components/constants"
import CameraCard from "@evercam/shared/components/CameraCard.vue"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default Vue.extend({
  components: {
    CameraCard,
    SummaryElement,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
  },
  data() {
    return {
      deviceId: {
        key: "deviceId",
        label: "Device ID",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [],
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore, useAccountStore, useCameraDialogStore),
    statusColor() {
      return CameraStatusMap[this.camera.status]?.color
    },
    exNvrDeviceStateColor() {
      return ExNvrDeviceStateColor[this.exNvrDeviceState]?.color
    },
    statusText() {
      return CameraStatusMap[this.camera.status]?.value
        ?.replace(" (Waiting for Site Visit)", "")
        ?.toLowerCase()
    },
    cameraCardWidth(): number {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 100
      } else if (this.exNvrDevice) {
        return 120
      } else {
        return 180
      }
    },
    imageUrl(): string {
      let image = ""

      if (
        [
          CameraModel.Axis,
          CameraModel.Hikvision,
          CameraModel.Milesight,
        ].includes(this.camera.cameraModel)
      ) {
        image = this.camera.cameraModel
      } else {
        image = CameraModel.Hikvision
      }

      return require(`~/assets/img/camera/${image}.gif`)
    },
    createdAt(): string {
      return this.$moment(this.camera.createdAt).format("DD MMM YYYY")
    },
    exNvrDevice(): ExNvrDeviceConfig | null {
      return this.kitSummaryStore.exNvrDeviceConfigByCamera?.[this.camera?.exid]
    },
    exNvrDeviceState(): ExNvrDeviceState | null {
      if (!this.exNvrDevice) {
        return null
      }

      return (this.exNvrDevice as ExNvrDeviceConfig).state
    },
    macAddress(): string {
      if (!this.exNvrDevice) {
        return ""
      }

      const mac = (this.exNvrDevice as ExNvrDeviceConfig)?.mac

      return mac?.match(/.{2}/g)?.join(":").toUpperCase() || ""
    },
    mainStreamUri(): string {
      if (!this.exNvrDevice) {
        return ""
      }

      return (this.exNvrDevice as ExNvrDeviceConfig).streamConfig
        .streamUri as string
    },
    subStreamUri(): string {
      if (!this.exNvrDevice) {
        return ""
      }

      return (this.exNvrDevice as ExNvrDeviceConfig).streamConfig
        .subStreamUri as string
    },
  },
  watch: {
    exNvrDevice() {
      this.cameraDialogStore.exNvrDevice = this.exNvrDevice as ExNvrDeviceConfig
    },
    "kitSummaryStore.exNvrDevices": {
      handler(devices) {
        if (!devices?.length) {
          return
        }

        this.deviceId.dataset = devices?.map(({ id }) => ({
          name: id,
          value: id,
        }))

        const isDeviceIdExists = devices.find(
          ({ id }) => id === this.camera.nvrDeviceId
        )

        if (!isDeviceIdExists) {
          this.$notifications.error({
            text: "Ex Nvr Device Id doesn't exist",
          })
        }

        this.deviceId.value = this.camera.nvrDeviceId
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openCameraDialog() {
      this.cameraDialogStore.openDialog({
        camera: this.camera.exid,
      })
    },
    async updateCamera(change) {
      try {
        await AdminApi.cameras.update(this.camera.exid, change)
        this.$notifications.success(
          `Camera "${this.camera.name}" updated successfully`
        )
      } catch (error) {
        this.$notifications.error({
          text: "Failed to update camera!",
          error,
        })
      }
    },
  },
})
</script>

<style lang="scss">
.kit-camera {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  .summary-element {
    min-height: 20px;
  }
  .snapshot-card__img,
  .camera-card {
    box-shadow: none !important;
  }
  .camera-card {
    border: 1px solid rgb(229, 231, 235);
  }
  &.kit-camera--detailed {
    .camera-card {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      height: fit-content;
    }
  }
  .e-flex-col-reverse {
    flex-direction: column-reverse;
  }
  .e-r-0 {
    right: 0;
  }

  @media (max-width: 960px) {
    max-width: 80%;
  }
  .summary-element__label {
    min-width: 5rem;
  }
}
</style>
