<template>
  <ECard
    title="NVR"
    :image="`${require('~/assets/img/sbc/rpi4.gif')}`"
    blur-background
    :image-translation="20"
    :image-size="70"
    :image-padding="5"
    v-bind="$attrs"
  >
    <!-- SERIAL NUMBER -->
    <SummaryElement
      v-model="serial"
      :has-default-save-callback="false"
      is-id
      :read-only="!hasNvr"
      :is-copyable="hasNvr"
      @save="saveNvr"
    />

    <!-- VERSION -->
    <SummaryElement
      label="Version"
      :value="kitSummaryStore.exNvrSystemStatus?.version || '-'"
      read-only
    />

    <!-- DEVICE TYPE -->
    <SummaryElement
      v-model="deviceType"
      :read-only="!hasNvr"
      :has-default-save-callback="false"
      @save="saveNvr"
    />

    <!-- NVR MODEL -->
    <SummaryElement read-only label="NVR type">
      {{ kitSummaryStore.nvr.model ? kitSummaryStore.nvr.model : "-" }}
    </SummaryElement>

    <!-- HTTP URL -->
    <SummaryElement
      :value="kitSummaryStore.nvr.httpUrl"
      label="HTTP URL"
      read-only
      :is-copyable="hasNvr"
    >
      <template #value-text>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
              <a target="_blank" :href="kitSummaryStore.nvr.httpUrl">
                <span>{{ kitSummaryStore.nvr.httpUrl }}</span>
              </a>
            </ETruncatedDiv>
          </template>
          <span>{{ kitSummaryStore.nvr.httpUrl }}</span>
        </v-tooltip>
      </template>
    </SummaryElement>

    <!-- RTSP URL -->
    <SummaryElement
      v-model="rtspUrl"
      :read-only="!hasNvr"
      :has-default-save-callback="false"
      @save="saveNvr"
    />

    <!-- USERNAME -->
    <SummaryElement
      v-model="username"
      :read-only="!hasNvr"
      :has-default-save-callback="false"
      :is-copyable="hasNvr"
      @save="saveNvr"
    />

    <!-- PÄSSWORD -->
    <SummaryElement
      v-model="password"
      :has-default-save-callback="false"
      :read-only="!hasNvr"
      :is-password="hasNvr"
      :is-copyable="hasNvr"
      @save="saveNvr"
    />

    <div class="kit-nvr__metrics-container e-flex e-flex-wrap">
      <div v-if="grafanaMetrics" class="kit-nvr__metrics--grafana">
        <div
          class="summary-element__label caption font-weight-regular e-mt-1 e-ml-1"
        >
          Grafana Metrics
        </div>
        <div class="kit-nvr__metrics">
          <KitMetricGaugeChart
            v-for="(metric, metricName) in grafanaMetrics"
            :key="metricName"
            :metric="metric"
            :title="metricName"
          />
        </div>
      </div>

      <div v-if="exNvrMetrics">
        <div
          class="summary-element__label caption font-weight-regular e-mt-1 e-ml-1"
        >
          ExNVR Metrics
        </div>
        <div class="kit-nvr__metrics">
          <KitMetricGaugeChart
            v-for="(metric, metricName) in exNvrMetrics"
            :key="metricName"
            :metric="metric"
            :title="metricName"
          />
        </div>
      </div>
    </div>
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import KitMetricGaugeChart from "@/components/kits/KitMetricGaugeChart.vue"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { Nvr } from "@evercam/shared/types/nvr"
import {
  ExNvrMetric,
  ExNvrMetricId,
  GrafanaMetricId,
  GrafanaMetricResponse,
} from "@evercam/shared/types"

export default Vue.extend({
  components: {
    SummaryElement,
    KitMetricGaugeChart,
  },
  props: {
    grafanaMetrics: {
      type: Object as PropType<Record<
        GrafanaMetricId,
        GrafanaMetricResponse
      > | null>,
      default: null,
    },
    exNvrMetrics: {
      type: Object as PropType<Record<ExNvrMetricId, ExNvrMetric> | null>,
      default: null,
    },
  },
  data() {
    return {
      nvr: {} as Nvr,
      serial: {
        key: "serial",
        label: "Serial",
        value: "",
        type: "TEXT_FIELD",
      },
      deviceType: {
        key: "deviceType",
        label: "SBC type",
        value: "",
        type: "TEXT_FIELD",
      },
      username: {
        key: "username",
        label: "Username",
        value: "",
        type: "TEXT_FIELD",
      },
      password: {
        key: "password",
        label: "Password",
        value: "",
        type: "TEXT_FIELD",
      },
      rtspUrl: {
        key: "rtspUrl",
        label: "RTSP URL",
        value: "",
        type: "TEXT_FIELD",
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    hasNvr(): boolean {
      return !!this.kitSummaryStore.nvr.id
    },
  },
  watch: {
    "kitSummaryStore.nvr": {
      async handler() {
        this.bindNvr()
      },
    },
  },
  mounted() {
    this.bindNvr()
  },
  methods: {
    bindNvr() {
      this.rtspUrl.value = this.kitSummaryStore.nvr.rtspUrl
      this.password.value = this.kitSummaryStore.nvr.password
      this.username.value = this.kitSummaryStore.nvr.username
      this.deviceType.value = this.kitSummaryStore.nvr.deviceType
      this.serial.value = this.kitSummaryStore.nvr.serial
    },
    async saveNvr() {
      let params = {
        rtspUrl: this.rtspUrl.value,
        password: this.password.value,
        username: this.username.value,
        deviceType: this.deviceType.value,
        serial: this.serial.value,
      }
      this.kitSummaryStore.updateNvr(params)
    },
  },
})
</script>

<style lang="scss">
.kit-nvr__metrics {
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, 186px);
  .summary-element {
    min-height: 18px !important;
    max-height: 18px;
  }
}
.kit-nvr__metrics--grafana {
  flex-grow: 0.25;
}
</style>
