import type { Timestamp } from "@evercam/api/types"

// TODO - Use in <ImagePlayer /> and <Recordings* />
export interface Frame {
  label: string
  src: string
  srcSet?: SrcSet
  timestamp: Timestamp
}

// TODO - Use in <ImagePlayerActions />
export interface InfoText {
  index: string
  label: string
}

export type EventMarker<T extends {}> = T & {
  label: string
  thumbnailUrl: string
  isActive: boolean
  cssClass?: Object | string
}

export type SnapshotEvent<T extends {}> = T & {
  eventTime: Timestamp
}

export type MatchedSnapshotEvent<T extends {}> = SnapshotEvent<T> & {
  frameIndex: number
  snapshotTimestamp: Timestamp
}

export enum ImageQuality {
  ThureeSixty = "360",
  FourEighty = "480",
  SevenTwenty = "720",
  OneZeroEightZero = "1080",
  Auto = "auto",
}

export type SrcSet = { [q in ImageQuality]: string }
