<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="progress photos logs"
          sort-by="insertedAt"
          :sort-desc="false"
          item-key="id"
          :headers="headers"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #item.insertedAt="{ item }">
            {{ formatDate(item.insertedAt) }}
          </template>
          <template #item.recipients="{ item }">
            <EReadMore :content="item.recipients" :max-length="70" />
          </template>
          <template #item.cameraIds="{ item }">
            <EReadMore :content="item.cameraIds" :max-length="70" />
          </template>
          <template #item.failedCameraIds="{ item }">
            <EReadMore :content="item.failedCameraIds" :max-length="70" />
          </template>
          <template #item.actions="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  x-small
                  v-on="on"
                  @click="openProgressPhoto(item)"
                >
                  <v-icon small> far fa-envelope-open </v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
          </template>
        </ReportTable>
        <v-dialog
          v-model="progressPhotoDialog"
          max-width="1000"
          @click:outside="
            () => {
              progressPhotoDialog = false
              progressPhoto = null
            }
          "
        >
          <v-card>
            <v-card-title> Progress Photo </v-card-title>
            <v-progress-linear
              v-if="loadProgressPhoto"
              indeterminate
              color="primary"
            />
            <v-divider></v-divider>
            <v-card-text v-html="progressPhoto"> </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="
                  () => {
                    progressPhotoDialog = false
                    progressPhoto = null
                  }
                "
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import headers from "@/components/progressPhotosHistory/progressPhotosHistoryHeaders"
import filterFields from "@/components/progressPhotosHistory/progressPhotosHistorySearchFilters"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.PROGRESS_PHOTOS.VIEW_LOGS],
  },
  components: {
    ReportTable,
  },
  data() {
    return {
      loadProgressPhoto: false,
      progressPhotoDialog: false,
      progressPhoto: null,
      filterFields: {},
    }
  },
  head() {
    return {
      title: "Admin - Progress Photos History",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    provider() {
      return AdminApi.progressPhotos.getProgressPhotosHistory
    },
    headers() {
      return headers(this)
    },
  },
  created() {
    this.filterFields = filterFields(this)
  },
  methods: {
    async openProgressPhoto(item) {
      this.loadProgressPhoto = true
      this.progressPhotoDialog = true
      await AdminApi.progressPhotos
        .getProgressPhotoTemplate({ id: item.id })
        .then((result) => {
          const { progressPhoto } = result
          this.progressPhoto = progressPhoto
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Could not load progress photo template!",
            error,
          })
        })
        .finally(() => {
          this.loadProgressPhoto = false
        })
    },
  },
}
</script>
