<template>
  <v-container fluid class="pa-0">
    <ERow class="pa-5">
      <ECol cols="12" sm="12" md="3" lg="3">
        <Autocomplete
          v-model="selectedCamera"
          item-value="exid"
          item-text="name"
          label="Camera"
          no-filter
          :cache-items="false"
          resource="cameras"
        />
      </ECol>
      <ECol cols="12" sm="12" md="2" lg="2" class="pl-0">
        <v-select
          v-if="models.length > 0"
          v-model="model"
          :items="models"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Model"
          @change="getPendingImages"
        ></v-select>
      </ECol>
      <v-spacer></v-spacer>
      <ECol cols="12" sm="12" md="5" lg="5" class="pl-0 text-right">
        <v-btn color="primary" @click="cameraParamsPopup = true">
          Upload Camera Parameters
        </v-btn>
        <v-btn color="primary" @click="uploadBim()">Upload Bim</v-btn>
      </ECol>
      <ECol v-if="pendingImages.length > 0" col="12" sm="12" md="12" lg="12">
        <v-carousel
          v-model="selectedPendingBIMIndex"
          hide-delimiters
          show-arrows
          :mandatory="false"
          class="mx-auto mt-5 mr-5 mx-5"
        >
          <img
            alt="thumbnail"
            :src="selectedPendingBIM.url"
            class="position-absolute d-block w-100 thumbnail__background-img"
          />
          <VueCtkDateTimePicker
            v-model="selectedPendingBIM.date"
            :no-value-to-custom-elem="true"
            format="YYYY-MM-DD HH:mm:ss"
            formatted="DD/MM/YYYY HH:mm"
            button-color="#1976d2"
            color="#1976d2"
            class="datePicker"
            no-button-now
            no-shortcuts
            no-header
          >
            <ERow>
              <v-btn class="pa-5" color="primary">
                <v-icon x-large>fa-calendar</v-icon>
              </v-btn>
              <div
                v-if="selectedPendingBIM.date"
                class="e-text-white pt-3 px-4 ml-2"
                @click.stop
              >
                <v-icon style="font-size: 1.5em !important">
                  fa-circle-check
                </v-icon>
                {{ selectedPendingBIM.date }}
              </div>
            </ERow>
          </VueCtkDateTimePicker>
          <div class="carrousselIndex e-bg-white pa-2">
            {{ selectedPendingBIMIndex + 1 }}/{{ pendingImages.length }}
          </div>
          <template #prev="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              :disabled="selectedPendingBIMIndex === 0"
              v-on="on"
            >
              <v-icon> fa-chevron-left </v-icon>
            </v-btn>
          </template>
          <template #next="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              :disabled="selectedPendingBIMIndex === pendingImages.length - 1"
              v-on="on"
            >
              <v-icon> fa-chevron-right </v-icon>
            </v-btn>
          </template>
          <EZoomable :ignore-pointer-events="true">
            <v-carousel-item
              v-for="(image, index) in pendingImages"
              :key="image.key"
              :value="index"
              :src="image.url"
              contain
            >
              <template #placeholder>
                <EvercamLoadingAnimation class="mx-auto h-100" />
              </template>
            </v-carousel-item>
          </EZoomable>
        </v-carousel>
      </ECol>
      <ECol v-else col="12" sm="12" md="12" lg="12">
        There are no pending images for the selected camera.
      </ECol>
      <ECol v-if="pendingImages.length > 0" col="12" sm="12" md="12" lg="12">
        <v-btn
          block
          color="primary"
          :disabled="proccessedImagesCount < pendingImages.length"
          @click="processConfirmationDialog = true"
        >
          Process New Dates ({{ proccessedImagesCount }}/{{
            pendingImages.length
          }})
        </v-btn>
      </ECol>
      <v-dialog v-model="processConfirmationDialog" width="450">
        <v-card>
          <v-card-title>Process new dates</v-card-title>
          <v-divider />
          <v-card-text class="pt-3">
            Are you sure you want to process these dates ?
            <v-checkbox
              v-model="distortToggle"
              label="Apply distortion"
              class="resize-checkbox"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="processConfirmationDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="processDates"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <BimUploadDialog
        :uploadPopup="uploadPopup"
        @onClose="uploadPopup = false"
      />
      <CameraParametersUploaderDialog
        :popup="cameraParamsPopup"
        @onClose="cameraParamsPopup = false"
      />
    </ERow>
  </v-container>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import { debounce } from "@evercam/shared/utils"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import BimUploadDialog from "~/components/bimUploader/BimUploadDialog"
import CameraParametersUploaderDialog from "~/components/bimUploader/CameraParametersUploaderDialog"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import axios from "@evercam/shared/api/client/axios"
import moment from "moment-timezone"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.BIM.UPLOAD],
  },
  components: {
    VueCtkDateTimePicker,
    Autocomplete,
    BimUploadDialog,
    CameraParametersUploaderDialog,
    EvercamLoadingAnimation,
  },
  data() {
    return {
      processConfirmationDialog: false,
      pendingImages: [],
      uploadPopup: false,
      cameraParamsPopup: false,
      distortToggle: true,
      form: {
        parentId: [],
      },
      account: "-1",
      selectedCamera: null,
      model: undefined,
      models: [],
      selectedPendingBIMIndex: undefined,
    }
  },
  head() {
    return {
      title: "Admin - Bim Uploader",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    selectedPendingBIM() {
      return this.pendingImages[this.selectedPendingBIMIndex]
    },
    proccessedImagesCount() {
      return this.pendingImages.reduce((acc, pending) => {
        if (pending.date) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
    },
  },
  watch: {
    selectedCamera: "debouncedFetchAvailableBims",
  },
  methods: {
    cameraParams(val) {
      return {
        account: this.account,
        search: val,
      }
    },
    cameraUploadParams(val) {
      return {
        account: this.account,
        search: val,
      }
    },
    saveDate(index) {
      this.$set(this.pendingImages[index], "date", this.form.parentId[index])
    },
    changeDate(index) {
      this.$set(this.pendingImages[index], "date", "")
    },
    debouncedFetchAvailableBims: debounce(function () {
      this.fetchAvailableBims()
    }),
    async fetchAvailableBims() {
      try {
        if (!this.selectedCamera) {
          return
        }
        const cancelToken = axios.generateCancelTokenSource()

        const { models } = await EvercamApi.bim.getBimModel(
          this.selectedCamera.exid,
          {
            timestamp: moment().toISOString(),
          },
          { cancelToken: cancelToken.token }
        )
        this.model = undefined
        this.models = models
        this.pendingImages = []
      } catch (error) {
        this.$notifications.error({
          text: `Failed pending BIM images check! for camera ${this.selectedCamera.name}`,
          error,
        })
      }
    },
    async getPendingImages() {
      try {
        if (!this.model) {
          this.$notifications.error({
            text: "No model selected...",
          })

          return
        }
        let response = await EvercamApi.bim.pendingBimImages(
          this.selectedCamera.exid,
          {
            model: this.model,
            apiId: this.selectedCamera.userApiId,
            apiKey: this.selectedCamera.userApiKey,
          }
        )
        if (response.length > 0) {
          this.pendingImages = response
          this.selectedPendingBIMIndex = 0
        } else {
          this.pendingImages = []
          this.$notifications.warn(
            `There is no pending images for camera ${this.selectedCamera.name}`
          )
        }
      } catch (error) {
        this.$notifications.error({
          text: `Failed pending BIM images check! for camera ${this.selectedCamera.name}`,
          error,
        })
      }
    },
    async processDates() {
      this.processConfirmationDialog = false
      if (this.proccessedImagesCount < this.pendingImages.length) {
        this.$notifications.warn("Fill in all required entry fields")
      } else {
        try {
          await EvercamApi.bim.processPendingImages(this.selectedCamera.exid, {
            model: this.model,
            names: this.pendingImages,
            enableDistortion: this.distortToggle,
            apiId: this.selectedCamera.userApiId,
            apiKey: this.selectedCamera.userApiKey,
          })
          this.form = {
            parentId: [],
          }
          this.pendingImages = []
          this.models = []
          this.model = undefined
          this.selectedCamera = null
          this.$notifications.success("We are processing your request!")
        } catch (error) {
          this.$notifications.error({
            text: "Cannot process pending images",
            error,
          })
        }
      }
    },
    uploadBim() {
      this.uploadPopup = true
    },
  },
}
</script>

<style scoped>
.time-picker input {
  border-right: 0px !important;
  padding-left: 0px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-left: 0px !important;
  border-top: 0px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

#pendingImagesCount {
  text-align: center !important;
  width: 100% !important;
  position: relative;
  top: 20px;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #1976d2 !important;
}

.thumbnail__background-img {
  transform: scale(2);
  filter: blur(8px);
}

.datePicker {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 999;
  min-width: 500px;
}

.carrousselIndex {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  z-index: 999;
  opacity: 0.8;
}
</style>
