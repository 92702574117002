<template>
  <div class="e-flex e-justify-center e-items-center">
    <SummaryElement read-only :label="formattedTitles[title] || title">
      <ScoreBar
        class="kit-metric__gauge-chart"
        :score="value"
        :max-score="computedMax"
        invert-colors
        :suffix="suffix"
      />
    </SummaryElement>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import ScoreBar from "@evercam/shared/components/ScoreBar.vue"
import {
  ExNvrMetricId,
  GrafanaMetricId,
  GrafanaMetricResponse,
} from "@evercam/shared/types"
import SummaryElement from "~/components/cameraTabs/summary/SummaryElement.vue"

export default Vue.extend({
  name: "KitMetricGaugeChart",
  components: {
    SummaryElement,
    ScoreBar,
  },
  props: {
    metric: {
      type: [Object, Number] as PropType<GrafanaMetricResponse | number>,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: [Number, undefined],
      default: undefined,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formattedTitles: {
        [GrafanaMetricId.SbcRootFsUsed]: "Disk Usage",
        [GrafanaMetricId.SbcRamUsed]: "RAM Usage",
        [GrafanaMetricId.SbcSystemLoad5MnAvg]: "System load",
        [GrafanaMetricId.RouterTemperature]: "Temperature",
        [GrafanaMetricId.RouterCpuLoad]: "CPU Usage",
        [ExNvrMetricId.CpuUsage]: "CPU Usage",
        [ExNvrMetricId.RamUsage]: "RAM Usage",
        [ExNvrMetricId.SwapUsage]: "Swap Usage",
      },
    }
  },
  computed: {
    isExNvrMetric(): boolean {
      return [
        ExNvrMetricId.CpuUsage,
        ExNvrMetricId.RamUsage,
        ExNvrMetricId.SwapUsage,
      ].includes(this.title)
    },
    value(): number {
      if (this.isExNvrMetric) {
        return this.metric.value
      }

      if (!this.metric?.results) {
        return this.min
      }

      const firstKey = Object.keys(this.metric.results)[0]
      if (
        !firstKey ||
        !this.metric.results[firstKey]?.frames?.[0]?.data?.values
      ) {
        return this.min
      }
      const values = this.metric.results[firstKey].frames[0].data.values

      return (values[1]?.[0] || this.min) as number
    },
    isTemperature(): boolean {
      return this.title.toLowerCase().includes("temperature")
    },
    suffix(): string {
      return this.isTemperature ? "°C" : "%"
    },
    computedMax(): number {
      if (this.max) {
        return this.max
      }

      return this.isTemperature ? 80 : 100
    },
  },
})
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
.kit-metric__gauge-chart {
  border-radius: 0.2em;
  width: 78px;
  height: 12px !important;
  .v-progress-linear__content {
    font-weight: 600;
    font-size: 10px !important;
    mix-blend-mode: color-burn;
    filter: contrast(0.9);
  }
}
</style>
