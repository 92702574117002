<template>
  <div v-if="isLoading" class="d-flex grow align-center justify-center">
    <EvercamLoadingAnimation :verify-theme-mode="true" size="FourXl" />
  </div>
  <div v-else class="d-flex grow flex-column">
    <div class="d-flex align-center ml-3 mb-2">
      <v-icon class="grey--text text--darken-1 stats-icon pr-2 mb-1">
        fa-camera
      </v-icon>
      <div class="subtitle-1 grey--text text--darken-1 font-weight-medium mr-3">
        {{ title }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <ChipStat
        v-for="(object, index) in Object.values(camerasStats)"
        :key="index"
        :color="object.color"
        :title="object.title"
        :value="object.value"
        class="cursor-pointer"
        @click.native="openLink(object.status)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ChipStat from "@/components/homePage/ChipStat"
import { CameraStatus } from "@evercam/shared/types/camera"
import { AdminApi } from "@evercam/shared/api/adminApi"
import Vue from "vue"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "CamerasStats",
  components: {
    ChipStat,
    EvercamLoadingAnimation,
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    byEmail: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      camerasStats: {
        online: {
          color: "#388e3c",
          title: "Online",
          value: 0,
          status: CameraStatus.Online,
        },
        offline: {
          color: "#d32f2f",
          title: "Offline",
          value: 0,
          status: CameraStatus.Offline,
        },
        offlineScheduled: {
          color: "#d32f2f",
          title: "Offline (Scheduled)",
          value: 0,
          status: CameraStatus.OfflineScheduled,
        },
        onHold: {
          color: "#e64a19",
          title: "On Hold",
          value: 0,
          status: CameraStatus.OnHold,
        },
        waiting: {
          color: "#000",
          title: "Waiting For Activation",
          value: 0,
          status: CameraStatus.Waiting,
        },
        underMaintenance: {
          color: "#fbc02d",
          title: "Under Maintenance",
          value: 0,
          status: CameraStatus.UnderMaintenance,
        },
        waitingForSiteVisit: {
          color: "#fbc02d",
          title: "Under Maintenance (Waiting For Site Visit)",
          value: 0,
          status: CameraStatus.WaitingForSiteVisit,
        },
        decommissioned: {
          color: "#0097a7",
          title: "Decommissioned",
          value: 0,
          status: CameraStatus.Decommissioned,
        },
      },
      isLoading: false,
    }
  },
  mounted() {
    this.fetchCamerasStats()
  },
  methods: {
    async fetchCamerasStats() {
      try {
        this.isLoading = true
        let params = {
          email: this.byEmail,
        }
        let statuses = await AdminApi.cameras.getCamerasStats({
          params,
        })
        Object.keys(this.camerasStats).forEach(
          (status) => (this.camerasStats[status].value = statuses[status] || 0)
        )
      } catch (error) {
        this.$notifications.error({
          text: "Could not load cameras stats!",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    openLink(status) {
      let link = `/cameras?sort=created_at%7Cdesc&limit=50&page=1&statuses=${status}${
        this.byEmail ? `&user_email=${this.byEmail}` : ""
      }`
      window.open(link, "_self").focus()
    },
  },
})
</script>

<style scoped>
.stats-icon {
  font-size: 15px !important;
}
</style>
