<template>
  <div
    class="summary-element__default-content d-flex align-center justify-start w-100"
  >
    <!-- VALUE TEXT SLOT -->
    <slot name="value-text">
      <!-- TEXT CONTENT -->
      <span class="caption font-weight-regular text-truncate max-w-100">
        {{
          !isPassword || (isPassword && showPassword) || !isValid
            ? valueText
            : "********"
        }}
      </span>
    </slot>

    <!-- SHOW / HIDE PASSWORD -->
    <div v-if="isPassword && isValid" @click.stop="() => {}">
      <v-btn small icon>
        <v-icon v-if="showPassword" small @click="showPassword = !showPassword">
          fa fa-eye
        </v-icon>
        <v-icon v-else small @click="showPassword = !showPassword">
          fa fa-eye-slash
        </v-icon>
      </v-btn>
    </div>

    <!-- COPY BUTTON -->
    <div v-if="isCopyable && isValid" @click.stop="() => {}">
      <CopyToClipboardBtn :text="valueText" color="primary" icon />
    </div>

    <!-- DEFAULT SLOT -->
    <slot></slot>
  </div>
</template>

<script>
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn.vue"

export default {
  components: { CopyToClipboardBtn },
  props: {
    isPassword: {
      type: Boolean,
      default: false,
    },
    isCopyable: {
      type: Boolean,
      default: false,
    },
    valueText: {
      type: [String, Number],
      default: "-",
    },
  },
  data() {
    return {
      showPassword: false,
    }
  },
  computed: {
    isValid() {
      return this.valueText && !["-", ""].includes(`${this.valueText}`.trim())
    },
  },
}
</script>
