<template>
  <ReportTable
    name="siteView"
    item-key="trueIndex"
    :headers="filteredHeaders"
    :provider="fetchBimCameras"
    data-field="data"
    :process-items="processItems"
    :filter-fields="filterFields"
    :copy-to-clipboard="false"
    :hide-show="false"
  >
    <template #actions-left>
      <div class="body-1 text--secondary my-3">Site view</div>
    </template>
    <template #actions-right>
      <v-btn
        :loading="isCopyLoading"
        color="primary"
        dark
        class="mr-1"
        small
        @click="openCopyDatabaseDialog"
      >
        Copy Database
      </v-btn>

      <AddSiteViewDialog v-if="canEditSiteView" :getBIMCameras="refreshTable" />
      <EditSiteViewDialog
        v-if="canEditSiteView"
        v-model="editedItem"
        @edit-items="editItems"
      />
    </template>
    <template #item.edit="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="editedItem = item"
        >
          <v-icon small> fa-pencil-alt </v-icon>
        </v-btn>
      </ERow>
    </template>
    <template #item.projectName="{ item }">
      {{ item.projectName }}
      <OpenInNewWindowButton
        :target-link="`${$config.public.dashURL}/projects/${item.projectId}/${
          item.type === 'Cesium' ? 'drone' : 'bim'
        }`"
      />
    </template>
    <template #item.projectId="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <nuxt-link
              :to="{
                path: `/site-view/${item.projectId}`,
                query: { type: item.type },
              }"
            >
              <span class="cursor-pointer primary--text"
                >{{ item.projectId }}
              </span>
            </nuxt-link>
          </span>
        </template>
        <span>
          <v-img
            v-if="item.type === 'iTwin'"
            :src="`https://firebasestorage.googleapis.com/v0/b/bim-three-vue.appspot.com/o/${
              item.projectId
            }-${item.type.toLowerCase()}.png?alt=media&token=${
              $config.public.LOAD_ITWIN_THUMBNAIL_TOKEN
            }`"
            aspect-ratio="1.4"
            width="285"
          />
        </span>
        <span> {{ item.projectName }} | {{ item.software }}</span>
      </v-tooltip>
    </template>
    <template #item.missingExids="{ item }">
      <div class="d-flex flex-wrap">
        <span v-for="(exid, index) in item.missingExids" :key="exid">
          <v-chip
            :class="{ 'mr-1': index < item.missingExids.length - 1 }"
            small
            rounded
            outlined
            color="primary"
          >
            <CopyToClipboardBtn :text="exid" icon color="primary" />
            {{ exid }}
          </v-chip>
        </span>
      </div>
    </template>
    <template #item.delete="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="openDeleteDialog(item)"
        >
          <v-icon small color="red"> fas fa-trash </v-icon>
        </v-btn>
      </ERow>
    </template>
  </ReportTable>
</template>

<script>
import filterFields from "@/components/siteView/siteViewSearchFilters"
import headers from "@/components/siteView/siteViewHeaders"
import AddSiteViewDialog from "@/components/siteView/AddSiteViewDialog"
import EditSiteViewDialog from "@/components/siteView/EditSiteViewDialog"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import axios from "axios"
import ReportTable from "@/components/ReportTable"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_VIEWS.VIEW],
  },
  name: "SiteViews",
  components: {
    OpenInNewWindowButton,
    AddSiteViewDialog,
    EditSiteViewDialog,
    ReportTable,
    CopyToClipboardBtn,
  },
  data() {
    return {
      loading: true,
      editedItem: null,
      searchFields: {},
      headers,
      isCopyLoading: false,
      filterFields,
    }
  },
  computed: {
    ...mapStores(useReportStore),
    filteredHeaders() {
      return this.headers.filter((header) => {
        if (header.value === "delete") {
          return this.canDeleteSiteView
        }
        if (header.value === "edit") {
          return this.canEditSiteView
        }

        return true
      })
    },
    canEditSiteView() {
      return this.$permissions.user.can(PERMISSIONS.SITE_VIEWS.UPDATE)
    },
    canDeleteSiteView() {
      return this.$permissions.user.can(PERMISSIONS.SITE_VIEWS.DELETE)
    },
  },
  methods: {
    async fetchBimCameras() {
      return axios
        .get(`${this.$config.public.firebaseDbLink}data/cameras-list.json`)
        .then((res) => {
          res.data = res.data
            .map((item, trueIndex) => ({ ...item, trueIndex }))
            .reverse()

          return res
        })
    },
    processItems(items) {
      return items.map((element) => ({ ...element }))
    },
    refreshTable() {
      this.$root.$emit("refresh-report-table")
    },
    async openDeleteDialog(deletedItem) {
      if (
        await this.$confirmDialog.open({
          title: "Delete site view",
          message:
            "Are you sure you want to delete this site view ?. If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        this.deleteSiteView(deletedItem)
      }
    },
    async deleteSiteView(deleteItem) {
      this.reportStore.items = this.reportStore.items.filter(
        (el) => el.trueIndex !== deleteItem.trueIndex
      )

      const items = [...this.reportStore.items].map(
        ({ trueIndex, ...item }) => ({ ...item })
      )

      try {
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/cameras-list.json`,
          { ...items }
        )
        await axios.delete(
          `${
            this.$config.public.firebaseDbLink
          }data/projects/${deleteItem.type.toLowerCase()}/${
            deleteItem.projectId
          }.json`
        )
        this.$notifications.success("Site view successfully deleted")
      } catch (error) {
        this.$notifications.error({
          text: "Failed to delete Site view!",
          error,
        })
      }
    },
    async editItems({ siteView, itemData, initialViewer }) {
      try {
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/cameras-list/${siteView.trueIndex}.json`,
          { ...siteView, trueIndex: undefined }
        )

        if (siteView.type === "iTwin") {
          await axios.patch(
            `${this.$config.public.firebaseDbLink}data/projects/itwin/${siteView.projectId}.json`,
            { ...itemData }
          )
        } else if (siteView.type === "Forge") {
          await axios.patch(
            `${this.$config.public.firebaseDbLink}data/projects/forge/${siteView.projectId}.json`,
            { ...itemData }
          )
        } else {
          await axios.patch(
            `${this.$config.public.firebaseDbLink}data/projects/cesium/${siteView.projectId}/transform.json`,
            { ...itemData }
          )
        }

        this.refreshTable()
        this.$notifications.success("Site view successfully saved")
        if (initialViewer !== siteView.type) {
          await axios.delete(
            `${
              this.$config.public.firebaseDbLink
            }data/projects/${initialViewer.toLowerCase()}/${
              siteView.projectId
            }.json`
          )
        }
      } catch (error) {
        this.$notifications.error({ text: "Failed to save Site view!", error })
      } finally {
        this.editedItem = null
      }
    },
    async copyDatabase() {
      try {
        this.isCopyLoading = true
        let { data } = await axios.get(
          `${this.$config.public.firebaseDbLink}data.json`
        )
        await axios.put(
          `${this.$config.public.firebaseStagingDbLink}data.json`,
          {
            ...data,
          }
        )
        this.$notifications.success("Database successfully copied")
      } catch (error) {
        this.$notifications.error({ text: "Failed to copy database!", error })
      } finally {
        this.isCopyLoading = false
      }
    },
    async openCopyDatabaseDialog() {
      if (
        await this.$confirmDialog.open({
          title: "Copy Database",
          message:
            "Are you sure you want to migrate the database from Production to Staging? If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        await this.copyDatabase()
      }
    },
  },
}
</script>
