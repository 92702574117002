<template>
  <ECol class="pa-0">
    <ERow no-gutters class="align-start justify-start">
      <!-- Selected cameras  -->
      <ECol
        :class="{
          'flex-grow-0': gateReportStore.cameras.length <= 2,
        }"
      >
        <ChipsToggleFilter
          v-model="gateReportStore.selectedCameraExids"
          title="Cameras"
          :items="gateReportStore.cameras"
          all-label="All Cameras"
          :item-key="(item) => item.exid"
          :item-label="(item) => `${item.name} - (${item.exid})`"
          class="ma-0"
        />
      </ECol>

      <!-- Selected truck types  -->
      <ECol class="pl-2">
        <ChipsToggleFilter
          v-model="gateReportStore.selectedVehicleTypes"
          title="Truck types"
          :items="gateReportStore.vehicleTypes"
          all-label="All trucks"
          :item-key="(item) => item.value"
          :item-label="(item) => item.label"
          class="ma-0"
        />
      </ECol>

      <!-- Selected verified events visibility  -->
      <ECol cols="2">
        <div class="font-weight-500">Visibility</div>
        <v-chip
          v-for="{
            text,
            value,
          } in gateReportStore.verifiedEventsVisibilityItems"
          :key="value"
          x-small
          outlined
          label
          class="mr-1 cursor-pointer"
          :color="
            gateReportStore.selectedVerifiedEventsVisibility === value ||
            gateReportStore.selectedVerifiedEventsVisibility === 0
              ? 'primary'
              : 'secondary'
          "
          @click="gateReportStore.selectedVerifiedEventsVisibility = value"
        >
          {{ text }}
        </v-chip>
      </ECol>
    </ERow>
  </ECol>
</template>

<script>
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import ChipsToggleFilter from "@evercam/shared/components/ChipsToggleFilter"

export default {
  name: "EventsFiltersSummary",
  components: {
    ChipsToggleFilter,
  },
  computed: {
    ...mapStores(useGateReportStore),
  },
}
</script>
