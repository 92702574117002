<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="companies"
          sort-by="created_at"
          item-key="exid"
          :headers="headers"
          :provider="provider"
          :show-select="canDeleteCompanies || canEditCompanies"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              v-if="canDeleteCompanies"
              color="error"
              small
              :disabled="reportStore.selectedItems.length === 0"
              :loading="loading"
              @click="deleteAll"
            >
              Delete
            </v-btn>
            <v-btn
              v-if="canEditCompanies"
              color="primary"
              small
              :disabled="reportStore.selectedItems.length === 0"
              @click="resync"
            >
              Re-Sync
            </v-btn>
            <v-dialog
              v-model="dialog"
              max-width="300px"
              persistent
              @keydown.esc="close"
            >
              <v-card>
                <v-card-title>
                  <span>{{ formTitle }}</span>
                </v-card-title>
                <v-divider />
                <ValidationObserver v-slot="{ invalid, passes }">
                  <v-card-text class="pt-5">
                    <v-form>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          dense
                          outlined
                          :error-messages="errors"
                          label="Name"
                          placeholder="Construction Company"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Company ID"
                        rules="required"
                      >
                        <v-text-field
                          v-model="editedItem.newExid"
                          dense
                          outlined
                          :error-messages="errors"
                          label="Company ID"
                          placeholder="Construction Company"
                        />
                      </ValidationProvider>
                      <v-text-field
                        v-model="editedItem.domains"
                        label="Domains"
                        dense
                        outlined
                        placeholder="Comma separated domains."
                      />
                      <v-text-field
                        v-model="editedItem.zohoId"
                        dense
                        outlined
                        label="Zoho ID"
                        placeholder=""
                      />
                    </v-form>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close"> Cancel </v-btn>
                    <v-btn
                      color="primary"
                      text
                      :disabled="invalid"
                      @click="passes(saveCompany)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </ValidationObserver>
              </v-card>
            </v-dialog>
          </template>
          <template #item.actions="{ item }">
            <ERow justify="center" align="center">
              <!-- EDIT -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="editItem(item)"
                  >
                    <v-icon small> fa-pencil-alt </v-icon>
                  </v-btn>
                </template>
                <span>Edit Company</span>
              </v-tooltip>
            </ERow>
          </template>
          <template #item.name="{ item }">
            <span class="float-left mr-1">
              <NuxtLink :to="`/companies/${item.id}`">{{ item.name }}</NuxtLink>
            </span>
          </template>
          <template #item.zohoId="{ item }">
            <OpenInZohoButton
              v-if="item.zohoId"
              :zoho-id="item.zohoId"
              type="company"
            />
            <span v-else>-</span>
          </template>
          <template #item.exid="{ item }">
            {{ item.exid }}
            <OpenInNewWindowButton
              :target-link="`//${item.exid}`"
              hint="Open website in a new tab"
            />
          </template>
          <template #item.domains="{ item }">
            {{ item.domains || "-" }}
          </template>
          <template #item.linkedinUrl="{ item }">
            <v-btn
              v-if="item.linkedinUrl"
              icon
              color="primary"
              target="_blank"
              :href="`${item.linkedinUrl}`"
              x-small
            >
              <v-icon small> fab fa-linkedin-in </v-icon>
            </v-btn>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import CompanyHeaders from "@/components/companies/companiesHeaders"
import filterFields from "@/components/companies/companiesSearchFilters"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.COMPANIES.VIEW],
  },
  name: "Companies",
  components: {
    OpenInZohoButton,
    OpenInNewWindowButton,
    ReportTable,
  },
  data: () => {
    return {
      headers: [],
      loading: false,
      filterFields: {},
      editedItem: {
        name: "",
        zohoId: "",
        exid: "",
        domains: "",
        newExid: "",
      },
      defaultItem: {
        name: "",
        zohoId: "",
        exid: "",
        domains: "",
        newExid: "",
      },
      editedIndex: -1,
      dialog: false,
      selectedItem: null,
    }
  },
  head() {
    return {
      title: "Admin - Companies",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.companies.getCompanies
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Company" : "Edit Company"
    },
    canEditCompanies() {
      return this.$permissions.user.can(PERMISSIONS.COMPANIES.EDIT)
    },
    canDeleteCompanies() {
      return this.$permissions.user.can(PERMISSIONS.COMPANIES.DELETE)
    },
  },
  created() {
    this.headers = CompanyHeaders(this).filter(
      (header) => this.canEditCompanies || header.value !== "actions"
    )
    this.filterFields = filterFields(this)
  },
  methods: {
    async deleteAll() {
      if (
        await this.$confirmDialog.open({
          title: "Delete companies ?",
          message: "This will permanently delete selected companies!",
        })
      ) {
        try {
          this.loading = true
          const ids = this.reportStore.selectedItems.map(
            (company) => company.exid
          )
          await AdminApi.companies.deleteCompany({
            ids,
          })
          this.reportStore.items = this.reportStore.items.filter(
            (item) => !ids.includes(item.exid)
          )
          this.reportStore.selectedItems = []
          this.$notifications.success("Companies deleted successfully.")
        } catch (error) {
          this.$notifications.error({
            text: "Delete selected companies failed!",
            error,
          })
        } finally {
          this.loading = false
        }
      }
    },
    saveCompany() {
      let params = {
        companyExid: this.editedItem.newExid,
        companyName: this.editedItem.name,
        zohoId: this.editedItem.zohoId,
        domains: this.editedItem.domains,
      }
      AdminApi.companies
        .updateCompany(this.editedItem.exid, params)
        .then((response) => {
          this.$notifications.success("Company name updated successfully.")
          this.selectedItem.name = response.name
          this.selectedItem.domains = response.domains
          this.selectedItem.zohoId = response.zohoId
          this.selectedItem.exid = response.exid
          this.close()
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Failed to update company!",
            error,
          })
        })
    },
    editItem(item) {
      this.editedIndex = this.reportStore.items.indexOf(item)
      this.selectedItem = item
      this.editedItem = (({ name, zohoId, exid, domains }) => ({
        name,
        zohoId,
        exid,
        domains,
      }))(item)
      this.editedItem.newExid = this.editedItem.exid
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.selectedItem = null
        this.editedIndex = -1
      })
    },
    resync() {
      let ids = this.reportStore.selectedItems.map((item) => item.id)
      AdminApi.companies.reSyncCompanies({ company_ids: ids })

      this.$notifications.success(
        "Companies sync started and will be complete in few minutes."
      )
    },
  },
}
</script>
