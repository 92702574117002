<template>
  <ERow no-gutters class="anpr e-w-full px-2">
    <ECol cols="6" class="ma-0">
      <ERow no-gutters class="e-h-100 e-w-full">
        <ECol cols="12" class="ma-0 pa-2">
          <div class="d-flex align-center justify-space-between pb-3">
            <h2 class="e-font-mono e-text-base">{{ formattedSelectedDate }}</h2>
            <div class="d-flex">
              <OpenInDashGateReportBtn
                v-if="canImpersonateUsers"
                :date="anprStore.selectedDate"
                class="mx-1"
              />
              <MarkDayAsVerifiedButton
                v-if="canUpdateEvents"
                :selected-date="anprStore.selectedDate"
                @verify-day="anprStore.verifyDay"
              />
            </div>
          </div>
          <EventsHeatmap
            class="d-flex justify-center flex-grow-1 w-100"
            :selected-date="anprStore.selectedDate"
            :items="items"
            :format-label="formatLabel"
            :is-date-disabled="isDateDisabled"
            @date-selected="onDateSelected"
          />
        </ECol>
        <ECol cols="12" class="ma-0">
          <div class="position-relative anpr__snapshot h-90">
            <EZoomableImg
              :src="
                errorLoadingSnapshot ? '/unavailable.jpg' : eventSnapshotUrl
              "
              :slider="true"
              :blur-background="true"
              :hide-content-on-blur="false"
              @load="loadingSnapshot = false"
              @error="handleErrorLoadingSnapshot"
            >
              <div class="e-w-full e-h-full pa-5 e-flex e-justify-end">
                <EToggleSwitch
                  v-model="selectedThumbnailOption"
                  :options="thumbnailOptions"
                  size="sm"
                  color="primary"
                />
              </div>
            </EZoomableImg>
            <v-fade-transition>
              <v-overlay :value="loadingSnapshot" absolute>
                <EvercamLoadingAnimation
                  size="FiveXl"
                  :verify-theme-mode="false"
                />
              </v-overlay>
            </v-fade-transition>
          </div>
        </ECol>
      </ERow>
    </ECol>
    <ECol cols="6" class="ma-0">
      <ECol cols="12" class="ma-0">
        <AnprFiltersSection />
      </ECol>
      <ECol cols="12" class="ma-0">
        <AnprEventsList
          :selected-camera-type="selectedThumbnailOption"
          :events="filteredAnprEvents"
          :selected-timestamp="anprStore.selectedEvent.captureTime"
          :read-only="!canUpdateEvents"
          @event-selected="onEventSelected"
          @events-updated="onEventsUpdated"
        />
      </ECol>
    </ECol>
  </ERow>
</template>

<script>
import { mapStores } from "pinia"
import { useAnprStore } from "@/stores/anpr"
import { useDayVerificationStore } from "@/stores/dayVerification"
import { useAccountStore } from "@/stores/account"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import AnprEventsList from "@/components/gateReport/anpr/AnprEventsList.vue"
import AnprFiltersSection from "@/components/gateReport/anpr/AnprFiltersSection.vue"
import OpenInDashGateReportBtn from "@/components/gateReport/OpenInDashGateReportBtn"
import EventsHeatmap from "@/components/gateReport/EventsHeatmap"
import MarkDayAsVerifiedButton from "@/components/gateReport/MarkDayAsVerifiedButton"

import { getAiApiUrl } from "@evercam/shared/api/aiApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { GateReportVehicleType } from "@evercam/shared/types"
import { caseInsensitiveIncludes } from "@evercam/shared/utils"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.VIEW],
  },
  components: {
    OpenInDashGateReportBtn,
    EvercamLoadingAnimation,
    AnprEventsList,
    AnprFiltersSection,
    EventsHeatmap,
    MarkDayAsVerifiedButton,
  },
  async middleware({ params }) {
    await useAnprStore().init(params.exid)
  },
  data() {
    return {
      anprCounts: [],
      thumbnailOptions: [
        { label: "ANPR", value: "anpr" },
        { label: "Context", value: "context" },
      ],
      selectedThumbnailOption: "anpr",
      loadingSnapshot: true,
      errorLoadingSnapshot: false,
      errorLoadingResizedSnapshot: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useAnprStore, useDayVerificationStore),
    eventSnapshotUrl() {
      if (this.errorLoadingResizedSnapshot) {
        return this.anprStore.selectedEvent.thumbnails[
          `${this.selectedThumbnailOption}FullSize`
        ]
      }

      return this.anprStore.selectedEvent.thumbnails[
        this.selectedThumbnailOption
      ]
    },
    formattedSelectedDate() {
      return this.$moment(this.anprStore.selectedDate).format(
        "dddd, MMMM Do YYYY"
      )
    },
    filteredAnprEvents() {
      return this.anprStore.events.filter((event) => {
        const filterByPlateNumber = caseInsensitiveIncludes(
          event.plateNumber,
          this.anprStore.plateNumber
        )

        const filterByDirection = caseInsensitiveIncludes(
          event.direction,
          this.anprStore.direction
        )

        const filterByPostProcessed =
          (this.anprStore.showPostProcessed &&
            this.anprStore.showNotPostProcessed) ||
          (this.anprStore.showPostProcessed && event.isPostprocessed) ||
          (this.anprStore.showNotPostProcessed && !event.isPostprocessed)

        const filterByDuplicates =
          this.anprStore.showDuplicates || event.isDuplicate !== true
        const filterByFalsePositives =
          this.anprStore.showFalsePositives || event.isPlate !== false

        // Handle the case when votedVehicleType is null or doesn't belong to gate report truck classes
        const vehicleType = this.anprStore.vehicleTypeIds.includes(
          event.votedVehicleType
        )
          ? event.votedVehicleType
          : GateReportVehicleType.Unknown

        const filterByVehcileType =
          this.anprStore.selectedVehicleTypes.includes(vehicleType)
        const filterByCamera = this.anprStore.selectedCameraExids.includes(
          event.cameraex
        )

        return (
          filterByPostProcessed &&
          filterByPlateNumber &&
          filterByDirection &&
          filterByDuplicates &&
          filterByFalsePositives &&
          filterByVehcileType &&
          filterByCamera
        )
      })
    },
    items() {
      return this.anprStore.dailyCounts.map((d) => {
        const totalIn = d?.counts?.forward || 0
        const totalOut = d?.counts?.reverse || 0
        const totalUnknown = d?.counts?.unknown || 0
        this.anprStore.dailyStatus = [...this.anprStore.dailyStatus, d.date]

        return {
          ...d,
          day: d.date,
          count: Math.ceil((totalIn + totalOut + totalUnknown) / 2),
          disabled: false,
        }
      })
    },
    labels() {
      return {
        countIn: "in",
        countOut: "out",
        countUnknown: "unknown",
        na: "n/a",
        unprocessed: "Not processed",
      }
    },
    canUpdateEvents() {
      return this.$permissions.user.can(PERMISSIONS.GATE_REPORT.UPDATE_EVENTS)
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
  },
  watch: {
    eventSnapshotUrl() {
      this.loadingSnapshot = true
      this.errorLoadingSnapshot = false
    },
  },
  async mounted() {
    await this.init()
    useBreadcrumbStore().breadcrumbs = [
      {
        name: "Gate report",
        href: "/gate-report",
      },
      {
        name: this.anprStore.selectedProject.exid,
        href: `/gate-report/${this.anprStore.selectedProject.exid}`,
      },
      {
        name: "ANPR post-processing",
      },
    ]
  },
  beforeDestroy() {
    this.anprStore.clearStates()
  },
  methods: {
    isDateDisabled(date) {
      return !this.anprStore.dailyStatus?.includes(date)
    },
    formatLabel(item) {
      const date = this.$moment(item.day || item.date).format("ddd DD/MM/YYYY")
      const totalIn = item?.counts?.forward || null
      const totalOut = item?.counts?.reverse || null
      const totalUnknown = item?.counts?.unknown || null

      if (item.disabled) {
        return `${date} (${this.labels.unprocessed})`
      }

      const textIn = `${this.labels.countIn}: ${totalIn || this.labels.na}`
      const textOut = `${this.labels.countOut}: ${totalOut || this.labels.na}`
      const textUnknown = `${this.labels.countUnknown}: ${
        totalUnknown || this.labels.na
      }`

      const verificationInfo = this.dayVerificationStore.verifiedDays.find(
        ({ day }) => day === item.day
      )
      const verificationInfoText = verificationInfo
        ? `, verified by: ${
            verificationInfo.verifiedBy || "n/a"
          } on ${this.$moment(verificationInfo.insertedAt).format(
            "ddd DD/MM/YYYY, HH:mm"
          )}`
        : ""

      return `${date} (${textIn}, ${textOut}, ${textUnknown})${verificationInfoText}`
    },
    async onDateSelected(date) {
      this.anprStore.selectedDate = this.$moment(date).format("YYYY/MM/DD")
      await this.anprStore.fetchEvents()
      this.onEventSelected(this.anprStore.events?.[0]?.id)
    },
    async init() {
      await this.anprStore.fetchEvents()
      this.onEventSelected(this.anprStore.events?.[0]?.id)
    },
    getEventById(id) {
      return this.anprStore.events.find((event) => event.id === id)
    },
    onEventSelected(eventId) {
      this.loadingSnapshot = true
      this.errorLoadingSnapshot = false
      this.errorLoadingResizedSnapshot = false

      const event = this.getEventById(eventId)
      if (!eventId || !event) {
        this.loadingSnapshot = false
        this.errorLoadingSnapshot = true

        return
      }
      const anprThumbailUrl = `${getAiApiUrl()}${
        event.anprSnapshotLink
      }?authorization=${this.accountStore.token}`
      const contextThumbailUrl = `${getAiApiUrl()}${
        event.contextSnapshotLink
      }?authorization=${this.accountStore.token}`
      this.anprStore.selectedEvent = {
        ...event,
        thumbnails: {
          anpr: this.$imgproxy.get1080pResizedImageUrl(anprThumbailUrl),
          anprFullSize: anprThumbailUrl,
          context: this.$imgproxy.get1080pResizedImageUrl(contextThumbailUrl),
          contextFullSize: contextThumbailUrl,
        },
      }
    },
    onEventsUpdated(updatedEvents) {
      const eventsMap = new Map(
        this.anprStore.events.map((event) => [event.id, event])
      )

      updatedEvents.forEach((editedEvent) => {
        if (eventsMap.has(editedEvent.id)) {
          eventsMap.set(editedEvent.id, {
            ...eventsMap.get(editedEvent.id),
            ...editedEvent,
          })
        }
      })

      this.anprStore.events = Array.from(eventsMap.values())
      this.selectNextEvent(updatedEvents)
    },
    selectNextEvent(updatedEvents) {
      const lastEditedEvent = updatedEvents.sort((a, b) => a.id - b.id).pop()
      if (!lastEditedEvent) {
        return
      }

      const lastEditedEventIndex = this.filteredAnprEvents.findIndex(
        (event) => event.id === lastEditedEvent.id
      )

      if (
        lastEditedEventIndex !== -1 &&
        lastEditedEventIndex < this.filteredAnprEvents.length - 1
      ) {
        const nextEvent = this.filteredAnprEvents[lastEditedEventIndex + 1]
        this.onEventSelected(nextEvent?.id)
      }
    },
    handleErrorLoadingSnapshot() {
      if (!this.errorLoadingResizedSnapshot) {
        this.errorLoadingResizedSnapshot = true

        return
      }
      this.errorLoadingSnapshot = true
      this.loadingSnapshot = false
    },
  },
}
</script>
