<template>
  <div>
    <SummaryElement
      v-if="cameraOwnerField"
      v-model="cameraOwnerField"
      :has-default-save-callback="false"
      :read-only="readOnly"
      @input="onOwnerChange"
    />
    <SummaryElement v-else :read-only="readOnly" label="Owner">
      {{ camera.userFullname }}
    </SummaryElement>
  </div>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  name: "CameraSummaryOwner",
  components: {
    SummaryElement,
  },
  props: {
    camera: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      constructionAccounts: [],
      cameraOwnerField: null,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  async mounted() {
    await this.fetchConstructionAccounts()
    const selectedAccount = this.constructionAccounts.find(
      ({ name }) => name === this.camera.userFullname
    )

    if (!selectedAccount) {
      return
    }

    this.cameraOwnerField = {
      key: "owner",
      label: "Owner",
      value: selectedAccount.id,
      type: "SINGLE_SELECT",
      dataset: this.constructionAccounts.map(({ id, name }) => ({
        name,
        value: id,
      })),
    }
  },
  methods: {
    async fetchConstructionAccounts() {
      const { items } = await AdminApi.search.search({
        params: { resource: "users", term: "construction@evercam.io" },
      })
      this.constructionAccounts = items
    },
    async onOwnerChange(owner) {
      try {
        await EvercamApi.shares.transferOwnership(this.camera.exid, {
          userId: owner.value,
        })
        this.cameraDialogStore.updateCamera({
          fullname: this.constructionAccounts.find(
            ({ id }) => owner.value === id
          ).name,
        })
      } catch (error) {
        this.$notifications.error({
          text: `Could not transfer ownership to ${owner.name}`,
          error,
        })
      }
    },
  },
})
</script>
